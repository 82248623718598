/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import MakeByBeit from '../MakeByBeit';
import ProcinalBlanco from '../../assets/images/logo-white.png';
import {
  changeModalLogin,
  changeModalRegister,
  userLogout,
} from '../../store/actions/UserActions';
import './Footer.css';
import BePass from '../../assets/images/bepass.png';
import AppStore from '../../assets/images/app-store.png';
import GooglePlay from '../../assets/images/google-play.png';

function Footer(props) {
  const {
    userReducer: { user },
    match: {
      params: { city },
    },
    changeModalLogin,
    changeModalRegister,
    userLogout,
  } = props;

  const cityName = city ? city : 'bogota';

  return (
    <>
      <div className="vertical-gradient Footer_apps">
        <h3>Descarga el app en:</h3>
        <div className="Footer_apps-menu">
          <a
            href="https://apps.apple.com/co/app/procinal/id1643611249"
            target="_blank"
            rel="noreferrer"
          >
            <img src={AppStore} alt="AppStore" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.co.procinal"
            target="_blank"
            rel="noreferrer"
          >
            <img src={GooglePlay} alt="AppStore" />
          </a>
        </div>
      </div>
      <footer className="Footer">
        <div className="Footer-container">
          <div className="Footer-row">
            <img src={ProcinalBlanco} alt="procinal logo" />
            <div className="Footer-social-title">SÍGUENOS</div>
            <div className="Footer-social">
              <a
                href="https://www.facebook.com/procinal"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-facebook-f" />
              </a>
              <a
                href="https://www.instagram.com/procinal/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-instagram" />
              </a>
              <a
                href="https://twitter.com/cineprocinal"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-twitter" />
              </a>
              <a
                href="https://www.youtube.com/user/CinemaProcinal"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-youtube" />
              </a>
            </div>
          </div>
          <div className="Footer-row">
            <div className="Footer-menu">
              <h5>PROCINAL BOGOTÁ</h5>
              <Link to="/">Inicio</Link>
              <Link to="/corporativo">Información Corporativa</Link>
              <Link to="/terminos-y-condiciones">Términos y Condiciones</Link>
              <Link to="/contactanos">Contáctanos</Link>
              {user ? (
                <>
                  <Link to="/perfil">Mi Perfil</Link>
                  <Link to="/tickets">Mis Tickets</Link>
                  <div onClick={userLogout}>Cerrar sesión</div>
                </>
              ) : (
                <>
                  <div onClick={() => changeModalLogin('visible')}>
                    Iniciar sesión
                  </div>
                  <div onClick={() => changeModalRegister('visible')}>
                    Registrarme
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="Footer-row">
            <div className="Footer-menu">
              <h5>SERVICIOS</h5>
              {/* <Link to="/confiteria">Nuestra Confitería</Link> */}
              <Link to="/corporativo/ventas-empresariales">
                Ventas Empresariales
              </Link>
              <Link to="/corporativo/alquiler-de-salas">Alquiler de Salas</Link>
              <Link to="/corporativo/funciones-privadas">
                Funciones Privadas
              </Link>
              <Link to="/corporativo/pauta-publicitaria">
                Pauta Publicitaria
              </Link>
              {/* <Link to="/corporativo/bonos-regalo">Bonos Regalo</Link> */}
            </div>
          </div>
          <div className="Footer-row">
            <div className="Footer-menu">
              <h5>PELÍCULAS</h5>
              <Link to={`/ciudad/${cityName}/cartelera`}>En Cartelera</Link>
              <Link to="/proximos-estrenos">Próximos Estrenos</Link>
            </div>
          </div>
          <div className="Footer-row">
            <div className="Footer-menu">
              <h6>Powered By</h6>
              <a href="https://bepass.co/" target="_blank" rel="noreferrer">
                <img className="Footer-bepass" src={BePass} alt="bypass logo" />
              </a>
            </div>
          </div>
        </div>
      </footer>
      <MakeByBeit />
    </>
  );
}

const mapStateToProps = ({ userReducer }) => {
  return {
    userReducer,
  };
};

const mapDispatchToProps = {
  changeModalLogin,
  changeModalRegister,
  userLogout,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));
