import { POP_UP_IMAGE, POP_UP_VISIBLE, POP_UP_WIDTH } from '../constants/PopUp';

export const popUpChangeVisible = (payload) => (dispatch) => {
  dispatch({
    type: POP_UP_VISIBLE,
    payload,
  });
};

export const popUpChangeImage = (payload) => (dispatch) => {
  dispatch({
    type: POP_UP_IMAGE,
    payload,
  });
};

export const popUpChangeWidth = (payload) => (dispatch) => {
  dispatch({
    type: POP_UP_WIDTH,
    payload,
  });
};
