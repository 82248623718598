import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import { confectioneryClearCache } from '../../store/actions/ConfectioneryActions';
import { changeVisible } from '../../store/actions/PaymentActions';
import { cinemasChangeVisible } from '../../store/actions/CinemasActions';
import './CountDown.css';

function CountDown(props) {
  const {
    endDate,
    confectioneryClearCache,
    onEnd,
    changeVisible,
    cinemasChangeVisible,
  } = props;
  const [timer, setTimer] = useState('00:00:00');

  useEffect(() => {
    let start = Date.now();
    // const timestamp = endDate._seconds || endDate.seconds || null;
    let end = endDate;
    // end = end * 1000;
    let resDate = (end - start + 1000) / 1000;
    let interval = null;

    if (resDate > 1) {
      interval = setInterval(() => {
        counterStart(interval, end);
      }, 1000);
    } else {
      setTimer('00:00:00');
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  const counterStart = (interval, end) => {
    const start = Date.now();
    const resDate = (end - start + 1000) / 1000;
    if (resDate > 1) {
      let seconds = `${Math.floor(resDate % 60)}`.padStart(2, 0);
      let minutes = `${Math.floor((resDate / 60) % 60)}`.padStart(2, 0);
      let hours = `${Math.floor((resDate / (60 * 60)) % 24)}`.padStart(2, 0);
      setTimer(`${hours}:${minutes}:${seconds}`);
    } else if (interval) {
      clearInterval(interval);
      setTimer('00:00:00');
      changeVisible('');
      cinemasChangeVisible('');
      if (confectioneryClearCache) {
        swal('Upsss!!!', 'Se termino el tiempo de la reserva.', 'warning').then(
          () => {
            confectioneryClearCache(true);
            if (onEnd) {
              onEnd();
            }
          }
        );
      }
    }
  };

  return (
    <div className="CountDown_container">
      <div className="CountDown_time">Tiempo restante:</div>
      <div className="CountDown_counter">{timer}</div>
    </div>
  );
}

CountDown.defaultProps = {
  onEnd: null,
};

const mapDispatchToProps = {
  confectioneryClearCache,
  changeVisible,
  cinemasChangeVisible,
};

export default connect(null, mapDispatchToProps)(CountDown);
