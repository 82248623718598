import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  contentGetDetail,
  contentDetail,
  contentLoading,
} from '../../store/actions/ContentActions';
import { globalGetCinemas } from '../../store/actions/GlobalActions';
import PageLoader from '../PageLoader';
import './Contact.css';

class Contact extends Component {
  componentDidMount() {
    const {
      globalReducer: { global_cinema_list },
      globalGetCinemas,
      contentGetDetail,
    } = this.props;
    if (!global_cinema_list.length) {
      globalGetCinemas();
    }
    contentGetDetail('contactanos');
  }

  componentWillUnmount() {
    const { contentDetail, contentLoading } = this.props;
    contentDetail(null);
    contentLoading(true);
  }

  render() {
    const {
      contentReducer: { content_detail, content_loading },
    } = this.props;

    if (content_loading) {
      return <PageLoader />;
    }

    return (
      <div className="Contact-container">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <h1 className="Content-title">{content_detail.title}</h1>
              <div
                className="Content-content"
                dangerouslySetInnerHTML={{ __html: content_detail.content }}
              />
            </div>
          </div>
        </div>
        <div className="CorporativeForm-container white">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <iframe
                  className="CorporativeForm-iframe"
                  id="fluentform"
                  frameBorder="0"
                  src="https://procinal.believepro.co/?ff_landing=3&embedded=2"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ globalReducer, contentReducer, contactReducer }) => {
  return {
    globalReducer,
    contentReducer,
    contactReducer,
  };
};

const mapDispatchToProps = {
  contentGetDetail,
  contentDetail,
  contentLoading,
  globalGetCinemas,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
