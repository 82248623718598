import React, { Component } from 'react';
import { connect } from 'react-redux';
import { contentGetDetail } from '../../store/actions/ContentActions';
import './ImaxHeader.css';
import logo from '../../assets/images/logo-white.png';
import imaxLogo from '../../assets/images/imax/logo.png';
import circle01 from '../../assets/images/imax/circle-01.png';
import circle02 from '../../assets/images/imax/circle-02.png';
import circle03 from '../../assets/images/imax/circle-03.png';

class ImaxHeader extends Component {
  state = {
    movie: null,
  };

  componentDidMount() {
    const {
      contentReducer: { content_detail },
      contentGetDetail,
    } = this.props;

    if (!content_detail) {
      contentGetDetail('imax-video');
    }

    this.setCurrentMovie();
  }

  setCurrentMovie = () => {
    const {
      theaterReducer: { theater_movies, theater_date },
    } = this.props;

    let currentMovie = null;

    theater_movies.forEach((movie) => {
      movie.list.forEach((showtime) => {
        let { list_showtimes } = showtime;

        let currentShowTimes = list_showtimes.filter(
          (showtime) => showtime.fecha_funcion === theater_date
        );

        if (currentShowTimes.length) {
          currentMovie = movie;
          return;
        }
      });
    });

    this.setState({ movie: currentMovie });
  };

  getVideo = (trailer) => {
    let videoId = trailer.split('/');
    videoId = videoId[videoId.length - 1];
    if (videoId.indexOf('watch') >= 0) {
      videoId = videoId.split('v=');
      videoId = videoId[videoId.length - 1];
    }

    return (
      // eslint-disable-next-line jsx-a11y/iframe-has-title
      <iframe
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    );
  };

  render() {
    const {
      contentReducer: { content_detail },
    } = this.props;
    const { movie } = this.state;

    return (
      <div className="ImaxHeader-container">
        <div className="Imax-content">
          <div className="container">
            <div className="row">
              <div className="col-md-6 Imax-logos">
                <img
                  src={imaxLogo}
                  alt="Imax Logo"
                  className="Imax-imax-logo"
                />
                <img src={logo} alt="Logo" className="Imax-logo" />
              </div>
              <div className="col-md-6 Imax-logos">
                {content_detail && (
                  <div
                    className="Imax-video"
                    dangerouslySetInnerHTML={{ __html: content_detail.content }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="Imax-box-title">
                  <h1 className="Imax-title-1">
                    LA EXPERIENCIA MAX EMOCIONANTE TE ESPERA​
                  </h1>
                  <h1 className="Imax-title-2">
                    EN EL IMAX MÁS GRANDE DE LATINOAMÉRICA:​
                  </h1>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 Imax-circle">
                <img src={circle01} alt="Circle 01" className="" />
                <span>603 SILLAS</span>
              </div>
              <div className="col-md-4 Imax-circle">
                <img src={circle02} alt="Circle 02" className="" />
                <span>
                  PANTALLA DE 30 METROS
                  <br />
                  QUE TE ENVUELVE EN LA PELÍCULA
                </span>
              </div>
              <div className="col-md-4 Imax-circle">
                <img src={circle03} alt="Circle 03" className="" />
                <span>
                  SONIDO ENVOLVENTE PARA QUE NO
                  <br />
                  TE PIERDAS NINGÚN DETALLE
                </span>
              </div>
            </div>
            {movie && (
              <div className="row">
                <div className="col-md-12">
                  <div className="Imax-billboard">HOY EN CARTELERA</div>
                  <div className="Imax-current-movie">
                    <img src={movie.imagen} alt={movie.titulo} />
                    <div className="Imax-movie-trailer">
                      <div className="ImaxHeader-iframe">
                        {this.getVideo(movie.trailer1)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ contentReducer, theaterReducer }) => {
  return {
    contentReducer,
    theaterReducer,
  };
};

const mapDispatchToProps = {
  contentGetDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImaxHeader);
