import React from 'react';
import radioChecked from '../../../assets/images/radio-checked.svg';
import radioCheck from '../../../assets/images/radio-check.svg';
import { getConfectioneryDetail } from '../../../Utils/confectioneryManager';
import { connect } from 'react-redux';

function ProductRecipe(props) {
  const {
    product,
    index,
    onSelectProduct,
    selected,
    productIndex,
    confectioneryReducer: { confectionery_api_products },
  } = props;
  const productApi = getConfectioneryDetail(
    product.Codigo,
    confectionery_api_products
  );

  if (!productApi) return null;

  return (
    <button
      className={`modalProduct_product modalProduct_product-${productIndex} ${selected}`}
      onClick={() => onSelectProduct(product, index)}
    >
      <div className="modalProduct_product-description">
        <div className="modalProduct_product-name">{productApi.name}</div>
      </div>
      <img
        src={selected === 'selected' ? radioChecked : radioCheck}
        alt="radioCheck"
      />
    </button>
  );
}

const mapStateToProps = ({ confectioneryReducer }) => {
  return {
    confectioneryReducer,
  };
};

export default connect(mapStateToProps, null)(ProductRecipe);
