import React from 'react';
import { connect } from 'react-redux';
import { subscriptionChangePriceRecharge } from '../../store/actions/SubscriptionActions';
import ReactSlider from 'react-slider';
import './SliderRecharge.css';

const {
  REACT_APP_STEP_RECHARGE,
  REACT_APP_MIN_RECHARGE,
  REACT_APP_MAX_RECHARGE,
} = process.env;

function SliderRecharge(props) {
  const {
    subscriptionReducer: { subscription_price_recharge },
    subscriptionChangePriceRecharge,
  } = props;
  const numberFormat = new Intl.NumberFormat('es-CO');

  return (
    <div className="form-group">
      <div>
        Valor a recargar:{' '}
        <strong className="SliderRecharge-price">
          $ {numberFormat.format(subscription_price_recharge)}
        </strong>
      </div>
      <ReactSlider
        className="SliderRecharge-slider"
        thumbClassName="SliderRecharge-thumb"
        trackClassName="SliderRecharge-track"
        defaultValue={parseInt(REACT_APP_MIN_RECHARGE) + 10000}
        step={parseInt(REACT_APP_STEP_RECHARGE)}
        min={parseInt(REACT_APP_MIN_RECHARGE)}
        max={parseInt(REACT_APP_MAX_RECHARGE)}
        onChange={(val) => subscriptionChangePriceRecharge(val)}
      />
    </div>
  );
}

const mapStateToProps = ({ subscriptionReducer }) => {
  return {
    subscriptionReducer,
  };
};

const mapDispatchToProps = {
  subscriptionChangePriceRecharge,
};

export default connect(mapStateToProps, mapDispatchToProps)(SliderRecharge);
