import { formatDate } from '../actions/GlobalActions';
import {
  THEATER_ALL_MOVIES,
  THEATER_CLEAR,
  THEATER_DATE,
  THEATER_DATE_MENU,
  THEATER_DETAIL,
  THEATER_LOADING,
  THEATER_MOVIES,
  THEATER_SCHEDULE,
} from '../constants/Theater';

let dates = [];

for (let i = 0; i < 7; i++) {
  let d = new Date();
  d.setDate(d.getDate() + i);

  dates.push(formatDate(d));
}

const INITIAL_STATE = {
  theater_schedule_menu: [
    {
      id: 'monday-thursday',
      name: 'Lunes y Jueves',
    },
    {
      id: 'tuesday-wednesday',
      name: 'Martes y Miércoles',
    },
    {
      id: 'weekeend',
      name: 'Viernes, Sábados Domingos y Festivos',
    },
  ],
  theater_loading: true,
  theater_detail: null,
  theater_all_movies: [],
  theater_movies: [],
  theater_date: dates[0].value,
  theater_date_menu: dates,
  theater_schedule: 'monday-thursday', // monday-thursday tuesday-wednesday - weekeend
};

const theaterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case THEATER_CLEAR:
      return {
        ...state,
        theater_loading: true,
        theater_detail: null,
        theater_movies: [],
        theater_date: dates[0].value,
        theater_date_menu: dates,
        theater_schedule: 'monday-thursday',
      };
    case THEATER_LOADING:
      return {
        ...state,
        theater_loading: action.payload,
      };
    case THEATER_DETAIL:
      return {
        ...state,
        theater_detail: action.payload,
      };
    case THEATER_ALL_MOVIES:
      return {
        ...state,
        theater_all_movies: action.payload,
      };
    case THEATER_MOVIES:
      return {
        ...state,
        theater_movies: action.payload,
      };
    case THEATER_DATE:
      return {
        ...state,
        theater_date: action.payload,
      };
    case THEATER_DATE_MENU:
      return {
        ...state,
        theater_date_menu: action.payload,
      };
    case THEATER_SCHEDULE:
      return {
        ...state,
        theater_schedule: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default theaterReducer;
