import swal from 'sweetalert';
import { getSettingValue } from '../../functions/currentFunctions';
import {
  getCombosToPay,
  getTotalCombos,
} from '../../Utils/confectioneryManager';
import {
  CHECKOUT_CHAIRS,
  CHECKOUT_CLEAR,
  CHECKOUT_DATE,
  CHECKOUT_ERROR,
  CHECKOUT_FACE_MASK,
  CHECKOUT_HOUR,
  CHECKOUT_HOURS,
  CHECKOUT_LOCATION,
  CHECKOUT_LOCATIONS,
  CHECKOUT_MOVIE,
  CHECKOUT_MOVIE_LOADING,
  CHECKOUT_OTHER_PRICE,
  CHECKOUT_PAY_OPTION,
  CHECKOUT_POPUP,
  CHECKOUT_RESERVATION,
  CHECKOUT_ROOM,
  CHECKOUT_ROOM_LOADING,
  CHECKOUT_ROOM_STATUS,
  CHECKOUT_SCORE_BILL,
  CHECKOUT_SHOWTIME,
  CHECKOUT_STEP,
  CHECKOUT_TOTAL,
  CHECKOUT_TOTAL_TIKETS,
  CHECKOUT_TOTAL_TRANSACTION,
} from '../constants/Checkout';
import Api from './Api';
import {
  confectioneryChangeCombos,
  confectioneryChangeDate,
  confectioneryTheaterDetail,
  onCancelReservation,
} from './ConfectioneryActions';
import { changeLoading } from './GlobalActions';
const { REACT_APP_LOCAL_STORAGE: LOCAL_STORAGE } = process.env;

export const checkoutClear = (payload) => (dispatch, getState) => {
  dispatch({
    type: CHECKOUT_CLEAR,
  });
};

export const changeStep = (payload) => (dispatch, getState) => {
  window.scrollTo(0, 0);
  dispatch({
    type: CHECKOUT_STEP,
    payload,
  });
};

export const changeLocations = (payload) => (dispatch, getState) => {
  dispatch({
    type: CHECKOUT_LOCATIONS,
    payload,
  });
};

export const checkoutChangeRoomLoading = (payload) => (dispatch) => {
  dispatch({
    type: CHECKOUT_ROOM_LOADING,
    payload,
  });
};

export const checkoutChangeRoomStatus = (payload) => (dispatch) => {
  dispatch({
    type: CHECKOUT_ROOM_STATUS,
    payload,
  });
};

export const changeMovieLoading = (payload) => (dispatch) => {
  dispatch({
    type: CHECKOUT_MOVIE_LOADING,
    payload,
  });
};

export const changeCheckoutError = (payload) => (dispatch) => {
  dispatch({
    type: CHECKOUT_ERROR,
    payload,
  });
};

export const changeCheckoutMovie = (payload) => (dispatch) => {
  dispatch({
    type: CHECKOUT_MOVIE,
    payload,
  });
};

export const getRoom = (movie_id, payload) => (dispatch, getState) => {
  const { user } = getState().userReducer;

  const { global_movies } = getState().globalReducer;

  dispatch({
    type: CHECKOUT_ROOM_LOADING,
    payload: true,
  });

  const movie = global_movies.filter(
    (movie) => parseInt(movie.id) === parseInt(movie_id)
  );

  if (movie.length) {
    const showtime = movie[0].showtimes.filter(
      (showtime) => parseInt(showtime.id) === parseInt(payload)
    );

    dispatch({
      type: CHECKOUT_MOVIE,
      payload: movie[0],
    });
    setTimeout(() => {
      dispatch({
        type: CHECKOUT_MOVIE_LOADING,
        payload: false,
      });
    }, 250);

    if (showtime.length) {
      dispatch({
        type: CHECKOUT_SHOWTIME,
        payload: showtime[0],
      });

      Api.callMethod(
        `showtimes/${payload}?email=${user.email}`,
        'GET',
        null,
        (res) => {
          dispatch({
            type: CHECKOUT_ROOM_LOADING,
            payload: false,
          });
          dispatch({
            type: CHECKOUT_LOCATIONS,
            payload: res.prices,
          });
          dispatch({
            type: CHECKOUT_ROOM,
            payload: res.mapRoom,
          });
          dispatch({
            type: CHECKOUT_ROOM_STATUS,
            payload: res.statusRoom,
          });
          dispatch({
            type: CHECKOUT_SCORE_BILL,
            payload: res.bill,
          });
        },
        (error) => {
          console.log('Error getRoom: ', error);
          swal(
            'Upsss!!!',
            'Hubo un error al momento de solictar los precios, vuelve a intetarlo más tarde.',
            'error'
          ).then(() => {
            dispatch({
              type: CHECKOUT_ROOM_LOADING,
              payload: false,
            });
            dispatch({
              type: CHECKOUT_ERROR,
              payload: true,
            });
          });
        }
      );
      return null;
    }
  }
  dispatch({
    type: CHECKOUT_ERROR,
    payload: true,
  });
};

export const checkoutChangeChairs = (payload) => (dispatch) => {
  dispatch({
    type: CHECKOUT_CHAIRS,
    payload,
  });
};

export const checkoutChangeMovie = (payload) => (dispatch) => {
  dispatch({
    type: CHECKOUT_MOVIE,
    payload,
  });
  setTimeout(() => {
    dispatch({
      type: CHECKOUT_MOVIE_LOADING,
      payload: false,
    });
  }, 250);
};

export const changeLocation = (payload, clearChairs = true) => (dispatch) => {
  if (clearChairs) dispatch(checkoutChangeChairs([]));
  dispatch({
    type: CHECKOUT_LOCATION,
    payload,
  });
};

export const changeHour = (payload) => (dispatch, getState) => {
  dispatch({
    type: CHECKOUT_HOUR,
    payload,
  });
  dispatch(changeLocation(null));
  if (payload) {
    dispatch(getRoom());
  }
};

export const changeDate = (payload, room_id) => (dispatch, getState) => {
  const {
    global_movie: { showtimes },
  } = getState().globalReducer;
  const hours = showtimes.filter(
    (item) =>
      item.fecha_funcion === payload && parseInt(item.room_id) === room_id
  );

  dispatch(changeHour(null));
  dispatch({
    type: CHECKOUT_DATE,
    payload,
  });
  dispatch({
    type: CHECKOUT_HOURS,
    payload: hours,
  });
};

export const changeRoom = (payload) => (dispatch) => {
  dispatch({
    type: CHECKOUT_ROOM,
    payload,
  });
};

export const checkoutChangeTotalTickets = (payload) => (dispatch) => {
  dispatch({
    type: CHECKOUT_TOTAL_TIKETS,
    payload,
  });
};

export const checkoutChangeTotal = (payload) => (dispatch) => {
  dispatch({
    type: CHECKOUT_TOTAL,
    payload,
  });
};

export const checkoutChangeTotalTransaction = (payload) => (dispatch) => {
  dispatch({
    type: CHECKOUT_TOTAL_TRANSACTION,
    payload,
  });
};

export const checkoutChangeOtherPrice = (payload) => (dispatch) => {
  dispatch({
    type: CHECKOUT_OTHER_PRICE,
    payload,
  });
};

export const addOrRemoveChair = (payload) => (dispatch, getState) => {
  const {
    checkout_chairs,
    checkout_location,
    checkout_score_bill,
    checkout_pay_option,
    checkout_locations,
  } = getState().checkoutReducer;

  const section =
    checkout_pay_option === 'Deshabilitado' ? 'Habilitado' : 'Deshabilitado';
  const otherLocation = checkout_locations.find(
    (location) =>
      location.ClienteFrecuente === section &&
      location.zona === checkout_location.zona
  );

  // const chairs = localStorage.getItem('@PROCINAL:chair');
  const chairs = null;
  let listChairs = checkout_chairs;

  if (chairs) {
    listChairs = JSON.parse(chairs);
  }
  const transactionValue = checkout_score_bill[0].Recargo_Venta_Internet;
  const index = listChairs.indexOf(payload);
  const price = checkout_location;

  if (index >= 0) {
    listChairs.splice(index, 1);
  } else {
    listChairs.push(payload);
  }

  const totalTikets = parseInt(listChairs.length) * parseInt(price.valor);
  const totalTransaction =
    parseInt(transactionValue) * parseInt(listChairs.length);
  const total = parseInt(totalTikets) + parseInt(totalTransaction);

  if (otherLocation) {
    const otherTikets =
      parseInt(listChairs.length) * parseInt(otherLocation.valor);
    const otherTotal = parseInt(otherTikets) + parseInt(totalTransaction);
    // Differential value
    let otherPrice = total - otherTotal;
    otherPrice = otherPrice < 0 ? otherPrice * -1 : otherPrice;
    dispatch(checkoutChangeOtherPrice(otherPrice));
  }
  // localStorage.setItem('@PROCINAL:chair', JSON.stringify(listChairs));

  dispatch(checkoutChangeChairs(listChairs));
  dispatch(checkoutChangeTotalTickets(totalTikets));
  dispatch(checkoutChangeTotal(total));
  dispatch(checkoutChangeTotalTransaction(totalTransaction));
};

export const changePopup = (payload) => (dispatch) => {
  dispatch({
    type: CHECKOUT_POPUP,
    payload,
  });
};

export const checkoutChangePayOption = (payload, clearData = true) => (
  dispatch
) => {
  dispatch({
    type: CHECKOUT_PAY_OPTION,
    payload,
  });

  if (clearData) {
    dispatch(changeLocation(null));
    dispatch(checkoutChangeChairs([]));
    dispatch(checkoutChangeTotalTickets(0));
    dispatch(checkoutChangeTotal(0));
    dispatch(checkoutChangeOtherPrice(0));
    dispatch(checkoutChangeTotalTransaction(0));
  }
};

export const changeScoreBill = (payload) => (dispatch) => {
  dispatch({
    type: CHECKOUT_SCORE_BILL,
    payload,
  });
};

export const changeShowtime = (payload) => (dispatch) => {
  dispatch({
    type: CHECKOUT_SHOWTIME,
    payload,
  });
};

export const changeFaceMask = (payload) => (dispatch) => {
  dispatch({
    type: CHECKOUT_FACE_MASK,
    payload,
  });
};

export const changeCheckoutReservation = (payload) => (dispatch) => {
  dispatch({
    type: CHECKOUT_RESERVATION,
    payload,
  });
};

export const getMapRoom = (chairs = null) => (dispatch, getState) => {
  const { checkout_showtime } = getState().checkoutReducer;
  const { user } = getState().userReducer;

  dispatch({
    type: CHECKOUT_ROOM,
    payload: null,
  });

  if (checkout_showtime && user) {
    Api.callMethod(
      `showtimes/room/${checkout_showtime.id}?email=${user.email}`,
      'GET',
      null,
      (res) => {
        dispatch({
          type: CHECKOUT_ROOM,
          payload: res.mapRoom,
        });
        dispatch({
          type: CHECKOUT_ROOM_STATUS,
          payload: res.statusRoom,
        });

        if (chairs) {
          dispatch(checkoutChangeChairs(chairs));
        }
      },
      (error) => {
        console.log('Error getRoom: ', error);
      }
    );
  }
};

export const checkoutReservation = (
  theater,
  pathname = '',
  loading = false
) => (dispatch, getState) => {
  const {
    checkout_pay_option,
    checkout_total_tikets,
    checkout_total_transaction,
    checkout_total,
    checkout_chairs,
    checkout_room,
    checkout_locations,
    checkout_room_status,
    checkout_location,
    checkout_showtime,
    checkout_score_bill,
    checkout_movie,
  } = getState().checkoutReducer;
  const {
    confectionery_combos,
    confectionery_api_products,
  } = getState().confectioneryReducer;
  const { global_settings } = getState().globalReducer;

  if (!checkout_chairs.length) {
    swal(
      'Upsss!!!',
      'Para continuar debes seleccionar mínimo una silla.',
      'warning'
    );
    return;
  }

  let products = [];
  let products_bepass = [];
  let description = '';
  let combosTotal = 0;
  const combosService = parseInt(
    getSettingValue(global_settings, 'confiteria_price_service', 500)
  );

  let total = checkout_total || 0;
  let total_transaction = checkout_total_transaction || 0;
  let subtotal = checkout_total_tikets || 0;

  //   Validate if confectionery exist
  if (confectionery_combos.length) {
    products = getCombosToPay(
      confectionery_combos,
      checkout_pay_option,
      confectionery_api_products
    );
    description = products.description;
    products_bepass = products.combosBepass;
    products = products.combos;
    combosTotal = getTotalCombos(confectionery_combos);
    combosTotal =
      checkout_pay_option === 'Deshabilitado'
        ? combosTotal.totalOthers
        : combosTotal.totalCinemas;

    total = total + combosTotal + combosService;
    total_transaction = total_transaction + combosService;
    subtotal = subtotal + combosTotal;
  }

  const {
    ColumnaRelativa,
    ColumnaTotal,
    FilaRelativa,
    FilaTotal,
  } = checkout_room;
  const price = checkout_location;

  const chairStorage = null;
  let listChairs = checkout_chairs;

  if (chairStorage) {
    listChairs = JSON.parse(chairStorage);
  }

  const chairs = listChairs.map((item) => ({
    Fila: FilaTotal[item],
    Columna: ColumnaTotal[item],
    FilRelativa: FilaRelativa[item],
    ColRelativa: ColumnaRelativa[item],
    Tarifa: price.codigo,
  }));

  const dataReservation = {
    showtime: checkout_showtime.id,
    chairs: chairs,
    products,
    total: total,
    total_transaction: total_transaction,
    subtotal: subtotal,
    cash_payment: 0,
    score_bill: checkout_score_bill[0].Secuencia,
    is_member: checkout_pay_option !== 'Deshabilitado',
    products_bepass,
    description,
  };

  dispatch(confectioneryChangeDate(0));
  dispatch(changeCheckoutReservation(false));

  let confectionerySection = document.getElementById('Checkout_cinema');

  const onPost = () =>
    Api.callMethod(
      'auth/payment/reservation',
      'POST',
      dataReservation,
      (res) => {
        if (res.code === 200) {
          let date = new Date();
          date = date.getTime() + 10 * 60000;
          const storageData = {
            theater,
            checkout_pay_option,
            checkout_total_tikets,
            checkout_total_transaction,
            checkout_total,
            checkout_chairs,
            checkout_room,
            checkout_location,
            checkout_locations,
            checkout_room_status,
            checkout_showtime,
            checkout_score_bill,
            checkout_movie,
            date,
            pathname,
          };
          localStorage.setItem(
            `${LOCAL_STORAGE}::ticketConfectionery`,
            JSON.stringify(storageData)
          );
          dispatch(confectioneryChangeDate(date));
          dispatch(changeCheckoutReservation(true));
          if (confectionerySection) {
            confectionerySection.scrollIntoView({ behavior: 'smooth' }, true);
          }
          if (loading) dispatch(changeLoading('', ''));
        } else {
          dispatch(checkoutChangeChairs([]));
          dispatch(getMapRoom());
          swal(
            'Upsss!!!',
            'Las sillas seleccionadas ya están ocupadas, selecciona otras.',
            'warning'
          ).then(() => {
            if (loading) dispatch(changeLoading('', ''));
          });
        }
      },
      (error) => {
        console.log('Error checkoutReservation: ', error);
        dispatch(checkoutChangeChairs([]));
        dispatch(getMapRoom());
        swal(
          'Upsss!!!',
          'Las sillas seleccionadas ya están ocupadas, selecciona otras.',
          'warning'
        ).then(() => {
          if (loading) dispatch(changeLoading('', ''));
        });
      }
    );

  if (loading) {
    dispatch(changeLoading('visible', 'Reservando...'));
    setTimeout(() => {
      onPost();
    }, 1000);
  } else {
    onPost();
  }
};

export const checkoutRemoveStorage = () => (dispatch) => {
  console.log('Removemos el caché');
  localStorage.removeItem(`${LOCAL_STORAGE}::ticketConfectionery`);
  localStorage.removeItem(`${LOCAL_STORAGE}::confectionery`);
  dispatch(onCancelReservation());
};

export const checkoutCacheReservation = (data, refresh = false) => (
  dispatch
) => {
  dispatch(checkoutChangePayOption(data.checkout_pay_option, refresh));
  dispatch(checkoutChangeMovie(data.checkout_movie));
  //   dispatch(changeRoom(data.checkout_room));
  //   dispatch(checkoutChangeRoomStatus(data.checkout_room_status));
  dispatch(changeLocation(data.checkout_location, refresh));
  dispatch(changeLocations(data.checkout_locations));
  dispatch(changeScoreBill(data.checkout_score_bill));
  dispatch(changeShowtime(data.checkout_showtime));
  dispatch(checkoutChangeTotalTickets(data.checkout_total_tikets));
  dispatch(checkoutChangeTotal(data.checkout_total));
  dispatch(checkoutChangeTotalTransaction(data.checkout_total_transaction));
  if (data.combos) {
    dispatch(confectioneryChangeCombos(data.combos));
  }
  dispatch(confectioneryChangeDate(data.date));
  dispatch(confectioneryTheaterDetail(data.theater));
  dispatch(getMapRoom(data.checkout_chairs));
};
