import React from 'react';
import { connect } from 'react-redux';
import {
  changeMethod,
  changePseTypePerson,
  changePseBank,
  changePseNameAndLastName,
  changePseDocType,
  changePseDocNumber,
  changePseEmail,
  changePsePhone,
  validationPSE,
} from '../../../store/actions/PaymentActions';
import { ticketPayToPSE } from '../../../store/actions/PayTicketActions';

function PSEForm(props) {
  const {
    paymentReducer: {
      payment_pse_type_person,
      payment_pse_bank,
      payment_pse_nameAndLastName,
      payment_pse_doc_type,
      payment_pse_doc_number,
      payment_pse_email,
      payment_pse_phone,
      payment_banks,
      payment_type,
    },
    changeMethod,
    changePseTypePerson,
    changePseBank,
    changePseNameAndLastName,
    changePseDocType,
    changePseDocNumber,
    changePseEmail,
    changePsePhone,
    validationPSE,
    ticketPayToPSE,
  } = props;
  const personsType = [
    { id: 0, name: 'Persona natural' },
    { id: 1, name: 'Persona jurídica' },
  ];

  return (
    <>
      <div className="PaymentMethods_header">
        <button
          className="PaymentMethods_back"
          type="button"
          onClick={() => changeMethod('')}
        >
          <i className="fas fa-chevron-left" />
          Cambiar medio de pago
        </button>
      </div>
      <div className="PaymentMethods_form">
        <span className="PaymentMethods_step">Paso 2 de 2</span>
        <h5>Completa la información</h5>
        <div className="PaymentMethods_radio">
          {personsType.map((item) => (
            <label key={item.id}>
              <input
                type="radio"
                name="type-person"
                value={payment_pse_type_person}
                checked={item.id === payment_pse_type_person}
                onChange={() => changePseTypePerson(item.id)}
              />
              {item.name}
            </label>
          ))}
        </div>
        <div className="PaymentMethods_box-input">
          <select
            value={payment_pse_bank}
            onChange={(input) => changePseBank(input.target.value)}
          >
            {payment_banks.map((item) => (
              <option value={item.bankCode} key={item.bankCode}>
                {item.bankName}
              </option>
            ))}
          </select>
        </div>
        <div className="PaymentMethods_box-input">
          <input
            type="text"
            placeholder="Nombre y apellidos"
            value={payment_pse_nameAndLastName}
            onChange={(input) => changePseNameAndLastName(input.target.value)}
          />
        </div>
        <div className="PaymentMethods_row-input">
          <div className="PaymentMethods_box-input">
            <select
              value={payment_pse_doc_type}
              onChange={(input) => changePseDocType(input.target.value)}
            >
              <option value="CC">CC</option>
              <option value="NIT">NIT</option>
              <option value="CE">CE</option>
              <option value="PPN">PPN</option>
            </select>
          </div>
          <div className="PaymentMethods_box-input PaymentMethods_doc-number">
            <input
              type="number"
              placeholder="Número de Documento"
              value={payment_pse_doc_number}
              onChange={(input) => changePseDocNumber(input.target.value)}
            />
          </div>
        </div>
        <div className="PaymentMethods_box-input">
          <input
            type="email"
            placeholder="Email"
            value={payment_pse_email}
            onChange={(input) => changePseEmail(input.target.value)}
          />
        </div>
        <div className="PaymentMethods_box-input">
          <input
            type="number"
            placeholder="Número de móvil"
            value={payment_pse_phone}
            onChange={(input) => changePsePhone(input.target.value)}
          />
        </div>
      </div>
      <button
        onClick={payment_type !== 6 ? validationPSE : ticketPayToPSE}
        type="button"
        className="PaymentMethods_continue"
      >
        CONTINUAR
      </button>
    </>
  );
}

const mapStatToProps = ({ paymentReducer }) => {
  return {
    paymentReducer,
  };
};

const mapDisptachToProps = {
  changeMethod,
  changePseTypePerson,
  changePseBank,
  changePseNameAndLastName,
  changePseDocType,
  changePseDocNumber,
  changePseEmail,
  changePsePhone,
  validationPSE,
  ticketPayToPSE,
};

export default connect(mapStatToProps, mapDisptachToProps)(PSEForm);
