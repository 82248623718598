import React from 'react';
import { connect } from 'react-redux';
import { checkoutChangePayOption } from '../../../store/actions/CheckoutActions';
import { changeVisible } from '../../../store/actions/PaymentActions';
import { getSetting } from '../../../Utils/currentActions';

function PayMethods(props) {
  const {
    checkoutReducer: { checkout_pay_options, checkout_pay_option },
    userReducer: { user_score, user_score_balance, user_score_loading },
    globalReducer: { global_settings },
    checkoutChangePayOption,
    changeVisible,
  } = props;

  const numberFormat = new Intl.NumberFormat('es-CO');

  const checkoutMobile = getSetting(
    global_settings,
    'cinemas_checkout_image_mobile'
  );
  const checkoutDesktop = getSetting(global_settings, 'cinemas_checkout_image');

  return (
    <>
      <div className="Checkout_image">
        {!user_score_loading &&
        (!user_score ||
          (user_score.Estado === 'Usuario sin tarjeta activa' &&
            checkoutMobile &&
            checkoutDesktop)) ? (
          <button
            type="button"
            className="Checkout_banner"
            onClick={() => changeVisible('visible', 3)}
          >
            <picture>
              <source media="(max-width: 768px)" srcSet={checkoutMobile} />
              <img src={checkoutDesktop} alt="Checkout Header" />
            </picture>
          </button>
        ) : null}
      </div>
      <h5>Selecciona el medio de Pago:</h5>
      <div className="Checkout_box-selected">
        <div className="Checkout_row-selected">
          {checkout_pay_options.map((option, index) => {
            const selected =
              option.id === checkout_pay_option ? 'selected' : null;

            if (
              (!user_score ||
                user_score.Estado === 'Usuario sin tarjeta activa') &&
              option.id === 'Habilitado'
            ) {
              return (
                <button
                  type="button"
                  key={index}
                  className={`Checkout_location`}
                  onClick={() => changeVisible('visible', 3)}
                >
                  Afiliate a CINE+
                </button>
              );
            }

            return (
              <button
                type="button"
                key={index}
                className={`Checkout_location ${selected}`}
                onClick={() => checkoutChangePayOption(option.id)}
              >
                <div dangerouslySetInnerHTML={{ __html: option.name }} />
                {user_score_balance && option.id === 'Habilitado' ? (
                  <div className="small">
                    Tu saldo:
                    <br />
                    <strong>
                      $ {numberFormat.format(user_score_balance.Saldo_Total)}
                    </strong>
                  </div>
                ) : null}
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ checkoutReducer, userReducer, globalReducer }) => {
  return {
    checkoutReducer,
    userReducer,
    globalReducer,
  };
};

const mapDispatchToProps = {
  checkoutChangePayOption,
  changeVisible,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayMethods);
