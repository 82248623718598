import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import './CorporativeHeader.css';

function CorporativeHeader(props) {
  const {
    corporativeReducer: { corporative_list },
  } = props;

  return (
    <div className="CorporativeHeader-content">
      {corporative_list.map((item) => (
        <NavLink
          to={`/corporativo/${item.label}`}
          key={item.id}
          activeClassName="active"
        >
          <div className="CorporativeHeader-link">{item.title}</div>
          <div className="CorporativeHeader-active" />
        </NavLink>
      ))}
    </div>
  );
}

const mapStateToProps = ({ corporativeReducer }) => {
  return {
    corporativeReducer,
  };
};

export default connect(mapStateToProps, null)(CorporativeHeader);
