import React, { Component } from 'react';
import { connect } from 'react-redux';
import TicketCheckout from '../../components/TicketCheckout';
import DefaultLayout from '../../Layouts/DefaultLayout';
import {
  changeCity,
  changeMovieCity,
  changeMovieSlug,
} from '../../store/actions/GlobalActions';
import PageLoader from '../../components/PageLoader';

class TicketCheckoutPage extends Component {
  componentDidMount() {
    const {
      globalReducer: { global_movies },
      match: {
        params: { city, movie },
      },
      changeCity,
      changeMovieCity,
      changeMovieSlug,
    } = this.props;

    changeMovieCity(city);
    changeMovieSlug(movie);

    if (!global_movies.length) {
      changeCity(city);
    }
  }

  render() {
    const {
      globalReducer: { global_movies },
    } = this.props;
    return (
      <DefaultLayout>
        {global_movies.length ? <TicketCheckout /> : <PageLoader />}
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ globalReducer }) => {
  return {
    globalReducer,
  };
};

const mapDispatchToProps = {
  changeCity,
  changeMovieCity,
  changeMovieSlug,
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketCheckoutPage);
