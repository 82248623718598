import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changeCity, changeMovieCity } from '../../store/actions/GlobalActions';
import Benefits from '../Benefits';
import HowToBuy from '../HowToBuy';
import MovieList from '../MovieList';

class Billboard extends Component {
  componentDidMount() {
    const {
      globalReducer: { global_movies },
      match: {
        params: { city },
      },
      changeCity,
      changeMovieCity,
    } = this.props;

    changeMovieCity(city);

    if (!global_movies.length) {
      changeCity(city);
    }
  }

  render() {
    const {
      match: {
        params: { city },
      },
    } = this.props;

    return (
      <>
        <MovieList section="Cartelera" link={`/ciudad/${city}`} />
        <HowToBuy />
        <Benefits />
      </>
    );
  }
}

const mapStateToProps = ({ globalReducer }) => {
  return {
    globalReducer,
  };
};

const mapDispatchToProps = {
  changeCity,
  changeMovieCity,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Billboard)
);
