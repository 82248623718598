import React from 'react';
import DefaultLayout from '../../Layouts/DefaultLayout';
import './PoliciesPage.css';

function PoliciesPage() {
  return (
    <DefaultLayout>
      <div className="container PoliciesPage">
        <div className="row">
          <div className="col-md-12">
            <h1>
              POLÍTICA PARA EL TRATAMIENTO DE LA INFORMACIÓN Y MANUAL INTERNO
              PARA GARANTIZAR EL CUMPLIMIENTO DE LA LEY ESTATUTARIA SOBRE LA
              PROTECCIÓN DE DATOS PERSONALES
            </h1>
            <h2>I. CONSIDERACIONES GENERALES </h2>
            <p>
              El artículo 15 de la Constitución Política de la República de
              Colombia consagra el derecho de cualquier persona de conocer,
              actualizar y rectificar la información que exista sobre ella en
              bancos de datos o archivos de entidades públicas o privadas.
              Igualmente, ordena a quienes tienen datos personales de terceros a
              respetar los derechos y garantías previstos en la Constitución
              cuando se recolecta, trata y circula esta clase de información.
            </p>
            <p>
              La Ley Estatutaria 1581 del 17 de octubre de 2012 (en adelante, la
              “Ley 1581”) establece las condiciones mínimas para realizar el
              tratamiento legítimo de los datos personales de clientes,
              empleados y cualquier otra persona natural. Tanto los literales k)
              del artículo 17 como f) del artículo 18 de dicha ley obligan a los
              responsables y encargados del tratamiento de datos personales a
              “adoptar un manual interno de políticas y procedimientos para
              garantizar el adecuado cumplimiento de la presente ley y, en
              especial, para la atención de consultas y reclamos por parte de
              los titulares”.
            </p>
            <p>
              El artículo 25 de la misma ley ordena que las políticas de
              tratamiento de la información sean de obligatorio cumplimiento y
              establece que su desconocimiento acarreará sanciones. Dichas
              políticas no pueden garantizar un nivel de tratamiento inferior al
              establecido en la Ley 1581.
            </p>
            <p>
              El capítulo III del Decreto 1377 del 27 de junio de 2013 (en
              adelante, el “Decreto 1377”) reglamenta algunos aspectos
              relacionados con el contenido y requisitos de las políticas de
              tratamiento de la información y los avisos de privacidad.
            </p>
            <p>
              PROCINAL BOGOTA LTDA, MULTICINES S.A.S Y CINECITY S.A.S (grupo
              empresarial en desarrollo de la marca CINEMAS PROCINAL) están
              comprometidas con el respeto de los derechos de sus clientes,
              empleados y terceros en general. En consecuencia, adopta la
              siguiente política de tratamiento de la información de obligatoria
              aplicación en todas las actividades de la compañía que involucren
              el tratamiento de datos personales (en adelante, la “Política”).
            </p>
            <h2>II. OBLIGATORIEDAD </h2>
            <p>
              Esta Política es de obligatorio y estricto cumplimiento por parte
              de todos los empleados de PROCINAL BOGOTA LTDA, MULTICINES S.A.S Y
              CINECITY S.A.S (grupo empresarial en desarrollo de la marca
              CINEMAS PROCINAL), así como por los contratistas y terceros que
              obran en nombre de CINEMAS PROCINAL (cualquier sociedad del grupo
              empresarial).
            </p>
            <p>
              Todos los empleados de PROCINAL BOGOTA LTDA, MULTICINES S.A.S Y
              CINECITY S.A.S (grupo empresarial en desarrollo de la marca
              CINEMAS PROCINAL) deben observar y respetar esta Política en el
              cumplimiento de sus funciones. En los casos en que no exista
              vínculo laboral, se deberá incluir una cláusula contractual para
              que quienes obren en nombre de CINEMAS PROCINAL (cualquier
              sociedad del grupo empresarial) se obliguen a cumplir la Política.
            </p>
            <p>
              El incumplimiento de la misma originará sanciones de tipo laboral
              o responsabilidad contractual, según el caso. Lo anterior sin
              perjuicio del deber de responder patrimonialmente por los daños y
              perjuicios que se causen a los titulares de los datos o a PROCINAL
              BOGOTA LTDA, MULTICINES S.A.S Y CINECITY S.A.S (grupo empresarial
              en desarrollo de la marca CINEMAS PROCINAL) por el incumplimiento
              de la Política o el indebido tratamiento de datos personales.
            </p>
            <p>
              Para todos los efectos, este documento hará las veces de manual
              interno de políticas y procedimientos, así como de política de
              tratamiento de la información, a que se refieren los artículos 17
              (literal k), 18 (literal f) y 25 de la Ley 1581.
            </p>
            <h2>III. DEFINICIONES </h2>
            <p>
              3.1. “Afiliada”: Incluye la sociedad controlante de PROCINAL
              BOGOTA LTDA, MULTICINES S.A.S Y CINECITY S.A.S (grupo empresarial
              en desarrollo de la marca CINEMAS PROCINAL), así como todas las
              sociedades controladas directa o indirectamente por aquella en
              adelante CINEMAS PROCINAL.
            </p>
            <p>
              3.2. “Autorización”: Consentimiento previo, expreso e informado
              del Titular del Dato para llevar a cabo el Tratamiento.
            </p>
            <p>
              3.3. “Consulta”: Solicitud del Titular del Dato o las personas
              autorizadas por éste o por la ley para conocer la información del
              Titular que reposa en bases de datos o archivos.
            </p>
            <p>
              3.4. “Dato Personal”: Cualquier información vinculada o que pueda
              asociarse a una o varias personas naturales determinadas o
              determinables.
            </p>
            <p>
              Estos datos se clasifican en sensibles, públicos, privados y
              semiprivados.
            </p>
            <p>
              3.5. “Dato Personal Sensible”: Aquellos Datos Personales que
              afectan la intimidad del Titular o cuyo uso indebido puede generar
              su discriminación, tales como aquellos que revelen el origen
              racial o étnico, la orientación política, las convicciones
              religiosas o filosóficas, la pertenencia a sindicatos,
              organizaciones sociales, de derechos humanos o que promuevan
              intereses de cualquier partido político o que garanticen los
              derechos y garantías de partidos políticos de oposición, así como
              los datos relativos a la salud, la vida sexual y los datos
              biométricos (huellas dactilares, entre otros).
            </p>
            <p>
              3.6. “Dato Personal público”: Los Datos Personales calificados
              como tal según los mandatos de la ley o de la Constitución
              Política y todos aquellos que no sean semiprivados o privados. Son
              públicos, entre otros, los Datos Personales relativos al estado
              civil de las personas, a su profesión u oficio y a su calidad de
              comerciante o de servidor público. Por su naturaleza, pueden estar
              contenidos en documentos públicos, registros públicos, gacetas y
              boletines oficiales, y sentencias judiciales debidamente
              ejecutoriadas que no estén sometidas a reserva. Asimismo, son
              públicos los Datos Personales existentes en el registro mercantil
              de las Cámaras de Comercio (artículo 26 del Código de Comercio
              Colombiano).
            </p>
            <p>
              Estos Datos Personales pueden ser obtenidos y ofrecidos sin
              reserva alguna y sin importar si hacen alusión a información
              general, privada o personal.
            </p>
            <p>
              3.7. “Dato Personal privado”: Es el Dato Personal que por su
              naturaleza íntima o reservada sólo es relevante para el Titular
              del Dato. Ejemplos: libros de los comerciantes, documentos
              privados, información extraída a partir de la inspección del
              domicilio.
            </p>
            <p>
              3.8. “Dato Personal semiprivado”: Es semiprivado el Dato Personal
              que no tiene naturaleza íntima, reservada, ni pública y cuyo
              conocimiento o divulgación puede interesar no sólo a su Titular
              sino a cierto sector o grupo de personas o a la sociedad en
              general, como, entre otros: el Dato Personal referente al
              cumplimiento e incumplimiento de las obligaciones financieras o
              los Datos Personales relativos a las relaciones con entidades de
              seguridad social. 3.9. “Encargado del Tratamiento” o “Encargado”:
              Persona natural o jurídica que realiza, por sí misma o en asocio
              con otros, el Tratamiento de Datos Personales por cuenta del
              responsable del tratamiento.
            </p>
            <p>
              3.10. “Reclamo”: Solicitud del Titular del Dato o las personas
              autorizadas por éste o por la ley para corregir, actualizar o
              suprimir sus Datos Personales.
            </p>
            <p>
              3.11. “Responsable del Tratamiento” o “responsable”: Persona
              natural o jurídica que decide sobre la base de datos y/o el
              Tratamiento de los Datos Personales, determinando entre otros, la
              recolección y fines del Tratamiento.
            </p>
            <p>
              Para efectos de la presente Política, se entiende que el
              Responsable es PROCINAL BOGOTA LTDA, MULTICINES S.A.S. y CINECITY
              S.A.S. (marca CINEMAS PROCINAL) las tres sociedades colombianas de
              derecho privado, domiciliadas en la ciudad de Bogotá en la AV 15
              No. 119-43 oficina 601, identificadas con los NIT 800030932-1,
              830049056-5 y 901265789-8 respectivamente.
            </p>
            <p>
              3.12. “Titular del Dato” o “Titular”: Es la persona natural a que
              se refieren los Datos Personales objeto de Tratamiento.
            </p>
            <p>
              3.13. “Tratamiento”: Cualquier operación o conjunto de operaciones
              sobre Datos Personales como, entre otros, la recolección,
              almacenamiento, uso, circulación o supresión de esta clase de
              información.
            </p>
            <p>
              3.14. “Transmisión”: Tratamiento de Datos Personales que implica
              la comunicación de los mismos dentro (Transmisión nacional) o
              fuera de Colombia (Transmisión internacional) y que tiene por
              objeto la realización de un Tratamiento por el Encargado por
              cuenta del Responsable.
            </p>
            <p>
              3.15. “Transferencia”: Tiene lugar cuando el Responsable y/o
              Encargado del Tratamiento de Datos Personales, ubicado en
              Colombia, envía los Datos Personales a un receptor, que a su vez
              es responsable del Tratamiento y se encuentra dentro o fuera del
              país.
            </p>
            <h2>IV. PRINCIPIOS PARA EL TRATAMIENTO DE DATOS PERSONALES </h2>
            <p>
              El Tratamiento de Datos Personales debe realizarse respetando las
              normas generales y especiales sobre la materia, así como
              ejecutando actividades permitidas por la ley. En el desarrollo,
              interpretación y aplicación de la presente Política, se aplicarán
              de manera armónica e integral los siguientes principios:
            </p>
            <p>
              4.1. Principios relacionados con la recolección de Datos
              Personales.
            </p>
            <p>
              4.1.1. Principio de libertad: Salvo norma legal en contrario, la
              recolección de los Datos Personales solo puede realizarse con la
              Autorización previa, expresa e informada del Titular del Dato. Los
              Datos Personales no podrán ser obtenidos o divulgados sin previo
              consentimiento, o en ausencia de mandato legal o judicial que
              releve el consentimiento.
            </p>
            <p>
              Se deberá informar al Titular del Dato de manera clara, suficiente
              y previa acerca de la finalidad de la información suministrada y,
              por tanto, no podrán recopilarse Datos Personales sin la
              especificación sobre la finalidad de su Tratamiento.
            </p>
            <p>
              El principio de libertad debe observarse tanto para el caso de los
              Datos Personales que se recolectan a través de formatos, como los
              que hacen parte de los anexos o documentos que los Titulares de
              los Datos entregan a CINEMAS PROCINAL (cualquier sociedad del
              grupo empresarial).
            </p>
            <p>
              No se podrán utilizar medios engañosos o fraudulentos para
              recolectar y realizar Tratamiento de Datos Personales.
            </p>
            <p>
              4.1.2. Principio de limitación de la recolección: Solo deben
              recolectarse los Datos Personales que sean estrictamente
              necesarios para el cumplimiento de las finalidades del
              Tratamiento, de tal forma que se encuentra prohibido el registro y
              divulgación de Datos Personales que no guarden estrecha relación
              con el objetivo del Tratamiento. En consecuencia, debe hacerse
              todo lo razonablemente posible para limitar el procesamiento de
              Datos Personales al mínimo necesario. Es decir, los Datos
              Personales deberán ser: (i) adecuados, (ii) pertinentes, y (iii)
              acordes con las finalidades para las cuales fueron previstos.
            </p>
            <p>4.2. Principios relacionados con el uso de Datos Personales. </p>
            <p>
              4.2.1. Principio de finalidad: El Tratamiento debe obedecer a una
              finalidad legítima de acuerdo con la Constitución y la ley, la
              cual debe ser informada al Titular. Se deberá comunicar al Titular
              del Dato de manera clara, suficiente y previa la finalidad de la
              información suministrada y, por tanto, no podrán recopilarse Datos
              Personales sin una finalidad específica.
            </p>
            <p>
              Los Datos Personales deben ser tratados de acuerdo a los usos
              autorizados. Si con el tiempo, el uso de los Datos Personales
              cambia a formas que la persona no espera, es necesario obtener
              nuevamente el consentimiento previo del Titular.
            </p>
            <p>
              4.2.2. Principio de temporalidad: Los Datos Personales se
              conservarán únicamente por el tiempo razonable y necesario para
              cumplir la finalidad de su Tratamiento, así como las exigencias
              legales o instrucciones de autoridades de vigilancia y control u
              otras autoridades competentes. Los Datos Personales serán
              conservados cuando ello sea necesario para el cumplimiento de una
              obligación legal o contractual. Para determinar el término del
              Tratamiento se considerarán las normas aplicables a cada finalidad
              y los aspectos administrativos, contables, fiscales, jurídicos e
              históricos de la información.
            </p>
            <p>
              Una vez cumplida o alcanzada la o las finalidades, se procederá a
              la supresión de los Datos Personales.
            </p>
            <p>
              4.2.3. Principio de no discriminación: Queda prohibido realizar
              cualquier acto de discriminación en razón de las informaciones
              recaudadas en las bases de datos o archivos.
            </p>
            <p>
              4.2.4. Principio de reparación: Es obligación indemnizar los
              perjuicios causados por las posibles fallas en el Tratamiento de
              Datos Personales.
            </p>
            <p>
              4.3. Principios relacionados con la calidad de la información.
            </p>
            <p>
              4.3.1. Principio de veracidad o calidad: La información sujeta a
              Tratamiento debe ser veraz, completa, exacta, actualizada,
              comprobable y comprensible. Se prohíbe el Tratamiento de Datos
              Personales parciales, incompletos, fraccionados o que induzcan a
              error. Se deberán adoptar medidas razonables para asegurar que los
              Datos Personales sean precisos y suficientes y, cuando así lo
              solicite el Titular o cuando CINEMAS PROCINAL (cualquier sociedad
              del grupo empresarial) lo determine, sean actualizados,
              rectificados o suprimidos, de ser procedente.
            </p>
            <p>
              4.4. Principios relacionados con la protección, el acceso y la
              circulación de Datos Personales.
            </p>
            <p>
              4.4.1. Principio de seguridad: Las personas indicadas en la
              sección II de esta Política deberán cumplir con las medidas
              técnicas, humanas y administrativas necesarias que establezca
              CINEMAS PROCINAL (cualquier sociedad del grupo empresarial) para
              otorgar seguridad a los Datos Personales, evitando su
              adulteración, pérdida, Consulta, uso o acceso no autorizado o
              fraudulento.
            </p>
            <p>
              4.4.2. Principio de transparencia: En el Tratamiento debe
              garantizarse el derecho del Titular del Dato a obtener, en
              cualquier momento y sin restricciones, información acerca de la
              existencia de Datos Personales que le conciernan.
            </p>
            <p>
              4.4.3. Principio de acceso restringido: Solo se permitirá acceso a
              los Datos Personales a las siguientes personas:
            </p>
            <p>(i) Al Titular del Dato. </p>
            <p>(ii) A las personas autorizadas por el Titular del Dato. </p>
            <p>
              (iii) A las personas que por mandato legal u orden judicial sean
              autorizadas para conocer la información del Titular del Dato.
            </p>
            <p>
              (iv) A las demás personas legitimadas según lo indica el artículo
              20 del Decreto 1377.
            </p>
            <p>
              En todos los casos, antes de dar acceso a los Datos Personales, se
              debe establecer con certeza y suficiencia la identidad de la
              persona que solicita conocer los Datos Personales.
            </p>
            <p>
              Los Datos Personales, salvo la información pública, no podrán
              estar disponibles en internet u otros medios de divulgación o
              comunicación masiva, salvo que el acceso sea técnicamente
              controlable para brindar un conocimiento restringido solo a los
              Titulares o terceros autorizados conforme a la ley y a la presente
              Política.
            </p>
            <p>
              4.4.4. Principio de circulación restringida: Solo se pueden enviar
              o suministrar los Datos Personales a las siguientes personas:
            </p>
            <p>
              (i) Al Titular del Dato, (ii) A las personas autorizadas por el
              Titular del Dato, (iii) A las demás personas legitimadas según lo
              indica el artículo 20 del Decreto 1377, (iv) A las entidades
              públicas o administrativas en ejercicio de sus funciones legales o
              por orden judicial. En este último caso, de conformidad con la
              Corte Constitucional, se procederá de la siguiente manera:
            </p>
            <p>
              En primer lugar, la entidad pública o administrativa debe
              justificar su solicitud indicando el vínculo entre la necesidad de
              obtener el Dato Personal y el cumplimiento de sus funciones
              constitucionales o legales.
            </p>
            <p>
              En segundo lugar, con la entrega de la información se le informará
              a la entidad pública o administrativa que, como responsable del
              Tratamiento, debe cumplir los deberes y obligaciones que le impone
              la Ley 1581 y sus normas reglamentarias. La entidad administrativa
              receptora de los Datos Personales debe cumplir con las
              obligaciones de protección y las garantías que se derivan de la
              citada Ley, en especial la observancia de los principios de
              finalidad, uso legítimo, circulación restringida, confidencialidad
              y seguridad.
            </p>
            <p>
              4.4.5. Principio de confidencialidad: Todas las personas que
              intervengan en el Tratamiento de Datos Personales, que no tengan
              la naturaleza de públicas, están obligadas a garantizar la reserva
              de la información, inclusive después de finalizada su relación con
              alguna de las labores que comprende el Tratamiento, pudiendo solo
              realizar suministro o comunicación de Datos Personales cuando ello
              corresponda al desarrollo de las actividades autorizadas en la
              ley.
            </p>
            <h2>
              V. BASES DE DATOS DE CINEMAS PROCINAL (cualquier sociedad del
              grupo empresarial) Y FINALIDADES DEL TRATAMIENTO AL QUE SERÁN
              SOMETIDOS LOS DATOS PERSONALES
            </h2>
            <p>
              CINEMAS PROCINAL (cualquier sociedad del grupo empresarial), en su
              calidad de Responsable del Tratamiento de Datos Personales y
              considerando la naturaleza especial de dichos datos y la finalidad
              de su Tratamiento, ha clasificado sus bases de datos de la
              siguiente manera:
            </p>
            <p>
              5.1. Accionistas: Contiene Datos Personales de las personas
              naturales propietarias de acciones de CINEMAS PROCINAL (cualquier
              sociedad del grupo empresarial), cuyo Tratamiento tiene como
              finalidad cumplir con las disposiciones legales, estatutarias,
              reglamentarias e internas, para: (i) distribuir dividendos; (ii)
              realizar convocatorias a reuniones de la Asamblea General de
              Accionistas; (iii) emitir certificaciones; (iv) fines
              administrativos y de contacto; (v) entre otras actividades
              relacionadas con los accionistas, su participación en las
              reuniones de la Asamblea General de Accionistas, el acceso a la
              información confidencial de la compañía, y la gestión de su
              participación en el capital de la misma.
            </p>
            <p>
              5.2. Candidatos: Contiene Datos Personales de las personas
              naturales que hayan iniciado algún proceso de selección de
              personal para vincularse laboralmente a CINEMAS PROCINAL
              (cualquier sociedad del grupo empresarial), cuyo Tratamiento tiene
              como finalidad: (i) la adopción de medidas tendientes a la
              prevención de actividades ilícitas, incluyendo actividades
              relacionadas con el lavado de activos y el financiamiento del
              terrorismo; (ii) fines de contacto; (iii) evaluar las
              características y circunstancias de los candidatos a un
              determinado cargo dentro de la estructura de CINEMAS PROCINAL
              (cualquier sociedad del grupo empresarial); (iv) corroborar que la
              información proporcionada por el aspirante sea correcta; (v) entre
              otras actividades relacionadas con el reclutamiento y selección de
              personal.
            </p>
            <p>
              5.3. Clientes: Contiene Datos Personales de las personas naturales
              que han adquirido productos y/o servicios de CINEMAS PROCINAL
              (cualquier sociedad del grupo empresarial), y demás personas que
              de manera voluntaria y en ejercicio de sus derechos han
              suministrado directamente sus Datos Personales a las compañías
              porque desean o requieren tener contacto con esta. El Tratamiento
              de esta base de datos tiene como finalidad: (i) el desarrollo del
              objeto social de las compañías; (ii) la ejecución de la relación
              contractual o comercial con CINEMAS PROCINAL (cualquier sociedad
              del grupo empresarial); (iii) el envío de información comercial,
              promocional, novedades y/o noticias relacionadas con los productos
              y/o servicios ofrecidos por la compañía; (iv) el levantamiento de
              registros estadísticos; (v) la gestión de análisis permanente de
              las preferencias, gustos y perfilamiento de los clientes en
              aspectos demográficos y hábitos de consumo, consulta de saldos,
              etc.; (vi) entre otros fines administrativos, comerciales y de
              contacto para dar cumplimiento al objeto social de la compañía.
            </p>
            <p>
              El envío de información comercial a clientes, de conformidad con
              lo indicado arriba, podrá realizarse a través de: (i) correo
              electrónico, (ii) mensajes de texto (SMS), y/o (iii) llamadas
              telefónicas (teléfonos fijos o móviles).
            </p>
            <p>
              Con su Autorización, el cliente Titular del Dato permite a CINEMAS
              PROCINAL (cualquier sociedad del grupo empresarial) compartir sus
              Datos Personales con los aliados comerciales de la compañía y/o
              sus Afiliadas. Asimismo, autoriza a CINEMAS PROCINAL (cualquier
              sociedad del grupo empresarial), sus aliados comerciales y/o
              Afiliadas para utilizar sus Datos Personales en la gestión propia
              de CINEMAS PROCINAL (cualquier sociedad del grupo empresarial) y/o
              sus Afiliadas, a efectos de generar valores agregados.
            </p>
            <p>
              5.4. Contratistas, Proveedores y Promotores de Espectáculos
              Públicos: Contiene Datos Personales de las personas naturales que
              tienen un vínculo contractual y/o comercial con CINEMAS PROCINAL
              (cualquier sociedad del grupo empresarial), como proveedores,
              contratistas y/o promotores de espectáculos públicos. El
              Tratamiento de esta base de datos tiene como finalidad: (i) el
              desarrollo del objeto social de la compañía; (ii) la ejecución de
              la relación contractual o comercial con CINEMAS PROCINAL
              (cualquier sociedad del grupo empresarial); (iii) el levantamiento
              de registros contables; (iv) la gestión de facturación; (v) la
              adopción de medidas tendientes a la prevención de actividades
              ilícitas, incluyendo actividades relacionadas con el lavado de
              activos y el financiamiento del terrorismo; (vi) entre otros fines
              administrativos, comerciales y de contacto para dar cumplimiento
              al objeto social de la compañía.
            </p>
            <p>
              5.5. Empleados y Exempleados: Contiene Datos Personales de las
              personas naturales que actualmente se encuentran vinculadas
              laboralmente a CINEMAS PROCINAL (cualquier sociedad del grupo
              empresarial), aquellas que estuvieron vinculadas laboralmente en
              el pasado, los pensionados de la compañía y/o sus supervivientes.
              El Tratamiento de esta base de datos tiene como finalidad cumplir
              con las disposiciones legales y reglamentarias en materia laboral
              y tributaria, así como: (i) la gestión de nómina y pagos de
              aportes al sistema de seguridad social y de pensiones; (ii) el
              registro y control del personal; (iii) la ejecución de la relación
              contractual; (iv) reportes a autoridades de control y vigilancia;
              (v) cumplimento de instrucciones de las autoridades judiciales y/o
              administrativas competentes; (vi) implementación de políticas y
              estrategias laborales y organizacionales de la casa matriz; (vi)
              implementación de programas y realización de actividades
              orientadas al bienestar y desarrollo del talento humano; (vii)
              entre otros fines administrativos y de contacto.
            </p>
            <p>
              5.6. PQRS: Contiene Datos Personales de las personas naturales que
              han elevado consultas, peticiones, quejas, reclamos y/o
              sugerencias a CINEMAS PROCINAL (cualquier sociedad del grupo
              empresarial), en relación con los productos y/o servicios
              ofrecidos por la compañía, cuyo Tratamiento tiene como finalidad:
              (i) atención y trámite de la solicitud; (ii) contacto; (iii) el
              levantamiento de registros estadísticos; (iv) reportes a
              autoridades de control y vigilancia; y (v) otras actividades
              relacionadas con el servicio al cliente.
            </p>
            <p>
              5.7. Visitantes: Contiene Datos Personales de las personas
              naturales que visitan las instalaciones de la sede administrativa
              de CINEMAS PROCINAL (cualquier sociedad del grupo empresarial),
              cuyo Tratamiento tiene como finalidad cumplir con las
              disposiciones internas de seguridad y control de acceso al
              edificio de la Dirección General, ubicado en la AV 15 NO. 119-43
              piso 6 de la ciudad de Bogotá D.C., incluyendo control y monitoreo
              de todas las personas y bienes en las instalaciones.
            </p>
            <p>
              En el proceso de administración de las bases de datos de CINEMAS
              PROCINAL (cualquier sociedad del grupo empresarial) se debe dar
              aplicación a los principios rectores derivados directamente de la
              Constitución, al igual que a aquellos derivados de la ley y esta
              Política.
            </p>
            <p>
              Al visitar los portales web de CINEMAS PROCINAL (cualquier
              sociedad del grupo empresarial) www.procinal.com.co, Cinemas
              Procinal APP, http://checkout.procinal.com.co/, cierta información
              se almacena automáticamente en los servidores para la
              administración del sistema, su procesamiento, fines estadísticos o
              para mantener copias de seguridad. Esta información comprende: (i)
              el nombre del proveedor de servicio de Internet utilizado; (ii) en
              algunos casos, la dirección de protocolo de Internet (IP); (iii)
              la versión de software de búsqueda (browser software); (iv) el
              sistema operativo del dispositivo electrónico usado para tener
              acceso a los portales que están siendo visitados; e inclusive (v)
              cualquier antecedente de búsqueda que se haya venido utilizando
              para encontrar dichos portales.
            </p>
            <h2>VI. USO DE COOKIES Y OTRAS TECNOLOGÍAS SIMILARES </h2>
            <p>
              En los portales de CINEMAS PROCINAL (cualquier sociedad del grupo
              empresarial) se utilizan “Cookies” y otras tecnologías propias y
              de terceros con el objetivo de mejorar la experiencia del usuario,
              facilitar su navegación, optimizar nuestros servicios y brindarle
              información relacionada con sus preferencias mediante el análisis
              de sus hábitos de navegación. Así mismo, pueden ser utilizadas
              para facilitar el uso de los portales web antes indicados.
            </p>
            <p>
              Al ingresar y permanecer en nuestros portales web, el usuario
              autoriza que sean utilizados y almacenados sus datos de navegación
              mediante el uso de Cookies y otras tecnologías en el computador o
              dispositivo móvil dispuesto por él.
            </p>
            <p>
              Las Cookies son pequeñas unidades de información almacenada
              temporalmente en el dispositivo electrónico del usuario y sirven,
              para el control de sesiones, en particular navegación mejorada,
              para obtener un alto desempeño como usuario amigable de un sitio
              web, para almacenar información personal relativa a
              identificación, así como para permitir que el servidor proporcione
              a los usuarios de los sitios web anuncios y servicios.
            </p>
            <p>
              CINEMAS PROCINAL (cualquier sociedad del grupo empresarial) puede
              utilizar estas tecnologías para autenticar a un usuario, para
              recordar sus preferencias para el uso del Sitio Web, para
              presentar ofertas que puedan ser de interés y para facilitar
              cualquier tipo de transacciones.
            </p>
            <p>
              Los portales web de CINEMAS PROCINAL (cualquier sociedad del grupo
              empresarial) podrán compartir la información obtenida con terceros
              autorizados y controlados con el fin de mejorar los servicios
              brindados, crear perfiles de usuarios, ofrecer campañas
              personalizadas y, en todo caso, realizar un análisis estadístico
              del comportamiento de navegación de cada usuario así sea anónimo.
            </p>
            <p>
              Estos terceros únicamente darán el uso establecido en esta
              Política y en caso de requerir algún otro actuar, este será
              informado a todos los usuarios por el medio que los portales web
              de CINEMAS PROCINAL (cualquier sociedad del grupo empresarial)
              dispongan.
            </p>
            <p>
              La mayoría de los “browsers” de la red aceptan estas tecnologías
              automáticamente, sin embargo, el usuario puede evitar esto
              cambiando la configuración de su browser. Puede eliminar las
              almacenadas en su dispositivo electrónico en cualquier momento,
              suprimiendo los archivos de Internet temporales
              (“Herramientas/Extras” de la barra del Browser – “Opciones de
              Internet”).
            </p>
            <p>
              CINEMAS PROCINAL (cualquier sociedad del grupo empresarial)
              también podrá tratar los Datos Personales para los siguientes
              fines:
            </p>
            <p>
              6.1. Efectuar las gestiones pertinentes para el desarrollo de la
              etapa precontractual, contractual y/o post contractual de los
              Titulares de los Datos, frente a cualquiera de los contratos en
              los que hace parte CINEMAS PROCINAL (cualquier sociedad del grupo
              empresarial) así como dar cumplimiento a la ley colombiana o
              extranjera y a las órdenes de autoridades judiciales o
              administrativas.
            </p>
            <p>
              6.2. Dar a conocer, transferir y/o transmitir Datos Personales
              dentro y fuera del país a terceros, a consecuencia de un contrato,
              ley o vínculo lícito que así lo requiera o para cumplir los
              contratos en que sea parte CINEMAS PROCINAL (cualquier sociedad
              del grupo empresarial), así como implementar servicios de
              computación en la nube y big data.
            </p>
            <p>
              6.3. Implementar estrategias de CRM (customer relationship
              management), que comprenden, entre otras: (i) un modelo de gestión
              de toda la organización, basado en la orientación al cliente y
              marketing relacional; (ii) crear bases de datos o sistemas
              informáticos de apoyo a la gestión de las relaciones con los
              clientes, a la venta y al marketing.
            </p>
            <p>
              6.4. Realizar invitaciones a eventos, mejorar productos y/o
              servicios u ofertar nuevos productos, y todas aquellas actividades
              asociadas a la relación comercial o vínculo existente con CINEMAS
              PROCINAL (cualquier sociedad del grupo empresarial), o aquel que
              se llegare a tener.
            </p>
            <p>
              6.5. Gestionar trámites (solicitudes, quejas, reclamos), efectuar
              encuestas de satisfacción respecto de los bienes y/o servicios de
              CINEMAS PROCINAL (cualquier sociedad del grupo empresarial), sus
              Afiliadas o aliados comerciales.
            </p>
            <p>
              6.6. Suministrar información de contacto y documentos pertinentes
              a la fuerza comercial y/o red de distribución, tele mercadeo, y
              cualquier tercero con el cual CINEMAS PROCINAL (cualquier sociedad
              del grupo empresarial) posea un vínculo contractual de cualquier
              índole.
            </p>
            <h2>VII. DERECHOS DE LOS TITULARES DE LOS DATOS PERSONALES </h2>
            <p>
              Las personas obligadas a cumplir esta Política deben respetar y
              garantizar los siguientes derechos de los Titulares de los Datos:
            </p>
            <p>
              7.1. Conocer, actualizar y rectificar los Datos Personales. Para
              el efecto, es necesario establecer previamente la identificación
              de la persona, para evitar que terceros no autorizados accedan a
              los Datos Personales del Titular.
            </p>
            <p>
              7.2. Obtener copia de la Autorización de Tratamiento cuando el
              cliente la solicite.
            </p>
            <p>
              7.3. Informar sobre el uso que CINEMAS PROCINAL (cualquier
              sociedad del grupo empresarial) ha dado o está dando a los Datos
              Personales del Titular.
            </p>
            <p>
              7.4. Dar trámite a las Consultas y/o Reclamos, siguiendo las
              pautas establecidas en la ley y en la presente Política.
            </p>
            <p>
              7.5. Acceder a la solicitud de revocatoria de la Autorización y/o
              supresión del Dato Personal cuando la Superintendencia de
              Industria y Comercio haya determinado que en el Tratamiento por
              parte de CINEMAS PROCINAL (cualquier sociedad del grupo
              empresarial) se ha incurrido en conductas contrarias a la Ley 1581
              o a la Constitución.
            </p>
            <p>
              El Titular también podrá revocar la Autorización o solicitar la
              supresión del Dato Personal, cuando no exista un mandato legal o
              contractual que le imponga el deber de permanecer en la base de
              datos o archivo del Responsable o Encargado, este proceso deberá
              suceder dentro de los siguientes 30 días a la solicitud.
            </p>
            <p>
              La solicitud de supresión de la información y la revocatoria de la
              Autorización no procederán cuando el Titular tenga un deber legal
              o contractual de permanecer en la base de datos del Responsable o
              Encargado.
            </p>
            <p>
              7.6. Acceder en forma gratuita a sus Datos Personales. La
              información solicitada por el Titular del Dato podrá ser
              suministrada por cualquier medio, incluyendo los electrónicos,
              según lo requiera el Titular del Dato. La información deberá ser
              de fácil lectura, sin barreras técnicas que impidan su acceso y
              deberá corresponder en un todo a aquella que repose en la base de
              datos correspondiente.
            </p>
            <p>
              Los derechos de los Titulares, de conformidad con el artículo 20
              del Decreto 1377, podrán ejercerse por las siguientes personas:
            </p>
            <p>
              7.6.1. Por el Titular del Dato, quien deberá acreditar su
              identidad en forma suficiente por los distintos medios que CINEMAS
              PROCINAL (cualquier sociedad del grupo empresarial) ponga a su
              disposición.
            </p>
            <p>
              7.6.2. Por sus causahabientes, quienes deberán acreditar tal
              calidad.
            </p>
            <p>
              7.6.3. Por el representante y/o apoderado del Titular del Dato,
              previa acreditación de la representación o apoderamiento.
            </p>
            <p>7.6.4. Por estipulación a favor de otro o para otro. </p>
            <p>
              Los derechos de los niños, niñas o adolescentes se ejercerán por
              las personas que estén facultadas para representarlos.
            </p>
            <h2>
              VIII. DEBERES DE CINEMAS PROCINAL (cualquier sociedad del grupo
              empresarial) CUANDO OBRA COMO RESPONSABLE DEL TRATAMIENTO DE DATOS
              PERSONALES
            </h2>
            <p>
              Todos los obligados a observar esta Política han de tener presente
              que CINEMAS PROCINAL (cualquier sociedad del grupo empresarial)
              debe cumplir los deberes impuestos por la ley colombiana. Por
              ende, deben obrar de tal forma que cumplan las siguientes
              obligaciones:
            </p>
            <p>
              8.1. Deberes de CINEMAS PROCINAL (cualquier sociedad del grupo
              empresarial) respecto del Titular del Dato.
            </p>
            <p>
              8.1.1 Solicitar y conservar, en las condiciones previstas en esta
              Política y en la ley, copia de la respectiva Autorización otorgada
              por el Titular del Dato.
            </p>
            <p>
              8.1.2 Informar de manera clara y suficiente al Titular del Dato
              sobre la finalidad de la recolección y los derechos que le asisten
              por virtud de la Autorización otorgada.
            </p>
            <p>
              8.1.3 Garantizar al Titular del Dato, en todo tiempo, el pleno y
              efectivo ejercicio del derecho de hábeas data, es decir, conocer,
              actualizar o rectificar sus Datos Personales.
            </p>
            <p>
              8.1.4 Informar, a solicitud del Titular del Dato, sobre el uso
              dado a sus Datos Personales.
            </p>
            <p>
              8.1.5 Tramitar las Consultas y Reclamos formulados en los términos
              señalados en la presente Política y en la ley.
            </p>
            <p>
              8.2. Deberes de CINEMAS PROCINAL (cualquier sociedad del grupo
              empresarial) respecto de la calidad, seguridad y confidencialidad
              de los Datos Personales.
            </p>
            <p>
              8.2.1. Observar los principios de veracidad, calidad, seguridad y
              confidencialidad en los términos establecidos en esta Política.
            </p>
            <p>
              8.2.2. Conservar la información bajo las condiciones de seguridad
              necesarias para impedir su adulteración, pérdida, Consulta, uso o
              acceso no autorizado o fraudulento.
            </p>
            <p>8.2.3. Actualizar la información cuando sea necesario. </p>
            <p>
              8.2.4. Rectificar los Datos Personales cuando ello sea procedente.
            </p>
            <p>
              8.3. Deberes de CINEMAS PROCINAL (cualquier sociedad del grupo
              empresarial) cuando realiza el Tratamiento a través de un
              Encargado.
            </p>
            <p>
              8.3.1. Suministrar al Encargado del Tratamiento únicamente los
              Datos Personales cuyo Tratamiento esté previamente autorizado.
              Cuando se trate de Transmisiones nacionales e internacionales, se
              deberá suscribir un contrato de Transmisión de Datos Personales o
              pactar cláusulas contractuales que contengan lo dispuesto en el
              artículo 25 del Decreto 1377.
            </p>
            <p>
              8.3.2. Garantizar que la información que se suministre al
              Encargado del Tratamiento sea veraz, completa, exacta,
              actualizada, comprobable y comprensible.
            </p>
            <p>
              8.3.3. Comunicar de forma oportuna al Encargado del Tratamiento,
              todas las novedades respecto de los Datos Personales que
              previamente le haya suministrado, y adoptar las demás medidas
              necesarias para que la información suministrada a éste se mantenga
              actualizada.
            </p>
            <p>
              8.3.4. Informar de manera oportuna al Encargado del Tratamiento
              las rectificaciones realizadas sobre los Datos Personales, para
              que éste proceda a realizar los ajustes pertinentes.
            </p>
            <p>
              8.3.5. Exigir al Encargado del Tratamiento, en todo momento, el
              respeto a las condiciones de seguridad y privacidad de la
              información del Titular del Dato.
            </p>
            <p>
              8.3.6. Informar al Encargado del Tratamiento cuando determinada
              información se encuentre en discusión por parte del Titular del
              Dato, una vez se haya presentado la reclamación y no haya
              finalizado el trámite respectivo.
            </p>
            <p>
              8.4. Deberes de CINEMAS PROCINAL (cualquier sociedad del grupo
              empresarial) respecto de la Superintendencia de Industria y
              Comercio.
            </p>
            <p>
              8.4.1. Informarle las eventuales violaciones a los códigos de
              seguridad y cuando existan riesgos en la administración de la
              información de los Titulares de Datos.
            </p>
            <p>
              8.4.2. Cumplir las instrucciones y requerimientos que imparta la
              Superintendencia de Industria y Comercio.
            </p>
            <h2>
              IX. DEBERES DE CINEMAS PROCINAL CUANDO OBRA COMO ENCARGADO DEL
              TRATAMIENTO DE DATOS PERSONALES
            </h2>
            <p>
              Si CINEMAS PROCINAL (cualquier sociedad del grupo empresarial)
              realiza el Tratamiento de Datos Personales en nombre de otra
              entidad u organización (la cual, en consecuencia, se reputará
              responsable del Tratamiento), deberá cumplir los siguientes
              deberes:
            </p>
            <p>
              9.1. Establecer que el responsable del Tratamiento está autorizado
              para tratar los Datos Personales que suministra a CINEMAS PROCINAL
              (cualquier sociedad del grupo empresarial).
            </p>
            <p>
              9.2. Garantizar al Titular del Dato, en todo tiempo, el pleno y
              efectivo ejercicio del derecho de hábeas data.
            </p>
            <p>
              9.3. Conservar la información bajo las condiciones de seguridad
              necesarias para impedir su adulteración, pérdida, Consulta, uso o
              acceso no autorizado o fraudulento.
            </p>
            <p>
              9.4. Realizar oportunamente la actualización, rectificación o
              supresión de los Datos Personales.
            </p>
            <p>
              9.5. Actualizar la información reportada por los responsables del
              Tratamiento dentro de cinco (5) días hábiles contados a partir de
              su recibo.
            </p>
            <p>
              9.6. Tramitar las Consultas y Reclamos formulados por los
              Titulares de Datos en los términos señalados en la presente
              Política y en la ley.
            </p>
            <p>
              9.7. Registrar en la base de datos la leyenda “reclamo en trámite”
              en la forma en que se establece en la presente Política y en la
              ley.
            </p>
            <p>
              9.8. Insertar en la base de datos la leyenda “información en
              discusión judicial” una vez notificado por parte de la autoridad
              competente sobre procesos judiciales relacionados con la calidad
              del Dato Personal.
            </p>
            <p>
              9.9. Abstenerse de circular información que esté siendo
              controvertida por el Titular del Dato y cuyo bloqueo haya sido
              ordenado por la Superintendencia de Industria y Comercio.
            </p>
            <p>
              9.10. Permitir el acceso a la información únicamente a las
              personas autorizadas por el Titular del Dato o facultadas por la
              ley para dicho efecto.
            </p>
            <p>
              9.11. Informar a la Superintendencia de Industria y Comercio
              cuando se presenten violaciones a los códigos de seguridad y
              existan riesgos en la administración de la información de los
              Titulares.
            </p>
            <p>
              9.12. Cumplir las instrucciones y requerimientos que imparta la
              Superintendencia de Industria y Comercio.
            </p>
            <h2>X. DE LA AUTORIZACIÓN DEL TRATAMIENTO DE DATOS PERSONALES </h2>
            <p>
              La Ley 1581 exige que la Autorización para recolectar Datos
              Personales (dirección de correo electrónico, teléfono, etc.) sea
              informada de manera previa y expresa, tal y como se ilustra a
              continuación:
            </p>
            <p>
              Así las cosas, los obligados a cumplir esta Política deberán
              obtener de parte del Titular del Dato su Autorización previa,
              expresa e informada para recolectar y tratar sus Datos Personales.
              La Autorización no es necesaria cuando se trate de: (i) Datos
              Personales Públicos, (ii) Tratamiento de información para fines
              históricos, estadísticos o científicos, en los cuales no se
              vincule la información a una persona específica; y (iii) Datos
              Personales relacionados con el Registro Civil de las personas.
            </p>
            <p>
              Para obtener la Autorización se deberán seguir las siguientes
              instrucciones:
            </p>
            <p>
              En primer lugar, antes de que la persona autorice el Tratamiento
              de sus Datos Personales, es necesario informarle de forma clara y
              expresa lo siguiente:
            </p>
            <p>
              (i) El Tratamiento al cual serán sometidos sus Datos Personales y
              la finalidad del mismo, (ii) El carácter facultativo de sus
              respuestas a las preguntas que le sean hechas, cuando estas versen
              sobre Datos Sensibles o sobre Datos de niñas, niños y
              adolescentes, (iii) Los derechos que le asisten como Titular del
              Dato, previstos en el artículo 8 de la Ley 1581, (iv) La
              identificación, dirección física o electrónica y teléfono de
              CINEMAS PROCINAL (cualquier sociedad del grupo empresarial).
            </p>
            <p>
              En segundo lugar, se obtendrá el consentimiento del Titular a
              través de cualquier medio que pueda ser objeto de Consulta
              posterior.
            </p>
            <p>
              Se deberá dejar prueba del cumplimiento de la obligación de
              informar y del consentimiento otorgado por el Titular del Dato. Si
              el Titular solicita copia de estos, CINEMAS PROCINAL (cualquier
              sociedad del grupo empresarial) deberá suministrárselas en un
              período no mayor a 30 días.
            </p>
            <p>
              La Autorización también podrá obtenerse a partir de conductas
              inequívocas del Titular del Dato, que permitan concluir de manera
              razonable que este otorgó su consentimiento para el Tratamiento de
              su información. Dicha(s) conducta(s) debe(n) ser muy clara(s), de
              manera que no admita(n) duda o equivocación sobre la voluntad de
              autorizar el Tratamiento. En ningún caso el silencio del Titular
              del Dato podrá considerarse como una conducta inequívoca.
            </p>
            <p>Se encuentran legitimados para otorgar el consentimiento: </p>
            <p>
              (i) El Titular del Dato, quien deberá acreditar su identidad en
              forma suficiente por los distintos medios que CINEMAS PROCINAL
              (cualquier sociedad del grupo empresarial) ponga a su disposición,
              (ii) Los causahabientes del Titular del Dato, quienes deberán
              acreditar tal calidad, (iii) El representante y/o apoderado del
              Titular del Dato, previa acreditación de la representación o
              apoderamiento, (iv) La Autorización también podrá otorgarse cuando
              se den casos de estipulación a favor de otro o para otro.
            </p>
            <p>10.1. Autorización para el Tratamiento de Datos Sensibles. </p>
            <p>
              Cuando se trate de la recolección de Datos Sensibles, se deben
              cumplir los siguientes requisitos:
            </p>
            <p>10.1.1. La Autorización debe ser explícita. </p>
            <p>
              10.1.2. Se debe informar al Titular del Dato que por tratarse de
              Datos Sensibles no está obligado a autorizar su Tratamiento.
            </p>
            <p>
              10.1.3. Se debe informar de forma explícita y previa al Titular
              del Dato cuáles de los Datos Personales que serán objeto de
              Tratamiento son sensibles y la finalidad del Tratamiento.
            </p>
            <p>
              Ninguna actividad podrá condicionarse a que el Titular del Dato
              suministre Datos Personales Sensibles.
            </p>
            <p>
              10.2. Autorización para el Tratamiento de Datos de niños, niñas y
              adolescentes.
            </p>
            <p>
              Cuando se trate de la recolección y Tratamiento de Datos
              Personales de niños, niñas y adolescentes, se deben cumplir los
              siguientes requisitos:
            </p>
            <p>
              10.2.1. La Autorización debe ser otorgada por personas que estén
              facultadas para representar a los niños, niñas y adolescentes. El
              representante de los niños, niñas y adolescentes deberá
              garantizarles el derecho a ser escuchados y valorar su opinión del
              Tratamiento, teniendo en cuenta la madurez, autonomía y capacidad
              de los niños, niñas y adolescentes para entender el asunto.
            </p>
            <p>
              10.2.2. Se debe informar que es facultativo responder preguntas
              sobre Datos Personales de niños, niñas y adolescentes.
            </p>
            <p>
              10.2.3. El Tratamiento debe asegurar el respeto del interés
              superior y los derechos fundamentales de los niños, niñas y
              adolescentes.
            </p>
            <p>
              10.2.4. Se debe informar de forma explícita y previa al Titular
              del Dato cuáles de los Datos Personales que serán objeto de
              Tratamiento son sensibles y la finalidad de dicho Tratamiento.
            </p>
            <h2>
              XI. CLASIFICACIÓN Y TRATAMIENTO ESPECIAL DE CIERTOS DATOS
              PERSONALES
            </h2>
            <p>
              Las personas obligadas al cumplimiento de esta Política deben
              identificar los Datos Sensibles y de niños, niñas y adolescentes
              que eventualmente recolecten o almacenen, con miras a:
            </p>
            <p>
              11.1. Implementar responsabilidad reforzada en el Tratamiento de
              estos Datos Personales, lo cual se traduce en una exigencia mayor
              en términos de cumplimiento de los principios rectores y deberes
              del Responsable.
            </p>
            <p>11.2. Aumentar los niveles de seguridad de esa información. </p>
            <p>
              11.3. Incrementar las restricciones de acceso y uso por parte del
              personal de CINEMAS PROCINAL (cualquier sociedad del grupo
              empresarial) y de terceros.
            </p>
            <p>
              11.4. Tener presentes los requisitos legales y contenidos en esta
              Política para su recolección.
            </p>
            <h2>XII. TRANSFERENCIA INTERNACIONAL DE DATOS PERSONALES </h2>
            <p>
              Cuando se envíen o transfieran Datos Personales a otro país, será
              necesario contar con la Autorización del Titular del Dato Personal
              que es objeto de Transferencia. Salvo que la ley diga lo
              contrario, dicha Autorización es prerrequisito para la circulación
              internacional de Datos Personales. En este sentido, antes de
              enviar Datos Personales a responsables del Tratamiento ubicados en
              otro país, los obligados a cumplir esta Política deberán verificar
              que se cuenta con la Autorización previa, expresa e inequívoca del
              Titular del Dato que permita dicha Transferencia.
            </p>
            <p>
              Cuando así se requiera de conformidad con lo dispuesto en el
              artículo 26 de la Ley 1581, CINEMAS PROCINAL (cualquier sociedad
              del grupo empresarial) solicitará la declaración de conformidad
              correspondiente a la Superintendencia de Industria y Comercio.
            </p>
            <h2>
              XIII. TRANSMISIONES INTERNACIONALES Y NACIONALES DE DATOS
              PERSONALES
            </h2>
            <p>
              Cuando CINEMAS PROCINAL (cualquier sociedad del grupo empresarial)
              desee enviar o transmitir Datos Personales a uno o varios
              Encargados ubicados dentro o fuera del territorio nacional,
              deberá, mediante cláusulas contractuales o a través de un contrato
              de Transmisión de Datos Personales, pactar lo siguiente:
            </p>
            <p>13.1. Los alcances del Tratamiento. </p>
            <p>
              13.2. Las actividades que el Encargado realizará en nombre de
              CINEMAS PROCINAL (cualquier sociedad del grupo empresarial).
            </p>
            <p>
              13.3. Las obligaciones que debe cumplir el Encargado frente al
              Titular del Dato y a CINEMAS PROCINAL (cualquier sociedad del
              grupo empresarial).
            </p>
            <p>
              13.4. La obligación del Encargado de dar cumplimiento a los
              deberes del Responsable, observando la presente Política y la ley.
            </p>
            <p>
              13.5. El deber del Encargado de tratar los Datos Personales de
              acuerdo con la finalidad autorizada y observando los principios
              establecidos en la ley colombiana y en la presente Política.
            </p>
            <p>
              13.6. La obligación del Encargado de proteger adecuadamente los
              Datos Personales y las bases de datos, así como de guardar
              confidencialidad respecto del Tratamiento de los Datos Personales
              transmitidos.
            </p>
            <h2>
              XIV. PROCEDIMIENTOS PARA QUE LOS TITULARES PUEDAN EJERCER SUS
              DERECHOS
            </h2>
            <p>
              A continuación, se detallan los procedimientos para que los
              Titulares de los Datos puedan ejercer sus derechos a conocer,
              actualizar, rectificar y suprimir su información o revocar la
              Autorización otorgada para su Tratamiento.
            </p>
            <p>
              Los derechos de los Titulares de los Datos podrán ejercerse por
              las siguientes personas, legitimadas de conformidad con el
              artículo 20 del Decreto 1377:
            </p>
            <p>
              (i) Por el Titular del Dato, quien deberá acreditar su identidad
              en forma suficiente por los distintos medios que CINEMAS PROCINAL
              (cualquier sociedad del grupo empresarial) ponga a su disposición,
              (ii) Por sus causahabientes, quienes deberán acreditar tal
              calidad, (iii) Por el representante y/o apoderado del Titular del
              Dato, previa acreditación de la representación o apoderamiento,
              (iv) Por estipulación a favor de otro o para otro.
            </p>
            <p>
              Los derechos de los niños, niñas o adolescentes se ejercerán por
              las personas que estén facultadas para representarlos.
            </p>
            <p>14.1. Canales para ejercer derechos. </p>
            <p>
              Todas las Consultas y Reclamos se canalizarán a través de los
              medios habilitados por CINEMAS PROCINAL (cualquier sociedad del
              grupo empresarial) para tal fin. Entre otros, la compañía ha
              dispuesto los siguientes medios de recepción y atención de
              Consultas y Reclamos de los Titulares:
            </p>
            <p>
              14.1.1. Sección de Preguntas, Quejas, Reclamos y Sugerencias
              (“PQRS”) del Portal www.procinal.com.co, Cinemas Procinal APP,
              http://checkout.procinal.com.co/,
            </p>
            <p>14.1.2. Correo electrónico: servicioalcliente@Procinal.com.co</p>
            <p>14.1.3. Puntos de atención personal a nivel nacional. </p>
            <p>
              En principio, las Consultas y Reclamos que sean recibidas por los
              canales de atención señalados en los primeros siete (7) numerales
              de esta sección, serán atendidos por la Gerencia de Servicio al
              Cliente de CINEMAS PROCINAL (cualquier sociedad del grupo
              empresarial).
            </p>
            <p>14.2. Consultas. </p>
            <p>
              Todas las Consultas que realicen las personas legitimadas para
              conocer los Datos Personales que reposen en CINEMAS PROCINAL
              (cualquier sociedad del grupo empresarial) se canalizarán a través
              de los medios indicados en la sección 14.1 de esta Política o
              aquellos que sean habilitados por la compañía. En todo caso, es
              necesario dejar prueba de lo siguiente: (i) Fecha de recibo de la
              Consulta, e (ii) Identidad del solicitante.
            </p>
            <p>
              Una vez verificada la identidad del Titular del Dato, se le
              suministrarán los Datos Personales, cuando sea requerido por la
              ley. La respuesta a la Consulta deberá comunicarse al solicitante
              en un término máximo de diez (10) días hábiles contados a partir
              de la fecha de recibo de la misma. Cuando no fuere posible atender
              la Consulta dentro de dicho término, esto será informado al
              interesado, expresando los motivos de la demora y señalando la
              fecha en que se atenderá su Consulta, la cual en ningún caso podrá
              superar los cinco (5) días hábiles siguientes al vencimiento del
              primer término.
            </p>
            <p>14.3. Reclamos. </p>
            <p>
              Los Reclamos tienen por objeto corregir, actualizar o suprimir
              Datos Personales, o elevar una queja por el presunto
              incumplimiento de cualquiera de los deberes contenidos en la Ley
              1581 y en esta Política.
            </p>
            <p>
              El Reclamo debe presentarse mediante solicitud dirigida a CINEMAS
              PROCINAL (cualquier sociedad del grupo empresarial), que contenga
              la siguiente información:
            </p>
            <p>
              14.3.1. Nombre e identificación del Titular del Dato o la persona
              legitimada al efecto.
            </p>
            <p>
              14.3.2. Descripción precisa y completa de los hechos que dan lugar
              al Reclamo.
            </p>
            <p>
              14.3.3. Dirección física o electrónica para remitir la respuesta e
              informar sobre el estado del trámite.
            </p>
            <p>
              14.3.4. Documentos y demás pruebas pertinentes que quiera hacer
              valer.
            </p>
            <p>
              Si el Reclamo está completo, se incluirá en la base de datos o
              sistema de información una leyenda que diga “reclamo en trámite” y
              el motivo del mismo, en un término no mayor a dos (2) días
              hábiles. La leyenda deberá mantenerse hasta que el Reclamo sea
              decidido.
            </p>
            <p>
              Si el Reclamo resulta incompleto, se requerirá al interesado
              dentro de los cinco (5) días hábiles siguientes a la recepción del
              Reclamo para que subsane las fallas. Transcurridos dos (2) meses
              desde la fecha del requerimiento, sin que el solicitante presente
              la información requerida, se entenderá que ha desistido del
              Reclamo.
            </p>
            <p>
              El término máximo para atender el Reclamo será de quince (15) días
              hábiles contados a partir del día siguiente a la fecha de su
              recibo. Cuando no fuere posible atender el Reclamo dentro de dicho
              término, se informarán al interesado los motivos de la demora y la
              fecha en que se atenderá su Reclamo, la cual en ningún caso podrá
              superar los ocho (8) días hábiles siguientes al vencimiento del
              primer término.
            </p>
            <h2>XV. ÁREA ENCARGADA DE LA PROTECCIÓN DE DATOS PERSONALES </h2>
            <p>
              La Gerencia Jurídica de CINEMAS PROCINAL (cualquier sociedad del
              grupo empresarial) es la dependencia o área encargada de la
              función de protección de Datos Personales.
            </p>
            <p>
              Al dar trámite a las solicitudes de los Titulares de los Datos
              para el ejercicio de los derechos a que se refieren la Ley 1581 y
              esta Política, la Gerencia Jurídica contará con el apoyo de la
              Gerencia de Servicio al Cliente.
            </p>
            <p>
              El área encargada de protección de Datos Personales velará por la
              implementación efectiva de las políticas y procedimientos
              adoptados por CINEMAS PROCINAL (cualquier sociedad del grupo
              empresarial) para cumplir con las normas vigentes. Asimismo,
              implementará buenas prácticas de gestión de Datos Personales
              dentro de la empresa.
            </p>
            <p>
              Dentro de sus actividades se encuentran, entre otras, las
              siguientes:
            </p>
            <p>
              15.1. Impulsar una cultura de protección de Datos Personales
              dentro de la organización.
            </p>
            <p>
              15.2. Registrar las bases de datos de CINEMAS PROCINAL (cualquier
              sociedad del grupo empresarial) en el Registro Nacional de Bases
              de Datos y actualizar el reporte atendiendo las instrucciones que
              sobre el particular emita la Superintendencia de Industria y
              Comercio.
            </p>
            <p>
              15.3. Revisar los contenidos de los contratos de Transmisiones
              internacionales de Datos que se suscriban con Encargados no
              residentes en Colombia.
            </p>
            <p>
              15.4. Realizar entrenamientos generales en protección de Datos
              Personales para los empleados de la compañía.
            </p>
            <p>
              15.5. Acompañar y asistir a la empresa en la atención de las
              visitas y los requerimientos que realice la Superintendencia de
              Industria y Comercio.
            </p>
            <p>
              15.6. Coordinar la definición e implementación de controles a esta
              Política.
            </p>
            <h2>XVI. VIDEO VIGILANCIA </h2>
            <p>
              CINEMAS PROCINAL (cualquier sociedad del grupo empresarial),
              garantizando la protección del derecho a la intimidad, utiliza
              diversos dispositivos de video vigilancia instalados en
              ubicaciones internas y/o externas de sus instalaciones u oficinas
              y salas a nivel nacional. Este sistema de video vigilancia
              contempla la captación de imágenes.
            </p>
            <p>
              En lo referente a las imágenes de personas determinadas o
              determinables, operaciones como la captación, grabación,
              Transmisión, almacenamiento, conservación o reproducción en tiempo
              real o posterior, entre otras, son consideradas como Tratamiento
              de Datos Personales y, en consecuencia, se encuentran sujetas al
              régimen legal de protección de Datos Personales y a esta Política.
            </p>
            <p>
              Como mecanismo de obtención de la Autorización de los Titulares de
              este tipo de Datos Personales, CINEMAS PROCINAL (cualquier
              sociedad del grupo empresarial) utiliza avisos distintivos en las
              zonas de video vigilancia, los cuales deben cumplir, como mínimo,
              con el contenido de un aviso de privacidad, en los términos del
              Decreto 1377. Se debe garantizar que los avisos sean visibles y
              legibles.
            </p>
            <p>
              La información recolectada se utilizará para fines de seguridad de
              las personas, los bienes y las instalaciones. Esta información
              puede ser empleada como prueba en cualquier tipo de proceso ante
              cualquier tipo de autoridad u organización.
            </p>
            <p>
              En atención a los principios de finalidad y de limitación de la
              recolección, así como al carácter intrusivo de los sistemas de
              video vigilancia, CINEMAS PROCINAL (cualquier sociedad del grupo
              empresarial) dispone que solo se recauden los Datos Personales
              necesarios para cumplir las finalidades perseguidas. En
              consecuencia, estos Datos Personales serán adecuados, pertinentes
              y acordes con las finalidades para las cuales fueron recolectados.
            </p>
            <h2>XVII. PREVALENCIA DE LA POLÍTICA </h2>
            <p>
              En caso de existir inconsistencias sobre el Tratamiento de Datos
              Personales entre esta Política y cualquier otra norma interna de
              CINEMAS PROCINAL (cualquier sociedad del grupo empresarial), se
              aplicará lo señalado en la Política.
            </p>
            <h2>
              XVIII. RESPONSABILIDAD DEMOSTRADA FRENTE AL TRATAMIENTO DE DATOS
              PERSONALES
            </h2>
            <p>
              CINEMAS PROCINAL (cualquier sociedad del grupo empresarial)
              adoptará las medidas necesarias para, de ser necesario, demostrar
              ante la Superintendencia de Industria y Comercio que ha
              implementado medidas apropiadas y efectivas para cumplir con sus
              obligaciones legales en todo lo relacionado con el Tratamiento de
              Datos Personales. Dichas medidas serán consistentes con las
              instrucciones que para el efecto imparta la Superintendencia de
              Industria y Comercio y con los mandatos de los artículos 26 y 27
              del Decreto 1377.
            </p>
            <h2>
              XIX. FECHA DE ENTRADA EN VIGENCIA DE LA POLÍTICA Y PERÍODO DE
              VIGENCIA DE LAS BASES DE DATOS
            </h2>
            <p>Esta Política entrará en vigencia a partir de su publicación.</p>
            <p>
              CINEMAS PROCINAL (cualquier sociedad del grupo empresarial) se
              reserva el derecho a modificar la Política en cualquier momento.
              Toda modificación entrará en vigencia y tendrá efectos frente a
              terceros desde su publicación en el canal correspondiente. En
              consecuencia, se recomienda a los clientes y otros interesados
              visitar y consultar frecuentemente los siguientes portales web:
              www.procinal.com.co, Cinemas Procinal APP,
              http://checkout.procinal.com.co/, en el módulo correspondiente,
              para conocer los cambios.
            </p>
            <p>
              Si el cambio de esta Política consiste en modificar: (i) el nombre
              del Responsable o (ii) la finalidad del Tratamiento, CINEMAS
              PROCINAL (cualquier sociedad del grupo empresarial) comunicará los
              cambios al Titular del Dato antes o a más tardar al momento de
              implementación de la nueva Política. Además, cuando el cambio se
              refiera a la finalidad del Tratamiento, deberá obtener del Titular
              del Dato una nueva Autorización.
            </p>
            <p>
              La vigencia de las bases de datos será el tiempo razonable y
              necesario para cumplir las finalidades del Tratamiento, teniendo
              en cuenta lo dispuesto en el artículo 11 del Decreto 1377, y que
              los Datos Personales deberán ser conservados cuando así se
              requiera para el cumplimiento de una obligación legal o
              contractual.
            </p>
            <h2>XX. DATOS DEL RESPONSABLE DEL TRATAMIENTO </h2>
            <p>
              20.1. Razón Social: PROCINAL BOGOTA LTDA, MULTICINES S.A.S y
              CINECITY S.A.S. (marca CINEMAS PROCINAL).
            </p>
            <p>
              20.2. Domicilio o Dirección: Autopista Norte No. 232-35 Local 101
              Cinemas
            </p>
            <p>20.3. Correo Electrónico: servicioalcliente@procinal.com.co </p>
            <p>20.4. Página Web: www.procinal.com.co </p>
            <h2>XXI. FECHA Y VERSIÓN.</h2>
            <p>Enero 2021</p>
            <p>Atentamente, </p>
            <p>
              <strong>DIEGO LIBARDO QUIROGA PASTRAN</strong>
            </p>
            Gerente jurídico CINEMAS PROCINAL
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default PoliciesPage;
