import {
  SUBSCRIPTION_CITY,
  SUBSCRIPTION_PRICE_RECHARGE,
  SUBSCRIPTION_RECHARGE,
  SUBSCRIPTION_THEATER,
  SUBSCRIPTION_TOTAL,
  SUBSCRIPTION_THEATERS,
  SUBSCRIPTION_TERMS,
} from '../constants/Subscription';
const { REACT_APP_MIN_RECHARGE } = process.env;

const INITIAL_STATE = {
  subscription_city: '',
  subscription_theater: '',
  subscription_recharge: false,
  subscription_price_recharge: parseInt(REACT_APP_MIN_RECHARGE) + 10000,
  subscription_total: 0,
  subscription_theaters: [],
  subscription_terms: false,
};

const subscriptionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SUBSCRIPTION_CITY:
      return {
        ...state,
        subscription_city: action.payload,
      };
    case SUBSCRIPTION_THEATER:
      return {
        ...state,
        subscription_theater: action.payload,
      };
    case SUBSCRIPTION_RECHARGE:
      return {
        ...state,
        subscription_recharge: action.payload,
      };
    case SUBSCRIPTION_PRICE_RECHARGE:
      return {
        ...state,
        subscription_price_recharge: action.payload,
      };
    case SUBSCRIPTION_TOTAL:
      return {
        ...state,
        subscription_total: action.payload,
      };
    case SUBSCRIPTION_THEATERS:
      return {
        ...state,
        subscription_theaters: action.payload,
      };
    case SUBSCRIPTION_TERMS:
      return {
        ...state,
        subscription_terms: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default subscriptionReducer;
