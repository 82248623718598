import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import MyCoupons from '../../components/MyCoupons';
import ProfileLayout from '../../Layouts/ProfileLayout';

function MyCouponsPage(props) {
  const {
    userReducer: { user },
  } = props;
  if (!user) {
    return <Redirect to="/" />;
  }

  return (
    <ProfileLayout>
      <MyCoupons />
    </ProfileLayout>
  );
}

const mapStateToProps = ({ userReducer }) => {
  return {
    userReducer,
  };
};

export default connect(mapStateToProps, null)(MyCouponsPage);
