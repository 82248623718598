import { IMAX_NEXT_RELEASES } from '../constants/Imax';

const INITIAL_STATE = {
  next_releases: [],
};

const ImaxReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IMAX_NEXT_RELEASES:
      return {
        ...state,
        next_releases: action.payload,
      };
    default:
      return state;
  }
};

export default ImaxReducer;
