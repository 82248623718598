import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import MyCoupon from '../../components/MyCoupon';
import ProfileLayout from '../../Layouts/ProfileLayout';

function MyCouponPage(props) {
  const {
    userReducer: { user },
  } = props;
  if (!user) {
    return <Redirect to="/" />;
  }

  return (
    <ProfileLayout>
      <MyCoupon />
    </ProfileLayout>
  );
}

const mapStateToProps = ({ userReducer }) => {
  return {
    userReducer,
  };
};

export default connect(mapStateToProps, null)(MyCouponPage);
