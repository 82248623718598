import React from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import MovieBox from '../../MovieBox';
import './SliderMovies.css';

function SliderMovies(props) {
  const {
    globalReducer: { global_movies_view, global_city },
  } = props;
  const minItems =
    global_movies_view.length < 4 ? global_movies_view.length : 4;
  const stettings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: minItems,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  if (!global_movies_view.length) {
    return null;
  }

  const renderSlides = (status) => {
    const style = status ? { width: `calc(100% / ${minItems})` } : {};
    const html = global_movies_view.map((movie) => {
      return (
        <div className="SliderMovies_slide" key={movie.id} style={style}>
          <MovieBox
            link={`/ciudad/${global_city}`}
            size="small"
            slug={movie.slug}
            titulo_original={movie.titulo_original}
            titulo={movie.titulo}
            tipo={movie.tipo}
            imagen={movie.imagen}
            first_showtime={movie.showtimes[0]}
          />
        </div>
      );
    });

    return html;
  };

  return (
    <div className="SliderMovies_container">
      {global_movies_view.length >= 4 ? (
        <Slider {...stettings}>{renderSlides(false)}</Slider>
      ) : (
        <div className="SliderMovies_slider">{renderSlides(true)}</div>
      )}
    </div>
  );
}

const mapStateToProps = ({ globalReducer }) => {
  return {
    globalReducer,
  };
};

export default connect(mapStateToProps, null)(SliderMovies);
