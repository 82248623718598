import React from 'react';
import { connect } from 'react-redux';

function HeaderCoupon(props) {
  const {
    couponReducer: { coupon_detail, coupon_total },
  } = props;
  const numberFormat = new Intl.NumberFormat('en-CO');

  return (
    <div className="PaymentMethods_resume">
      <div className="PaymentMethods_title">
        {coupon_detail.name.toUpperCase()}
      </div>
      <div className="PaymentMethods_price">
        <strong>{numberFormat.format(coupon_total).replace(',', '.')}</strong>{' '}
        COP
      </div>
    </div>
  );
}

const mapStateToProps = ({ couponReducer }) => {
  return {
    couponReducer,
  };
};

export default connect(mapStateToProps, null)(HeaderCoupon);
