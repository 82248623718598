import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getTicketStatus } from '../../store/actions/PayTicketActions';
import DefaultLayout from '../../Layouts/DefaultLayout';
import PageLoader from '../../components/PageLoader';
import checkout from '../../assets/images/checkout.png';

function TicketStatusPage(props) {
  const {
    match: {
      params: { bill },
    },
    payTicketReducer: { loading, ticket, redirect },
    getTicketStatus,
  } = props;
  const numberFormat = new Intl.NumberFormat('es-CO');

  useEffect(() => {
    getTicketStatus(bill);
  }, []);

  if (redirect !== '') {
    <Redirect to={redirect} />;
  }

  return (
    <DefaultLayout>
      <div className="Status">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {loading || !ticket ? (
                <PageLoader />
              ) : (
                <>
                  <div className="Status_container">
                    <h2>{ticket.showtime.movie.titulo.toUpperCase()}</h2>
                    <div className="Status_content">
                      <img src={checkout} alt="checkout" />
                      <div className="Status_header">
                        <h3>Order Completada</h3>
                        <p>
                          Tu Orden fue Recibida el:{' '}
                          {ticket.epayco_response.fecha
                            ? ticket.epayco_response.fecha
                            : ticket.ticket.created_at}
                        </p>
                      </div>
                      <div className="Status_body">
                        <h3>Resumen de la Orden</h3>
                        <p>
                          <strong>Teatro:</strong>{' '}
                          {ticket.showtime.room.cinema.nombre_completo
                            ? ticket.showtime.room.cinema.nombre_completo.toUpperCase()
                            : ticket.showtime.room.cinema.nombre.toUpperCase()}
                        </p>
                        <p>
                          <strong>Sala:</strong>{' '}
                          {ticket.showtime.room.numero_sala}
                        </p>
                        <p>
                          <strong>Estado de pago:</strong>{' '}
                          {ticket.epayco_response.estado
                            ? ticket.epayco_response.estado
                            : ticket.ticket.status}
                        </p>
                        <p>
                          <strong>Orden No:</strong> {bill}
                        </p>
                        <p>
                          <strong>Email de envío:</strong> {ticket.ticket.email}
                        </p>
                        <p>
                          <strong>Fecha Función:</strong>{' '}
                          {ticket.showtime.fecha_funcion}
                        </p>
                        <p>
                          <strong>Hora Función:</strong>{' '}
                          {ticket.showtime.hora_cadena}
                        </p>
                        <p>
                          <strong>Método de Pago:</strong>{' '}
                          {ticket.epayco_response.franquicia
                            ? ticket.epayco_response.franquicia
                            : ticket.epayco_response.banco
                            ? ticket.epayco_response.banco
                            : ticket.ticket.bank}
                        </p>
                        <div className="mb-10">
                          <strong>No. Entradas: </strong>
                          {ticket.ticket.quantity}
                        </div>

                        {parseInt(ticket.ticket.response_code) === 1 && (
                          <>
                            <hr />
                            <div>
                              <strong>Gracias por tu compra.</strong> En un
                              plazo de 15 minutos recibirás tus entradas a tu
                              email registrado. Recuerda revisar tu bandeja de
                              entrada y la carpeta de correos no deseados
                              (spam). <strong>Que disfrutes tu función.</strong>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="Status_footer">
                        VALOR:{' '}
                        <span>
                          ${numberFormat.format(parseInt(ticket.ticket.total))}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

const mapStateToProps = ({ payTicketReducer }) => {
  return {
    payTicketReducer,
  };
};

const mapDispatchToProps = {
  getTicketStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketStatusPage);
