import React from 'react';
import Content from '../../components/Content';
import DefaultLayout from '../../Layouts/DefaultLayout';

function ContentPage() {
  return (
    <DefaultLayout>
      <Content />
    </DefaultLayout>
  );
}

export default ContentPage;
