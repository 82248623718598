import React from 'react';
import { Link } from 'react-router-dom';

function HowToSubscription(props) {
  return (
    <div className="HowToBuy_container">
      <h2 className="HowToBuy_title">¿Cómo adquirir CINE+?</h2>
      <p className="HowToBuy_description">
        <strong className="color-red">CINE+</strong> es el Programa de
        Fidelizacion que te da <strong className="color-red">+</strong>{' '}
        descuentos en boletería y confitería.
      </p>
      <div className="HowToBuy_steps">
        <div className="HowToBuy_step">
          <div className="HowToBuy_number">1</div>
          <i className="icon-check" />
          <p>
            Regístrate o<br />
            Inicia sesión
          </p>
        </div>
        <div className="HowToBuy_step">
          <div className="HowToBuy_number">2</div>
          <i className="icon-profile" />
          <p>
            Ingresa a tu perfil y da clic en
            <br />
            <strong>"Adquirir CINE+"</strong>
          </p>
        </div>
        <div className="HowToBuy_step">
          <div className="HowToBuy_number">3</div>
          <i className="icon-checkboxes" />
          <p>Elige la ciudad y teatro de tu preferencia</p>
        </div>
        <div className="HowToBuy_step">
          <div className="HowToBuy_number">4</div>
          <i className="icon-recharge" />
          <p>Selecciona el valor de tu primera recarga</p>
        </div>
        <div className="HowToBuy_step">
          <div className="HowToBuy_number">5</div>
          <i className="icon-payment" />
          <p>Paga con tu medio de pago preferido</p>
        </div>
      </div>
      <Link to="/cinemas" className="btn btn-procinal mt-4">
        ¿Quieres saber más?
      </Link>
    </div>
  );
}

export default HowToSubscription;
