export const CHECKOUT_DATE = 'CHECKOUT_DATE';
export const CHECKOUT_HOUR = 'CHECKOUT_HOUR';
export const CHECKOUT_HOURS = 'CHECKOUT_HOURS';
export const CHECKOUT_CHAIRS = 'CHECKOUT_CHAIRS';
export const CHECKOUT_ROOM = 'CHECKOUT_ROOM';
export const CHECKOUT_ROOM_STATUS = 'CHECKOUT_ROOM_STATUS';
export const CHECKOUT_STEP = 'CHECKOUT_STEP';
export const CHECKOUT_ROOM_LOADING = 'CHECKOUT_ROOM_LOADING';
export const CHECKOUT_LOCATIONS = 'CHECKOUT_LOCATIONS';
export const CHECKOUT_LOCATION = 'CHECKOUT_LOCATION';
export const CHECKOUT_TOTAL_TIKETS = 'CHECKOUT_TOTAL_TIKETS';
export const CHECKOUT_TOTAL_TRANSACTION = 'CHECKOUT_TOTAL_TRANSACTION';
export const CHECKOUT_TOTAL_CONFECTIONERY = 'CHECKOUT_TOTAL_CONFECTIONERY';
export const CHECKOUT_TOTAL = 'CHECKOUT_TOTAL';
export const CHECKOUT_CLEAR = 'CHECKOUT_CLEAR';
export const CHECKOUT_MOVIE = 'CHECKOUT_MOVIE';
export const CHECKOUT_MOVIE_LOADING = 'CHECKOUT_MOVIE_LOADING';
export const CHECKOUT_SCORE_BILL = 'CHECKOUT_SCORE_BILL';
export const CHECKOUT_SHOWTIME = 'CHECKOUT_SHOWTIME';
export const CHECKOUT_ERROR = 'CHECKOUT_ERROR';
export const CHECKOUT_POPUP = 'CHECKOUT_POPUP';
export const CHECKOUT_PAY_OPTION = 'CHECKOUT_PAY_OPTION';
export const CHECKOUT_OTHER_PRICE = 'CHECKOUT_OTHER_PRICE';
export const CHECKOUT_FACE_MASK = 'CHECKOUT_FACE_MASK';
export const CHECKOUT_RESERVATION = 'CHECKOUT_RESERVATION';
