import React from 'react';
import './TheaterFooter.css';
import TheaterTitle from '../TheaterTitle';

function TheaterFooter(props) {
  const { theater } = props;

  return (
    <div className="TheaterFooter_container">
      <TheaterTitle
        name={
          theater.nombre_completo ? theater.nombre_completo : theater.nombre
        }
        address={theater.direccion}
        size="small"
      />
      <div className="TheaterFooter_content">
        <img src={theater.imagen} alt={theater.nombre} />
        <div
          className="TheaterFooter_map"
          dangerouslySetInnerHTML={{ __html: theater.mapa }}
        />
      </div>
    </div>
  );
}

export default TheaterFooter;
