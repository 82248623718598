import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { userLogout } from '../../store/actions/UserActions';
import './MenuProfile.css';

function MenuProfile(props) {
  const { userLogout } = props;

  return (
    <nav className="MenuProfile">
      <NavLink to="/perfil" activeClassName="active">
        Mi Perfil
      </NavLink>
      <NavLink to="/tickets" activeClassName="active">
        Mis Tickets
      </NavLink>
      {/* <NavLink to="/mis-cupones" activeClassName="active">
        Mis Cupones
      </NavLink> */}
      <button onClick={() => userLogout('visible')}>Cerrar Sesión</button>
    </nav>
  );
}

const mapDispatchToProps = {
  userLogout,
};

export default connect(null, mapDispatchToProps)(MenuProfile);
