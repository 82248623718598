import React from 'react';
import { connect } from 'react-redux';
import { createPortal } from 'react-dom';
import './Login.css';
import {
  changePassword,
  goToLogin,
  changeModalLogin,
  changeModalRegister,
  userChangeFormLogin,
  forgotPassword,
  changeDni,
} from '../../store/actions/UserActions';

function Login(props) {
  const {
    userReducer: { user_dni, user_password, user_modal_login, user_form_login },
    changeDni,
    changePassword,
    goToLogin,
    changeModalLogin,
    changeModalRegister,
    userChangeFormLogin,
    forgotPassword,
  } = props;

  return createPortal(
    <div className={`Login ${user_modal_login}`}>
      <div className="Login_container">
        <button onClick={() => changeModalLogin('')}>
          <i className="fas fa-times" />
        </button>
        <label htmlFor="email">
          <span>NÚMERO DE DOCUMENTO:</span>
          <input
            className="form-control"
            type="number"
            value={user_dni}
            onChange={(input) => changeDni(input.target.value)}
          />
        </label>
        {user_form_login === 'login' ? (
          <>
            <label htmlFor="pass">
              <span>CONTRASEÑA:</span>
              <input
                className="form-control"
                type="password"
                value={user_password}
                onChange={(input) => changePassword(input.target.value)}
              />
            </label>
            <button
              className="Login-pass-fogotten"
              onClick={() => userChangeFormLogin('forgot')}
            >
              Olvide mi contraseña
            </button>
            <button onClick={goToLogin} type="button" className="Login_button">
              INICIAR SESIÓN
            </button>
            <div className="Login_account">
              Si no tienes cuenta,{' '}
              <span
                onClick={() => {
                  changeModalLogin('');
                  changeModalRegister('visible');
                }}
              >
                registrate acá.
              </span>
            </div>
          </>
        ) : (
          <>
            <button
              onClick={forgotPassword}
              type="button"
              className="Login_button"
            >
              RECUPERAR CONTRASEÑA
            </button>
            <div className="Login_account">
              <span onClick={() => userChangeFormLogin('login')}>
                Iniciar Sesión
              </span>
            </div>
          </>
        )}
      </div>
    </div>,
    document.getElementById('modal-login')
  );
}

const mapStatToProps = ({ userReducer }) => {
  return {
    userReducer,
  };
};

const mapDispatchToProps = {
  changeDni,
  changePassword,
  goToLogin,
  changeModalLogin,
  changeModalRegister,
  userChangeFormLogin,
  forgotPassword,
};

export default connect(mapStatToProps, mapDispatchToProps)(Login);
