import React from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { couponLegals } from '../../store/actions/CouponActions';
import './CouponLegals.css';

function CouponLegals(props) {
  const {
    couponReducer: { coupon_show_legals, coupon_detail },
    couponLegals,
  } = props;
  return createPortal(
    <div className={`CouponLegals ${coupon_show_legals}`}>
      <div className="CouponLegals_content">
        <button onClick={() => couponLegals('')} className="CouponLegals_close">
          <i className="far fa-times-circle" />
        </button>
        <h1 className="CouponLegals_title">CONDICIONES Y RESTRICCIONES</h1>
        <div
          className="CouponLegals_small"
          dangerouslySetInnerHTML={{ __html: coupon_detail.legals }}
        />
      </div>
    </div>,
    document.getElementById('modal-loading')
  );
}

const mapStateToProps = ({ couponReducer }) => {
  return {
    couponReducer,
  };
};

const mapDispatchToProps = {
  couponLegals,
};

export default connect(mapStateToProps, mapDispatchToProps)(CouponLegals);
