import { POP_UP_IMAGE, POP_UP_VISIBLE, POP_UP_WIDTH } from '../constants/PopUp';

const INITIAL_STATE = {
  pop_up_visible: '',
  pop_up_image: '',
  pop_up_width: '',
};

const popUpReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POP_UP_VISIBLE:
      return {
        ...state,
        pop_up_visible: action.payload,
      };
    case POP_UP_IMAGE:
      return {
        ...state,
        pop_up_image: action.payload,
      };
    case POP_UP_WIDTH:
      return {
        ...state,
        pop_up_width: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default popUpReducer;
