/* eslint-disable import/no-anonymous-default-export */
import {
  USER_AGE,
  USER_CELLPHONE,
  USER_CLEAR,
  USER_DATA,
  USER_DATEBORN,
  USER_DNI,
  USER_DNITYPE,
  USER_EDIT_PROFILE,
  USER_EMAIL,
  USER_EMAIL_REGISTER,
  USER_FORM_LOGIN,
  USER_GENDER,
  USER_LASTNAME,
  USER_MODAL_LOGIN,
  USER_MODAL_REGISTER,
  USER_NAME,
  USER_PASSWORD,
  USER_PASSWORD_CONFIRM,
  USER_PASSWORD_REGISTER,
  USER_POLICIES,
  USER_SCORE,
  USER_SCORE_GET,
  USER_SCORE_BALANCE,
  USER_SCORE_LOADING,
  USER_SCORE_LOADING_BALANCE,
  USER_TERMS,
  USER_EMAIL_REPEAT,
  USER_REPEAT_DNI,
  USER_SCORE_BALANCE_GET,
} from '../constants/User';

const INITIAL_STATE = {
  user: null,
  user_doc_types: [
    {
      id: 'CC',
      name: 'Cédula de Ciudadanía',
    },
    {
      id: 'CE',
      name: 'Cédula de Extranjería',
    },
    {
      id: 'PPN',
      name: 'Pasaporte',
    },
    {
      id: 'NIT',
      name: 'Número de Identificación Tibutaria (NIT)',
    },
  ],
  user_email: '',
  user_password: '',
  user_name: '',
  user_lastName: '',
  user_gender: '',
  user_age: '',
  user_dniType: 'CC',
  user_dni: '',
  user_cellPhone: '',
  user_email_register: '',
  user_email_repeat: '',
  user_repeat_dni: '',
  user_dateBorn: '',
  user_password_register: '',
  user_password_confirm: '',
  user_modal_login: '',
  user_modal_register: '',
  user_form_login: 'login',
  user_policies: false,
  user_terms: false,
  user_edit_profile: false,
  user_score_loading: true,
  user_score_get: false,
  user_score: null,
  user_score_balance: 0,
  user_score_loading_balance: true,
  user_score_balance_get: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_DATA:
      return {
        ...state,
        user: action.payload,
      };
    case USER_EMAIL:
      return {
        ...state,
        user_email: action.payload,
      };
    case USER_PASSWORD:
      return {
        ...state,
        user_password: action.payload,
      };
    case USER_NAME:
      return {
        ...state,
        user_name: action.payload,
      };
    case USER_LASTNAME:
      return {
        ...state,
        user_lastName: action.payload,
      };
    case USER_GENDER:
      return {
        ...state,
        user_gender: action.payload,
      };
    case USER_AGE:
      return {
        ...state,
        user_age: action.payload,
      };
    case USER_DNITYPE:
      return {
        ...state,
        user_dniType: action.payload,
      };
    case USER_DNI:
      return {
        ...state,
        user_dni: action.payload,
      };
    case USER_REPEAT_DNI:
      return {
        ...state,
        user_repeat_dni: action.payload,
      };
    case USER_CELLPHONE:
      return {
        ...state,
        user_cellPhone: action.payload,
      };
    case USER_EMAIL_REGISTER:
      return {
        ...state,
        user_email_register: action.payload,
      };
    case USER_EMAIL_REPEAT:
      return {
        ...state,
        user_email_repeat: action.payload,
      };
    case USER_DATEBORN:
      return {
        ...state,
        user_dateBorn: action.payload,
      };
    case USER_PASSWORD_REGISTER:
      return {
        ...state,
        user_password_register: action.payload,
      };
    case USER_PASSWORD_CONFIRM:
      return {
        ...state,
        user_password_confirm: action.payload,
      };
    case USER_MODAL_LOGIN:
      return {
        ...state,
        user_modal_login: action.payload,
      };
    case USER_MODAL_REGISTER:
      return {
        ...state,
        user_modal_register: action.payload,
      };
    case USER_CLEAR:
      return {
        ...state,
        user_email: '',
        user_password: '',
        user_name: '',
        user_lastName: '',
        user_gender: '',
        user_age: '',
        user_dniType: '',
        user_dni: '',
        user_cellPhone: '',
        user_email_register: '',
        user_dateBorn: '',
        user_password_register: '',
        user_password_confirm: '',
        user_form_login: 'login',
      };
    case USER_FORM_LOGIN:
      return {
        ...state,
        user_form_login: action.payload,
      };
    case USER_POLICIES:
      return {
        ...state,
        user_policies: action.payload,
      };
    case USER_TERMS:
      return {
        ...state,
        user_terms: action.payload,
      };
    case USER_EDIT_PROFILE:
      return {
        ...state,
        user_edit_profile: action.payload,
      };
    case USER_SCORE_LOADING:
      return {
        ...state,
        user_score_loading: action.payload,
      };
    case USER_SCORE:
      return {
        ...state,
        user_score: action.payload,
      };
    case USER_SCORE_GET:
      return {
        ...state,
        user_score_get: action.payload,
      };
    case USER_SCORE_LOADING_BALANCE:
      return {
        ...state,
        user_score_loading_balance: action.payload,
      };
    case USER_SCORE_BALANCE:
      return {
        ...state,
        user_score_balance: action.payload,
      };
    case USER_SCORE_BALANCE_GET:
      return {
        ...state,
        user_score_balance_get: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
