import React, { Component } from 'react';
import { connect } from 'react-redux';
import { confectioneryAddCombo } from '../../store/actions/ConfectioneryActions';
import {
  confectioneryChangeTheater,
  confectioneryGetProducts,
  confectioneryGetApiProducts,
} from '../../store/actions/ConfectioneryActions';
import ModalProduct from './ModalProduct/ModalProduct';
import Products from './Products';
import TheaterSelect from './TheaterSelect';
import Cart from './Cart';
import './Confectionery.css';

class Confectionery extends Component {
  state = {
    modal: '',
    products: [],
    product: null,
  };

  componentDidMount() {
    const {
      theater,
      confectioneryChangeTheater,
      confectioneryGetProducts,
      confectioneryReducer: { confectionery_api_loading },
      confectioneryGetApiProducts,
    } = this.props;

    if (confectionery_api_loading) {
      confectioneryGetApiProducts();
    }
    if (theater) {
      confectioneryChangeTheater(theater);
      confectioneryGetProducts(theater);
    }
  }

  onSelectProduct = (product) => {
    this.setState({ product, modal: 'show' });
  };

  addProduct = (product, theater) => {
    const { confectioneryAddCombo } = this.props;

    confectioneryAddCombo(product, theater ? false : null);
    this.onCloseModal();
  };

  onCloseModal = () => {
    this.setState({ modal: '' });
    setTimeout(() => {
      this.setState({ product: null });
    }, 300);
  };

  render() {
    const { showTheaterSelect, showCart, theaters } = this.props;
    const { modal, product } = this.state;

    return (
      <>
        {showTheaterSelect && <TheaterSelect theaters={theaters} />}
        <ModalProduct
          modal={modal}
          product={product}
          addProduct={this.addProduct}
          onCloseModal={this.onCloseModal}
        />
        <Products showCart={showCart} onSelectProduct={this.onSelectProduct} />
        {showTheaterSelect && <Cart showPaymentType={true} />}
      </>
    );
  }
}

Confectionery.defaultProps = {
  theater: null,
  theaters: null,
  loading: false,
  showTheaterSelect: false,
  showCart: false,
};

const mapDispatchToProps = {
  confectioneryAddCombo,
  confectioneryChangeTheater,
  confectioneryGetProducts,
  confectioneryGetApiProducts,
};

const mapStateToProps = ({ confectioneryReducer }) => {
  return {
    confectioneryReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Confectionery);
