import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getImaxNextReleases } from '../../store/actions/ImaxActions';
import MovieBox from '../MovieBox';
import './ImaxNextReleases.css';

function ImaxNextReleases(props) {
  const {
    imaxReducer: { next_releases },
    getImaxNextReleases,
  } = props;

  useEffect(() => {
    if (!next_releases.length) {
      getImaxNextReleases();
    }
    // eslint-disable-next-line
  }, []);

  if (!next_releases.length) {
    return null;
  }

  return (
    <div className="ImaxNextRelease-container">
      <div className="Imax-billboard">PRÓXIMOS ESTRENOS</div>
      <div className="container">
        <div className="row">
          {next_releases.map((movie) => (
            <div className="col-sm-6 col-md-3 mb-4" key={movie.id}>
              <MovieBox {...movie} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ imaxReducer }) => {
  return {
    imaxReducer,
  };
};

const mapDispatchToProps = {
  getImaxNextReleases,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImaxNextReleases);
