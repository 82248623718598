import {
  CINEMAS_CONFIRM_PASSWORD,
  CINEMAS_ERROR,
  CINEMAS_LOADING,
  CINEMAS_PASSWORD,
  CINEMAS_VISIBLE,
} from '../constants/Cinemas';
import { changeVisible } from './PaymentActions';
import swal from 'sweetalert';
import Api from './Api';
import { getSettingValue } from '../../functions/currentFunctions';
import {
  getCombosToPay,
  getTotalCombos,
} from '../../Utils/confectioneryManager';

const { REACT_APP_LOCAL_STORAGE: LOCAL_STORAGE } = process.env;

export const cinemasChangeVisible = (payload) => (dispatch) => {
  dispatch(cinemasChangePassword(''));
  dispatch(cinemasChangeConfirmPassword(''));
  dispatch(cinemasChangeError(''));
  dispatch({
    type: CINEMAS_VISIBLE,
    payload,
  });
};

export const cinemasChangeLoading = (payload) => (dispatch) => {
  dispatch({
    type: CINEMAS_LOADING,
    payload,
  });
};

export const cinemasChangePassword = (payload) => (dispatch) => {
  dispatch({
    type: CINEMAS_PASSWORD,
    payload,
  });
  dispatch(cinemasChangeConfirmPassword(''));
};

export const cinemasChangeError = (payload) => (dispatch) => {
  dispatch({
    type: CINEMAS_ERROR,
    payload,
  });
};

export const cinemasChangeConfirmPassword = (payload) => (
  dispatch,
  getState
) => {
  const { cinemas_password } = getState().cinemasReducer;
  if (payload.length > 6 && cinemas_password !== payload) {
    dispatch(cinemasChangeError('Las contraseñas no coinciden.'));
  } else {
    dispatch(cinemasChangeError(''));
  }

  dispatch({
    type: CINEMAS_CONFIRM_PASSWORD,
    payload,
  });
};

export const cinemasGoToPay = () => (dispatch, getState) => {
  const { checkout_total } = getState().checkoutReducer;
  const { user_score_balance } = getState().userReducer;
  const { confectionery_combos } = getState().confectioneryReducer;
  let total = checkout_total || 0;

  if (confectionery_combos.length) {
    const combosTotal = getTotalCombos(confectionery_combos);
    total = total + combosTotal.totalCinemas;
  }

  if (!total) {
    swal('Upsss!!!', 'No tienes productos para efectuar la compra.', 'warning');
    return null;
  }

  if (total > user_score_balance.Saldo_Total) {
    swal(
      'Upsss!!!',
      'El saldo de tu cuenta CINE+, es inferior al pago a realizar.',
      'warning',
      {
        buttons: ['Cancelar', 'Recargar mi CINE+'],
      }
    ).then((res) => {
      if (res) {
        dispatch(changeVisible('visible', 4));
      }
    });
  } else {
    dispatch(cinemasChangeVisible('visible'));
  }
};

export const cinemasToPay = () => (dispatch, getState) => {
  const {
    checkout_total_tikets,
    checkout_total_transaction,
    checkout_total,
    checkout_chairs,
    checkout_room,
    checkout_location,
    checkout_showtime,
    checkout_score_bill,
  } = getState().checkoutReducer;
  const {
    cinemas_password,
    cinemas_confirm_password,
    cinemas_error,
  } = getState().cinemasReducer;
  const { global_settings } = getState().globalReducer;
  const {
    confectionery_theater,
    confectionery_combos,
    confectionery_api_products,
  } = getState().confectioneryReducer;

  let total = checkout_total || 0;
  let total_transaction = checkout_total_transaction || 0;
  let subtotal = checkout_total_tikets || 0;

  let chairs = [];
  let products = [];
  let products_bepass = [];
  let description = '';

  if (!cinemas_password.length) {
    swal('Upsss!!!', 'Debes escribir la cotraseña.', 'warning');
    return;
  }
  if (!cinemas_confirm_password.length) {
    swal(
      'Upsss!!!',
      'Debes escribir la confirmación de la cotraseña.',
      'warning'
    );
    return;
  }
  if (cinemas_error.length) {
    swal('Upsss!!!', 'Las contraseñas no coinciden.', 'warning');
    return;
  }

  if (checkout_chairs && checkout_chairs.length) {
    const {
      ColumnaRelativa,
      ColumnaTotal,
      FilaRelativa,
      FilaTotal,
    } = checkout_room;
    const price = checkout_location;

    const chairStorage = null;
    let listChairs = checkout_chairs;

    if (chairStorage) {
      listChairs = JSON.parse(chairStorage);
    }

    chairs = listChairs.map((item) => ({
      Fila: FilaTotal[item],
      Columna: ColumnaTotal[item],
      FilRelativa: FilaRelativa[item],
      ColRelativa: ColumnaRelativa[item],
      Tarifa: price.codigo,
    }));
  }
  if (confectionery_combos.length) {
    products = getCombosToPay(
      confectionery_combos,
      'Habilitado',
      confectionery_api_products
    );
    description = products.description;
    products_bepass = products.combosBepass;
    products = products.combos;
    const combosTotal = getTotalCombos(confectionery_combos);
    const recargo = parseInt(
      getSettingValue(global_settings, 'confiteria_price_service', 500)
    );

    total = total + combosTotal.totalCinemas + recargo;
    total_transaction = total_transaction + recargo;
    subtotal = subtotal + combosTotal.totalCinemas;
  }

  const data = {
    showtime: checkout_showtime ? checkout_showtime.id : null,
    chairs,
    products,
    total,
    total_transaction,
    subtotal,
    cash_payment: 0,
    score_bill: checkout_score_bill ? checkout_score_bill[0].Secuencia : null,
    password: cinemas_password,
    score_theater: confectionery_theater || null,
    description,
    products_bepass,
  };

  dispatch(cinemasChangeLoading(true));

  localStorage.removeItem(`${LOCAL_STORAGE}::ticketConfectionery`);
  localStorage.removeItem(`${LOCAL_STORAGE}::confectionery`);

  Api.callMethod(
    'auth/cine-mas/pay',
    'post',
    data,
    (res, status) => {
      if (status === 201) {
        const { payment_status } = res;
        window.location.href = `/payment/status/${payment_status.factura}`;
        dispatch(cinemasChangeLoading(false));
      } else {
        swal('Upsss!!!', res.message, 'warning').then(() => {
          dispatch(cinemasChangeLoading(false));
          window.location.reload();
        });
      }
    },
    (error) => {
      console.log('Error cinemasToPay: ', error);
      swal('Upsss!!!', 'Hubo era un error al procesar el pago.', 'error').then(
        () => {
          dispatch(cinemasChangeLoading(false));
          window.location.reload();
        }
      );
    }
  );
};
