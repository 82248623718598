import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import loadingWhite from '../../assets/images/loading-white.svg';
import { changeCity } from '../../store/actions/GlobalActions';
import './SelectCity.css';

class SelectCity extends Component {
  state = {
    redirect: false,
    redirectTo: '',
  };

  componentDidMount() {
    const {
      globalReducer: { global_city },
      match: {
        params: { city },
      },
      changeCity,
    } = this.props;
    if (city && !global_city) {
      changeCity(city);
    }
  }

  componentWillUnmount() {
    this.setState({
      redirect: false,
      redirectTo: '',
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.redirect) {
      this.setState({
        redirect: false,
        redirectTo: '',
      });
      this.props.changeCity(this.state.redirectTo);
    }
  }

  changeSelect = (value) => {
    if (value === '') {
      return null;
    }
    if (value === 'otras-ciudades') {
      window.location.href = 'https://www.procinal.com/landing';
      return null;
    }
    this.setState({
      redirectTo: value,
      redirect: true,
    });
  };

  render() {
    const {
      globalReducer: { global_cities, global_loading_cities, global_city },
      type,
    } = this.props;

    if (this.state.redirect) {
      return <Redirect to={`/ciudad/${this.state.redirectTo}`} />;
    }

    if (global_loading_cities) {
      return (
        <img
          src={loadingWhite}
          alt="Loading"
          style={{ width: 40, height: 40 }}
        />
      );
    }

    if (type === 'select') {
      return (
        <>
          <div>Ciudad:</div>
          <select
            onChange={(input) => this.changeSelect(input.target.value)}
            defaultValue={global_city}
          >
            {global_cities.map((city) => {
              return (
                <option key={city.id} value={city.slug}>
                  {city.nombre}
                  {city.complemento && ` ${city.complemento}`}
                </option>
              );
            })}
          </select>
        </>
      );
    }

    return (
      <div className="SelectCity_container">
        {global_cities.map((city) => {
          return (
            <div className="SelectCity_item" key={city.id}>
              {city.slug === 'bogota' && <i className="hand icon-hand" />}
              <button onClick={() => this.changeSelect(city.slug)}>
                {city.nombre}
                {city.complemento && (
                  <div className="small">{city.complemento}</div>
                )}
              </button>
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = ({ globalReducer }) => {
  return {
    globalReducer,
  };
};

const mapDispatchToProps = {
  changeCity,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SelectCity)
);
