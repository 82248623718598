import React from 'react';
import './HowToBuyImax.css';
import number01 from '../../assets/images/imax/number-01.png';
import number02 from '../../assets/images/imax/number-02.png';
import number03 from '../../assets/images/imax/number-03.png';
import number04 from '../../assets/images/imax/number-04.png';
import number05 from '../../assets/images/imax/number-05.png';
import cinemas from '../../assets/images/imax/cinemas.png';

function HowToBuyImax() {
  return (
    <div className="HowToBuyImax-container">
      <h1>COMPRAR TUS ENTRADAS ONLINE, ES ASÍ DE FÁCIL:</h1>
      <div className="HowToBuyImax-steps">
        <div className="HowToBuyImax-step">
          <img src={number01} alt="Step 01" />
          <span>Escoge la película​</span>
        </div>
        <div className="HowToBuyImax-step">
          <img src={number02} alt="Step 02" />
          <span>
            Elige el día y la hora para nuestro IMAX Plaza de las Américas​
          </span>
        </div>
        <div className="HowToBuyImax-step">
          <img src={number03} alt="Step 03" />
          <span>Registra tus datos​</span>
        </div>
        <div className="HowToBuyImax-step">
          <img src={number04} alt="Step 04" />
          <span>Escoge tu silla​</span>
        </div>
        <div className="HowToBuyImax-step">
          <img src={number05} alt="Step 05" />
          <span>Presenta el QR en la entrada del cine desde tu teléfono​</span>
        </div>
      </div>
      <div className="HowToBuyImax-footer">
        <h2>¡Y AÚN HAY MAX!​</h2>
        <h3>
          SI ERES CLIENTE CINE+, TIENES BENEFICIOS COMO DESCUENTOS EN BOLETERÍA
          Y COMBOS.
        </h3>
        <img src={cinemas} alt="Logo Cinemas" />
      </div>
    </div>
  );
}

export default HowToBuyImax;
