import { IMAX_NEXT_RELEASES } from '../constants/Imax';
import Api from './Api';

export const getImaxNextReleases = () => (dispatch) => {
  Api.callMethod('movies/imax-next-releases', 'GET', null, (response) => {
    const { data } = response;

    dispatch({
      type: IMAX_NEXT_RELEASES,
      payload: data,
    });
  });
};
