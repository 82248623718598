import React from 'react';
import { connect } from 'react-redux';
import { changeModalRegister } from '../../store/actions/UserActions';
import { changeVisible } from '../../store/actions/PaymentActions';
import DefaultLayout from '../../Layouts/DefaultLayout';
import './Cinemas.css';
import logo from '../../assets/images/cinemas/logo.png';
import text01 from '../../assets/images/cinemas/text-01.png';
import text02 from '../../assets/images/cinemas/text-02.png';
import text03 from '../../assets/images/cinemas/text-03.png';
import step01 from '../../assets/images/cinemas/step-01.png';
import step02 from '../../assets/images/cinemas/step-02.png';
import step03 from '../../assets/images/cinemas/step-03.png';
import image01 from '../../assets/images/cinemas/image-01.png';
import image02 from '../../assets/images/cinemas/image-02.png';
import image03 from '../../assets/images/cinemas/image-03.png';

function CinemasPage(props) {
  const {
    userReducer: { user, user_score },
    changeModalRegister,
    changeVisible,
  } = props;

  const openForm = () => {
    if (!user) {
      changeModalRegister('visible');
    } else {
      if (user_score && user_score.Estado === 'Usuario sin tarjeta activa') {
        changeVisible('visible', 3);
      }
    }
  };

  return (
    <DefaultLayout>
      <div className="Cinemas-container">
        <h1>CINE+</h1>
        <div className="Cinemas-content">
          <img className="Cinemas-text01" src={text01} alt="text01" />
          <img className="Cinemas-logo" src={logo} alt="logo" />
          <img className="Cinemas-text02" src={text02} alt="text02" />
          <div className="Cinemas-sections">
            <button className="Cinemas-section Cinemas-step" onClick={openForm}>
              <img src={step01} alt="step01" />
            </button>
            <div className="Cinemas-section Cinemas-step">
              <img src={step02} alt="step02" />
            </div>
            <div className="Cinemas-section Cinemas-step">
              <img src={step03} alt="step03" />
            </div>
          </div>
          <div className="Cinemas-sections">
            <div className="Cinemas-section Cinemas-image">
              <img src={image01} alt="image01" />
            </div>
            <div className="Cinemas-section Cinemas-image">
              <img src={image02} alt="image02" />
            </div>
            <div className="Cinemas-section Cinemas-image">
              <img src={image03} alt="image03" />
            </div>
          </div>
          <img className="Cinemas-text03" src={text03} alt="text03" />
        </div>
      </div>
    </DefaultLayout>
  );
}

const mapStateToProps = ({ userReducer }) => {
  return {
    userReducer,
  };
};

const mapDispatchToProps = {
  changeModalRegister,
  changeVisible,
};

export default connect(mapStateToProps, mapDispatchToProps)(CinemasPage);
