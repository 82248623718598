import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Dates from './Dates';
import PageLoader from '../../PageLoader';
import './MovieTheater.css';
import {
  moviesPerCinema,
  moviePerCinemaLoading,
} from '../../../store/actions/GlobalActions';
import { changeModalLogin } from '../../../store/actions/UserActions';
import { getCinemaName } from '../../../Utils/currentActions';

class MovieTheater extends Component {
  componentDidMount() {
    const {
      globalReducer: { global_cinema_movies_loading },
      match: {
        params: { movie },
      },
      moviesPerCinema,
    } = this.props;

    if (global_cinema_movies_loading) {
      moviesPerCinema(movie);
    }
  }

  componentWillUnmount() {
    this.props.moviePerCinemaLoading(true);
  }

  renderCinemas = () => {
    const {
      globalReducer: { global_cinema_movies, global_setting_payments },
      userReducer: { user },
      match: {
        params: { city },
      },
      changeModalLogin,
    } = this.props;

    let response = global_cinema_movies.map((cinema) => {
      if (!cinema.movies.length) {
        return null;
      }

      return (
        <div className="MovieTheater_container" key={cinema.id}>
          <h4>{getCinemaName(cinema.nombre).toUpperCase()}</h4>
          {cinema.movies.map((movie, index1) => {
            let list_showtimes = movie.list_showtimes;
            list_showtimes = list_showtimes.sort(
              (a, b) =>
                parseInt(a.hora_funcion.replaceAll(':', '')) -
                parseInt(b.hora_funcion.replaceAll(':', ''))
            );

            return (
              <div key={index1}>
                <div className="MovieTheater_subtitle">
                  {movie.version} - {movie.formato}
                </div>
                {list_showtimes.length ? (
                  <div className="MovieTheater_hours">
                    {list_showtimes.map((showtime, index2) => {
                      if (!global_setting_payments || !cinema.can_sell) {
                        return (
                          <div className="button" key={index2}>
                            {showtime.hora_cadena}
                          </div>
                        );
                      }
                      if (user) {
                        return (
                          <Link
                            to={`/checkout/ciudad/${city}/pelicula/${movie.slug}/id/${movie.id}/showtime/${showtime.id}`}
                            key={index2}
                          >
                            {showtime.hora_cadena}
                          </Link>
                        );
                      }
                      return (
                        <button
                          type="button"
                          onClick={() => changeModalLogin('visible')}
                          key={index2}
                        >
                          {showtime.hora_cadena}
                        </button>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      );
    });

    response = response.filter((item) => item);

    if (!response.length) {
      return (
        <h3 className="text-center mb-4">
          No hay funciones para la fecha seleccionada.
        </h3>
      );
    }

    return response;
  };

  render() {
    const {
      globalReducer: { global_cinema_movies_loading },
    } = this.props;

    return (
      <div className="MovieTheater">
        {global_cinema_movies_loading ? (
          <PageLoader />
        ) : (
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h4>SELECCIONA UNA FECHA</h4>
                <Dates />
                <h2>TEATROS</h2>
                {this.renderCinemas()}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ globalReducer, userReducer }) => {
  return {
    globalReducer,
    userReducer,
  };
};

const mapDispatchToProps = {
  moviesPerCinema,
  moviePerCinemaLoading,
  changeModalLogin,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MovieTheater)
);
