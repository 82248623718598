import {
  COUPON_LIST,
  COUPON_LOADING,
  COUPON_MY_LIST,
  COUPON_DETAIL,
  COUPON_ERROR,
  COUPON_SHOW_LEGALS,
  COUPON_AMOUNT,
  COUPON_TOTAL,
} from '../constants/Coupon';

const INITIAL_STATE = {
  coupon_list: [],
  coupon_loading: true,
  coupon_my_list: [],
  coupon_detail: null,
  coupon_error: null,
  coupon_show_legals: '',
  coupon_amount: 0,
  coupon_total: 0,
};

const couponReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case COUPON_LIST:
      return {
        ...state,
        coupon_list: action.payload,
      };
    case COUPON_LOADING:
      return {
        ...state,
        coupon_loading: action.payload,
      };
    case COUPON_MY_LIST:
      return {
        ...state,
        coupon_my_list: action.payload,
      };
    case COUPON_DETAIL:
      return {
        ...state,
        coupon_detail: action.payload,
      };
    case COUPON_ERROR:
      return {
        ...state,
        coupon_error: action.payload,
      };
    case COUPON_SHOW_LEGALS:
      return {
        ...state,
        coupon_show_legals: action.payload,
      };
    case COUPON_AMOUNT:
      return {
        ...state,
        coupon_amount: action.payload,
      };
    case COUPON_TOTAL:
      return {
        ...state,
        coupon_total: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default couponReducer;
