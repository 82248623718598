/* eslint-disable import/no-anonymous-default-export */
import {
  GLOBAL_CITIES,
  GLOBAL_MOVIES,
  GLOBAL_MOVIE,
  GLOBAL_LOADING_CITIES,
  GLOBAL_LOADING_MOVIES,
  GLOBAL_LOADING_MOVIE,
  GLOBAL_CITY,
  GLOBAL_ALL_MOVIES,
  GLOBAL_MODAL_LOADING,
  GLOBAL_CINEMAS,
  GLOBAL_CINEMA,
  GLOBAL_ALL_CINEMAS,
  GLOBAL_DATE,
  GLOBAL_MOVIE_CITY,
  GLOBAL_MOVIE_SLUG,
  GLOBAL_MOVIE_ID,
  GLOBAL_MOVIE_CLEAR,
  GLOBAL_CINEMA_MOVIES,
  GLOBAL_CINEMA_MOVIES_LOADING,
  GLOBAL_DATES,
  GLOBAL_MENU_MOBILE,
  GLOBAL_MOVIES_VIEW,
  GLOBAL_BANNERS,
  GLOBAL_CINEMA_LIST,
  GLOBAL_SETTING_CARD,
  GLOBAL_SETTING_PSE,
  GLOBAL_SETTING_PAYMENTS,
  GLOBAL_SETTINGS,
  GLOBAL_CINE_MAS,
  GLOBAL_CINEMAS_GET,
} from '../constants/Global';

const d = new Date();
let year = d.getFullYear();
let month = d.getMonth() + 1;
let day = d.getDate();
month = month < 10 ? `0${month}` : month;
day = day < 10 ? `0${day}` : day;

const INITIAL_STATE = {
  global_cities: [],
  global_banners: [],
  global_loading_cities: true,
  global_all_movies: [],
  global_movies: [],
  global_movies_view: [],
  global_loading_movies: true,
  global_movie: null,
  global_loading_movie: true,
  global_city: null,
  global_modal_loading: '',
  global_modal_message: '',
  global_all_cinemas: [],
  global_cinemas: [],
  global_cinemas_get: false,
  global_cinema: '',
  global_current_date: `${year}-${month}-${day}`,
  global_date: null,
  global_dates: null,
  global_movie_city: null,
  global_movie_slug: null,
  global_movie_id: null,
  global_cinema_movies: [],
  global_cinema_movies_loading: true,
  global_menu_mobile: '',
  global_cinema_list: [],
  global_setting_card: 1,
  global_setting_pse: 1,
  global_setting_payments: 0,
  global_settings: [],
  global_cine_mas: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GLOBAL_CITIES:
      return {
        ...state,
        global_cities: action.payload,
        global_loading_cities: false,
      };
    case GLOBAL_BANNERS:
      return {
        ...state,
        global_banners: action.payload,
      };
    case GLOBAL_LOADING_CITIES:
      return {
        ...state,
        global_loading_cities: action.payload,
      };
    case GLOBAL_MOVIES:
      return {
        ...state,
        global_movies: action.payload,
        global_loading_movies: false,
      };
    case GLOBAL_LOADING_MOVIES:
      return {
        ...state,
        global_loading_movies: action.payload,
      };
    case GLOBAL_MOVIE:
      return {
        ...state,
        global_movie: action.payload,
      };
    case GLOBAL_LOADING_MOVIE:
      return {
        ...state,
        global_loading_movie: action.payload,
      };
    case GLOBAL_CITY:
      return {
        ...state,
        global_city: action.payload,
      };
    case GLOBAL_ALL_MOVIES:
      return {
        ...state,
        global_all_movies: action.payload,
      };
    case GLOBAL_MODAL_LOADING:
      return {
        ...state,
        global_modal_loading: action.payload.status,
        global_modal_message: action.payload.message,
      };
    case GLOBAL_ALL_CINEMAS:
      return {
        ...state,
        global_all_cinemas: action.payload,
      };
    case GLOBAL_CINEMAS:
      return {
        ...state,
        global_cinemas: action.payload,
      };
    case GLOBAL_CINEMA:
      return {
        ...state,
        global_cinema: action.payload,
      };
    case GLOBAL_DATE:
      return {
        ...state,
        global_date: action.payload,
      };
    case GLOBAL_MOVIE_CITY:
      return {
        ...state,
        global_movie_city: action.payload,
      };
    case GLOBAL_MOVIE_SLUG:
      return {
        ...state,
        global_movie_slug: action.payload,
      };
    case GLOBAL_MOVIE_ID:
      return {
        ...state,
        global_movie_id: action.payload,
      };
    case GLOBAL_MOVIE_CLEAR:
      return {
        ...state,
        global_date: null,
        global_movie_city: null,
        global_movie_slug: null,
        global_movie_id: null,
        global_movie: null,
        global_cinema_movies: [],
        global_loading_movie: true,
        global_cinema_movies_loading: true,
        global_dates: null,
      };
    case GLOBAL_CINEMA_MOVIES:
      return {
        ...state,
        global_cinema_movies: action.payload,
      };
    case GLOBAL_CINEMA_MOVIES_LOADING:
      return {
        ...state,
        global_cinema_movies_loading: action.payload,
      };
    case GLOBAL_DATES:
      return {
        ...state,
        global_dates: action.payload,
      };
    case GLOBAL_MENU_MOBILE:
      return {
        ...state,
        global_menu_mobile: action.payload,
      };
    case GLOBAL_MOVIES_VIEW:
      return {
        ...state,
        global_movies_view: action.payload,
      };
    case GLOBAL_CINEMA_LIST:
      return {
        ...state,
        global_cinema_list: action.payload,
      };
    case GLOBAL_SETTING_CARD:
      return {
        ...state,
        global_setting_card: action.payload,
      };
    case GLOBAL_SETTING_PSE:
      return {
        ...state,
        global_setting_pse: action.payload,
      };
    case GLOBAL_SETTING_PAYMENTS:
      return {
        ...state,
        global_setting_payments: action.payload,
      };
    case GLOBAL_SETTINGS:
      return {
        ...state,
        global_settings: action.payload,
      };
    case GLOBAL_CINE_MAS:
      return {
        ...state,
        global_cine_mas: action.payload,
      };
    case GLOBAL_CINEMAS_GET:
      return {
        ...state,
        global_cinemas_get: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
