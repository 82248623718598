import React from 'react';
import { Link } from 'react-router-dom';
import { getCurrentDate } from '../../functions/currentFunctions';
import './MovieBox.css';
import { connect } from 'react-redux';

function MovieBox(props) {
  const {
    globalReducer: { global_city },
    slug,
    link,
    titulo_original,
    titulo,
    tipo,
    fecha_estreno,
    imagen,
    first_showtime,
    size,
    box_type,
    imageLink,
  } = props;
  const currentDate = getCurrentDate();
  let currentLink = link ? `${link}/` : '/';
  const backgroundColor =
    tipo && (tipo === 'Estreno' || tipo === 'Preventa') ? 'orange' : 'red';

  if (tipo === 'Preventa' && global_city) {
    currentLink = `/ciudad/${global_city}/`;
  }

  if (box_type === 'info') {
    return (
      <div className={`MovieBox white ${size}`}>
        {imageLink ? (
          <Link to={imageLink}>
            <img src={imagen} alt={titulo} />
          </Link>
        ) : (
          <img src={imagen} alt={titulo} />
        )}
        <div className="MovieBox_title">
          <h3>{titulo}</h3>
          <p>{titulo_original}</p>
        </div>
        <div className="MovieBox_content">{props.children}</div>
      </div>
    );
  }

  return (
    <Link
      to={`${currentLink}pelicula/${slug}`}
      className={`MovieBox ${backgroundColor} ${size}`}
    >
      <img src={imagen} alt={titulo} />
      <div className="MovieBox_title">
        <h3>{titulo}</h3>
        <p>{titulo_original}</p>
      </div>
      {tipo && (tipo === 'Estreno' || tipo === 'Preventa') && (
        <div className="MovieBox_type orange">{tipo.toUpperCase()}</div>
      )}
      {first_showtime && tipo && tipo === 'Normal' && (
        <div className="MovieBox_type red">
          {first_showtime.fecha_funcion === currentDate
            ? `HOY`
            : first_showtime.fecha_cadena.toUpperCase()}
        </div>
      )}
      {fecha_estreno && (
        <div className="MovieBox_date red">
          <b>Fecha de estreno</b>
          <br />
          {fecha_estreno}
        </div>
      )}
    </Link>
  );
}

MovieBox.defaultProps = {
  size: '',
  imageLink: null,
};

const mapStateToProps = ({ globalReducer }) => {
  return {
    globalReducer,
  };
};

export default connect(mapStateToProps, null)(MovieBox);
