import { CONTENT_DETAIL, CONTENT_LOADING } from '../constants/Content';

const INITIAL_STATE = {
  content_loading: true,
  content_detail: null,
};

const contentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONTENT_LOADING:
      return {
        ...state,
        content_loading: action.payload,
      };
    case CONTENT_DETAIL:
      return {
        ...state,
        content_detail: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default contentReducer;
