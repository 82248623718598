import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './TheaterSchedule.css';
import { theaterSchedule } from '../../../store/actions/TheaterActions';

function TheaterSchedule(props) {
  const {
    theaterReducer: { theater_detail },
  } = props;
  const [schedule, setSchedule] = useState(null);

  useEffect(() => {
    const { timetables } = theater_detail;

    if (timetables.length) {
      setSchedule(timetables[0]);
    }

    return () => {
      setSchedule(null);
    };
  }, [theater_detail]);

  if (!schedule) {
    return null;
  }

  return (
    <div className="TheaterSchedule_container">
      <h2>
        Precios Boletería{' '}
        {theater_detail.nombre_completo ?? theater_detail.nombre}
      </h2>
      <div className="TheaterSchedule_content">
        <div className="TheaterSchedule_header">
          <div className="TheaterSchedule_menu">
            {theater_detail.timetables.map((item) => {
              return (
                <button
                  className={`TheaterSchedule_button ${
                    schedule.id === item.id ? 'active' : ''
                  }`}
                  key={item.id}
                  onClick={() => setSchedule(item)}
                >
                  {item.title}
                </button>
              );
            })}
          </div>
        </div>
        <div className="TheaterSchedule_body">
          <div className="table-responsive">
            <div
              className="TheaterSchedule_table"
              dangerouslySetInnerHTML={{ __html: schedule.schedules }}
            />
          </div>
        </div>
      </div>
      <h3>*Los precios están sujetos a cambios sin previo aviso*</h3>
    </div>
  );
}

const mapStateToProps = ({ theaterReducer }) => {
  return {
    theaterReducer,
  };
};

const mapDispatchToProps = {
  theaterSchedule,
};

export default connect(mapStateToProps, mapDispatchToProps)(TheaterSchedule);
