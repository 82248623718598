import React from 'react';
import './TheaterTitle.css';

function TheaterTitle(props) {
  const { name, address, size } = props;
  return (
    <div className={`TheaterTitle_content ${size}`}>
      <div className="TheaterTitle_name">
        <span>{name}</span>
      </div>
      <div className="TheaterTitle_address">
        <span>{address}</span>
      </div>
    </div>
  );
}

TheaterTitle.defaultProps = {
  size: '',
};

export default TheaterTitle;
