import React, { Component } from 'react';
import { connect } from 'react-redux';
import CouponDetail from '../../components/CouponDetail';
import PageLoader from '../../components/PageLoader';
import DefaultLayout from '../../Layouts/DefaultLayout';
import {
  getCoupon,
  couponLoading,
  couponError,
} from '../../store/actions/CouponActions';

class CouponPage extends Component {
  componentDidMount() {
    const {
      match: {
        params: { coupon },
      },
      getCoupon,
    } = this.props;

    getCoupon(coupon);
  }

  componentWillUnmount() {
    this.props.couponLoading(true);
    this.props.couponError(null);
  }

  render() {
    const {
      couponReducer: { coupon_loading },
    } = this.props;

    return (
      <DefaultLayout>
        {coupon_loading ? <PageLoader /> : <CouponDetail />}
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ couponReducer }) => {
  return {
    couponReducer,
  };
};

const mapDisptachToProps = {
  getCoupon,
  couponLoading,
  couponError,
};

export default connect(mapStateToProps, mapDisptachToProps)(CouponPage);
