import React, { Component } from 'react';
import { connect } from 'react-redux';
import DefaultLayout from '../../Layouts/DefaultLayout';
import MovieDetails from '../../components/Movie/MovieDetails';
import PageLoader from '../../components/PageLoader';
import { movieClear } from '../../store/actions/GlobalActions';
import { getMovieDetail } from '../../store/actions/MovieActions';

class NextReleasePage extends Component {
  componentDidMount() {
    const {
      match: {
        params: { movie },
      },
      getMovieDetail,
    } = this.props;

    getMovieDetail(movie);
  }

  componentWillUnmount() {
    this.props.movieClear();
  }

  render() {
    const {
      globalReducer: { global_movie },
    } = this.props;

    return (
      <DefaultLayout>
        {global_movie ? <MovieDetails /> : <PageLoader />}
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ movieReducer, globalReducer }) => {
  return {
    movieReducer,
    globalReducer,
  };
};

const mapDispatchToProps = {
  movieClear,
  getMovieDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(NextReleasePage);
