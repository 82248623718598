import React from 'react';
import { Link } from 'react-router-dom';

function ModalCinema({ onCloseModal, cinemaName, city }) {
  return (
    <div className="Checkout_modal">
      <div className="Checkout_modal-content">
        <button onClick={onCloseModal} className="Checkout_modal-close">
          &times;
        </button>
        <h3>Vas a comprar las boletas en el teatro:</h3>
        <h2>{cinemaName}</h2>
        <div className="Checkout_modal-menu">
          <Link to={`/ciudad/${city}/teatros`}>Escoger otro teatro</Link>
          <button className="btn btn-procinal" onClick={onCloseModal}>
            Continuar
          </button>
        </div>
      </div>
    </div>
  );
}

export default ModalCinema;
