import React from 'react';
import ImaxHeader from '../ImaxHeader';
import file1 from '../../assets/images/sound.svg';
import file2 from '../../assets/images/points.svg';
import file3 from '../../assets/images/circle-location.svg';
import file4 from '../../assets/images/circles.svg';
import file5 from '../../assets/images/triangle.svg';
import file6 from '../../assets/images/circle.svg';
import file7 from '../../assets/images/pie.svg';
import file8 from '../../assets/images/check.svg';
import './Imax.css';

function Imax() {
  return (
    <>
      <ImaxHeader />
      <div className="Imax-container">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Imax-row">
                <h3>
                  Sumérjete en la experiencia
                  <br />
                  más innovadora
                  <br />
                  de cine en el mundo
                </h3>
                <p className="Imax-p-1">
                  Imax Procinal es la sala más grande de Colombia, la No. 1 en
                  Latinomérica y la 3 en el mundo.{' '}
                </p>
                <div className="Imax-title">Sonido que vibra Intensamente</div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="Imax-image">
                    <img src={file1} alt="file-1" />
                  </div>
                  <h5 className="Item-subtitle">SINTONÍA PERFECTA</h5>
                  <p>Un sonido perfectamente ajustado, siempre.</p>
                </div>
                <div className="col-md-4">
                  <div className="Imax-image">
                    <img src={file2} alt="file-2" />
                  </div>
                  <h5 className="Item-subtitle">INFINITOS PUNTOS ÓPTIMOS</h5>
                  <p>En un cine IMAX no hay asientos malos.</p>
                </div>
                <div className="col-md-4">
                  <div className="Imax-image">
                    <img src={file3} alt="file-3" />
                  </div>
                  <h5 className="Item-subtitle">UNA PRECISIÓN DESTACADA</h5>
                  <p>Ubicación de sonido alineado con láser.</p>
                </div>
              </div>
              <div className="Imax-row">
                <p className="Imax-p-2">
                  Una explosión abrumadora. Un susurro por encima de su hombro.
                  El bajo retumbante que se siente y no se oye. La combinación
                  de nuestro sistema de sonido integrado y perfectamente
                  ajustado y la orientación precisa de los parlantes le
                  garantizan que podrá oír hasta la caída de un alfiler y podrá
                  decir exactamente dónde cayó. Cada nota de la banda sonora y
                  cada roce de un zapato es más claro en IMAX con una
                  transformación completa del audio de cada película,
                  supervisada por el director de la película. Todo este sonido
                  prístino luego se lleva a un cine que se ha personalizado para
                  lograr una experiencia óptima.
                </p>
                <div className="Imax-title">
                  Imágenes verdaderamente asombrosas
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="Imax-image">
                    <img src={file4} alt="file-4" />
                  </div>
                  <h5 className="Item-subtitle">Doble proyección</h5>
                  <p>Brillo sin igual y brillante claridad.</p>
                </div>
                <div className="col-md-4">
                  <div className="Imax-image">
                    <img src={file5} alt="file-5" />
                  </div>
                  <h5 className="Item-subtitle">Remasterización artesanal</h5>
                  <p>Una perfecta ejecución de la visión del director.</p>
                </div>
                <div className="col-md-4">
                  <div className="Imax-image">
                    <img src={file6} alt="file-6" />
                  </div>
                  <h5 className="Item-subtitle">Mayor realismo</h5>
                  <p>
                    Las pantallas reflectantes personalizadas son más
                    brillantes.
                  </p>
                </div>
              </div>
              <div className="Imax-row">
                <p className="Imax-p-2">
                  La claridad, el detalle y el tamaño hacen que IMAX sea mucho
                  más que una simple película. Nuestro proceso de
                  remasterización o DMR transforma totalmente cada fotograma de
                  una película para producir la mejor versión posible de la
                  visión de un cineasta. Hay dos proyectores que funcionan
                  simultáneamente para proporcionar una imagen perfecta con un
                  equilibrio de calidez y nitidez. IMAX le ofrece lo más cercano
                  a la realidad que pueda haber experimentado.
                </p>
                <div className="Imax-title">EXPERIENCIAS ENVOLVENTES</div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="Imax-image">
                    <img src={file7} alt="file-7" />
                  </div>
                  <h5 className="Item-subtitle">MONITOREO REMOTO</h5>
                  <p>24x7x365 para un rendimiento óptimo</p>
                </div>
                <div className="col-md-4">
                  <div className="Imax-image">
                    <img src={file8} alt="file-8" />
                  </div>
                  <h5 className="Item-subtitle">CALIDAD Y CONSISTENCIA</h5>
                  <p>Diseñado y desarrollado a medida.</p>
                </div>
                <div className="col-md-4">
                  <div className="Imax-image">
                    <img src={file1} alt="file-1" />
                  </div>
                  <h5 className="Item-subtitle">IMAGEN PERFECTA</h5>
                  <p>Ajustes del sistema en tiempo real.</p>
                </div>
              </div>
              <div className="Imax-row">
                <p className="Imax-p-2">
                  Las películas se miran. Pero ver una película en un cine IMAX®
                  es mucho más que eso, es lo que llamamos The IMAX Experience®.
                  En nuestros cines se planea, diseña y coloca cada elemento con
                  los estándares más exigentes para crear la experiencia más
                  intensa. Esta ciencia es nuestra geometría del cine, y
                  garantiza la magia del cine cada vez que las luces se apagan.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Imax;
