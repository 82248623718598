import React from 'react';
import DefaultLayout from '../../Layouts/DefaultLayout';
import Response from '../../components/Response';

function ResponsePage() {
  return (
    <DefaultLayout>
      <Response />
    </DefaultLayout>
  );
}

export default ResponsePage;
