import React from 'react';
import { connect } from 'react-redux';
import { getSettingValue } from '../../../functions/currentFunctions';
import { formatDate } from '../../../store/actions/GlobalActions';
import { getCinemaName } from '../../../Utils/currentActions';

function HeaderTicket(props) {
  const {
    checkoutReducer: {
      checkout_total,
      checkout_total_transaction,
      checkout_total_tikets,
      checkout_movie,
      checkout_showtime,
      checkout_movie: { rooms },
    },
    globalReducer: { global_cinemas, global_settings },
    payTicketReducer: { quantity },
  } = props;
  const numberFormat = new Intl.NumberFormat('es-CO');
  let cinema = [];
  const room = rooms.filter(
    (room) => parseInt(room.id) === parseInt(checkout_showtime.room_id)
  );

  if (room.length) {
    cinema = global_cinemas.filter(
      (cinema) => parseInt(cinema.id) === parseInt(room[0].cinema_id)
    );
  }
  cinema = cinema.length ? cinema[0].nombre : '';

  let showtime_date = new Date(`${checkout_showtime.fecha_funcion}T01:00:00`);
  showtime_date = formatDate(showtime_date);

  return (
    <div className="PaymentMethods_resume">
      <div className="PaymentMethods_title">
        {checkout_movie.titulo.toUpperCase()}, {checkout_movie.version} -{' '}
        {checkout_movie.formato}
      </div>
      <div className="PaymentMethods_description">
        <div className="PaymentMethods_description-item">
          Teatro: <span>{getCinemaName(cinema).toUpperCase()}</span>
        </div>
        <div className="PaymentMethods_description-item">
          Número de entradas: <span>{quantity}</span>
        </div>
        <div className="PaymentMethods_description-item">
          Fecha: <span>{showtime_date.name}</span>
        </div>
        <div className="PaymentMethods_description-item">
          Hora: <span>{checkout_showtime.hora_cadena}</span>
        </div>
      </div>
      <div className="PaymentMethods_price">
        VALOR ENTRADAS:{' '}
        <strong className="color-red">
          ${numberFormat.format(checkout_total_tikets)}
        </strong>{' '}
      </div>
      <div className="PaymentMethods_price">
        SERVICIO ENTRADAS:{' '}
        <strong className="color-red">
          ${numberFormat.format(checkout_total_transaction)}
        </strong>{' '}
      </div>
      <div className="PaymentMethods_price">
        TOTAL ENTRADAS:{' '}
        <strong className="color-red">
          ${numberFormat.format(checkout_total)}
        </strong>{' '}
      </div>
      <div className="PaymentMethods_description-item small">
        Conoce nuestros términos y condiciones{' '}
        <a
          href="/terminos-y-condiciones-para-las-ventas-virtuales"
          target="_blank"
          rel="noreferrer"
        >
          aquí
        </a>
        .
      </div>
    </div>
  );
}

const mapStateToProps = ({
  checkoutReducer,
  globalReducer,
  payTicketReducer,
}) => {
  return {
    checkoutReducer,
    globalReducer,
    payTicketReducer,
  };
};

export default connect(mapStateToProps, null)(HeaderTicket);
