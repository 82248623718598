import { combineReducers } from 'redux';
import paymentReducer from './PaymentReducer';
import userReducer from './UserReducer';
import globalReducer from './GlobalReducer';
import checkoutReducer from './CheckoutReducer';
import ticketReducer from './TicketReducer';
import couponReducer from './CouponReducer';
import movieReducer from './MovieReducer';
import contentReducer from './ContentReducer';
import theaterReducer from './TheaterReducer';
import corporativeReducer from './CorporativeReducer';
import contactReducer from './ContactReducer';
import subscriptionReducer from './SubscriptionReducer';
import cinemasReducer from './CinemasReducer';
import popUpReducer from './PopUpReducer';
import confectioneryReducer from './ConfectioneryReducer';
import payTicketReducer from './PayTicketReducer';
import imaxReducer from './ImaxReducer';

export default combineReducers({
  paymentReducer,
  userReducer,
  globalReducer,
  checkoutReducer,
  ticketReducer,
  couponReducer,
  movieReducer,
  contentReducer,
  theaterReducer,
  corporativeReducer,
  contactReducer,
  subscriptionReducer,
  cinemasReducer,
  popUpReducer,
  confectioneryReducer,
  payTicketReducer,
  imaxReducer,
});
