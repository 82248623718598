import {
  CONTACT_NAME,
  CONTACT_EMAIL,
  CONTACT_PHONE,
  CONTACT_PQRS,
  CONTACT_CINEMA_ID,
  CONTACT_DESCRIPTION,
  CONTACT_LOADING,
  CONTACT_CLEAR,
} from '../constants/Contact';

const INITIAL_STATE = {
  contact_name: '',
  contact_email: '',
  contact_phone: '',
  contact_pqrs: '',
  contact_cinema_id: '',
  contact_description: '',
  contact_loading: false,
};

const contactReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONTACT_NAME:
      return {
        ...state,
        contact_name: action.payload,
      };
    case CONTACT_EMAIL:
      return {
        ...state,
        contact_email: action.payload,
      };
    case CONTACT_PHONE:
      return {
        ...state,
        contact_phone: action.payload,
      };
    case CONTACT_PQRS:
      return {
        ...state,
        contact_pqrs: action.payload,
      };
    case CONTACT_CINEMA_ID:
      return {
        ...state,
        contact_cinema_id: action.payload,
      };
    case CONTACT_DESCRIPTION:
      return {
        ...state,
        contact_description: action.payload,
      };
    case CONTACT_LOADING:
      return {
        ...state,
        contact_loading: action.payload,
      };
    case CONTACT_CLEAR:
      return {
        ...state,
        contact_name: '',
        contact_email: '',
        contact_phone: '',
        contact_pqrs: '',
        contact_cinema_id: '',
        contact_description: '',
        contact_loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default contactReducer;
