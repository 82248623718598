import React from 'react';
import { connect } from 'react-redux';
import loading from '../../assets/images/loading-white.svg';
import { changeModalLogin } from '../../store/actions/UserActions';
import { getConfectioneryDetail } from '../../Utils/confectioneryManager';

function Products(props) {
  const {
    confectioneryReducer: {
      confectionery_products,
      confectionery_loading,
      confectionery_api_loading,
      confectionery_api_products,
    },
    userReducer: { user },
    onSelectProduct,
    changeModalLogin,
  } = props;

  if (confectionery_loading || confectionery_api_loading) {
    return (
      <div className="confectionery_loading confectionery_products">
        <img src={loading} alt="Loading..." />
      </div>
    );
  }

  if (!confectionery_products) {
    return <div className="confectionery_products" />;
  }

  return (
    <div className="confectionery_products">
      <div className="row">
        <div className="col-md-12">
          <div className="confectionery_product-title">
            Listado de productos
          </div>
        </div>
        {confectionery_products.map((product) => {
          const productApi = getConfectioneryDetail(
            product.Codigo,
            confectionery_api_products
          );
          if (!productApi) return null;
          return (
            <div className="col-6 col-sm-6 col-md-3" key={product.Codigo}>
              <div className="confectionery_product">
                <img src={productApi.image} alt={product.Descripcion} />
                <div className="confectionery_product-name">
                  {productApi.name}
                  {user ? (
                    <button onClick={() => onSelectProduct(product)}>
                      COMPRAR
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => changeModalLogin('visible')}
                    >
                      COMPRAR
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const mapStateToProps = ({ confectioneryReducer, userReducer }) => {
  return {
    confectioneryReducer,
    userReducer,
  };
};

const mapDisptachToProps = {
  changeModalLogin,
};

export default connect(mapStateToProps, mapDisptachToProps)(Products);
