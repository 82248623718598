import React from 'react';
import { connect } from 'react-redux';
import { changeCouponAmount } from '../../../store/actions/CouponActions';
import { changeVisible } from '../../../store/actions/PaymentActions';
import epayco from '../../../assets/images/epayco-white.png';
import './CouponPayment.css';

function CouponPayment(props) {
  const {
    couponReducer: { coupon_detail, coupon_amount, coupon_total },
    changeCouponAmount,
    changeVisible,
  } = props;
  const numberFormat = new Intl.NumberFormat('en-CO');

  return (
    <div className="CouponPayment_resume">
      <div className="CouponPayment_box">
        <h4>DETALLE COMPRA</h4>
        <div className="CouponPayment_row">
          <div>Valor Unidad:</div>
          <div>
            ${numberFormat.format(coupon_detail.price).replace(',', '.')}
          </div>
        </div>
        <div className="CouponPayment_row">
          <div>Total Cupones</div>
          <div>
            <div className="CouponPayment_box-couter">
              <button
                type="button"
                onClick={() => changeCouponAmount('remove')}
              >
                -
              </button>
              <div className="CouponPayment_couter">{coupon_amount}</div>
              <button type="button" onClick={() => changeCouponAmount('add')}>
                +
              </button>
            </div>
          </div>
        </div>
        <div className="CouponPayment_total CouponPayment_row">
          <div>TOTAL:</div>
          <div>${numberFormat.format(coupon_total).replace(',', '.')}</div>
        </div>
        <button
          type="button"
          className="CouponPayment_button"
          onClick={() => changeVisible('visible', 2)}
        >
          Pagar con
          <img src={epayco} alt="Logo Epayco" />
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = ({ couponReducer }) => {
  return {
    couponReducer,
  };
};

const mapDispatchToProps = {
  changeCouponAmount,
  changeVisible,
};

export default connect(mapStateToProps, mapDispatchToProps)(CouponPayment);
