import {
  PAY_TICKET,
  PAY_TICKET_SHOWTIME,
  PAY_TICKET_NAME,
  PAY_TICKET_LASTNAME,
  PAY_TICKET_EMAIL,
  PAY_TICKET_CONFIRM_EMAIL,
  PAY_TICKET_DOC_NUMBER,
  PAY_TICKET_PHONE,
  PAY_TICKET_QUANTITY,
  PAY_TICKET_TOTAL,
  PAY_TICKET_CLEAR,
  PAY_TICKET_LOADING,
  PAY_TICKET_REDIRECT,
} from '../constants/PayTicket';

const INITIAL_STATE = {
  name: '',
  lastname: '',
  email: '',
  confirm_email: '',
  doc_number: '',
  phone: '',
  quantity: 1,
  total: '',
  loading: false,
  redirect: '',
  ticket: null,
  showtime: null,
};

const payTicketReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PAY_TICKET:
      return {
        ...state,
        ticket: action.payload,
      };
    case PAY_TICKET_SHOWTIME:
      return {
        ...state,
        showtime: action.payload,
      };
    case PAY_TICKET_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case PAY_TICKET_LASTNAME:
      return {
        ...state,
        lastname: action.payload,
      };
    case PAY_TICKET_CONFIRM_EMAIL:
      return {
        ...state,
        confirm_email: action.payload,
      };
    case PAY_TICKET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case PAY_TICKET_DOC_NUMBER:
      return {
        ...state,
        doc_number: action.payload,
      };
    case PAY_TICKET_PHONE:
      return {
        ...state,
        phone: action.payload,
      };
    case PAY_TICKET_QUANTITY:
      return {
        ...state,
        quantity: action.payload,
      };
    case PAY_TICKET_TOTAL:
      return {
        ...state,
        total: action.payload,
      };
    case PAY_TICKET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case PAY_TICKET_REDIRECT:
      return {
        ...state,
        redirect: action.payload,
      };
    case PAY_TICKET_CLEAR:
      return {
        ...state,
        name: '',
        lastname: '',
        email: '',
        confirm_email: '',
        doc_number: '',
        phone: '',
        quantity: 1,
        total: '',
        loading: false,
        redirect: '',
        ticket: null,
        showtime: null,
      };
    default:
      return { ...state };
  }
};

export default payTicketReducer;
