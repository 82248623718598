import React from 'react';
import { connect } from 'react-redux';
import CombosDescription from '../CombosDescription';

function HeaderConfectionery(props) {
  const {
    globalReducer: { global_cinemas },
    confectioneryReducer: { confectionery_theater },
  } = props;

  const getCinemaName = () => {
    let cinemaName = '';
    if (global_cinemas && global_cinemas.length) {
      let cinema = global_cinemas.find(
        (cinema) => cinema.score_id === confectionery_theater
      );

      if (cinema) {
        cinemaName = cinema.nombre_completo || cinema.nombre;
      }
    }

    return cinemaName.toUpperCase();
  };

  return (
    <div className="PaymentMethods_resume">
      <div className="PaymentMethods_title">COMPRA DE CONFITERÍA</div>
      <div className="PaymentMethods_title">
        Reclamar en:{' '}
        <strong>
          <span className="color-red">{getCinemaName()}</span>
        </strong>
      </div>
      <div className="PaymentMethods_description">
        <CombosDescription />
        <div className="PaymentMethods_description-item small">
          Conoce nuestros términos y condiciones{' '}
          <a
            href="/terminos-y-condiciones-para-las-ventas-virtuales"
            target="_blank"
            rel="noreferrer"
          >
            aquí
          </a>
          .
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ globalReducer, confectioneryReducer }) => {
  return {
    globalReducer,
    confectioneryReducer,
  };
};

export default connect(mapStateToProps, null)(HeaderConfectionery);
