import React from 'react';
import { connect } from 'react-redux';
import {
  subscriptionChangeCity,
  subscriptionChangeTheater,
  subscriptionChangeRecharge,
  subscriptionChangeTerms,
} from '../../../store/actions/SubscriptionActions';

const { REACT_APP_CHARGE, REACT_APP_PRICE_SUBSCRIPTION } = process.env;

function HeaderSubscription(props) {
  const {
    globalReducer: { global_cities, global_cine_mas },
    subscriptionReducer: {
      subscription_total,
      subscription_city,
      subscription_theaters,
      subscription_terms,
    },
    subscriptionChangeCity,
    subscriptionChangeTheater,
    subscriptionChangeTerms,
  } = props;
  const numberFormat = new Intl.NumberFormat('es-CO');
  const checkedTerms = subscription_terms ? 'checked' : '';
  const priceSubscription = global_cine_mas
    ? global_cine_mas.price_subscription
    : REACT_APP_PRICE_SUBSCRIPTION;
  const priceCharge = global_cine_mas
    ? global_cine_mas.charge_service
    : REACT_APP_CHARGE;

  return (
    <div className="PaymentMethods_resume">
      <div className="PaymentMethods_title">
        Adquirir <strong className="color-red">CINE+</strong>
      </div>
      <div className="PaymentMethods_subscription">
        En esta compra <strong>vas a pagar</strong> lo siguiente:
        <ul>
          <li>
            Afiliación a <strong className="color-red">CINE+</strong>
          </li>
          <li>Cargos por servicio</li>
          <li>
            Ten presente que el teatro que hayas seleccionado será el unico
            donde pordrás reclamar tu obsequio por afiliarte.
          </li>
        </ul>
      </div>
      <div className="PaymentMethods_description">
        <div className="form-group">
          Ciudad:
          <select
            className="form-control"
            onChange={(input) => subscriptionChangeCity(input.target.value)}
            value={subscription_city}
          >
            <option value="">-- Seleccionar --</option>
            {global_cities.map((city) => (
              <option value={city.id} key={city.id}>
                {city.nombre}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          Selecciona el teatro donde vas a hacer tu primer compra:
          <select
            className="form-control"
            onChange={(input) => subscriptionChangeTheater(input.target.value)}
          >
            <option value="">-- Seleccionar --</option>
            {subscription_theaters.map((theater) => (
              <option value={theater.score_id} key={theater.score_id}>
                {theater.nombre_completo}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          Valor afiliación:{' '}
          <strong className="SliderRecharge-price">
            $ {numberFormat.format(parseInt(priceSubscription))}
          </strong>
        </div>
        <div className="form-group">
          Cargo por servicio:{' '}
          <strong className="SliderRecharge-price">
            $ {numberFormat.format(parseInt(priceCharge))}
          </strong>
        </div>
      </div>
      <div className="PaymentMethods_price">
        TOTAL A PAGAR:{' '}
        <strong>$ {numberFormat.format(subscription_total)}</strong>
      </div>
      <div className="form-group Payment_subscription-tc">
        <label className="form-label">
          <input
            type="checkbox"
            checked={checkedTerms}
            onChange={subscriptionChangeTerms}
          />
          Acepto los{' '}
          <a
            href="https://www.procinal.com.co/terminos-y-condiciones-del-programa-cine"
            target="_blank"
            rel="noreferrer"
          >
            términos y condiciones de CINE+
          </a>
        </label>
      </div>
    </div>
  );
}

const mapStateToProps = ({ globalReducer, subscriptionReducer }) => {
  return {
    globalReducer,
    subscriptionReducer,
  };
};

const mapDispatchToProps = {
  subscriptionChangeCity,
  subscriptionChangeTheater,
  subscriptionChangeRecharge,
  subscriptionChangeTerms,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSubscription);
