import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import checkout from '../../assets/images/checkout.png';
import './Status.css';
import {
  getPaymentStatus,
  changeRedirect,
  changeVisible,
} from '../../store/actions/PaymentActions';
import PageLoader from '../PageLoader';
import Resume from '../Confectionery/Resume';
import { getConfectioneryDetail } from '../../Utils/confectioneryManager';

class Status extends Component {
  componentDidMount() {
    const {
      match: {
        params: { bill },
      },
      getPaymentStatus,
    } = this.props;

    getPaymentStatus(bill);
  }

  render() {
    const {
      paymentReducer: { payment_response },
      userReducer: { user },
      match: {
        params: { bill },
      },
      confectioneryReducer: {
        confectionery_api_loading,
        confectionery_api_products,
      },
      changeVisible,
    } = this.props;

    if (!payment_response || confectionery_api_loading) {
      return <PageLoader />;
    }

    const {
      cinema,
      data_sale,
      epayco_response,
      showtime,
      coupon,
      payment_status,
    } = payment_response;

    const numberFormat = new Intl.NumberFormat('es-CO');

    return (
      <div className="Status">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Status_container">
                {showtime !== undefined && showtime ? (
                  <h2>{showtime.movie.titulo.toUpperCase()}</h2>
                ) : coupon ? (
                  <h2>{coupon.name.toUpperCase()}</h2>
                ) : (
                  <h2>
                    {epayco_response.descripcio
                      ? epayco_response.descripcion
                      : payment_status.descripcion}
                  </h2>
                )}
                <div className="Status_content">
                  <img src={checkout} alt="checkout" />
                  <div className="Status_header">
                    <h3>Order Completada</h3>
                    <p>
                      Tu Orden fue Recibida el:{' '}
                      {epayco_response.fecha
                        ? epayco_response.fecha
                        : payment_status.created_at}
                    </p>
                  </div>
                  <div className="Status_body">
                    <h3>Resumen de la Orden</h3>
                    {showtime !== undefined && showtime ? (
                      <>
                        <p>
                          <strong>Teatro:</strong>{' '}
                          {cinema.nombre_completo
                            ? cinema.nombre_completo.toUpperCase()
                            : cinema.nombre.toUpperCase()}
                        </p>
                        <p>
                          <strong>Sala:</strong> {showtime.room.numero_sala}
                        </p>
                        <p>
                          <strong>Estado de pago:</strong>{' '}
                          {epayco_response.estado
                            ? epayco_response.estado
                            : payment_status.estado}
                        </p>
                        <p>
                          <strong>Orden No:</strong> {bill}
                        </p>
                        <p>
                          <strong>Email de envío:</strong>{' '}
                          {data_sale.CorreoCliente}
                        </p>
                        <p>
                          <strong>Fecha Función:</strong>{' '}
                          {showtime.fecha_funcion}
                        </p>
                        <p>
                          <strong>Hora Función:</strong> {showtime.hora_cadena}
                        </p>
                        <p>
                          <strong>Método de Pago:</strong>{' '}
                          {epayco_response.franquicia
                            ? epayco_response.franquicia
                            : epayco_response.banco
                            ? epayco_response.banco
                            : payment_status.banco}
                        </p>
                        <div className="mb-10">
                          <strong>Sillas: </strong>
                          {data_sale.Ubicaciones.map((item, index) => (
                            <span key={index}>
                              {item.FilRelativa}
                              {item.ColRelativa}
                              {index < data_sale.Ubicaciones.length - 1
                                ? ' - '
                                : ''}
                            </span>
                          ))}
                        </div>
                      </>
                    ) : (
                      <>
                        <p>
                          <strong>Estado de pago:</strong>{' '}
                          {epayco_response.estado
                            ? epayco_response.estado
                            : payment_status.estado}
                        </p>
                        <p>
                          <strong>Orden No:</strong> {bill}
                        </p>
                        {user && (
                          <p>
                            <strong>Email de envío:</strong> {user.email}
                          </p>
                        )}
                      </>
                    )}
                    {data_sale.Productos.length ? (
                      <div className="mb-10">
                        <strong>Productos confitería: </strong>
                        {data_sale.Productos.map((recipe, index) => {
                          const productApi = getConfectioneryDetail(
                            recipe.Codigo,
                            confectionery_api_products
                          );
                          if (!productApi) return null;
                          return (
                            <div key={`${recipe.Codigo}-${index}`}>
                              {productApi.name}
                              :
                              <Resume
                                recipe={recipe.Receta}
                                showTitle={false}
                              />
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                    {parseInt(epayco_response.cod_respuesta) === 1 &&
                      parseInt(payment_status.payment_type_id) === 1 && (
                        <>
                          <hr />
                          <div>
                            <strong>Gracias por tu compra.</strong> En un plazo
                            de 15 minutos recibirás tus entradas a tu email
                            registrado. Recuerda revisar tu bandeja de entrada y
                            la carpeta de correos no deseados (spam).{' '}
                            <strong>Que disfrutes tu función.</strong>
                          </div>
                        </>
                      )}
                    {parseInt(payment_status.codigo_respuesta) === 1 &&
                      (parseInt(payment_status.payment_type_id) === 3 ||
                        parseInt(payment_status.payment_type_id) === 4) && (
                        <div className="d-flex justify-content-center">
                          <button
                            className="btn btn-procinal"
                            onClick={() => changeVisible('visible', 4)}
                          >
                            Hacer una recarga
                          </button>
                        </div>
                      )}
                  </div>
                  <div className="Status_footer">
                    VALOR:{' '}
                    <span>
                      $
                      {numberFormat.format(
                        parseInt(
                          epayco_response.valor
                            ? epayco_response.valor
                            : payment_status.valor
                        )
                      )}
                    </span>
                  </div>
                  {user && (
                    <>
                      <hr />
                      {showtime ? (
                        <Link to="/tickets" className="Status_tickets">
                          MIS TICKETS
                        </Link>
                      ) : coupon ? (
                        <Link to="/mis-cupones" className="Status_tickets">
                          MIS CUPONES
                        </Link>
                      ) : (
                        <Link to="/perfil" className="Status_tickets">
                          MI PERFIL
                        </Link>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  paymentReducer,
  userReducer,
  confectioneryReducer,
}) => {
  return {
    paymentReducer,
    userReducer,
    confectioneryReducer,
  };
};

const mapDispatchToProps = {
  getPaymentStatus,
  changeRedirect,
  changeVisible,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Status));
