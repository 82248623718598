import React from 'react';
import Corporative from '../../components/Corporative';
import DefaultLayout from '../../Layouts/DefaultLayout';

function CorporativePage() {
  return (
    <DefaultLayout>
      <Corporative />
    </DefaultLayout>
  );
}

export default CorporativePage;
