export const getConfectioneryDetail = (score_id, products) => {
  const confectionery = products.find(
    (product) => product.score_id === score_id
  );
  if (confectionery) {
    return confectionery;
  }

  return null;
};

export const getTotalsRecipe = (recipe = []) => {
  let totalCinemas = 0;
  let totalOthers = 0;

  if (!recipe || !recipe.length) {
    return {
      totalCinemas,
      totalOthers,
    };
  }

  recipe.forEach((item) => {
    if (item.Tipo === 'P') {
      const prices = item.Precios[0];
      totalCinemas = totalCinemas += prices.PagoInterno;
      totalOthers = totalOthers += prices.General;
    } else {
      item.Receta.forEach((product) => {
        const prices = product.Precios[0];
        totalCinemas = totalCinemas += prices.PagoInterno;
        totalOthers = totalOthers += prices.General;
      });
    }
  });

  return {
    totalCinemas,
    totalOthers,
  };
};

export const getTotalCombos = (combos) => {
  let totalCinemas = 0;
  let totalOthers = 0;

  if (!combos || !combos.length) {
    return {
      totalCinemas,
      totalOthers,
      saving: 0,
    };
  }

  combos.forEach((combo) => {
    let totals = getTotalsRecipe(combo.Receta);
    totals = {
      totalCinemas: totals.totalCinemas * combo.Cantidad,
      totalOthers: totals.totalOthers * combo.Cantidad,
    };
    totalCinemas = totalCinemas + totals.totalCinemas;
    totalOthers = totalOthers + totals.totalOthers;
  });

  return {
    totalCinemas,
    totalOthers,
    saving: totalOthers - totalCinemas,
  };
};

export const getCombosToPay = (selectedCombos, paymentType, productsApi) => {
  const price = paymentType === 'Deshabilitado' ? 1 : 3;
  const combos = [];
  const combosBepass = [];
  let description = '';

  selectedCombos.forEach((item, index) => {
    const firstDesctiption = getConfectioneryDetail(item.Codigo, productsApi);
    const firstName = firstDesctiption
      ? firstDesctiption.name
      : item.Descripcion;
    description += `${index > 0 ? ', ' : ''}${item.Cantidad} ${firstName}`;
    for (let i = 0; i < item.Cantidad; i++) {
      let products = [];
      let productsBepass = [];

      item.Receta.forEach((recipe) => {
        let data = {
          Codigo: recipe.Codigo,
          Descripcion: recipe.Descripcion,
          Tipo: recipe.Tipo,
          Cantidad: recipe.Cantidad,
        };
        if (recipe.Tipo === 'A') {
          let products = [];
          recipe.Receta.forEach((product) => {
            let data = {
              Cantidad: product.Cantidad,
              Codigo: product.Codigo,
              Descripcion: product.Descripcion,
              Tipo: product.Tipo,
            };
            let productDescription = getConfectioneryDetail(
              product.Codigo,
              productsApi
            );
            let description =
              productDescription.category_id === 1
                ? productDescription.name
                : `${productDescription.category}: ${productDescription.name}`;
            products.push(data);
            productsBepass.push({
              ...data,
              Descripcion: description,
            });
          });
          data.Receta = products;
        } else {
          let productDescription = getConfectioneryDetail(
            recipe.Codigo,
            productsApi
          );
          let description =
            productDescription.category_id === 1
              ? productDescription.name
              : `${productDescription.category}: ${productDescription.name}`;
          productsBepass.push({
            ...data,
            Descripcion: description,
          });
        }

        products.push(data);
      });

      let data = {
        Codigo: item.Codigo,
        Descripcion: item.Descripcion,
        Tipo: item.Tipo,
        Precio: price,
        Receta: products,
      };

      let productDescription = getConfectioneryDetail(item.Codigo, productsApi);
      let description =
        productDescription.category_id === 1
          ? productDescription.name
          : `${productDescription.category}: ${productDescription.name}`;

      combos.push(data);
      combosBepass.push({
        ...data,
        Descripcion: description,
        Receta: productsBepass,
      });
    }
  });

  return { combos, description, combosBepass };
};

export const getTotalTransaction = (
  combos,
  combosRecargo,
  totalTickets,
  paymentType
) => {
  if (!combos) {
    return 0;
  }
  let totalCombos = getTotalCombos(combos);
  totalCombos =
    paymentType === 'Deshabilitado'
      ? totalCombos.totalOthers
      : totalCombos.totalCinemas;
  totalCombos = combos.length ? totalCombos + combosRecargo : 0;

  let total = totalCombos + totalTickets;

  return total;
};
