import React from 'react';
import './HowToBuy.css';

function HowToBuy(props) {
  return (
    <div className="HowToBuy_container">
      <h2 className="HowToBuy_title">¿Cómo Comprar Online?</h2>
      <p className="HowToBuy_description">
        Cinemas Procinal de todas las ciudades quieren brindarte una experiencia
        única a la hora de escoger tu película, ven y disfruta
      </p>
      <div className="HowToBuy_steps">
        <div className="HowToBuy_step">
          <div className="HowToBuy_number">1</div>
          <i className="icon-desktop" />
          <p>
            Escoger
            <br />
            Película
          </p>
        </div>
        <div className="HowToBuy_step">
          <div className="HowToBuy_number">2</div>
          <i className="icon-calendar" />
          <p>
            Elegir día
            <br />Y hora
          </p>
        </div>
        <div className="HowToBuy_step">
          <div className="HowToBuy_number">3</div>
          <i className="icon-theater" />
          <p>
            Escoger el
            <br />
            Teatro
          </p>
        </div>
        <div className="HowToBuy_step">
          <div className="HowToBuy_number">4</div>
          <i className="icon-check" />
          <p>Registrarse</p>
        </div>
        <div className="HowToBuy_step">
          <div className="HowToBuy_number">5</div>
          <i className="icon-cinema-seat" />
          <p>
            Escoger
            <br />
            Silla
          </p>
        </div>
        <div className="HowToBuy_step">
          <div className="HowToBuy_number">6</div>
          <i className="icon-qr" />
          <p>
            Mostrar el QR
            <br />a la entrada
            <br />
            desde tu teléfono
          </p>
        </div>
      </div>
    </div>
  );
}

export default HowToBuy;
