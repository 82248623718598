import React from 'react';
import { connect } from 'react-redux';
import { getSettingValue } from '../../../functions/currentFunctions';
import { changeLocation } from '../../../store/actions/CheckoutActions';

function Locations(props) {
  let {
    checkoutReducer: {
      checkout_locations,
      checkout_location,
      checkout_pay_option,
    },
    globalReducer: { global_settings },
    changeLocation,
  } = props;

  const texto_general_mas = getSettingValue(
    global_settings,
    'texto_general_mas',
    '<p>(La ubicación General+ tendrá un recargo de $1.500 sobre el valor de la boleta)</p>'
  );

  const numberFormat = new Intl.NumberFormat('es-CO');

  const locations = checkout_locations.filter(
    (item) => item.ClienteFrecuente === checkout_pay_option
  );

  const otherPrice = (zone, status) => {
    const section =
      checkout_pay_option === 'Deshabilitado' ? 'Habilitado' : 'Deshabilitado';
    const location = checkout_locations.find(
      (location) =>
        location.ClienteFrecuente === section && location.zona === zone
    );

    if (!location) {
      return null;
    }

    return (
      <div className="Checkout_box-other-price small">
        {status} <strong>CINE+</strong>
        <div className="Checkout_other-price">
          $ {numberFormat.format(location.valor)}
        </div>
      </div>
    );
  };

  const response = locations.map((location, index) => {
    if (location.codigo) {
      const selected =
        checkout_location && location.codigo === checkout_location.codigo
          ? 'selected'
          : '';

      return (
        <button
          type="button"
          key={index}
          className={`Checkout_location ${selected}`}
          onClick={() => changeLocation(location)}
        >
          <span>Ubicación: {location.zona}</span>
          <div className="Checkout_price">
            $ {numberFormat.format(location.valor)}
          </div>
          {checkout_pay_option === 'Deshabilitado'
            ? otherPrice(location.zona, 'Con')
            : otherPrice(location.zona, 'Sin')}
        </button>
      );
    }
    return null;
  });

  return (
    <>
      <h5>
        Selecciona la ubicación:
        <span
          className="subtitle"
          dangerouslySetInnerHTML={{
            __html: texto_general_mas,
          }}
        />
      </h5>
      <div className="Checkout_box-selected">
        <div className="Checkout_row-selected">{response}</div>
      </div>
    </>
  );
}

const mapStateToProps = ({ checkoutReducer, globalReducer }) => {
  return {
    checkoutReducer,
    globalReducer,
  };
};

const mapDispatchToProps = {
  changeLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(Locations);
