export const PAY_TICKET = 'PAY_TICKET';
export const PAY_TICKET_SHOWTIME = 'PAY_TICKET_SHOWTIME';
export const PAY_TICKET_NAME = 'PAY_TICKET_NAME';
export const PAY_TICKET_LASTNAME = 'PAY_TICKET_LASTNAME';
export const PAY_TICKET_EMAIL = 'PAY_TICKET_EMAIL';
export const PAY_TICKET_CONFIRM_EMAIL = 'PAY_TICKET_CONFIRM_EMAIL';
export const PAY_TICKET_DOC_NUMBER = 'PAY_TICKET_DOC_NUMBER';
export const PAY_TICKET_PHONE = 'PAY_TICKET_PHONE';
export const PAY_TICKET_QUANTITY = 'PAY_TICKET_QUANTITY';
export const PAY_TICKET_TOTAL = 'PAY_TICKET_TOTAL';
export const PAY_TICKET_CLEAR = 'PAY_TICKET_CLEAR';
export const PAY_TICKET_LOADING = 'PAY_TICKET_LOADING';
export const PAY_TICKET_REDIRECT = 'PAY_TICKET_REDIRECT';
