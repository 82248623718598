import { CONTENT_DETAIL, CONTENT_LOADING } from '../constants/Content';
import Api from './Api';

export const contentLoading = (payload) => (dispatch) => {
  dispatch({
    type: CONTENT_LOADING,
    payload,
  });
};

export const contentDetail = (payload) => (dispatch) => {
  dispatch({
    type: CONTENT_DETAIL,
    payload,
  });
};

export const contentGetDetail = (slug) => (dispatch) => {
  dispatch(contentLoading(true));
  Api.callMethod(
    `contents/${slug}`,
    'get',
    null,
    (res, status) => {
      if (status === 200) {
        dispatch(contentDetail(res.data));
      } else {
        dispatch(
          contentDetail({
            title: '404',
            content: '404',
          })
        );
      }
      dispatch(contentLoading(false));
    },
    (error) => {
      console.log('Error contentGetDetail: ', error);
      dispatch(
        contentDetail({
          title: '404',
          content: '404',
        })
      );
      dispatch(contentLoading(false));
    }
  );
};
