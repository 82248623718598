import React from 'react';
import DefaultLayout from '../../Layouts/DefaultLayout';
import Status from '../../components/Status';

function StatusPage() {
  return (
    <DefaultLayout>
      <Status />
    </DefaultLayout>
  );
}

export default StatusPage;
