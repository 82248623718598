import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PageLoader from '../PageLoader';
import MovieDetails from './MovieDetails';
import MovieTheater from './MovieTheater';
import './Movie.css';
import { getMovie, movieClear } from '../../store/actions/GlobalActions';

class Movie extends Component {
  componentDidMount() {
    const {
      match: {
        params: { city, movie },
      },
      getMovie,
    } = this.props;

    getMovie(movie, city);
  }

  componentWillUnmount() {
    this.props.movieClear();
  }

  render() {
    const {
      globalReducer: { global_loading_movie },
    } = this.props;

    if (global_loading_movie) {
      return <PageLoader />;
    }

    return (
      <>
        <MovieDetails />
        <MovieTheater />
      </>
    );
  }
}

const mapStateToProps = ({ globalReducer }) => {
  return {
    globalReducer,
  };
};

const mapDispatchToProps = {
  getMovie,
  movieClear,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Movie));
