import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import Payment from './Payment';
import PageLoader from '../PageLoader';
import Room from './Room';
import {
  changeDate,
  changeHour,
  checkoutClear,
  getRoom,
  changeLocation,
  checkoutReservation,
  checkoutCacheReservation,
  checkoutChangeRoomLoading,
  changeCheckoutReservation,
} from '../../store/actions/CheckoutActions';
import { formatDate } from '../../store/actions/GlobalActions';
import { getSettingValue } from '../../functions/currentFunctions';
import { onCancelReservation } from '../../store/actions/ConfectioneryActions';
import Locations from './Locations';
import PayMethods from './PayMethods';
import Confectionery from '../Confectionery';
import CountDown from '../CountDown';
import GeneralCart from '../Confectionery/Cart/GeneralCart';
import ModalCinema from './ModalCiema';
import ModalOldPayment from './ModalOldPayment';
import './Checkout.css';

const MESSAGE_3D =
  '<p>Recuerda que <strong>puedes llevar tus gafas 3D</strong>, o <strong>comprarlas</strong> en cualquiera de <strong>nuestras taquillas o confiter&iacute;as</strong> por un valor adicional</p>';
const MESSAGE_3D_UNISUR =
  '<p><p>Recuerda que <strong>puedes llevar tus gafas 3D</strong>, o <strong>comprarlas</strong> en <strong>cualquiera de nuestras taquillas o confiter&iacute;as</strong> por un valor adicional, o si lo prefieres, te las podemos prestar.</p>';

class Checkout extends Component {
  state = {
    modal_cinema: true,
    messge_3d: '',
    message_3d_unisur: '',
    show_room: 'show',
    show_confectionery: '',
    show_payment: false,
    modal_old_payment: false,
  };

  componentDidMount() {
    const {
      userReducer: { user },
      match: {
        params: { movie_id, showtime },
      },
      getRoom,
    } = this.props;

    if (user && user.tickets) {
      const haveTickets = user.tickets.find(
        (ticket) => parseInt(ticket.showtime_id) === parseInt(showtime)
      );
      if (haveTickets) {
        this.setState({ modal_old_payment: true });
      }
    }

    getRoom(movie_id, showtime);

    this.getMessage3d();
  }

  componentWillUnmount() {
    this.props.checkoutClear();
  }

  getMessage3d = () => {
    const {
      globalReducer: { global_settings },
    } = this.props;

    const message_3d = getSettingValue(
      global_settings,
      'message_3d',
      MESSAGE_3D
    );
    const message_3d_unisur = getSettingValue(
      global_settings,
      'message_3d_unisur',
      MESSAGE_3D_UNISUR
    );
    this.setState({
      message_3d,
      message_3d_unisur,
    });
  };

  showRoom = () => {
    const { changeCheckoutReservation, onCancelReservation } = this.props;
    this.setState({
      show_room: 'show',
      show_confectionery: '',
      show_payment: false,
    });
    changeCheckoutReservation(false);
    onCancelReservation();
  };

  showConfectionery = (cinema) => {
    const {
      checkoutReducer: { checkout_chairs },
      location: { pathname },
      checkoutReservation,
    } = this.props;

    if (!checkout_chairs.length) {
      swal(
        'Upsss!!!',
        'Para continuar debes seleccionar mínimo una silla.',
        'warning'
      );
      return;
    }
    checkoutReservation(cinema, pathname, true);
    this.setState({
      show_room: '',
      show_confectionery: 'show',
      show_payment: false,
    });
  };

  render() {
    const {
      checkoutReducer: {
        checkout_room_loading,
        checkout_movie_loading,
        checkout_movie,
        checkout_showtime,
        checkout_error,
        checkout_location,
        checkout_pay_option,
        checkout_reservation,
      },
      userReducer: { user },
      globalReducer: { global_cinemas },
      confectioneryReducer: { confectionery_combos, confectionery_date },
      match: {
        params: { city, movie },
      },
    } = this.props;
    const {
      message_3d,
      message_3d_unisur,
      show_room,
      show_confectionery,
      show_payment,
      modal_cinema,
      modal_old_payment,
    } = this.state;

    if (checkout_movie_loading) {
      return <PageLoader />;
    }

    if (!user || checkout_error) {
      return <Redirect to={`/ciudad/${city}/pelicula/${movie}`} />;
    }

    const image = checkout_movie.imagen.replaceAll(' ', '_');
    let date_showtime = new Date(`${checkout_showtime.fecha_funcion}T01:00:00`);
    date_showtime = formatDate(date_showtime);

    let cinema = null;
    let cinemaName = '';
    let scoreCinema = null;
    const room = checkout_movie.rooms.filter(
      (room) => parseInt(room.id) === parseInt(checkout_showtime.room_id)
    );

    if (room.length) {
      cinema = global_cinemas.find(
        (cinema) => parseInt(cinema.id) === parseInt(room[0].cinema_id)
      );
      scoreCinema = cinema ? cinema.score_id : null;
      cinemaName = cinema ? cinema.nombre_completo || cinema.nombre : '';
    }

    return (
      <>
        {confectionery_combos.length && !show_room && checkout_reservation ? (
          <GeneralCart />
        ) : null}
        <div className="Checkout">
          {modal_cinema && !modal_old_payment && (
            <ModalCinema
              onCloseModal={() => this.setState({ modal_cinema: false })}
              cinemaName={cinemaName}
              city={city}
            />
          )}
          {modal_old_payment && (
            <ModalOldPayment
              onCloseModal={() => this.setState({ modal_old_payment: false })}
            />
          )}
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="Checkout_bread-crumbs">
                  <Link to={`/ciudad/${city}`}>Películas/</Link>
                  <Link to={`/ciudad/${city}/pelicula/${movie}`}>
                    {checkout_movie.titulo}/
                  </Link>
                  Checkout
                </div>
                <div className="Checkout_box-content">
                  <div className="col-md-12">
                    <h2 className="Checkout_event-title">
                      {checkout_movie.titulo}, {checkout_movie.version} -{' '}
                      {checkout_movie.formato}
                    </h2>
                  </div>
                  <div className="row" id="step-01">
                    <div className="col-md-2">
                      <img
                        src={image}
                        alt="Cover: "
                        className="Checkout_event-cover"
                      />
                      <p>
                        <strong>Título original:</strong>
                        <br />
                        {checkout_movie.titulo_original}
                      </p>
                      <p>
                        <strong>Título local:</strong>
                        <br />
                        {checkout_movie.titulo}
                      </p>
                      <p>
                        <strong>Fecha de estreno:</strong>
                        <br />
                        {checkout_movie.fecha_estreno}
                      </p>
                      {checkout_movie.clasificacion && (
                        <p>
                          <strong>Clasificación:</strong>
                          <br />
                          {checkout_movie.clasificacion}
                        </p>
                      )}
                    </div>
                    <div className="col-md-10">
                      <div className="Checkout_content">
                        <h3 className="Checkout_cinema" id="Checkout_cinema">
                          {cinemaName}
                        </h3>
                        <div className="row">
                          <div className="Checkout_date">
                            <div>
                              <strong>Fecha:</strong> {date_showtime.name}
                            </div>
                            <div>
                              <strong>Hora:</strong>{' '}
                              {checkout_showtime.hora_cadena.toUpperCase()}
                            </div>
                          </div>
                        </div>
                        {checkout_movie.formato.indexOf('3D') >= 0 &&
                        cinema.nombre !== 'imax' &&
                        cinema.nombre !== 'unisur' ? (
                          <div
                            className="Checkout_3d-message"
                            dangerouslySetInnerHTML={{
                              __html: message_3d,
                            }}
                          />
                        ) : checkout_movie.formato.indexOf('3D') >= 0 &&
                          cinema.nombre === 'unisur' ? (
                          <div
                            className="Checkout_3d-message"
                            dangerouslySetInnerHTML={{
                              __html: message_3d_unisur,
                            }}
                          />
                        ) : null}
                        {checkout_room_loading ? (
                          <PageLoader />
                        ) : (
                          <>
                            {!checkout_reservation && <PayMethods />}
                            {checkout_pay_option !== '' &&
                              !checkout_reservation && <Locations />}
                            {checkout_location !== null ? (
                              <>
                                {!checkout_reservation && (
                                  <>
                                    <h5>Selecciona tus sillas:</h5>
                                    <div className={`Checkout_option show`}>
                                      <Room cinema={cinema} />
                                    </div>
                                  </>
                                )}
                                {checkout_reservation && (
                                  <div
                                    className={`Checkout_option ${show_confectionery}`}
                                    id="Checkout_confectionery"
                                  >
                                    <div className="Checkout_confectionery-menu">
                                      <button
                                        className="Checkout_confectionery-button"
                                        onClick={this.showRoom}
                                      >
                                        <i className="fas fa-arrow-left" />
                                        Cambiar medio de pago - ubicación -
                                        sillas
                                      </button>
                                      {!show_room &&
                                      checkout_reservation &&
                                      confectionery_date ? (
                                        <CountDown
                                          onEnd={this.showRoom}
                                          endDate={confectionery_date}
                                        />
                                      ) : null}
                                    </div>
                                    <div className="vertical-gradient mb-3">
                                      <div className="col-md-12">
                                        <Confectionery theater={scoreCinema} />
                                      </div>
                                    </div>
                                    <div className="Checkout_confectionery-menu">
                                      <button
                                        className="Checkout_confectionery-button"
                                        onClick={this.showRoom}
                                      >
                                        <i className="fas fa-arrow-left" />
                                        Cambiar medio de pago - ubicación -
                                        sillas
                                      </button>
                                      {confectionery_combos &&
                                      !confectionery_combos.length ? (
                                        <button
                                          className="Checkout_confectionery-button"
                                          onClick={() =>
                                            this.setState({
                                              show_payment: true,
                                            })
                                          }
                                        >
                                          Finalizar Compra
                                        </button>
                                      ) : null}
                                    </div>
                                  </div>
                                )}
                                {(show_room || !checkout_reservation) && (
                                  <div className="Checkout_menu-reservation">
                                    <button
                                      onClick={() =>
                                        this.showConfectionery(cinema)
                                      }
                                    >
                                      Continuar con la compra
                                    </button>
                                  </div>
                                )}
                                {show_payment && !confectionery_combos.length && (
                                  <div className="Checkout_resume">
                                    <Payment />
                                  </div>
                                )}
                              </>
                            ) : null}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({
  checkoutReducer,
  userReducer,
  globalReducer,
  confectioneryReducer,
}) => {
  return {
    checkoutReducer,
    userReducer,
    globalReducer,
    confectioneryReducer,
  };
};
const mapDispatchToProps = {
  changeDate,
  changeHour,
  getRoom,
  checkoutClear,
  changeLocation,
  checkoutReservation,
  checkoutCacheReservation,
  checkoutChangeRoomLoading,
  changeCheckoutReservation,
  onCancelReservation,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Checkout)
);
