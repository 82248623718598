import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getTicket, clearTicket } from '../../store/actions/TicketActions';
import PageLoader from '../PageLoader';
import './Ticket.css';
import boleta from '../../assets/images/boleta.png';
import confiteria from '../../assets/images/confiteria.png';

const { REACT_APP_QR_URL } = process.env;
const qrUrl = REACT_APP_QR_URL
  ? REACT_APP_QR_URL
  : 'https://api.qrserver.com/v1/create-qr-code/?size=300x300&data';

class Ticket extends Component {
  componentDidMount() {
    const {
      match: {
        params: { ticket },
      },
      ticketReducer: { ticket_loading },
      getTicket,
    } = this.props;

    if (ticket_loading) {
      getTicket(ticket);
    }
  }

  componentWillUnmount() {
    this.props.clearTicket();
  }

  render() {
    const {
      ticketReducer: { ticket_loading, ticket },
    } = this.props;
    let theaterName = null;

    if (ticket) {
      const params = JSON.parse(ticket.params);
      if (params.cinema) {
        theaterName = params.cinema.nombre_completo
          ? params.cinema.nombre_completo
          : params.cinema.nombre;
        theaterName = theaterName.toUpperCase();
      }
    }

    return (
      <div className="row Tickets">
        <div className="col-md-12">
          <h4>TICKET{theaterName ? `: ${theaterName}` : ''}</h4>
          {ticket_loading ? (
            <PageLoader />
          ) : ticket ? (
            <div className="Ticket_movie">
              {ticket.products && ticket.products_qr && (
                <div className="Ticket_products">
                  <div className="Ticket_row-qr">
                    <img
                      src={confiteria}
                      alt="img-confiteria"
                      className="Ticket_row-image"
                    />
                    <div>
                      <h2>
                        PRESENTA ESTE
                        <br />
                        CÓDIGO QR EN CONFITERÍA
                      </h2>
                      <div className="Ticket_box-qr">
                        <div className="Ticket_row-qr-white">
                          <img
                            src={`https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=${ticket.products_qr}`}
                            alt="Código QR confitería"
                            className="Ticket_qr"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="Ticket_confectionery"
                    dangerouslySetInnerHTML={{ __html: ticket.products }}
                  />
                </div>
              )}
              {ticket.description && ticket.products && ticket.products_qr && (
                <div className="Ticket_line" />
              )}
              {ticket.description && (
                <>
                  <div className="Ticket_row-qr">
                    <img
                      src={boleta}
                      alt="img-boleta"
                      className="Ticket_row-image"
                    />
                    <div>
                      <h2>
                        PRESENTA ESTE
                        <br />
                        CÓDIGO QR EN LA SALA
                      </h2>
                      <div className="Ticket_box-qr">
                        <div className="Ticket_row-qr-white">
                          <img
                            src={`https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=bepass::ticket::checkin::${ticket.id_ticket}::${ticket.qrcode}`}
                            alt="Código QR"
                            className="Ticket_qr"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="Ticket_qr-description"
                    dangerouslySetInnerHTML={{ __html: ticket.description }}
                  />
                </>
              )}
            </div>
          ) : (
            <div>
              <h4>
                Ticket no encontrado, vuelve a intentarlo más tarde o ponte en
                contacto con nosotros.
              </h4>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ ticketReducer }) => {
  return {
    ticketReducer,
  };
};

const mapDispatchToProps = {
  getTicket,
  clearTicket,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Ticket));
