import React from 'react';
import Billboard from '../../components/Billboard';
import DefaultLayout from '../../Layouts/DefaultLayout';

function BillboardPage(props) {
  return (
    <DefaultLayout>
      <Billboard />
    </DefaultLayout>
  );
}

export default BillboardPage;
