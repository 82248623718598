import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  changeName,
  changeLastName,
  changeEmail,
  changePassword,
  changePasswordConfirm,
  changeDniType,
  changeGender,
  changeCellPhone,
  changeDni,
  userUpdate,
  userClear,
  userChangeEdit,
} from '../../../store/actions/UserActions';

class FormUpdate extends Component {
  componentDidMount() {
    const {
      userReducer: { user },
      changeName,
      changeLastName,
      changeEmail,
      changeDniType,
      changeGender,
      changeCellPhone,
      changeDni,
    } = this.props;
    changeName(user.nombre);
    changeLastName(user.apellido);
    changeEmail(user.email);
    changeDniType(user.tipo_documento);
    changeGender(user.sexo);
    changeCellPhone(user.telefono);
    changeDni(user.documento);
  }

  componentWillUnmount() {
    this.props.userClear();
  }

  render() {
    const {
      userReducer: {
        user_email,
        user_password,
        user_name,
        user_lastName,
        user_gender,
        user_dniType,
        user_dni,
        user_cellPhone,
      },
      changeName,
      changeLastName,
      changeEmail,
      changePassword,
      changeDniType,
      changeGender,
      changeCellPhone,
      userUpdate,
      userChangeEdit,
    } = this.props;

    const docType = user_dniType ? user_dniType : '';

    return (
      <div className="row">
        <div className="col-md-12">
          <h4>ACTUALIZAR MI PERFIL</h4>
        </div>
        <div className="col-md-10 offset-md-1">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>TIPO DE DOCUMENTO:</label>
                <select
                  className="form-control"
                  name="dniType"
                  id="dniType"
                  value={docType}
                  onChange={(input) => changeDniType(input.target.value)}
                >
                  <option value="">--SELECCIONAR-</option>
                  <option value="CC">Cédula de Ciudadanía</option>
                  <option value="CE">Cédula de Extranjería</option>
                  <option value="PPN">Pasaporte</option>
                  <option value="NIT">
                    Número de Identificación Tibutaria (NIT)
                  </option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>NÚMERO DE DOCUMENTO:</label>
                <div type="text" className="form-control">
                  {user_dni}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>NOMBRE:</label>
                <input
                  type="text"
                  className="form-control"
                  value={user_name}
                  onChange={(input) => changeName(input.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>APELLIDOS:</label>
                <input
                  type="text"
                  className="form-control"
                  value={user_lastName}
                  onChange={(input) => changeLastName(input.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>GÉNERO:</label>
                <select
                  className="form-control"
                  name="gender"
                  id="gender"
                  value={user_gender}
                  onChange={(input) => changeGender(input.target.value)}
                >
                  <option value="">--SELECCIONAR--</option>
                  <option value="M">M</option>
                  <option value="F">F</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>NÚMERO DE TELÉFONO:</label>
                <input
                  type="text"
                  className="form-control"
                  value={user_cellPhone}
                  onChange={(input) => changeCellPhone(input.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>EMAIL:</label>
                <input
                  type="text"
                  className="form-control"
                  value={user_email}
                  onChange={(input) => changeEmail(input.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>NUEVA CONTRASEÑA:</label>
                <input
                  type="password"
                  className="form-control"
                  value={user_password}
                  onChange={(input) => changePassword(input.target.value)}
                />
                <span className="small">
                  Debe tener mínimo 8 y máximo 12 caracteres, debe ser
                  alfanumérica sin caracteres especiales.
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <button
                className="btn btn-block btn-secondary"
                onClick={() => userChangeEdit(false)}
              >
                VOLVER
              </button>
            </div>
            <div className="col-md-6">
              <button className="btn btn-block btn-red" onClick={userUpdate}>
                ACTUALIZAR
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer }) => {
  return {
    userReducer,
  };
};

const mapDispatchToProps = {
  changeName,
  changeLastName,
  changeEmail,
  changePassword,
  changePasswordConfirm,
  changeDniType,
  changeGender,
  changeCellPhone,
  changeDni,
  userUpdate,
  userClear,
  userChangeEdit,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormUpdate);
