import React from 'react';
import Contact from '../../components/Contact';
import DefaultLayout from '../../Layouts/DefaultLayout';

function ContactPage() {
  return (
    <DefaultLayout>
      <Contact />
    </DefaultLayout>
  );
}

export default ContactPage;
