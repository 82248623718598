import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getMoviesNextReleases } from '../../store/actions/MovieActions';
import MovieBox from '../MovieBox';
import PageLoader from '../PageLoader';
import SectionTitle from '../SectionTitle';
import './NextReleases.css';

class NextReleases extends Component {
  componentDidMount() {
    const {
      movieReducer: { movies_next_releases_loading, movies_next_releases },
      getMoviesNextReleases,
    } = this.props;

    if (movies_next_releases_loading || !movies_next_releases.length) {
      getMoviesNextReleases();
    }
  }

  render() {
    const {
      movieReducer: { movies_next_releases_loading, movies_next_releases },
      limit,
    } = this.props;

    const maxPages = limit ? limit : movies_next_releases.length;

    return (
      <>
        <SectionTitle title="Próximos Estrenos" background="black" />
        <div className="NextReleases_container vertical-gradient">
          {movies_next_releases_loading ? (
            <PageLoader />
          ) : (
            <div className="container">
              <div className="row">
                {movies_next_releases.map((movie, index) => {
                  if (index < maxPages) {
                    return (
                      <div className="col-sm-6 col-md-3" key={movie.id}>
                        <MovieBox {...movie} />
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          )}
          {limit && limit < movies_next_releases.length && (
            <Link to="/proximos-estrenos" className="NextReleases_button">
              Ver más
            </Link>
          )}
        </div>
      </>
    );
  }
}

NextReleases.defaultProps = {
  limit: null,
};

const mapStateToProps = ({ movieReducer }) => {
  return {
    movieReducer,
  };
};

const mapDispatchToProps = {
  getMoviesNextReleases,
};

export default connect(mapStateToProps, mapDispatchToProps)(NextReleases);
