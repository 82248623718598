import {
  CINEMAS_CONFIRM_PASSWORD,
  CINEMAS_ERROR,
  CINEMAS_LOADING,
  CINEMAS_PASSWORD,
  CINEMAS_VISIBLE,
} from '../constants/Cinemas';

const INITIAL_STATE = {
  cinemas_visible: '',
  cinemas_loading: false,
  cinemas_password: '',
  cinemas_confirm_password: '',
  cinemas_error: '',
};

const cinemasReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CINEMAS_VISIBLE:
      return {
        ...state,
        cinemas_visible: action.payload,
      };
    case CINEMAS_LOADING:
      return {
        ...state,
        cinemas_loading: action.payload,
      };
    case CINEMAS_PASSWORD:
      return {
        ...state,
        cinemas_password: action.payload,
      };
    case CINEMAS_CONFIRM_PASSWORD:
      return {
        ...state,
        cinemas_confirm_password: action.payload,
      };
    case CINEMAS_ERROR:
      return {
        ...state,
        cinemas_error: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default cinemasReducer;
