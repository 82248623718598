import React from 'react';
import TheaterTitle from '../TheaterTitle';
import './TheaterServices.css';

function TheaterServices(props) {
  const {
    theater,
    theater: { benefits },
  } = props;

  return (
    <div className="TheaterServices_container">
      <TheaterTitle
        name={
          theater.nombre_completo ? theater.nombre_completo : theater.nombre
        }
        address={theater.direccion}
      />
      <div className="TheaterServices_content">
        <h1>Servicios:</h1>
        <div className="TheaterServices_list">
          {benefits.map((benefit) => (
            <div className="TheaterServices_item" key={benefit.id}>
              <span className={benefit.icon} />
              <h3>{benefit.name}</h3>
              {benefit.id === 2 && theater.nombre.toLowerCase() === 'maloka' ? (
                <p>3 horas gratis con tus boletas de cine</p>
              ) : (
                <p>{benefit.description}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TheaterServices;
