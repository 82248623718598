import React, { Component } from 'react';
import { connect } from 'react-redux';
import { globalGetCinemas } from '../../store/actions/GlobalActions';
import {
  changeName,
  changeLastName,
  changeEmail,
  changeDniType,
  changeGender,
  changeCellPhone,
  changeDni,
} from '../../store/actions/UserActions';
import FormUpdate from './FormUpdate';
import './Profile.css';
import ProfileData from './ProfileData';

class Profile extends Component {
  componentDidMount() {
    const {
      userReducer: { user },
      globalReducer: { global_cinema_list },
      changeName,
      changeLastName,
      changeEmail,
      changeDniType,
      changeGender,
      changeCellPhone,
      changeDni,
      globalGetCinemas,
    } = this.props;
    changeName(user.nombre);
    changeLastName(user.apellido);
    changeEmail(user.email);
    changeDniType(user.tipo_documento);
    changeGender(user.sexo);
    changeCellPhone(user.telefono);
    changeDni(user.documento);

    if (!global_cinema_list.length) {
      globalGetCinemas();
    }
  }

  render() {
    const {
      userReducer: { user_edit_profile },
    } = this.props;

    if (user_edit_profile) {
      return <FormUpdate />;
    }

    return <ProfileData />;
  }
}

const mapStateToProps = ({ userReducer, globalReducer }) => {
  return {
    userReducer,
    globalReducer,
  };
};

const mapDispatchToProps = {
  changeName,
  changeLastName,
  changeEmail,
  changeDniType,
  changeGender,
  changeCellPhone,
  changeDni,
  globalGetCinemas,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
