import React from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import loading from '../../assets/images/loading-red.svg';
import './ModalLoading.css';

function ModalLoading(props) {
  const {
    globalReducer: { global_modal_loading, global_modal_message },
  } = props;
  return createPortal(
    <div className={`ModalLoading ${global_modal_loading}`}>
      <img src={loading} alt="Loading" />
      {global_modal_message && <div>{global_modal_message}</div>}
    </div>,
    document.getElementById('modal-loading')
  );
}

const mapStateToProps = ({ globalReducer }) => {
  return {
    globalReducer,
  };
};

export default connect(mapStateToProps, null)(ModalLoading);
