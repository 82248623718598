import React from 'react';
import { connect } from 'react-redux';
import SliderRecharge from '../../SliderRecharge';

function HeaderRecharge(props) {
  const {
    subscriptionReducer: { subscription_total },
  } = props;
  const numberFormat = new Intl.NumberFormat('es-CO');

  return (
    <div className="PaymentMethods_resume">
      <div className="PaymentMethods_title">
        Recargar <strong className="color-red">CINE+</strong>
      </div>
      <div className="PaymentMethods_description">
        <SliderRecharge />
      </div>
      <div className="PaymentMethods_price">
        TOTAL:{' '}
        <strong color="red">
          ${numberFormat.format(subscription_total).replace(',', '.')}
        </strong>{' '}
      </div>
    </div>
  );
}

const mapStateToProps = ({ subscriptionReducer }) => {
  return {
    subscriptionReducer,
  };
};

export default connect(mapStateToProps, null)(HeaderRecharge);
