import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { checkoutClear } from '../../store/actions/CheckoutActions';
import { formatDate } from '../../store/actions/GlobalActions';
import { getSettingValue } from '../../functions/currentFunctions';
import { changeVisible } from '../../store/actions/PaymentActions';
import {
  getMovie,
  addRemoveQuantity,
  changePayTicketName,
  changePayTicketLastname,
  changePayTicketEmail,
  changePayTicketConfirmEmail,
  changePayTicketDocNumber,
  changePayTicketPhone,
} from '../../store/actions/PayTicketActions';
import PageLoader from '../PageLoader';
import ModalCinema from '../Checkout/ModalCiema';
import epayco from '../../assets/images/epayco-white.png';
import swal from 'sweetalert';
const email_reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const MESSAGE_3D =
  '<p>Recuerda que <strong>puedes llevar tus gafas 3D</strong>, o <strong>comprarlas</strong> en cualquiera de <strong>nuestras taquillas o confiter&iacute;as</strong> por un valor adicional</p>';
const MESSAGE_3D_UNISUR =
  '<p><p>Recuerda que <strong>puedes llevar tus gafas 3D</strong>, o <strong>comprarlas</strong> en <strong>cualquiera de nuestras taquillas o confiter&iacute;as</strong> por un valor adicional, o si lo prefieres, te las podemos prestar.</p>';

class TicketCheckout extends Component {
  state = {
    modal_cinema: true,
    messge_3d: '',
    message_3d_unisur: '',
    show_room: 'show',
    show_confectionery: '',
    show_payment: false,
    modal_old_payment: false,
  };

  componentDidMount() {
    const {
      match: {
        params: { movie_id, showtime },
      },
      getMovie,
    } = this.props;
    this.getMessage3d();

    if (showtime) {
      getMovie(movie_id, showtime);
    }
  }

  componentWillUnmount() {
    this.props.checkoutClear();
  }

  getMessage3d = () => {
    const {
      globalReducer: { global_settings },
    } = this.props;

    const message_3d = getSettingValue(
      global_settings,
      'message_3d',
      MESSAGE_3D
    );
    const message_3d_unisur = getSettingValue(
      global_settings,
      'message_3d_unisur',
      MESSAGE_3D_UNISUR
    );
    this.setState({
      message_3d,
      message_3d_unisur,
    });
  };

  formValidate = () => {
    const {
      payTicketReducer: {
        name,
        lastname,
        email,
        confirm_email,
        doc_number,
        phone,
      },
      changeVisible,
    } = this.props;

    if (
      !name.trim().length ||
      !lastname.trim().length ||
      !email.trim().length ||
      !confirm_email.trim().length ||
      !doc_number.trim().length ||
      !phone.trim().length
    ) {
      swal(
        'Upsss!!!',
        'Todos los campos del formulario son obligatorios.',
        'warning'
      );
      return;
    }
    if (!email_reg.test(email)) {
      swal(
        'Upsss!!!',
        'El formato del correo electrónico es incorrecto.',
        'warning'
      );
      return;
    }
    if (email !== confirm_email) {
      swal('Upsss!!!', 'Los correos electrónicos no coinciden.', 'warning');
      return;
    }
    changeVisible('visible', 6);
  };

  render() {
    const {
      checkoutReducer: {
        checkout_movie_loading,
        checkout_movie,
        checkout_showtime,
        checkout_error,
        checkout_total_tikets,
        checkout_total_transaction,
        checkout_total,
      },
      globalReducer: { global_cinemas },
      payTicketReducer: {
        name,
        lastname,
        email,
        confirm_email,
        doc_number,
        phone,
        quantity,
      },
      match: {
        params: { city, movie },
      },
      addRemoveQuantity,
      changePayTicketName,
      changePayTicketLastname,
      changePayTicketEmail,
      changePayTicketConfirmEmail,
      changePayTicketDocNumber,
      changePayTicketPhone,
    } = this.props;
    const { message_3d, message_3d_unisur, modal_cinema } = this.state;
    const numberFormat = new Intl.NumberFormat('es-CO');

    if (checkout_movie_loading) {
      return <PageLoader />;
    }

    if (checkout_error) {
      return <Redirect to={`/ciudad/${city}/pelicula/${movie}`} />;
    }

    const image = checkout_movie.imagen.replaceAll(' ', '_');
    let date_showtime = new Date(`${checkout_showtime.fecha_funcion}T01:00:00`);
    date_showtime = formatDate(date_showtime);

    let cinema = null;
    let cinemaName = '';
    let limit =
      checkout_showtime && checkout_showtime.disponibility < 10
        ? checkout_showtime.disponibility
        : 10;
    const room = checkout_movie.rooms.find(
      (room) => parseInt(room.id) === parseInt(checkout_showtime.room_id)
    );

    if (room) {
      cinema = global_cinemas.find(
        (cinema) => parseInt(cinema.id) === parseInt(room.cinema_id)
      );
      cinemaName = cinema ? cinema.nombre_completo || cinema.nombre : '';
    }

    return (
      <>
        <div className="Checkout">
          {modal_cinema && (
            <ModalCinema
              onCloseModal={() => this.setState({ modal_cinema: false })}
              cinemaName={cinemaName}
              city={city}
            />
          )}
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="Checkout_bread-crumbs">
                  <Link to={`/ciudad/${city}`}>Películas/</Link>
                  <Link to={`/ciudad/${city}/pelicula/${movie}`}>
                    {checkout_movie.titulo}/
                  </Link>
                  Checkout
                </div>
                <div className="Checkout_box-content">
                  <div className="col-md-12">
                    <h2 className="Checkout_event-title">
                      {checkout_movie.titulo}, {checkout_movie.version} -{' '}
                      {checkout_movie.formato}
                    </h2>
                  </div>
                  <div className="row" id="step-01">
                    <div className="col-md-2">
                      <img
                        src={image}
                        alt="Cover: "
                        className="Checkout_event-cover"
                      />
                      <p>
                        <strong>Título original:</strong>
                        <br />
                        {checkout_movie.titulo_original}
                      </p>
                      <p>
                        <strong>Título local:</strong>
                        <br />
                        {checkout_movie.titulo}
                      </p>
                      <p>
                        <strong>Fecha de estreno:</strong>
                        <br />
                        {checkout_movie.fecha_estreno}
                      </p>
                      {checkout_movie.clasificacion && (
                        <p>
                          <strong>Clasificación:</strong>
                          <br />
                          {checkout_movie.clasificacion}
                        </p>
                      )}
                    </div>
                    <div className="col-md-10">
                      <div className="Checkout_content">
                        <h3 className="Checkout_cinema" id="Checkout_cinema">
                          {cinemaName}
                        </h3>
                        <div className="row">
                          <div className="Checkout_date">
                            <div>
                              <strong>Fecha:</strong> {date_showtime.name}
                            </div>
                            <div>
                              <strong>Hora:</strong>{' '}
                              {checkout_showtime.hora_cadena.toUpperCase()}
                            </div>
                          </div>
                        </div>
                        {checkout_movie.formato.indexOf('3D') >= 0 &&
                        cinema.nombre !== 'imax' &&
                        cinema.nombre !== 'unisur' ? (
                          <div
                            className="Checkout_3d-message"
                            dangerouslySetInnerHTML={{
                              __html: message_3d,
                            }}
                          />
                        ) : checkout_movie.formato.indexOf('3D') >= 0 &&
                          cinema.nombre === 'unisur' ? (
                          <div
                            className="Checkout_3d-message"
                            dangerouslySetInnerHTML={{
                              __html: message_3d_unisur,
                            }}
                          />
                        ) : null}
                        <div className="cine_day">
                          <p>
                            ¡Celebremos juntos el{' '}
                            <strong>'Día del Cine'!</strong> Puedes comprar tus
                            boletas con anticipación, aunque no podrás
                            seleccionar ubicaciones específicas en ese momento.
                          </p>
                          <p>
                            No te preocupes, nosotros nos aseguraremos de
                            reservar las mejores ubicaciones para nuestros
                            clientes virtuales. La elección de tu asiento la
                            podrás hacer directamente en el teatro desde el
                            momento de tu compra o incluso hasta el mismo{' '}
                            <strong>'Día del Cine'</strong>, que se celebra el
                            25 de septiembre.
                          </p>
                          <h3>¡Esperamos verte allí!</h3>
                        </div>
                        <div className="row Checkout_form">
                          <div className="col-md-6 mb-1">
                            <div className="form-group">
                              <label>Nombre: *</label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Nombre"
                                value={name}
                                onChange={(input) =>
                                  changePayTicketName(input.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-6 mb-1">
                            <div className="form-group">
                              <label>Apellidos: *</label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Apellidos"
                                value={lastname}
                                onChange={(input) =>
                                  changePayTicketLastname(input.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-6 mb-1">
                            <div className="form-group">
                              <label>Número de Documento: *</label>
                              <input
                                className="form-control"
                                type="number"
                                placeholder="Número de Documento"
                                value={doc_number}
                                onChange={(input) =>
                                  changePayTicketDocNumber(input.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-6 mb-1">
                            <div className="form-group">
                              <label>Teléfono: *</label>
                              <input
                                className="form-control"
                                type="number"
                                placeholder="Teléfono"
                                value={phone}
                                onChange={(input) =>
                                  changePayTicketPhone(input.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-6 mb-1">
                            <div className="form-group">
                              <label>Correo electrónico: *</label>
                              <input
                                className="form-control"
                                type="email"
                                placeholder="Correo electrónico"
                                value={email}
                                onChange={(input) =>
                                  changePayTicketEmail(input.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-6 mb-1">
                            <div className="form-group">
                              <label>Confirmar correo electrónico: *</label>
                              <input
                                className="form-control"
                                type="email"
                                placeholder="Confirmar correo electrónico"
                                value={confirm_email}
                                onChange={(input) =>
                                  changePayTicketConfirmEmail(
                                    input.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="Payment_payment">
                          <div className="Payment_resume">
                            <h4>DETALLE COMPRA</h4>
                            <div className="Payment_cinema">
                              <strong>TEATRO: </strong>
                              {cinemaName}
                            </div>
                            <div className="Payment_prices">
                              <div className="Payment_price-row mb-1">
                                <div>Selecciona el número de entradas:</div>
                                <div className="combos_amout">
                                  <button
                                    className="combos_amout-button red combos_amout-remove"
                                    onClick={() =>
                                      addRemoveQuantity('remove', limit)
                                    }
                                  >
                                    -
                                  </button>
                                  <div className="combos_text-amout">
                                    {quantity}
                                  </div>
                                  <button
                                    className="combos_amout-button red combos_amout-add"
                                    onClick={() =>
                                      addRemoveQuantity('add', limit)
                                    }
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                              <div className="Payment_price-row">
                                <div>VALOR TAQUILLA:</div>
                                <div>
                                  ${numberFormat.format(checkout_total_tikets)}
                                </div>
                              </div>
                              <div className="Payment_price-row">
                                <div>CARGO POR SERVICIO:</div>
                                <div>
                                  $
                                  {numberFormat.format(
                                    checkout_total_transaction
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="Payment_price-row Payment_price-total">
                              <div className="color-red">
                                <strong>TOTAL A PAGAR:</strong>
                              </div>
                              <div className="color-red">
                                <strong>
                                  ${numberFormat.format(checkout_total)}
                                </strong>
                              </div>
                            </div>
                            <button
                              type="button"
                              className="Payment_button"
                              onClick={this.formValidate}
                            >
                              Pagar con
                              <img src={epayco} alt="Logo Epayco" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({
  checkoutReducer,
  globalReducer,
  payTicketReducer,
}) => {
  return {
    checkoutReducer,
    globalReducer,
    payTicketReducer,
  };
};
const mapDispatchToProps = {
  checkoutClear,
  getMovie,
  changeVisible,
  addRemoveQuantity,
  changePayTicketName,
  changePayTicketLastname,
  changePayTicketEmail,
  changePayTicketConfirmEmail,
  changePayTicketDocNumber,
  changePayTicketPhone,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TicketCheckout)
);
