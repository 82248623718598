import {
  CONFECTIONERY_COMBOS,
  CONFECTIONERY_DATE,
  CONFECTIONERY_LOADING,
  CONFECTIONERY_PRODUCTS,
  CONFECTIONERY_THEATER,
  CONFECTIONERY_THEATER_DETAIL,
  CONFECTIONERY_API_LOADING,
  CONFECTIONERY_API_PRODUCTS,
} from '../constants/Confectionery';

const INITIAL_STATE = {
  confectionery_loading: false,
  confectionery_theater: '',
  confectionery_products: null,
  confectionery_combos: [],
  confectionery_date: null,
  confectionery_theater_detail: null,
  confectionery_api_loading: true,
  confectionery_api_products: [],
};

const confectioneryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONFECTIONERY_LOADING:
      return {
        ...state,
        confectionery_loading: action.payload,
      };
    case CONFECTIONERY_THEATER:
      return {
        ...state,
        confectionery_theater: action.payload,
      };
    case CONFECTIONERY_PRODUCTS:
      return {
        ...state,
        confectionery_products: action.payload,
      };
    case CONFECTIONERY_COMBOS:
      return {
        ...state,
        confectionery_combos: action.payload,
      };
    case CONFECTIONERY_DATE:
      return {
        ...state,
        confectionery_date: action.payload,
      };
    case CONFECTIONERY_THEATER_DETAIL:
      return {
        ...state,
        confectionery_theater_detail: action.payload,
      };
    case CONFECTIONERY_API_LOADING:
      return {
        ...state,
        confectionery_api_loading: action.payload,
      };
    case CONFECTIONERY_API_PRODUCTS:
      return {
        ...state,
        confectionery_api_products: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default confectioneryReducer;
