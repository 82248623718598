import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeModalRegister } from '../../store/actions/UserActions';
import Header from '../../components/Header';
import Home from '../../components/Home';
import { Redirect } from 'react-router-dom';

class RegisterPage extends Component {
  componentDidMount() {
    const { changeModalRegister } = this.props;

    changeModalRegister('visible');
  }

  componentWillUnmount() {
    const { changeModalRegister } = this.props;

    changeModalRegister('');
  }

  render() {
    const {
      userReducer: { user },
    } = this.props;

    if (user) {
      return <Redirect to="/" />;
    }

    return (
      <>
        <Header type="black" />
        <Home />
      </>
    );
  }
}

const mapStateToProps = ({ userReducer }) => {
  return {
    userReducer,
  };
};

const mapDispatchToProps = {
  changeModalRegister,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
