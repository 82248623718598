import swal from 'sweetalert';
import {
  CONFECTIONERY_API_LOADING,
  CONFECTIONERY_API_PRODUCTS,
  CONFECTIONERY_COMBOS,
  CONFECTIONERY_DATE,
  CONFECTIONERY_LOADING,
  CONFECTIONERY_PRODUCTS,
  CONFECTIONERY_THEATER,
  CONFECTIONERY_THEATER_DETAIL,
} from '../constants/Confectionery';
import Api from './Api';
const { REACT_APP_LOCAL_STORAGE: LOCAL_STORAGE } = process.env;

export const confectioneryChangeLoading = (payload) => (dispatch) => {
  dispatch({
    type: CONFECTIONERY_LOADING,
    payload,
  });
};

export const confectioneryChangeTheater = (payload) => (dispatch) => {
  dispatch({
    type: CONFECTIONERY_THEATER,
    payload,
  });
};

export const confectioneryChangeProducts = (payload) => (dispatch) => {
  dispatch({
    type: CONFECTIONERY_PRODUCTS,
    payload,
  });
};

export const confectioneryChangeDate = (payload) => (dispatch) => {
  dispatch({
    type: CONFECTIONERY_DATE,
    payload,
  });
};

export const confectioneryChangeBeitLoading = (payload) => (dispatch) => {
  dispatch({
    type: CONFECTIONERY_API_LOADING,
    payload,
  });
};

export const confectioneryGetApiProducts = () => (dispatch) => {
  dispatch(confectioneryChangeBeitLoading(true));
  Api.callMethod(
    'confectioneries',
    'GET',
    null,
    (res, status) => {
      if (status === 200) {
        dispatch({
          type: CONFECTIONERY_API_PRODUCTS,
          payload: res.data,
        });
        dispatch(confectioneryChangeBeitLoading(false));
      }
    },
    (error) => {
      console.log('Error confectioneryGetApiProducts: ', error);
    }
  );
};

export const confectioneryGetProducts = (theater) => (dispatch, getState) => {
  const {
    confectionery_theater,
    confectionery_products,
  } = getState().confectioneryReducer;

  if (confectionery_products === theater) {
    console.log('confectionery_products: ', confectionery_products);
    dispatch(confectioneryChangeProducts(confectionery_products));
    return;
  }

  if (!theater) {
    theater = confectionery_theater;
  }

  if (!theater && theater === '') {
    swal(
      'Upsss!!!',
      'Debes seleccionar el teatro para ver la confitería.',
      'warning'
    );
    return;
  }

  dispatch(confectioneryChangeLoading(true));
  dispatch(confectioneryChangeProducts(null));

  Api.callMethod(
    `confectioneries/score?theater=${theater}`,
    'GET',
    null,
    (res, status) => {
      if (status === 200) {
        const { data } = res;

        if (data.Lista_Productos) {
          const { Lista_Productos } = data;
          dispatch(confectioneryChangeProducts(Lista_Productos));
        }
      }
      dispatch(confectioneryChangeLoading(false));
    },
    (error) => {
      console.log('Error confectioneryGetProducts: ', error);
      dispatch(confectioneryChangeLoading(false));
    }
  );
};

export const confectioneryClearCombos = () => (dispatch) => {
  dispatch({
    type: CONFECTIONERY_COMBOS,
    payload: [],
  });
};

export const confectioneryTheaterDetail = (payload) => (dispatch) => {
  dispatch({
    type: CONFECTIONERY_THEATER_DETAIL,
    payload,
  });
};

export const confectioneryChangeCombos = (combos, saveInCache = false) => (
  dispatch,
  getState
) => {
  const { confectionery_theater } = getState().confectioneryReducer;
  const confectioneryData = localStorage.getItem(
    `${LOCAL_STORAGE}::ticketConfectionery`
  );
  if (confectioneryData && combos) {
    let data = JSON.parse(confectioneryData);
    data.combos = combos;
    localStorage.setItem(
      `${LOCAL_STORAGE}::ticketConfectionery`,
      JSON.stringify(data)
    );
  }

  if (combos && combos.length) {
    const data = {
      theater: confectionery_theater,
      combos,
    };
    if (saveInCache) {
      localStorage.setItem(
        `${LOCAL_STORAGE}::confectionery`,
        JSON.stringify(data)
      );
    } else {
      localStorage.removeItem(`${LOCAL_STORAGE}::confectionery`);
    }
  } else {
    localStorage.removeItem(`${LOCAL_STORAGE}::confectionery`);
  }

  dispatch({
    type: CONFECTIONERY_COMBOS,
    payload: combos,
  });
};

export const confectioneryAddCombo = (combo, saveInCache = true) => (
  dispatch,
  getState
) => {
  const { confectionery_combos } = getState().confectioneryReducer;
  confectionery_combos.push(combo);

  dispatch(confectioneryChangeCombos(confectionery_combos, saveInCache));
};

export const confectioneryRemoveCombo = (index, saveInCache = true) => (
  dispatch,
  getState
) => {
  const { confectionery_combos } = getState().confectioneryReducer;
  confectionery_combos.splice(index, 1);

  dispatch(confectioneryChangeCombos(confectionery_combos, saveInCache));
};

export const confectioneryUpdateCombo = (combo, index) => (
  dispatch,
  getState
) => {
  const { confectionery_combos } = getState().confectioneryReducer;
  confectionery_combos[index] = combo;

  dispatch(confectioneryChangeCombos(confectionery_combos));
};

export const confectioneryClearCache = (deleteReservation = false) => (
  dispatch
) => {
  localStorage.removeItem(`${LOCAL_STORAGE}::ticketConfectionery`);
  dispatch(confectioneryChangeCombos([]));
  dispatch(confectioneryChangeDate(0));

  if (deleteReservation) {
    Api.callMethod(
      `auth/payment/reservation/cancel`,
      'GET',
      null,
      (res) => {
        window.location.reload();
      },
      (error) => {
        console.log('Error confectioneryClearCache: ', error);
      }
    );
  }
};

export const onCancelReservation = () => () => {
  Api.callMethod(
    `auth/payment/reservation/cancel`,
    'GET',
    null,
    (res) => {},
    (error) => {
      console.log('Error confectioneryClearCache: ', error);
    }
  );
};
