import React from 'react';
import './SectionTitle.css';

function SectionTitle(props) {
  const { background, title, subtitle, size } = props;
  return (
    <div className={`SectionTitle-content ${size} ${background}`}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="SectionTitle-title">{title}</div>
            {subtitle && (
              <div className="SectionTitle-subtitle">{subtitle}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

SectionTitle.defaultProps = {
  background: 'white',
  size: '',
};

export default SectionTitle;
