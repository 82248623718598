import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeVisible } from '../../store/actions/PaymentActions';
import { userGetScoreBalance } from '../../store/actions/UserActions';
import loading from '../../assets/images/loading-white.svg';
import './RechargeHeader.css';

class RechargeHeader extends Component {
  componentDidMount() {
    this.props.userGetScoreBalance();
  }

  render() {
    const {
      userReducer: {
        user_score,
        user_score_loading_balance,
        user_score_balance,
      },
      changeVisible,
    } = this.props;

    const numberFormat = new Intl.NumberFormat('es-CO');

    return (
      <div className="RechargeHeader-container">
        <div className="RechargeHeader-box">
          <div className="RechargeHeader-number">
            <div className="RechargeHeader-row">
              No. Tarjeta:{' '}
              <strong>{user_score && user_score.no_tarjeta}</strong>
            </div>
          </div>
          <div className="RechargeHeader-price">
            <div>Saldo:</div>
            {user_score_loading_balance ? (
              <img src={loading} alt="Cargando..." />
            ) : (
              <strong>
                $ {numberFormat.format(user_score_balance.Saldo_Total)}
              </strong>
            )}
          </div>
        </div>
        <button onClick={() => changeVisible('visible', 4)}>
          <div>
            Recargar <strong>CINE+</strong>
          </div>
        </button>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer }) => {
  return {
    userReducer,
  };
};

const mapDispatchToProps = {
  changeVisible,
  userGetScoreBalance,
};

export default connect(mapStateToProps, mapDispatchToProps)(RechargeHeader);
