/* eslint-disable no-useless-escape */
import swal from 'sweetalert';
import {
  USER_AGE,
  USER_DNI,
  USER_CELLPHONE,
  USER_DNITYPE,
  USER_EMAIL,
  USER_GENDER,
  USER_LASTNAME,
  USER_NAME,
  USER_PASSWORD,
  USER_EMAIL_REGISTER,
  USER_DATEBORN,
  USER_PASSWORD_REGISTER,
  USER_PASSWORD_CONFIRM,
  USER_MODAL_LOGIN,
  USER_MODAL_REGISTER,
  USER_DATA,
  USER_CLEAR,
  USER_FORM_LOGIN,
  USER_POLICIES,
  USER_TERMS,
  USER_SCORE_LOADING,
  USER_SCORE,
  USER_EDIT_PROFILE,
  USER_SCORE_LOADING_BALANCE,
  USER_SCORE_BALANCE,
  USER_EMAIL_REPEAT,
  USER_REPEAT_DNI,
  USER_SCORE_GET,
  USER_SCORE_BALANCE_GET,
} from '../constants/User';
import Api from './Api';
import { changeLoading } from './GlobalActions';
const { REACT_APP_EMERGENCY } = process.env;

const email_reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const number_reg = /^[0-9]+$/;
const password_reg = /^[0-9a-zA-ZñÑ]+$/;

export const userClear = () => (dispatch) => {
  dispatch({
    type: USER_CLEAR,
  });
};

export const changeModalLogin = (payload) => (dispatch) => {
  if (payload === 'visible') {
    document.body.style.overflowY = 'hidden';
  } else {
    document.body.style.overflowY = 'scroll';
  }
  dispatch({
    type: USER_MODAL_LOGIN,
    payload,
  });
};

export const changeModalRegister = (payload) => (dispatch) => {
  if (payload === 'visible') {
    document.body.style.overflowY = 'hidden';
  } else {
    document.body.style.overflowY = 'scroll';
  }
  dispatch(userClear());
  dispatch({
    type: USER_MODAL_REGISTER,
    payload,
  });
};

export const changeEmail = (payload) => (dispatch) => {
  dispatch({
    type: USER_EMAIL,
    payload,
  });
};

export const changePassword = (payload) => (dispatch) => {
  if (payload.length > 12) {
    return null;
  }
  dispatch({
    type: USER_PASSWORD,
    payload,
  });
};

export const changeName = (payload) => (dispatch) => {
  dispatch({
    type: USER_NAME,
    payload,
  });
};

export const changeLastName = (payload) => (dispatch) => {
  dispatch({
    type: USER_LASTNAME,
    payload,
  });
};

export const changeGender = (payload) => (dispatch) => {
  dispatch({
    type: USER_GENDER,
    payload,
  });
};

export const changeAge = (payload) => (dispatch) => {
  dispatch({
    type: USER_AGE,
    payload,
  });
};

export const changeDniType = (payload) => (dispatch) => {
  dispatch({
    type: USER_DNITYPE,
    payload,
  });
};

export const changeDni = (payload) => (dispatch) => {
  dispatch({
    type: USER_DNI,
    payload,
  });
};

export const changeRepeatDni = (payload) => (dispatch) => {
  dispatch({
    type: USER_REPEAT_DNI,
    payload,
  });
};

export const changeCellPhone = (payload) => (dispatch) => {
  dispatch({
    type: USER_CELLPHONE,
    payload,
  });
};

export const changeEmailRepeat = (payload) => (dispatch) => {
  dispatch({
    type: USER_EMAIL_REPEAT,
    payload,
  });
};

export const changeEmailRegister = (payload) => (dispatch) => {
  dispatch({
    type: USER_EMAIL_REGISTER,
    payload,
  });
};

export const changeDateBorn = (payload) => (dispatch, getState) => {
  const { user_dateBorn } = getState().userReducer;
  const add = user_dateBorn.length < payload.length ? true : false;

  if (add && payload.length === 4) {
    payload = `${payload}-`;
  }
  if (add && payload.length === 7) {
    payload = `${payload}-`;
  }

  if (add && payload.length > 10) {
    return null;
  }

  dispatch({
    type: USER_DATEBORN,
    payload,
  });
};

export const changePasswordRegister = (payload) => (dispatch) => {
  if (payload.length > 12) {
    return null;
  }
  dispatch({
    type: USER_PASSWORD_REGISTER,
    payload,
  });
};

export const changePasswordConfirm = (payload) => (dispatch) => {
  if (payload.length > 12) {
    return null;
  }
  dispatch({
    type: USER_PASSWORD_CONFIRM,
    payload,
  });
};

export const userChangeFormLogin = (payload) => (dispatch) => {
  dispatch({
    type: USER_FORM_LOGIN,
    payload,
  });
};

export const userChangePolicies = () => (dispatch, getState) => {
  const { user_policies } = getState().userReducer;

  dispatch({
    type: USER_POLICIES,
    payload: !user_policies,
  });
};

export const userChangeTerms = () => (dispatch, getState) => {
  const { user_terms } = getState().userReducer;

  dispatch({
    type: USER_TERMS,
    payload: !user_terms,
  });
};

const passwordSecurity = (password) => {
  let letter = false;
  let number = false;

  for (let i = 0; i < password.length; i++) {
    if (
      (password.charCodeAt(i) >= 65 && password.charCodeAt(i) <= 90) ||
      (password.charCodeAt(i) >= 97 && password.charCodeAt(i) <= 122)
    ) {
      letter = true;
    } else if (password.charCodeAt(i) >= 48 && password.charCodeAt(i) <= 57) {
      number = true;
    }
  }

  if (letter && number) {
    return true;
  }

  return false;
};

export const forgotPassword = () => (dispatch, getState) => {
  const { user_dni } = getState().userReducer;

  if (user_dni === '') {
    swal('Upsss!!!', 'Escribe el número de documento.', 'warning');
    return;
  }
  if (!number_reg.test(user_dni)) {
    swal(
      'Upsss!!!',
      'El número de documento, no debe tener espacios, puntos o carácteres especiales.',
      'warning'
    );
    return;
  }

  dispatch(changeLoading('visible', 'Recuperando contraseña...'));

  Api.callMethod(
    'auth/forgot',
    'POST',
    { doc_number: user_dni },
    (res) => {
      if (res.code === 200) {
        const { message } = res;
        swal('Felicidades!', message, 'success').then(() => {
          dispatch(changeLoading('', ''));
          dispatch(userChangeFormLogin('login'));
        });
        return;
      }
      swal('Upsss!!!', res.message, 'error').then(() => {
        dispatch(changeLoading('', ''));
      });
    },
    (error) => {
      console.log('Error forgot: ', error);
      swal(
        'Upsss!!!',
        'Hubo un error, vuelve a intentarlo más tarde.',
        'error'
      ).then(() => {
        dispatch(changeLoading('', ''));
      });
    }
  );
};

export const userLogout = () => (dispatch) => {
  dispatch(changeLoading('visible', 'Cerrando sesión...'));
  Api.callMethod(
    'auth/logout',
    'get',
    null,
    (res) => {
      document.cookie =
        'procinal_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC';
      setTimeout(() => {
        window.location.href = '/';
      }, 100);
      return res;
    },
    (err) => err
  );
};

export const userSessionStatus = () => (dispatch) => {
  const cookies = document.cookie.split(';');
  const token = cookies.filter((item) => item.indexOf('procinal_token=') >= 0);

  if (token.length) {
    Api.callMethod(
      'auth/user',
      'get',
      null,
      (res) => {
        if (res.code === 200) {
          dispatch({
            type: USER_DATA,
            payload: res.data,
          });
          dispatch(userGetScoreData());
          return;
        }
        dispatch(userLogout());
      },
      () => {
        dispatch(userLogout());
      }
    );
  }
};

export const goToLogin = () => (dispatch, getState) => {
  const { user_dni, user_password } = getState().userReducer;

  if (user_dni === '') {
    swal('Upsss!!!', 'Escribe el número de documento.', 'warning');
    return;
  }
  if (!number_reg.test(user_dni)) {
    swal(
      'Upsss!!!',
      'El número de documento, no debe tener espacios, puntos o carácteres especiales.',
      'warning'
    );
    return;
  }
  if (user_password === '') {
    swal('Upsss!!!', 'Escribe la contraseña', 'warning');
    return;
  }

  dispatch(changeLoading('visible', 'Iniciando sesión...'));

  const data = {
    documento: user_dni,
    clave: user_password,
  };

  Api.callMethod(
    'auth/login',
    'POST',
    data,
    (res) => {
      if (res.code === 200) {
        const { access_token, expires_at, user } = res;
        let expired_session = new Date(expires_at);
        expired_session = expired_session.toUTCString();
        document.cookie = `procinal_token=${access_token}; expires=${expired_session}`;
        document.cookie = `procinal_user=${JSON.stringify({
          id: user.id,
          nombre: user.nombre,
          apellido: user.apellido,
          email: user.email,
        })}; expires=${expired_session}`;
        dispatch({
          type: USER_DATA,
          payload: user,
        });
        swal(
          '¡Felicidades!',
          'Has iniciado sesión satisfactoriamente.',
          'success'
        ).then(() => {
          dispatch(changeLoading('', ''));
          dispatch(changeModalLogin(''));
          dispatch(userGetScoreData());
        });
        return;
      }
      if (res.code === 401) {
        swal(
          'Upsss!!!',
          'El usuario no se encuentra registrado.',
          'error'
        ).then(() => {
          dispatch(changeLoading('', ''));
        });
        return;
      }
      swal(
        'Upsss!!!',
        'Valida que los datos para iniciar sesión esten bien escritos.',
        'error'
      ).then(() => {
        dispatch(changeLoading('', ''));
      });
    },
    (error) => {
      console.log('Error login: ', error);
      swal('Upsss!!!', 'Hubo un error, intentalo más tarde.', 'error').then(
        () => {
          dispatch(changeLoading('', ''));
        }
      );
    }
  );
};

export const registerValidation = () => (dispatch, getState) => {
  const {
    user_name,
    user_lastName,
    user_gender,
    user_dniType,
    user_dni,
    user_repeat_dni,
    user_cellPhone,
    user_email_register,
    user_dateBorn,
    user_password_register,
    user_password_confirm,
    user_policies,
    user_terms,
    user_email_repeat,
  } = getState().userReducer;

  // Campos vacios
  if (user_name === '') {
    swal('Upsss!!!', 'Escribe el nombre.', 'warning');
    return;
  }
  if (user_lastName === '') {
    swal('Upsss!!!', 'Escribe el apellido.', 'warning');
    return;
  }
  if (user_dniType === '') {
    swal(
      'Upsss!!!',
      'Seleccione el tipo de documento de identificación.',
      'warning'
    );
    return;
  }
  if (user_dni === '') {
    swal(
      'Upsss!!!',
      'Escribe el número del documento de identificación.',
      'warning'
    );
    return;
  }
  if (!number_reg.test(user_dni)) {
    swal(
      'Upsss!!!',
      'El número de documento, no debe tener espacios, puntos o carácteres especiales.',
      'warning'
    );
    return;
  }
  if (user_repeat_dni === '') {
    swal(
      'Upsss!!!',
      'Debes repetir el número del documento de identificación.',
      'warning'
    );
    return;
  }
  if (user_repeat_dni !== user_dni) {
    swal('Upsss!!!', 'Los números de documentos no coinciden.', 'warning');
    return;
  }
  if (user_cellPhone === '') {
    swal('Upsss!!!', 'Escribe el número celular.', 'warning');
    return;
  }
  if (user_email_register === '') {
    swal('Upsss!!!', 'Escribe el correo.', 'warning');
    return;
  }
  // Formato de correo
  if (!email_reg.test(user_email_register)) {
    swal('Upsss!!!', 'El formato del correo es incorrecto.', 'warning');
    return;
  }
  if (user_email_register !== user_email_repeat) {
    swal(
      'Upsss!!!',
      'Los correos no coinciden, valida que los dos estén bien escritos.',
      'warning'
    );
    return;
  }
  // termina formato de correo
  if (user_password_register === '') {
    swal('Upsss!!!', 'Escribe la contraseña.', 'warning');
    return;
  }
  if (user_password_register.length < 8 || user_password_register.length > 12) {
    swal(
      'Upsss!!!',
      'La contraseña debe tener mínimo 8 y máximo 12 caracteres.',
      'warning'
    );
    return;
  }
  if (!password_reg.test(user_password_register)) {
    swal(
      'Upsss!!!',
      'La contraseña debe ser solo alfanumérica, no debe tener caracteres especiales.',
      'warning'
    );
    return;
  }
  if (!passwordSecurity(user_password_register)) {
    swal('Upsss!!!', 'La contraseña debe tener números y letras.', 'warning');
    return;
  }
  if (user_password_confirm === '') {
    swal('Upsss!!!', 'Escribe de nuevo la contraseña.', 'warning');
    return;
  }
  // Coincidencia de contraseña
  if (user_password_confirm !== user_password_register) {
    swal('Upsss!!!', 'Las contraseñas no coinciden.', 'warning');
    return;
  }

  if (user_gender === '') {
    swal('Upsss!!!', 'Seleccione el genero.', 'warning');
    return;
  }
  if (user_dateBorn === '') {
    swal('Upsss!!!', 'Escriba su fecha de nacimiento.', 'warning');
    return;
  }
  const birthdate = user_dateBorn.split('-');
  if (user_dateBorn.length < 10 || birthdate.length < 3) {
    swal('Upsss!!!', 'La fecha no tiene el formato de yyyy-mm-dd', 'warning');
    return;
  }
  if (parseInt(birthdate[1]) > 12) {
    swal(
      'Upsss!!!',
      'El mes en la fecha de nacimiento escrito no es válido.',
      'warning'
    );
    return;
  }
  if (parseInt(birthdate[2]) > 31) {
    swal(
      'Upsss!!!',
      'El día escrito en la fecha de nacimiento no es válido.',
      'warning'
    );
    return;
  }

  if (!user_policies) {
    swal(
      'Upsss!!!',
      'Debe aceptar las políticas de tratamiento y uso de datos.',
      'warning'
    );
    return;
  }
  if (!user_terms) {
    swal(
      'Upsss!!!',
      'Debe aceptar los términos y condiciones para las ventas virtuales y programa Cine+.',
      'warning'
    );
    return;
  }

  const data = {
    nombre: user_name,
    apellido: user_lastName,
    sexo: user_gender,
    email: user_email_register,
    cinema: '',
    genero: '',
    tipo_documento: user_dniType,
    documento: user_dni,
    reservas: '',
    noticias: '',
    cartelera: '',
    direccion: '',
    celular: user_cellPhone,
    otras_salas: '',
    barrio: '',
    municipio: '',
    clave: user_password_register,
    telefono: user_cellPhone,
    fecha_nacimiento: user_dateBorn,
  };
  dispatch(changeLoading('visible', 'Registrando...'));
  Api.callMethod(
    'auth/users',
    'POST',
    data,
    (res) => {
      if (res.code === 201) {
        const { access_token, expires_at, user } = res;
        let expired_session = new Date(expires_at);
        expired_session = expired_session.toUTCString();
        document.cookie = `procinal_token=${access_token}; expires=${expired_session}`;
        document.cookie = `procinal_user=${JSON.stringify({
          id: user.id,
          nombre: user.nombre,
          apellido: user.apellido,
          email: user.email,
        })}; expires=${expired_session}`;
        dispatch({
          type: USER_DATA,
          payload: user,
        });
        swal('¡Felicidades!', 'El registro ha sido éxitoso.', 'success').then(
          () => {
            dispatch(changeLoading('', ''));
            dispatch(changeModalRegister(''));
            dispatch(userGetScoreData());
          }
        );
        return;
      }
      if (res.code === 401) {
        swal(
          'Upsss!!!',
          'El usuario ya se encuentra registrado.',
          'error'
        ).then(() => {
          dispatch(changeLoading('', ''));
        });
        return;
      }
      swal(
        'Upsss!!!',
        'Hubo un error al procesar los datos, vuelve a intentarlo más tarde.',
        'error'
      ).then(() => {
        dispatch(changeLoading('', ''));
      });
    },
    (error) => {
      console.log('Error register: ', error);
      swal('Upsss!!!', 'Hubo un error, intentalo más tarde.', 'error').then(
        () => {
          dispatch(changeLoading('', ''));
        }
      );
    }
  );
};

export const userChangeEdit = (payload) => (dispatch) => {
  dispatch({
    type: USER_EDIT_PROFILE,
    payload,
  });
};

export const userUpdate = () => (dispatch, getState) => {
  const {
    user,
    user_name,
    user_lastName,
    user_gender,
    user_dniType,
    user_cellPhone,
    user_email,
    user_password,
  } = getState().userReducer;

  // Campos vacios
  if (user_name === '') {
    swal('Upsss!!!', 'Escribe el nombre.', 'warning');
    return;
  }
  if (user_lastName === '') {
    swal('Upsss!!!', 'Escribe el apellido.', 'warning');
    return;
  }
  if (user_dniType === '') {
    swal(
      'Upsss!!!',
      'Seleccione el tipo de documento de identificación.',
      'warning'
    );
    return;
  }
  if (user_gender === '') {
    swal('Upsss!!!', 'Seleccione el genero.', 'warning');
    return;
  }
  if (user_cellPhone === '') {
    swal('Upsss!!!', 'Escribe el número celular.', 'warning');
    return;
  }
  if (user_email === '') {
    swal('Upsss!!!', 'Escribe el correo.', 'warning');
    return;
  }
  if (!email_reg.test(user_email)) {
    swal('Upsss!!!', 'El formato del correo es incorrecto.', 'warning');
    return;
  }
  if (user_password !== '') {
    if (user_password.length < 8 || user_password.length > 12) {
      swal(
        'Upsss!!!',
        'La contraseña debe tener mínimo 8 y máximo 12 caracteres.',
        'warning'
      );
      return;
    }
    if (!password_reg.test(user_password)) {
      swal(
        'Upsss!!!',
        'La contraseña debe ser solo alfanumérica, no debe tener caracteres especiales.',
        'warning'
      );
      return;
    }
    if (!passwordSecurity(user_password)) {
      swal('Upsss!!!', 'La contraseña debe tener números y letras.', 'warning');
      return;
    }
  }

  const data = {
    nombre: user_name,
    apellido: user_lastName,
    sexo: user_gender,
    email: user_email,
    tipo_documento: user_dniType,
    telefono: user_cellPhone,
  };

  if (user_password !== '') {
    data.clave = user_password;
  }

  dispatch(changeLoading('visible', 'Actualizando datos...'));
  Api.callMethod(
    `auth/users/${user.id}`,
    'PUT',
    data,
    (res) => {
      if (res.code === 200) {
        dispatch({
          type: USER_DATA,
          payload: res.data,
        });
        document.cookie = `procinal_user=${JSON.stringify({
          id: res.data.id,
          nombre: res.data.nombre,
          apellido: res.data.apellido,
          email: res.data.email,
        })};`;
        swal(
          'Grandioso!',
          'Los datos se han actualizado con éxito.',
          'success'
        ).then(() => {
          dispatch(changeLoading('', ''));
          dispatch(userChangeEdit(false));
        });
        return;
      }
      swal('Upsss!!!', res.message, 'error').then(() => {
        dispatch(changeLoading('', ''));
      });
    },
    (error) => {
      console.log('Error UserUpdate: ', error);
      swal(
        'Upsss!!!',
        'Lo sentimos, hubo un error vuelve a intentarlo más tarde.',
        'error'
      ).then(() => {
        dispatch(changeLoading('', ''));
      });
    }
  );
};

export const userChangeScoreLoading = (payload) => (dispatch) => {
  dispatch({
    type: USER_SCORE_LOADING,
    payload,
  });
};

export const userErrorScoreData = (message) => {
  swal(
    'Upsss!!!',
    message
      ? message
      : 'Hubo un error al traer los datos del usuario, vuelve a intentarlo más tarde.',
    'error'
  ).then(() => {
    window.location.href = '/';
  });
};

export const userChangeScoreGet = (payload) => (dispatch) => {
  dispatch({
    type: USER_SCORE_GET,
    payload,
  });
};

export const userGetScoreData = () => (dispatch, getState) => {
  const { user_score_get } = getState().userReducer;

  if (user_score_get) {
    return;
  }

  if (REACT_APP_EMERGENCY === 'true') {
    return;
  }

  dispatch(userChangeScoreLoading(true));
  dispatch(userChangeScoreGet(true));

  Api.callMethod(
    'auth/cine-mas/membership-status?connection=web',
    'get',
    null,
    (res, status) => {
      if (status === 200) {
        dispatch({
          type: USER_SCORE,
          payload: res.data,
        });
        dispatch(userChangeScoreLoading(false));
        if (res.data.Estado === 'Usuario con tarjeta activa') {
          dispatch(userGetScoreBalance());
        }
      }
      dispatch(userChangeScoreGet(false));
    },
    (error) => {
      console.log('Error userGetScoreData: ', error);
      dispatch(userChangeScoreGet(true));
    }
  );
};

export const userChangeLoadingBalance = (payload) => (dispatch) => {
  dispatch({
    type: USER_SCORE_LOADING_BALANCE,
    payload,
  });
};

export const userScoreBalanceError = () => (dispatch) => {
  swal(
    'Upsss!!!',
    'Hubo un error al consultar tu saldo, vuelve a intentarlo más tarde',
    'error'
  ).then(() => {
    dispatch(userChangeLoadingBalance(false));
    dispatch({
      type: USER_SCORE_BALANCE,
      payload: {
        Saldo_Total: 0,
        Saldo_Disponible: 0,
      },
    });
  });
};

export const userChangeScoreBalanceGet = (payload) => (dispatch) => {
  dispatch({
    type: USER_SCORE_BALANCE_GET,
    payload,
  });
};

export const userGetScoreBalance = () => (dispatch, getState) => {
  const { user_score_balance_get } = getState().userReducer;
  dispatch(userChangeLoadingBalance(true));

  if (user_score_balance_get) {
    return;
  }

  dispatch(userChangeScoreBalanceGet(true));

  Api.callMethod(
    'auth/cine-mas/available-balance',
    'get',
    null,
    (res, status) => {
      if (status === 200) {
        dispatch(userChangeLoadingBalance(false));
        dispatch({
          type: USER_SCORE_BALANCE,
          payload: res.balance,
        });
      } else {
        dispatch(userScoreBalanceError());
      }
      dispatch(userChangeScoreBalanceGet(false));
    },
    (error) => {
      console.log('Error userGetScoreBalance: ', error);
      dispatch(userChangeScoreBalanceGet(false));
    }
  );
};
