import React, { Component } from 'react';
import './Theater.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  theaterDetail,
  theaterMovies,
} from '../../store/actions/TheaterActions';
import TheaterServices from './TheaterServices';
import PageLoader from '../PageLoader';
import TheaterFooter from './TheaterFooter';
import TheaterSchedule from './TheaterSchedule';
import TheaterMovies from './TheaterMovies';

class Theater extends Component {
  componentDidMount() {
    const {
      match: {
        params: { theater },
      },
      theaterDetail,
      theaterMovies,
    } = this.props;

    theaterDetail(theater);
    theaterMovies(theater);
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { theater: oldTheater },
      },
    } = prevProps;
    const {
      match: {
        params: { theater },
      },
      theaterDetail,
      theaterMovies,
    } = this.props;

    if (oldTheater !== theater) {
      theaterDetail(theater);
      theaterMovies(theater);
    }
  }

  render() {
    const {
      theaterReducer: { theater_detail },
    } = this.props;

    if (!theater_detail) {
      return <PageLoader />;
    }

    return (
      <div className="Theater_container">
        <TheaterServices theater={theater_detail} />
        <TheaterMovies can_sell={theater_detail.can_sell} />
        <TheaterSchedule />
        <TheaterFooter theater={theater_detail} />
      </div>
    );
  }
}

const mapStateToProps = ({ theaterReducer }) => {
  return {
    theaterReducer,
  };
};

const mapDispatchToProps = {
  theaterDetail,
  theaterMovies,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Theater)
);
