import React, { useEffect, useState } from 'react';
import logo from '../../assets/images/loading.gif';
const { REACT_APP_MESSAGE } = process.env;

function Intro() {
  const [message, setMessage] = useState(
    'Estamos cargando tu plan de cine favorito'
  );

  useEffect(() => {
    setTimeout(() => {
      setMessage(
        'Debido a la alta demanda, nuestra página puede tardar un poco en cargar. Agradecemos tu paciencia.'
      );
    }, 7500);
  }, []);

  return (
    <div className="Intro">
      <div className="Into_box-image">
        <img src={logo} alt="Logo Procinal" />
      </div>
      {REACT_APP_MESSAGE === 'true' && (
        <div className="Intro_text">{message}</div>
      )}
    </div>
  );
}

export default Intro;
