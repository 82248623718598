import swal from 'sweetalert';
import Api from './Api';
import {
  PAY_TICKET,
  PAY_TICKET_NAME,
  PAY_TICKET_LASTNAME,
  PAY_TICKET_EMAIL,
  PAY_TICKET_CONFIRM_EMAIL,
  PAY_TICKET_DOC_NUMBER,
  PAY_TICKET_PHONE,
  PAY_TICKET_QUANTITY,
  PAY_TICKET_TOTAL,
  PAY_TICKET_CLEAR,
  PAY_TICKET_LOADING,
  PAY_TICKET_REDIRECT,
} from '../constants/PayTicket';
import {
  changeCheckoutError,
  changeCheckoutMovie,
  changeMovieLoading,
  changeShowtime,
  checkoutChangeTotal,
  checkoutChangeTotalTickets,
  checkoutChangeTotalTransaction,
} from './CheckoutActions';
import { changeLoading } from './GlobalActions';
const email_reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const changePayTicketName = (payload) => (dispatch) => {
  dispatch({
    type: PAY_TICKET_NAME,
    payload,
  });
};

export const changePayTicketLastname = (payload) => (dispatch) => {
  dispatch({
    type: PAY_TICKET_LASTNAME,
    payload,
  });
};

export const changePayTicketEmail = (payload) => (dispatch) => {
  dispatch({
    type: PAY_TICKET_EMAIL,
    payload,
  });
};

export const changePayTicketConfirmEmail = (payload) => (dispatch) => {
  dispatch({
    type: PAY_TICKET_CONFIRM_EMAIL,
    payload,
  });
};

export const changePayTicketDocNumber = (payload) => (dispatch) => {
  dispatch({
    type: PAY_TICKET_DOC_NUMBER,
    payload,
  });
};

export const changePayTicketPhone = (payload) => (dispatch) => {
  dispatch({
    type: PAY_TICKET_PHONE,
    payload,
  });
};

export const changePayTicketQuantity = (payload) => (dispatch) => {
  dispatch({
    type: PAY_TICKET_QUANTITY,
    payload,
  });
};

export const changePayTicketTotal = (payload) => (dispatch) => {
  dispatch({
    type: PAY_TICKET_TOTAL,
    payload,
  });
};

export const changePayTicketClear = (payload) => (dispatch) => {
  dispatch({
    type: PAY_TICKET_CLEAR,
    payload,
  });
};

export const changeRedirect = (payload) => (dispatch) => {
  dispatch({
    type: PAY_TICKET_REDIRECT,
    payload,
  });
};

export const changePayTicketLoading = (payload) => (dispatch) => {
  dispatch({
    type: PAY_TICKET_LOADING,
    payload,
  });
};

export const changeTicket = (payload) => (dispatch) => {
  dispatch({
    type: PAY_TICKET,
    payload,
  });
};

export const getResponseTicket = (type, bill) => () => {
  Api.callMethod(
    `ticket/response/${type}/${bill}`,
    'post',
    null,
    (res) => {
      if (res.code === 200) {
        window.location.replace(`/ticket/status/${bill}`);
        return;
      }
      swal('Upsss!!!', 'Hubo un error, intentalo más tarde', 'error').then(
        () => {
          window.location.replace('/');
        }
      );
    },
    (error) => {
      console.log('Error ticketStatus: ', error);
      swal('Upsss!!!', 'Hubo un error, intentalo más tarde', 'error').then(
        () => {
          window.location.replace('/');
        }
      );
    }
  );
};

export const getTicketStatus = (bill) => (dispatch) => {
  dispatch(changeRedirect(''));
  dispatch(changePayTicketLoading(true));

  Api.callMethod(
    `ticket/status/${bill}`,
    'get',
    null,
    (res) => {
      if (res.code === 200) {
        dispatch(changeTicket(res.data));
        dispatch(changePayTicketLoading(false));
        return;
      }
      swal('Upsss!!!', 'Hubo un error, intentalo más tarde', 'error').then(
        () => {
          dispatch(changeRedirect('/'));
        }
      );
    },
    (error) => {
      console.log('Error ticketStatus: ', error);
      swal('Upsss!!!', 'Hubo un error, intentalo más tarde', 'error').then(
        () => {
          dispatch(changeRedirect('/'));
        }
      );
    }
  );
};

const setPrices = () => (dispatch, getState) => {
  const { checkout_showtime } = getState().checkoutReducer;
  dispatch(changePayTicketQuantity(1));

  dispatch(checkoutChangeTotalTickets(checkout_showtime.tarifa_web));
  dispatch(checkoutChangeTotalTransaction(checkout_showtime.recargo_web));
  dispatch(
    checkoutChangeTotal(
      checkout_showtime.tarifa_web + checkout_showtime.recargo_web
    )
  );
};

export const getMovie = (movie_id, showtime_id) => (dispatch, getState) => {
  const { global_movies } = getState().globalReducer;
  dispatch(changeMovieLoading(true));

  const movie = global_movies.find(
    (movie) => parseInt(movie.id) === parseInt(movie_id)
  );

  if (movie) {
    const showtime = movie.showtimes.find(
      (showtime) => parseInt(showtime.id) === parseInt(showtime_id)
    );
    dispatch(changeCheckoutMovie(movie));
    setTimeout(() => {
      dispatch(changeMovieLoading(false));
    }, 250);

    if (showtime) {
      dispatch(changeShowtime(showtime));
      dispatch(setPrices());
      return;
    }
  }
  dispatch(changeCheckoutError(true));
};

export const addRemoveQuantity = (action, limit) => (dispatch, getState) => {
  const { checkout_showtime } = getState().checkoutReducer;
  const { quantity } = getState().payTicketReducer;
  let tickets = quantity;

  if (action === 'add' && tickets < limit) {
    tickets++;
  }
  if (action === 'remove' && tickets > 1) {
    tickets--;
  }

  let totalTickets = tickets * checkout_showtime.tarifa_web;
  let totalTransaction = tickets * checkout_showtime.recargo_web;
  let total = totalTickets + totalTransaction;

  dispatch(checkoutChangeTotalTickets(totalTickets));
  dispatch(checkoutChangeTotalTransaction(totalTransaction));
  dispatch(checkoutChangeTotal(total));
  dispatch(changePayTicketQuantity(tickets));
};

export const ticketPayToCard = () => (dispatch, getState) => {
  const {
    payment_name,
    payment_card_number,
    payment_card_month,
    payment_card_year,
    payment_card_cvc,
    payment_card_dues,
  } = getState().paymentReducer;
  const {
    name,
    lastname,
    email,
    doc_number,
    phone,
    quantity,
  } = getState().payTicketReducer;
  const { checkout_showtime, checkout_total } = getState().checkoutReducer;

  if (payment_name === '') {
    swal('Upsss!!!', 'Escribe el nombre que aparece en la tarjeta', 'warning');
    return;
  }
  if (payment_card_number === '') {
    swal('Upsss!!!', 'Escribe el numero de la tarjeta', 'warning');
    return;
  }
  if (payment_card_month === '') {
    swal('Upsss!!!', 'Escribe el mes de vencimiento de la tarjeta', 'warning');
    return;
  }
  if (payment_card_year === '') {
    swal('Upsss!!!', 'Escribe el año de vencimiento en la tarjeta', 'warning');
    return;
  }
  if (payment_card_cvc === '') {
    swal(
      'Upsss!!!',
      'Escribe el CVC de la tarjeta (los 3 números en el reverso de la tarjeta)',
      'warning'
    );
    return;
  }
  if (payment_card_dues === '') {
    swal('Upsss!!!', 'Seleccione las cuotas', 'warning');
    return;
  }

  const data = {
    showtime: checkout_showtime.id,
    name,
    lastname,
    email,
    doc_number,
    phone,
    card_name: payment_name,
    card_number: payment_card_number,
    exp_year: payment_card_year,
    exp_month: payment_card_month,
    cvc: payment_card_cvc,
    dues: payment_card_dues,
    quantity,
    total: checkout_total,
  };

  dispatch(changeLoading('visible', 'Procesando el pago...'));

  Api.callMethod(
    'ticket/pay-to-card',
    'POST',
    data,
    (res) => {
      if (res.code === 201) {
        dispatch(changeLoading('', ''));
        window.location.replace(`/ticket/status/${res.ticket.bill}`);
        return;
      }
      swal('Estado de la transacción', res.message, 'warning').then(() => {
        dispatch(changeLoading('', ''));
        if (res.ticket && res.ticket.bill) {
          window.location.replace(`/ticket/response/card/${res.ticket.bill}`);
        } else {
          window.location.reload();
        }
      });
    },
    (error) => {
      console.log('Error PaymentCard: ', error);
      swal(
        'Upsss!!!',
        'Hubo un error al momento de procesar el pago, vuelve a intentarlo.',
        'warning'
      ).then(() => {
        dispatch(changeLoading('', ''));
        window.location.reload();
      });
    }
  );
};

export const ticketPayToPSE = () => (dispatch, getState) => {
  const {
    payment_pse_type_person,
    payment_pse_bank,
    payment_pse_nameAndLastName,
    payment_pse_doc_type,
    payment_pse_doc_number,
    payment_pse_email,
    payment_pse_phone,
  } = getState().paymentReducer;
  const {
    name,
    lastname,
    email,
    doc_number,
    phone,
    quantity,
  } = getState().payTicketReducer;
  const { checkout_showtime, checkout_total } = getState().checkoutReducer;

  if (payment_pse_type_person === '') {
    swal('Upsss!!!', 'Seleccione el tipo de persona', 'warning');
    return;
  }
  if (payment_pse_bank === '') {
    swal('Upsss!!!', 'Seleccione el banco', 'warning');
    return;
  }
  if (payment_pse_nameAndLastName === '') {
    swal('Upsss!!!', 'Escribe el nombre y apellido', 'warning');
    return;
  }
  if (payment_pse_doc_type === '') {
    swal('Upsss!!!', 'Seleccione el tipo de documento', 'warning');
    return;
  }
  if (payment_pse_doc_number === '') {
    swal('Upsss!!!', 'Escribe el número de documento', 'warning');
    return;
  }
  if (payment_pse_email === '') {
    swal('Upsss!!!', 'Escribe el correo', 'warning');
    return;
  }
  if (!email_reg.test(payment_pse_email)) {
    swal('Upsss!!!', 'El formato del correo es incorrecto', 'warning');
    return;
  }
  if (payment_pse_phone === '') {
    swal('Upsss!!!', 'Seleccione el número de móvil', 'warning');
    return;
  }

  const data = {
    showtime: checkout_showtime.id,
    name,
    lastname,
    email,
    payment_email: payment_pse_email,
    doc_number,
    doc_type: payment_pse_doc_type,
    doc_number_payment: payment_pse_doc_number,
    type_person: `${payment_pse_type_person}`,
    phone,
    cod_bank: payment_pse_bank,
    quantity,
    total: checkout_total,
  };

  dispatch(changeLoading('visible', 'Procesando el pago...'));

  Api.callMethod(
    'ticket/pay-to-pse',
    'POST',
    data,
    (res) => {
      if (res.code === 201) {
        dispatch(changeLoading('', ''));
        window.location.replace(res.ticket.pse_url);
        return;
      }
      swal('Upsss!!!', res.message, 'warning').then(() => {
        dispatch(changeLoading('', ''));
        window.location.reload();
      });
    },
    (error) => {
      console.log('Error PaymentCard: ', error);
      swal(
        'Upsss!!!',
        'Hubo un error al momento de procesar el pago, vuelve a intentarlo.',
        'warning'
      ).then(() => {
        dispatch(changeLoading('', ''));
        window.location.reload();
      });
    }
  );
};
