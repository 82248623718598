import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Tickets from '../../components/Tickets';
import ProfileLayout from '../../Layouts/ProfileLayout';

function TicketsPage(props) {
  const {
    userReducer: { user },
  } = props;
  if (!user) {
    return <Redirect to="/" />;
  }
  return (
    <ProfileLayout>
      <Tickets />
    </ProfileLayout>
  );
}

const mapStateToProps = ({ userReducer }) => {
  return {
    userReducer,
  };
};

export default connect(mapStateToProps, null)(TicketsPage);
