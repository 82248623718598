import React from 'react';
import { connect } from 'react-redux';
import { getSettingValue } from '../../../functions/currentFunctions';
import {
  getConfectioneryDetail,
  getTotalCombos,
  getTotalsRecipe,
} from '../../../Utils/confectioneryManager';

function CombosDescription(props) {
  const {
    globalReducer: { global_settings },
    confectioneryReducer: { confectionery_combos, confectionery_api_products },
    checkoutReducer: { checkout_pay_option },
  } = props;
  const numberFormat = new Intl.NumberFormat('es-CO');
  const recargo = parseInt(
    getSettingValue(global_settings, 'confiteria_price_service', 500)
  );

  const getComboPrice = (recipe) => {
    const totals = getTotalsRecipe(recipe);

    if (checkout_pay_option === 'Deshabilitado') {
      return totals.totalOthers;
    }

    return totals.totalCinemas;
  };

  const getCombosPrice = () => {
    const totals = getTotalCombos(confectionery_combos);
    const total =
      checkout_pay_option === 'Deshabilitado'
        ? totals.totalOthers
        : totals.totalCinemas;

    return total + recargo;
  };

  return (
    <div className="PaymentMethods_combos">
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Cantidad</th>
              <th>Nombre combo</th>
              <th>Precio</th>
            </tr>
          </thead>
          <tbody>
            {confectionery_combos.map((combo, index) => {
              const productApi = getConfectioneryDetail(
                combo.Codigo,
                confectionery_api_products
              );
              if (!productApi) return null;
              return (
                <tr key={`${combo.Codigo}-${index}`}>
                  <td>{combo.Cantidad}</td>
                  <td>{productApi.name}</td>
                  <td className="text-right">
                    $
                    {numberFormat.format(
                      getComboPrice(combo.Receta) * combo.Cantidad
                    )}
                  </td>
                </tr>
              );
            })}
            <tr className="PaymentMethods_total">
              <td colSpan={2} className="text-right">
                CARGO POR SERVICIO
              </td>
              <td className="text-right">${numberFormat.format(recargo)}</td>
            </tr>
            <tr className="PaymentMethods_total">
              <td colSpan={2} className="text-right">
                TOTAL CONFITERÍA
              </td>
              <td className="text-right PaymentMethods_total-number">
                ${numberFormat.format(getCombosPrice())}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

const mapStateToProps = ({
  globalReducer,
  confectioneryReducer,
  checkoutReducer,
}) => {
  return {
    globalReducer,
    confectioneryReducer,
    checkoutReducer,
  };
};

export default connect(mapStateToProps, null)(CombosDescription);
