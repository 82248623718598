import { GLOBAL_MOVIE } from '../constants/Global';
import {
  MOVIES_ACTIVE,
  MOVIES_ANIMATION,
  MOVIES_LOADING,
  MOVIES_NEXT_RELEASES,
  MOVIES_NEXT_RELEASES_LOADING,
  MOVIES_STEPS,
} from '../constants/Movie';
import Api from './Api';

export const changeMoviesLoading = (payload) => (dispatch) => {
  dispatch({
    type: MOVIES_LOADING,
    payload,
  });
};

export const changeMoviesAnimation = (payload) => (dispatch) => {
  dispatch({
    type: MOVIES_ANIMATION,
    payload,
  });
};

export const getMoviesActive = () => (dispatch) => {
  dispatch(changeMoviesLoading(true));

  Api.callMethod(
    'movies/active',
    'get',
    null,
    (res) => {
      let steps = 2;
      const WIDTH_WINDOW = window.innerWidth;
      const WIDTH_ROW = res.data.length * 156;

      if (WIDTH_WINDOW > WIDTH_ROW) {
        let diffSteps = Math.ceil(WIDTH_WINDOW / WIDTH_ROW);
        if (diffSteps !== Infinity) {
          steps = diffSteps;
        }
      }
      dispatch(changeMoviesLoading(false));
      dispatch({
        type: MOVIES_ACTIVE,
        payload: res.data,
      });
      dispatch({
        type: MOVIES_STEPS,
        payload: steps,
      });
    },
    (error) => {
      console.log('Error getMoviesActive: ', error);
    }
  );
};

export const getMoviesNextReleases = () => (dispatch) => {
  dispatch({
    type: MOVIES_NEXT_RELEASES_LOADING,
    payload: true,
  });

  Api.callMethod(
    'movies/next-releases',
    'get',
    null,
    (res) => {
      const { data } = res;
      const added = [];
      const movies = [];
      for (let i = 0; i < data.length; i++) {
        let isAdded = added.indexOf(data[i].slug);
        if (isAdded < 0) {
          added.push(data[i].slug);
          movies.push(data[i]);
        }
      }

      dispatch({
        type: MOVIES_NEXT_RELEASES_LOADING,
        payload: false,
      });
      dispatch({
        type: MOVIES_NEXT_RELEASES,
        payload: movies,
      });
    },
    (error) => {
      console.log('Error getMoviesNextReleases: ', error);
    }
  );
};

export const getMovieDetail = (slug) => (dispatch, getState) => {
  const { movies_next_releases } = getState().movieReducer;

  const movie = movies_next_releases.find((movie) => movie.slug === slug);

  if (movie) {
    dispatch({
      type: GLOBAL_MOVIE,
      payload: movie,
    });
  } else {
    Api.callMethod(
      `movies/${slug}`,
      'get',
      null,
      (res) => {
        const { data } = res;
        dispatch({
          type: GLOBAL_MOVIE,
          payload: data,
        });
      },
      (error) => {
        console.log('Error getMovieDetail: ', error);
      }
    );
  }
};
