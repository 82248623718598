import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeMoviesAnimation } from '../../store/actions/MovieActions';
import './Home.css';
import logo from '../../assets/images/logo-white.png';
import SelectCity from '../SelectCity';
import MovieHomeSlider from './HomeMovieSlider';

class Home extends Component {
  state = {
    movieHomeSlider: false,
  };

  componentDidMount() {
    this.setState({
      movieHomeSlider: true,
    });
  }

  componentWillUnmount() {
    this.setState({
      movieHomeSlider: false,
    });
    this.props.changeMoviesAnimation(true);
  }

  render() {
    const {
      movieReducer: { movies_loading },
    } = this.props;

    return (
      <div className="Home_container">
        <div className="Home_logo">
          <div className="Home_welcome">Bienvenidos a</div>
          <img src={logo} alt="Logo Procinal" />
        </div>
        <div className="Home_select">
          <div className="Home_title-select">Selecciona tu ciudad:</div>
          <SelectCity />
          <div className="Home_line" />
        </div>
        {!movies_loading && this.state.movieHomeSlider && <MovieHomeSlider />}
      </div>
    );
  }
}

const mapStateToProps = ({ movieReducer }) => {
  return {
    movieReducer,
  };
};

const mapDispatchToProps = {
  changeMoviesAnimation,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
