export const CORPORATIVE_LOADING = 'CORPORATIVE_LOADING';
export const CORPORATIVE_LIST = 'CORPORATIVE_LIST';
export const CORPORATIVE_LOADING_DETAIL = 'CORPORATIVE_LOADING_DETAIL';
export const CORPORATIVE_DETAIL = 'CORPORATIVE_DETAIL';
export const CORPORATIVE_BUSINES = 'CORPORATIVE_BUSINES';
export const CORPORATIVE_NIT = 'CORPORATIVE_NIT';
export const CORPORATIVE_CONTACT_NAME = 'CORPORATIVE_CONTACT_NAME';
export const CORPORATIVE_CONTACT_EMAIL = 'CORPORATIVE_CONTACT_EMAIL';
export const CORPORATIVE_CONTACT_PHONE = 'CORPORATIVE_CONTACT_PHONE';
export const CORPORATIVE_COMPLEMENT = 'CORPORATIVE_COMPLEMENT';
export const CORPORATIVE_ATTENDEES = 'CORPORATIVE_ATTENDEES';
export const CORPORATIVE_COMMENTS = 'CORPORATIVE_COMMENTS';
export const CORPORATIVE_FORM_LOADING = 'CORPORATIVE_FORM_LOADING';
export const CORPORATIVE_SECTION = 'CORPORATIVE_SECTION';
export const CORPORATIVE_CLEAR_FORM = 'CORPORATIVE_CLEAR_FORM';
export const CORPORATIVE_THEATERS = 'CORPORATIVE_THEATERS';
