/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import './MovieDetails.css';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

function MovieDetails(props) {
  const {
    globalReducer: { global_movie },
    match: {
      params: { city },
    },
  } = props;

  let videoId = global_movie.trailer1.split('/');
  videoId = videoId[videoId.length - 1];
  if (videoId.indexOf('watch') >= 0) {
    videoId = videoId.split('v=');
    videoId = videoId[videoId.length - 1];
  }
  const image = global_movie.imagen.replaceAll(' ', '_');

  return (
    <div className="site-container">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="Checkout_bread-crumbs">
              {city ? (
                <Link to={`/ciudad/${city}`}>Películas/</Link>
              ) : (
                <Link to="/proximos-estrenos">Próximos Estrenos/</Link>
              )}
              {global_movie.titulo}
            </div>
          </div>
          <div className="col-md-12">
            <div className="MovieDetails">
              <div className="row">
                <div className="col-md-3">
                  <img src={image} alt={global_movie.titulo} />
                  <p>
                    <strong>{'Título original:'}</strong>
                    <br />
                    <span>{global_movie.titulo_original}</span>
                  </p>
                  <p>
                    <strong>{'Título local:'}</strong>
                    <br />
                    <span>{global_movie.titulo}</span>
                  </p>

                  <p>
                    <strong>{'Fecha de estreno:'}</strong>
                    <br />
                    <span>{global_movie.fecha_estreno}</span>
                  </p>
                </div>
                <div className="col-md-9">
                  <div className="MovieDetails-caja-titulo">
                    <h2>{global_movie.titulo}</h2>
                    {/* <p>{'Genero'}</p> */}
                    <p>{global_movie.duracion} minutos</p>
                  </div>
                  <div className="MovieDetails_video">
                    <iframe
                      src={`https://www.youtube.com/embed/${videoId}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <div className="MovieDetails-sinopsis">
                    <h6>Sinopsis</h6>
                    <p>{global_movie.sinopsis}</p>
                  </div>
                  <div className="row">
                    <div className="row">
                      <div className="col-4 col-md-2">
                        <strong>País:</strong>
                      </div>
                      <div className="col-8 col-md-10">{global_movie.pais}</div>
                    </div>
                    <div className="row">
                      <div className="col-4 col-md-2">
                        <strong>Reparto:</strong>
                      </div>
                      <div className="col-8 col-md-10">
                        {global_movie.reparto}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4 col-md-2">
                        <strong>Director:</strong>
                      </div>
                      <div className="col-8 col-md-10">
                        {global_movie.director}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4 col-md-2">
                        <strong>Duración:</strong>
                      </div>
                      <div className="col-8 col-md-10">
                        {global_movie.duracion} minutos
                      </div>
                    </div>
                    {global_movie.clasificacion && (
                      <div className="row">
                        <div className="col-4 col-md-2">
                          <strong>Clasificación:</strong>
                        </div>
                        <div className="col-8 col-md-10">
                          {global_movie.clasificacion}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ globalReducer }) => {
  return {
    globalReducer,
  };
};

export default withRouter(connect(mapStateToProps, null)(MovieDetails));
