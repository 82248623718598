import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  corporativeGetList,
  corporativeDetail,
  corporativeSection,
} from '../../store/actions/CorporativeActions';
import { globalGetCinemas } from '../../store/actions/GlobalActions';
import PageLoader from '../PageLoader';
import CorporativeHeader from './CorporativeHeader';
import './Corporative.css';
import CorporativeForm from './CorporativeForm';

class Corporative extends Component {
  componentDidMount() {
    const {
      corporativeReducer: { corporative_loading },
      globalReducer: { global_cinema_list },
      match: {
        params: { corporative },
      },
      corporativeSection,
      corporativeGetList,
      corporativeDetail,
      globalGetCinemas,
    } = this.props;

    if (corporative) {
      corporativeSection(corporative);
    }
    if (corporative_loading) {
      corporativeGetList();
    } else {
      corporativeDetail();
    }
    if (!global_cinema_list.length) {
      globalGetCinemas();
    }
  }

  componentDidUpdate(oldProps) {
    const {
      match: {
        params: { corporative: oldCorporative },
      },
    } = oldProps;
    const {
      match: {
        params: { corporative },
      },
      corporativeSection,
      corporativeDetail,
    } = this.props;

    if (oldCorporative !== corporative) {
      corporativeSection(corporative);
      corporativeDetail();
    }
  }

  componentWillUnmount() {
    corporativeSection('');
  }

  render() {
    const {
      corporativeReducer: {
        corporative_loading,
        corporative_detail,
        corporative_loading_detail,
      },
      match: {
        params: { corporative },
      },
    } = this.props;

    if (corporative_loading) {
      return <PageLoader />;
    }

    return (
      <div className="Corporative-container">
        <CorporativeHeader />
        {corporative_loading_detail ? (
          <PageLoader />
        ) : (
          <>
            <div className="container Corporative-content">
              <div className="row">
                <div className="col-md-12">
                  <h1 className="Corporative-title">
                    {corporative_detail.title}
                  </h1>
                </div>
                {corporative === 'bonos-regalo' ? (
                  <>
                    <div className="col-md-3">
                      <img
                        src={corporative_detail.image}
                        alt={corporative_detail.title}
                        className="Corporative-image"
                      />
                    </div>
                    <div className="col-md-9">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: corporative_detail.content,
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <div className="col-md-8 offset-md-2">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: corporative_detail.content,
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <CorporativeForm />
      </div>
    );
  }
}

const mapStateToProps = ({ corporativeReducer, globalReducer }) => {
  return {
    corporativeReducer,
    globalReducer,
  };
};

const mapDispatchToProps = {
  corporativeGetList,
  corporativeDetail,
  corporativeSection,
  globalGetCinemas,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Corporative)
);
