import swal from 'sweetalert';
import {
  CORPORATIVE_LOADING,
  CORPORATIVE_LIST,
  CORPORATIVE_LOADING_DETAIL,
  CORPORATIVE_DETAIL,
  CORPORATIVE_BUSINES,
  CORPORATIVE_NIT,
  CORPORATIVE_CONTACT_NAME,
  CORPORATIVE_CONTACT_EMAIL,
  CORPORATIVE_CONTACT_PHONE,
  CORPORATIVE_COMPLEMENT,
  CORPORATIVE_ATTENDEES,
  CORPORATIVE_COMMENTS,
  CORPORATIVE_FORM_LOADING,
  CORPORATIVE_SECTION,
  CORPORATIVE_CLEAR_FORM,
  CORPORATIVE_THEATERS,
} from '../constants/Corporative';
import Api from './Api';

export const corportativeClearForm = () => (dispatch) => {
  dispatch({
    type: CORPORATIVE_CLEAR_FORM,
  });
};

export const corporativeTheaters = () => (dispatch) => {
  Api.callMethod('cinemas', 'get', null, (res, status) => {
    if (status === 200) {
      const { data } = res;
      dispatch({
        type: CORPORATIVE_THEATERS,
        payload: data,
      });
    }
  });
};

export const corporativeLoading = (payload) => (dispatch) => {
  dispatch({
    type: CORPORATIVE_LOADING,
    payload,
  });
};

export const corporativeSection = (payload) => (dispatch) => {
  dispatch(corportativeClearForm());
  dispatch({
    type: CORPORATIVE_SECTION,
    payload,
  });
};

export const corporativeLoadingDetail = (payload) => (dispatch) => {
  dispatch({
    type: CORPORATIVE_LOADING_DETAIL,
    payload,
  });
};

export const corporativeDetail = () => (dispatch, getState) => {
  dispatch(corporativeLoadingDetail(true));

  const {
    corporative_section,
    corporative_list,
  } = getState().corporativeReducer;

  if (!corporative_section || corporative_section === '') {
    dispatch({
      type: CORPORATIVE_DETAIL,
      payload: corporative_list[0],
    });
    dispatch(corporativeLoadingDetail(false));
    dispatch(corporativeSection(corporative_list[0].label));
  } else {
    const detail = corporative_list.find(
      (item) => item.label === corporative_section
    );
    if (detail) {
      dispatch({
        type: CORPORATIVE_DETAIL,
        payload: detail,
      });
    } else {
      dispatch({
        type: CORPORATIVE_DETAIL,
        payload: {
          title: 'Contenido no encontrado',
          content: '',
        },
      });
    }
    dispatch(corporativeLoadingDetail(false));
  }
};

export const corporativeGetList = () => (dispatch) => {
  dispatch(corporativeLoading(true));

  Api.callMethod('contents?type=2', 'get', null, (res, status) => {
    if (status === 200) {
      const { data } = res;
      dispatch({
        type: CORPORATIVE_LIST,
        payload: data,
      });
      dispatch(corporativeLoading(false));
      dispatch(corporativeDetail());
    }
  });
};

export const corporativeChangeBusines = (payload) => (dispatch) => {
  dispatch({
    type: CORPORATIVE_BUSINES,
    payload,
  });
};

export const corporativeChangeNit = (payload) => (dispatch) => {
  dispatch({
    type: CORPORATIVE_NIT,
    payload,
  });
};

export const corporativeChangeContactName = (payload) => (dispatch) => {
  dispatch({
    type: CORPORATIVE_CONTACT_NAME,
    payload,
  });
};

export const corporativeChangeContactEmail = (payload) => (dispatch) => {
  dispatch({
    type: CORPORATIVE_CONTACT_EMAIL,
    payload,
  });
};

export const corporativeChangeContactPhone = (payload) => (dispatch) => {
  dispatch({
    type: CORPORATIVE_CONTACT_PHONE,
    payload,
  });
};

export const corporativeChangeComplement = (payload) => (dispatch) => {
  dispatch({
    type: CORPORATIVE_COMPLEMENT,
    payload,
  });
};

export const corporativeChangeAttendees = (payload) => (dispatch) => {
  dispatch({
    type: CORPORATIVE_ATTENDEES,
    payload,
  });
};

export const corporativeChangeComments = (payload) => (dispatch) => {
  dispatch({
    type: CORPORATIVE_COMMENTS,
    payload,
  });
};

export const corportativeFormLoading = (payload) => (dispatch) => {
  dispatch({
    type: CORPORATIVE_FORM_LOADING,
    payload,
  });
};

export const corportativeFormError = () => (dispatch) => {
  swal(
    'Upsss!!!',
    'Hubo un error al momento de enviar el mensaje, vuelve a intentarlo más tarde.',
    'error'
  ).then(() => {
    dispatch(corportativeClearForm());
  });
};

export const corportativeSendForm = () => (dispatch, getState) => {
  const {
    corporative_busines,
    corporative_nit,
    corporative_contact_name,
    corporative_contact_email,
    corporative_contact_phone,
    corporative_detail,
    corporative_complement,
    corporative_attendees,
    corporative_comments,
  } = getState().corporativeReducer;

  let error = '';

  if (corporative_busines === '') {
    error = `${error}Nombre de la empresa.\n`;
  }
  if (corporative_nit === '') {
    error = `${error}NIT.\n`;
  }
  if (corporative_contact_name === '') {
    error = `${error}Nombre del contacto.\n`;
  }
  if (corporative_contact_email === '') {
    error = `${error}Email del contacto.\n`;
  }
  if (corporative_contact_phone === '') {
    error = `${error}Teléfono del contacto.\n`;
  }
  if (
    corporative_detail.label === 'ventas-empresariales' &&
    corporative_complement === ''
  ) {
    error = `${error}Tipo de bono.\n`;
  }
  if (
    corporative_detail.label === 'alquiler-de-salas' &&
    corporative_complement === ''
  ) {
    error = `${error}Teatro.\n`;
  }
  if (
    corporative_detail.label === 'ventas-empresariales' &&
    corporative_attendees === ''
  ) {
    error = `${error}Cantidad.\n`;
  }
  if (
    corporative_detail.label === 'alquiler-de-salas' &&
    corporative_attendees === ''
  ) {
    error = `${error}Número de Asistentes.\n`;
  }

  if (error !== '') {
    swal('Los campos son obligatorios:', error, 'warning');
    return;
  }

  const data = {
    section: corporative_detail.title,
    business: corporative_busines,
    nit: corporative_nit,
    contact_name: corporative_contact_name,
    contact_email: corporative_contact_email,
    contact_phone: corporative_contact_phone,
    comments: corporative_comments,
  };

  if (
    corporative_detail.label === 'ventas-empresariales' ||
    corporative_detail.label === 'alquiler-de-salas'
  ) {
    data.complement = corporative_complement;
    data.attendees = corporative_attendees;
  }

  dispatch(corportativeFormLoading(true));

  Api.callMethod(
    'corporative-contact',
    'post',
    data,
    (res, status) => {
      if (status === 201) {
        swal(
          '¡Felicidades!',
          'El mesaje ha sido enviado con éxito.',
          'success'
        ).then(() => {
          dispatch(corportativeFormLoading(false));
          dispatch(corportativeClearForm());
        });
      } else {
        dispatch(corportativeFormError());
      }
    },
    (error) => {
      console.log('Error corportativeSendForm: ', error);
      dispatch(corportativeFormError());
    }
  );
};
