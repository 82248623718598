import { TICKET_CLEAR, TICKET_DATA, TICKET_LOADING } from '../constants/Ticket';
const { REACT_APP_TICKET } = process.env;

export const clearTicket = () => (dispatch) => {
  dispatch({
    type: TICKET_CLEAR,
  });
};

export const getTicket = (ticket) => (dispatch, getState) => {
  const { user } = getState().userReducer;
  dispatch({
    type: TICKET_LOADING,
    payload: true,
  });

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  };

  let params = {
    mode: 'cors',
    method: 'POST',
    headers,
    body: JSON.stringify({
      email: user.email,
    }),
  };

  fetch(`${REACT_APP_TICKET}/${ticket}`, params)
    .then((res) => {
      res.json().then((response) => {
        if (response.error.code === 0) {
          const ticket = response.data.ticket;
          ticket.products_qr = response.data.productsQr;
          dispatch({
            type: TICKET_DATA,
            payload: ticket,
          });
        }
        dispatch({
          type: TICKET_LOADING,
          payload: false,
        });
      });
    })
    .catch((err) => {
      console.log('Error getTicket: ', err);
    });
};
