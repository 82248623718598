import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeDate } from '../../../../store/actions/GlobalActions';
import './Dates.css';

class Dates extends Component {
  renderDates = () => {
    const {
      globalReducer: { global_dates, global_date },
      changeDate,
    } = this.props;

    return global_dates.map((date) => {
      const selected = global_date === date.value ? 'selected' : '';
      return (
        <button
          type="button"
          className={`Dates_button ${selected}`}
          key={date.value}
          onClick={() => changeDate(date.value)}
        >
          {date.name}
        </button>
      );
    });
  };

  render() {
    return <div className="Dates">{this.renderDates()}</div>;
  }
}

const mapStateToProps = ({ globalReducer }) => {
  return {
    globalReducer,
  };
};

const mapDisptatchToProps = {
  changeDate,
};

export default connect(mapStateToProps, mapDisptatchToProps)(Dates);
