export const getCurrentDate = () => {
  const d = new Date();
  let year = d.getFullYear();
  let month = d.getMonth() + 1;
  month = month < 10 ? `0${month}` : month;
  let day = d.getDate();
  day = day < 10 ? `0${day}` : day;

  return `${year}-${month}-${day}`;
};

export const getCityName = (global_cities, global_city) => {
  let city = null;
  if (global_city) {
    city = global_cities.filter((item) => item.slug === global_city);
    city = city.length ? city[0].nombre : null;
  }

  return city;
};

export const getSettingValue = (settings, labelSetting, current = null) => {
  const setting = settings.find((setting) => setting.name === labelSetting);

  if (setting) {
    return setting.value;
  }

  return current || '';
};
