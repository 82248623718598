import React from 'react';

function Error404() {
  return (
    <div className="Erro404Page_container">
      <h1>404</h1>
      <p>Página no encontrada</p>
    </div>
  );
}

export default Error404;
