import React from 'react';
import { Link } from 'react-router-dom';

function ModalOldPayment({ onCloseModal }) {
  return (
    <div className="Checkout_modal">
      <div className="Checkout_modal-content">
        <button onClick={onCloseModal} className="Checkout_modal-close">
          &times;
        </button>
        <h2>Ya tienes una compra activa para está función</h2>
        <h3>¿Deseas continuar con la compra?</h3>
        <div className="Checkout_modal-menu">
          <Link to={`/tickets`} className="btn btn-black">
            Ver mis tickets
          </Link>
          <button className="btn btn-procinal" onClick={onCloseModal}>
            Continuar
          </button>
        </div>
      </div>
    </div>
  );
}

export default ModalOldPayment;
