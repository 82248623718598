import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { userSessionStatus } from './store/actions/UserActions';
import { getCoupons } from './store/actions/CouponActions';
import { getSite, getSettings } from './store/actions/GlobalActions';
import { confectioneryGetApiProducts } from './store/actions/ConfectioneryActions';
import HomePage from './pages/HomePage';
import CityPage from './pages/CityPage';
import MoviePage from './pages/MoviePage';
import ScrollToTop from './components/ScrollToTop';
import CheckoutPage from './pages/CheckoutPage';
import ModalLoading from './components/ModalLoading';
import ResponsePage from './pages/ResponsePage';
import StatusPage from './pages/StatusPage';
import ProfilePage from './pages/ProfilePage';
import PoliciesPage from './pages/PoliciesPage';
import TicketsPage from './pages/TicketsPage';
import TicketPage from './pages/TicketPage';
import MyCouponsPage from './pages/MyCouponsPage';
import MyCouponPage from './pages/MyCouponPage';
import CouponPage from './pages/CouponPage';
import BillboardPage from './pages/BillboardPage';
import NextReleasesPage from './pages/NextReleasesPage';
import TheatersPage from './pages/TheatersPage';
import Error404Page from './pages/Error404Page';
import NextReleasePage from './pages/NextReleasePage';
import TheaterPage from './pages/TheaterPage';
import RegisterPage from './pages/RegisterPage';
import ImaxPage from './pages/ImaxPage';
import CorporativePage from './pages/CorporativePage';
import ContentPage from './pages/ContentPage';
import ContactPage from './pages/ContactPage';
import Intro from './components/Intro';
import CinemasPage from './pages/CinemasPage';
import PaymentMethods from './components/PaymentMethods';
import CinemasPayment from './components/CinemasPayment';
import GoogleAnalytics from './Utils/GoogleAnalytics';
import ConfectioneryPage from './pages/ConfectioneryPage';
import TicketResponsePage from './pages/TicketResponsePage';
import TicketStatusPage from './pages/TicketStatusPage';
import TicketCheckoutPage from './pages/TicketCheckoutPage';

class Navigation extends Component {
  componentDidMount() {
    const {
      userSessionStatus,
      confectioneryGetApiProducts,
      getSite,
      getSettings,
      confectioneryReducer: { confectionery_api_loading },
    } = this.props;

    if (confectionery_api_loading) {
      confectioneryGetApiProducts();
    }
    userSessionStatus();
    getSite();
    getSettings();
  }

  render() {
    const {
      globalReducer: { global_loading_cities },
    } = this.props;
    if (global_loading_cities) {
      return <Intro />;
    }
    return (
      <>
        <ModalLoading />
        <Router>
          {GoogleAnalytics.init() && <GoogleAnalytics.RouteTracker />}
          <ScrollToTop />
          <PaymentMethods />
          <CinemasPayment />
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/perfil" component={ProfilePage} />
            <Route exact path="/tickets" component={TicketsPage} />
            <Route exact path="/tickets/:ticket" component={TicketPage} />
            <Route exact path="/ciudad/:city" component={CityPage} />
            <Route
              exact
              path="/ciudad/:city/confiteria"
              component={ConfectioneryPage}
            />
            <Route
              exact
              path="/ciudad/:city/pelicula/:movie"
              component={MoviePage}
            />
            <Route exact path="/pelicula/:movie" component={NextReleasePage} />
            <Route
              exact
              path="/checkout/ciudad/:city/pelicula/:movie/id/:movie_id/showtime/:showtime"
              component={CheckoutPage}
            />
            <Route
              exact
              path="/ticket/checkout/ciudad/:city/pelicula/:movie/id/:movie_id/showtime/:showtime"
              component={TicketCheckoutPage}
            />
            <Route
              exact
              path="/payment/response/:type/:bill"
              component={ResponsePage}
            />
            <Route
              exact
              path="/ticket/response/:type/:bill"
              component={TicketResponsePage}
            />
            <Route exact path="/payment/status/:bill" component={StatusPage} />
            <Route
              exact
              path="/ticket/status/:bill"
              component={TicketStatusPage}
            />
            <Route
              exact
              path="/politica-de-proteccion-de-datos-personales"
              component={PoliciesPage}
            />
            <Route exact path="/mis-cupones" component={MyCouponsPage} />
            <Route exact path="/mis-cupones/:coupon" component={MyCouponPage} />
            <Route exact path="/cupon/:coupon" component={CouponPage} />
            <Route
              exact
              path="/proximos-estrenos"
              component={NextReleasesPage}
            />
            <Route
              exact
              path="/ciudad/:city/cartelera"
              component={BillboardPage}
            />
            <Route
              exact
              path="/ciudad/:city/teatros"
              component={TheatersPage}
            />
            <Route
              exact
              path="/ciudad/:city/teatros/:theater"
              component={TheaterPage}
            />
            <Route exact path="/teatros/:theater/que-es" component={ImaxPage} />
            <Route exact path="/registro" component={RegisterPage} />
            <Route exact path="/corporativo" component={CorporativePage} />
            <Route
              exact
              path="/corporativo/:corporative"
              component={CorporativePage}
            />
            <Route exact path="/contactanos" component={ContactPage} />
            <Route exact path="/cinemas" component={CinemasPage} />
            <Route exact path="/:slug" component={ContentPage} />
            <Route component={Error404Page} />
          </Switch>
        </Router>
      </>
    );
  }
}

const mapStateToProps = ({
  globalReducer,
  movieReducer,
  confectioneryReducer,
}) => {
  return {
    globalReducer,
    movieReducer,
    confectioneryReducer,
  };
};

const mapDispatchToProps = {
  getSite,
  userSessionStatus,
  getCoupons,
  getSettings,
  confectioneryGetApiProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
