import React, { useEffect, useState } from 'react';
import Recipe from './Recipe';
import './ModalProduct.css';
import Resume from '../Resume';
import {
  getConfectioneryDetail,
  getTotalsRecipe,
} from '../../../Utils/confectioneryManager';
import swal from 'sweetalert';
import { connect } from 'react-redux';
import { getSettingValue } from '../../../functions/currentFunctions';

function ModalProduct(props) {
  const [recipeProducts, setRecipeProducts] = useState([]);
  const [principalProduct, setPrincipalProduct] = useState(null);
  const {
    modal,
    product,
    onCloseModal,
    addProduct,
    confectioneryReducer: { confectionery_api_products },
    globalReducer: { global_settings },
  } = props;
  const numberFormat = new Intl.NumberFormat('es-CO');
  const confectioneryExplanation = getSettingValue(
    global_settings,
    'confiteria_explicacion',
    ''
  );

  useEffect(() => {
    if (modal === '') {
      setRecipeProducts([]);
    }
    if (modal === 'show') {
      let recipes = [];

      const principalProduct = getConfectioneryDetail(
        product.Codigo,
        confectionery_api_products
      );
      if (principalProduct) {
        setPrincipalProduct(principalProduct);
      }

      if (product.Tipo === 'P') {
        recipes.push(product);
      } else {
        product.Receta.forEach((recipe) => {
          if (recipe.Tipo === 'P') {
            recipes.push(recipe);
          } else {
            const product = recipe.Receta[0];
            const data = {
              Codigo: recipe.Codigo,
              Descripcion: recipe.Descripcion,
              Tipo: recipe.Tipo,
            };
            if (recipe.Cantidad) {
              const products = [];
              for (let i = 0; i < recipe.Cantidad; i++) {
                products.push(product);
              }
              data.Cantidad = recipe.Cantidad;
              data.Receta = products;
            } else {
              data.Receta = [product];
            }
            recipes.push(data);
          }
        });
      }

      setRecipeProducts(recipes);
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'scroll';
    }
    // eslint-disable-next-line
  }, [modal]);

  if (!principalProduct) {
    return null;
  }

  const onSelectRecipeProducts = (recipe) => {
    const recipes = recipeProducts.filter(
      (item) => item.Codigo !== recipe.Codigo
    );
    recipes.push(recipe);

    setRecipeProducts(recipes);
  };

  const onSelectProduct = () => {
    if (product.Receta && product.Receta.length !== recipeProducts.length) {
      swal('Upsss!!!', 'Debes seleccionar la receta del combo.', 'warning');
      return;
    }
    const recipe = {
      Codigo: product.Codigo,
      Descripcion: product.Descripcion,
      Tipo: product.Tipo,
      Cantidad: 1,
      Receta: recipeProducts,
    };

    addProduct(recipe);
  };

  const renderResume = (recipe) => {
    let text = '';

    recipe.forEach((item, index) => {
      const productApi = getConfectioneryDetail(
        item.Codigo,
        confectionery_api_products
      );
      if (productApi) {
        const productName =
          productApi.category_id === 3 ||
          productApi.category_id === 7 ||
          productApi.category_id === 6 ||
          productApi.category_id === 8 ||
          productApi.category_id === 9 ||
          productApi.category_id === 10 ||
          productApi.category_id === 11
            ? productApi.category
            : productApi.name;
        text = `${text}${index > 0 && index < recipe.length ? ' + ' : ''}${
          item.Cantidad
        } ${productName}`;
      }
    });

    return text;
  };

  const renderTotal = () => {
    const totals = getTotalsRecipe(recipeProducts);

    return (
      <div className="modalProduct_footer-totals">
        <h3>Valor del combo:</h3>
        <div className="modalProduct_footer-box-totals">
          <div className="modalProduct_footer-totals-item cinemas">
            Pagando con CINE+
            <strong>${numberFormat.format(totals.totalCinemas)}</strong>
          </div>
          <div className="modalProduct_footer-totals-item others">
            Otros medios de pago
            <strong>${numberFormat.format(totals.totalOthers)}</strong>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`modalProduct ${modal}`}>
      {product && (
        <div className="modalProduct_content">
          <div className="modalProduct_container">
            <div className="modalProduct_header">
              <div className="modalProduct_box-name">
                <img src={principalProduct.image} alt={product.Descripcion} />
                <div className="modalProduct_name">
                  <h2>{principalProduct.name}</h2>
                  {product.Receta && (
                    <div>
                      <h4>Incluye:</h4>
                      {renderResume(product.Receta)}
                    </div>
                  )}
                </div>
              </div>
              <button onClick={onCloseModal}>&times;</button>
            </div>
            <div className="modalProduct_body">
              <h4>Personaliza tu combo</h4>
              <div
                className="modalProduct_explanation"
                dangerouslySetInnerHTML={{ __html: confectioneryExplanation }}
              />
              {product.Receta ? (
                product.Receta.map((recipe) => (
                  <Recipe
                    recipe={recipe}
                    key={recipe.Codigo}
                    onSelectRecipeProducts={onSelectRecipeProducts}
                    recipeProducts={recipeProducts}
                  />
                ))
              ) : (
                <Recipe
                  recipe={product}
                  key={product.Codigo}
                  onSelectRecipeProducts={onSelectRecipeProducts}
                  recipeProducts={recipeProducts}
                />
              )}
            </div>
          </div>
          {recipeProducts.length ? (
            <div className="modalProduct_footer">
              <Resume recipe={recipeProducts} />
              {renderTotal()}
              <button
                className="modalProduct_footer-button"
                onClick={onSelectProduct}
              >
                AGREGAR AL CARRITO
              </button>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = ({ confectioneryReducer, globalReducer }) => {
  return {
    confectioneryReducer,
    globalReducer,
  };
};

export default connect(mapStateToProps, null)(ModalProduct);
