import React from 'react';
import { connect } from 'react-redux';

function Ticket(props) {
  const {
    checkoutReducer: {
      checkout_movie,
      checkout_showtime,
      checkout_room,
      checkout_chairs,
      checkout_location,
    },
    globalReducer: { global_cinemas },
    confectioneryReducer: { confectionery_theater_detail },
  } = props;

  if (!checkout_movie) {
    return null;
  }

  let cinema = null;
  let cinemaName = '';
  const room = checkout_movie.rooms.filter(
    (room) => parseInt(room.id) === parseInt(checkout_showtime.room_id)
  );
  if (room.length) {
    cinema = global_cinemas.find(
      (cinema) => parseInt(cinema.id) === parseInt(room[0].cinema_id)
    );
    if (cinema) {
      cinemaName = cinema.nombre_completo || cinema.nombre;
    } else if (confectionery_theater_detail) {
      cinemaName =
        confectionery_theater_detail.nombre_completo ||
        confectionery_theater_detail.nombre;
    }
  }

  const renderChairs = () => {
    if (!checkout_room) {
      return;
    }
    const {
      ColumnaRelativa,
      ColumnaTotal,
      FilaRelativa,
      FilaTotal,
    } = checkout_room;

    const chairs = checkout_chairs.map((item) => ({
      Fila: FilaTotal[item],
      Columna: ColumnaTotal[item],
      FilRelativa: FilaRelativa[item],
      ColRelativa: ColumnaRelativa[item],
      Tarifa: checkout_location.codigo,
    }));

    let text = '';

    chairs.forEach((element, index) => {
      text = `${text}${element.FilRelativa}${element.ColRelativa}${
        index < chairs.length - 1 ? ' - ' : ''
      }`;
    });

    return text;
  };

  return (
    <>
      <h2 className="combos_title">
        Teatro: <span className="color-red">{cinemaName}</span>
      </h2>
      <div className="combos_row">
        <img src={checkout_movie.imagen} alt={cinemaName} />
        <div className="combos_text">
          <h3>{checkout_movie.titulo}</h3>
          <div className="confectionery_resume-row">
            <strong>Fecha función:</strong> {checkout_showtime.fecha_cadena}
          </div>
          <div className="confectionery_resume-row">
            <strong>Hora función:</strong> {checkout_showtime.hora_cadena}
          </div>
          <div className="confectionery_resume-row">
            <strong>Sillas:</strong> {renderChairs()}
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({
  checkoutReducer,
  globalReducer,
  paymentReducer,
  confectioneryReducer,
}) => {
  return {
    checkoutReducer,
    globalReducer,
    paymentReducer,
    confectioneryReducer,
  };
};

export default connect(mapStateToProps, null)(Ticket);
