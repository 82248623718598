import React from 'react';
import { connect } from 'react-redux';
import {
  confectioneryGetProducts,
  confectioneryChangeTheater,
} from '../../store/actions/ConfectioneryActions';

function TheaterSelect(props) {
  const {
    confectioneryReducer: { confectionery_theater },
    confectioneryChangeTheater,
    confectioneryGetProducts,
    theaters,
  } = props;

  return (
    <div className="confectionery_select">
      <div className="confectionery_title">
        Selecciona el teatro, para ver la confitería
      </div>
      <select
        value={confectionery_theater}
        onChange={(input) => confectioneryChangeTheater(input.target.value)}
      >
        <option value="">-- Seleccionar --</option>
        {theaters &&
          theaters.map((theater) => (
            <option key={theater.id} value={theater.score_id}>
              {theater.nombre_completo || theater.nombre}
            </option>
          ))}
      </select>
      <button onClick={() => confectioneryGetProducts(confectionery_theater)}>
        VER CONFITERÍA
      </button>
    </div>
  );
}

const mapStateToProps = ({ confectioneryReducer }) => {
  return {
    confectioneryReducer,
  };
};

const mapDispatchToProps = {
  confectioneryChangeTheater,
  confectioneryGetProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(TheaterSelect);
