import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import loading from '../../assets/images/loading-red.svg';
import './Response.css';
import {
  getResponsePayment,
  changeRedirect,
} from '../../store/actions/PaymentActions';

class Response extends Component {
  componentDidMount() {
    const {
      match: {
        params: { type, bill },
      },
      getResponsePayment,
    } = this.props;

    getResponsePayment(type, bill);
  }

  componentWillUnmount() {
    this.props.changeRedirect(null);
  }

  render() {
    const {
      paymentReducer: { payment_redirect },
    } = this.props;

    if (payment_redirect) {
      return <Redirect to={payment_redirect} />;
    }

    return (
      <div className="Response">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Response_content">
                <img src={loading} alt="loading" />
                <h4>Espera un momento.</h4>
                <p>Estamos validando el estado de pago de tu transacción.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ paymentReducer }) => {
  return {
    paymentReducer,
  };
};

const mapDispatchToProps = {
  getResponsePayment,
  changeRedirect,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Response)
);
