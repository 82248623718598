import React from 'react';
import Navigation from './Navigation';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import reduxThunk from 'redux-thunk';
import reducers from './store/reducers';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/fonts.css';
import './assets/styles/icons.css';
import './assets/styles/main.css';

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(reduxThunk));

const enhancerSelected =
  process.env.NODE_ENV === 'production'
    ? applyMiddleware(reduxThunk)
    : enhancer;

const store = createStore(reducers, {}, enhancerSelected);

function App() {
  return (
    <Provider store={store}>
      <Navigation />
    </Provider>
  );
}

export default App;
