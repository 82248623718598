import React from 'react';
import { connect } from 'react-redux';
import { changeMoviesAnimation } from '../../../store/actions/MovieActions';
import './HomeMovieSlider.css';

function HomeMovieSlider(props) {
  const {
    movieReducer: {
      movies_active,
      movies_steps,
      movies_animation,
      movies_image_size,
    },
    changeMoviesAnimation,
  } = props;

  if (!movies_active.length) {
    return null;
  }

  const renderSlides = () => {
    const html = [];

    for (let i = 0; i < movies_steps; i++) {
      let html2 = movies_active.map((item, index) => {
        let position = movies_active.length * i + index;
        let imagePosition = movies_image_size * position;

        return (
          <div
            key={`${item.id}-${i}`}
            className="HomeMovieSlider_image"
            style={{ left: imagePosition }}
          >
            <img src={item.imagen} alt={item.titulo} />
          </div>
        );
      });

      html.push(html2);
    }

    return html;
  };

  const move = (items) => {
    items.forEach((element) => {
      let moveTo = parseInt(element.style.left);
      if (moveTo > -movies_image_size) {
        moveTo = moveTo - 1;
        element.style.left = `${moveTo}px`;
      } else {
        moveTo = movies_image_size * (movies_active.length * movies_steps - 1);
        element.style.left = `${moveTo}px`;
      }
    });
  };

  function initAnimation() {
    let items = document.querySelectorAll('.HomeMovieSlider_image');
    changeMoviesAnimation(false);
    if (items.length > 0) {
      setInterval(() => {
        move(items);
      }, 75);
    }
  }
  setTimeout(() => {
    if (movies_animation) {
      initAnimation();
    }
  }, 10);

  return (
    <div className="HomeMovieSlider_container" id="HomeMovieSlider_container">
      {renderSlides()}
    </div>
  );
}

const mapStateToProps = ({ movieReducer }) => {
  return {
    movieReducer,
  };
};

const mapDispatchToProps = {
  changeMoviesAnimation,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeMovieSlider);
