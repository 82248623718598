/* eslint-disable import/no-anonymous-default-export */
import {
  CHECKOUT_CHAIRS,
  CHECKOUT_DATE,
  CHECKOUT_HOUR,
  CHECKOUT_HOURS,
  CHECKOUT_ROOM,
  CHECKOUT_ROOM_LOADING,
  CHECKOUT_STEP,
  CHECKOUT_LOCATION,
  CHECKOUT_LOCATIONS,
  CHECKOUT_ROOM_STATUS,
  CHECKOUT_TOTAL_TIKETS,
  CHECKOUT_TOTAL_CONFECTIONERY,
  CHECKOUT_TOTAL,
  CHECKOUT_CLEAR,
  CHECKOUT_MOVIE_LOADING,
  CHECKOUT_MOVIE,
  CHECKOUT_SCORE_BILL,
  CHECKOUT_SHOWTIME,
  CHECKOUT_ERROR,
  CHECKOUT_TOTAL_TRANSACTION,
  CHECKOUT_POPUP,
  CHECKOUT_PAY_OPTION,
  CHECKOUT_OTHER_PRICE,
  CHECKOUT_FACE_MASK,
  CHECKOUT_RESERVATION,
} from '../constants/Checkout';

const INTIIAL_STATE = {
  checkout_rooms: [],
  checkout_room_loading: true,
  checkout_room: null,
  checkout_room_status: null,
  checkout_hours: [],
  checkout_date: null,
  checkout_hour: null,
  checkout_chairs: [],
  checkout_step: 1,
  checkout_locations: [],
  checkout_location: null,
  checkout_total_tikets: 0,
  checkout_total_confectionery: 0,
  checkout_total_transaction: 0,
  checkout_total: 0,
  checkout_other_price: 0,
  checkout_score_bill: null,
  checkout_movie: null,
  checkout_movie_loading: true,
  checkout_showtime: null,
  checkout_error: false,
  checkout_popup: false,
  checkout_pay_options: [
    {
      id: 'Habilitado',
      name: '<span class="color-red">CINE+</span>',
    },
    {
      id: 'Deshabilitado',
      name: 'Tarjeta de Crédito / PSE',
    },
  ],
  checkout_pay_option: 'Deshabilitado',
  checkout_face_mask: false,
  checkout_reservation: false,
};

export default (state = INTIIAL_STATE, action) => {
  switch (action.type) {
    case CHECKOUT_DATE:
      return {
        ...state,
        checkout_date: action.payload,
      };
    case CHECKOUT_HOUR:
      return {
        ...state,
        checkout_hour: action.payload,
      };
    case CHECKOUT_HOURS:
      return {
        ...state,
        checkout_hours: action.payload,
      };
    case CHECKOUT_CHAIRS:
      return {
        ...state,
        checkout_chairs: action.payload,
      };
    case CHECKOUT_ROOM:
      return {
        ...state,
        checkout_room: action.payload,
      };
    case CHECKOUT_ROOM_STATUS:
      return {
        ...state,
        checkout_room_status: action.payload,
      };
    case CHECKOUT_STEP:
      return {
        ...state,
        checkout_step: action.payload,
      };
    case CHECKOUT_ROOM_LOADING:
      return {
        ...state,
        checkout_room_loading: action.payload,
      };
    case CHECKOUT_LOCATIONS:
      return {
        ...state,
        checkout_locations: action.payload,
      };
    case CHECKOUT_LOCATION:
      return {
        ...state,
        checkout_location: action.payload,
      };
    case CHECKOUT_TOTAL_TIKETS:
      return {
        ...state,
        checkout_total_tikets: action.payload,
      };
    case CHECKOUT_TOTAL_CONFECTIONERY:
      return {
        ...state,
        checkout_total_confectionery: action.payload,
      };
    case CHECKOUT_TOTAL:
      return {
        ...state,
        checkout_total: action.payload,
      };
    case CHECKOUT_OTHER_PRICE:
      return {
        ...state,
        checkout_other_price: action.payload,
      };
    case CHECKOUT_MOVIE_LOADING:
      return {
        ...state,
        checkout_movie_loading: action.payload,
      };
    case CHECKOUT_MOVIE:
      return {
        ...state,
        checkout_movie: action.payload,
      };
    case CHECKOUT_SCORE_BILL:
      return {
        ...state,
        checkout_score_bill: action.payload,
      };
    case CHECKOUT_SHOWTIME:
      return {
        ...state,
        checkout_showtime: action.payload,
      };
    case CHECKOUT_ERROR:
      return {
        ...state,
        checkout_error: action.payload,
      };
    case CHECKOUT_TOTAL_TRANSACTION:
      return {
        ...state,
        checkout_total_transaction: action.payload,
      };
    case CHECKOUT_FACE_MASK:
      return {
        ...state,
        checkout_face_mask: action.payload,
      };
    case CHECKOUT_POPUP:
      return {
        ...state,
        checkout_popup: action.payload,
      };
    case CHECKOUT_PAY_OPTION:
      return {
        ...state,
        checkout_pay_option: action.payload,
      };
    case CHECKOUT_RESERVATION:
      return {
        ...state,
        checkout_reservation: action.payload,
      };
    case CHECKOUT_CLEAR:
      return {
        ...state,
        checkout_rooms: [],
        checkout_room_loading: true,
        checkout_room: null,
        checkout_room_status: null,
        checkout_hours: [],
        checkout_date: null,
        checkout_hour: null,
        checkout_chairs: [],
        checkout_step: 1,
        checkout_locations: [],
        checkout_location: null,
        checkout_total_tikets: 0,
        checkout_total_confectionery: 0,
        checkout_total_transaction: 0,
        checkout_total: 0,
        checkout_score_bill: null,
        checkout_movie: null,
        checkout_movie_loading: true,
        checkout_showtime: null,
        checkout_error: false,
        checkout_popup: true,
        checkout_reservation: false,
      };
    default:
      return {
        ...state,
      };
  }
};
