import React from 'react';
import { connect } from 'react-redux';
import Confectionery from './Confectionery';
import Movie from './Movie';
import './Tickets.css';

function Tickets(props) {
  const {
    userReducer: {
      user: { tickets },
    },
  } = props;

  return (
    <div className="row Tickets">
      <div className="col-md-12">
        <h4>MIS TICKETS</h4>
        <div className="row">
          {tickets.length ? (
            tickets.map((item, index) => {
              const complement = JSON.parse(item.complemento);
              if (item.payment_type_id === 1 && item.showtime_id) {
                return <Movie item={item} key={index} />;
              }
              if (
                item.payment_type_id === 5 ||
                (complement.Productos.length && !complement.Ubicaciones.length)
              ) {
                return <Confectionery item={item} key={index} />;
              }
              return null;
            })
          ) : (
            <div className="col-md-12">En este momento no tienes tickets.</div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ userReducer }) => {
  return {
    userReducer,
  };
};

export default connect(mapStateToProps)(Tickets);
