import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getMyCoupon,
  setCouponDetail,
  couponLegals,
} from '../../store/actions/CouponActions';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import PageLoader from '../PageLoader';
import './MyCoupon.css';
import CouponLegals from '../CouponLegals';

const { REACT_APP_QR_URL } = process.env;
const qrUrl = REACT_APP_QR_URL
  ? REACT_APP_QR_URL
  : 'https://api.qrserver.com/v1/create-qr-code/?size=300x300&data';

class MyCoupon extends Component {
  componentDidMount() {
    const {
      match: {
        params: { coupon },
      },
      getMyCoupon,
    } = this.props;

    getMyCoupon(coupon);
  }

  componentWillUnmount() {
    this.props.setCouponDetail(null);
  }

  render() {
    const {
      couponReducer: { coupon_loading, coupon_detail, coupon_my_list },
      couponLegals,
    } = this.props;

    if (!coupon_my_list.length) {
      return <Redirect to="/mis-cupones" />;
    }

    if (!coupon_detail) {
      return null;
    }

    return (
      <div className="row Tickets">
        <div className="col-md-12">
          <CouponLegals />
          {coupon_loading ? (
            <PageLoader />
          ) : !coupon_loading && coupon_detail ? (
            <div className="MyCoupon_detail">
              <div className="row">
                <div className="col-md-3">
                  <img
                    src={coupon_detail.image}
                    alt={coupon_detail.name}
                    className="MyCoupon_image"
                  />
                  <p>
                    <b>Válido desde:</b>
                    <br />
                    {coupon_detail.valid_from}
                  </p>
                  <p>
                    <b>Válido hasta:</b>
                    <br />
                    {coupon_detail.expires_on}
                  </p>
                </div>
                <div className="col-md-9">
                  <div className="MyCoupon_content">
                    <h4>CUPÓN: {coupon_detail.name}</h4>
                    <div className="MyCoupon_codes">
                      {coupon_detail.ticket && (
                        <div className="MyCoupon_code">
                          <h5>CÓDIGO ENTRADA</h5>
                          <img
                            src={`${qrUrl}${coupon_detail.ticket}`}
                            alt="Code"
                            className="MyCoupon_code-image"
                          />
                          <div className="text-center">
                            {coupon_detail.ticket}
                          </div>
                        </div>
                      )}
                      {coupon_detail.confectionery && (
                        <div className="MyCoupon_code">
                          <h5>CÓDIGO CONFITERÍA</h5>
                          <img
                            src={`${qrUrl}${coupon_detail.confectionery}`}
                            alt="Code"
                            className="MyCoupon_code-image"
                          />
                          <div className="text-center">
                            {coupon_detail.confectionery}
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: coupon_detail.description,
                      }}
                    />
                    {coupon_detail.legals && (
                      <button
                        type="button"
                        onClick={() => couponLegals('visible')}
                        className="MyCoupon_legals"
                      >
                        CONDICIONES Y RESTRICCIONES
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              Lo sentimos, no hemos encontrado el cupón, intentalo más tarde.
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ couponReducer }) => {
  return {
    couponReducer,
  };
};

const mapDispatchToProps = {
  getMyCoupon,
  setCouponDetail,
  couponLegals,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyCoupon)
);
