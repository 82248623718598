import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getBanners } from '../../../store/actions/GlobalActions';
import Slider from 'react-slick';
import './SliderPublish.css';

function NextArrow(props) {
  const { onClick } = props;
  return (
    <button
      onClick={onClick}
      className="SliderPublish_button SliderPublish_next"
    >
      <i className="fas fa-arrow-right" />
    </button>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <button
      onClick={onClick}
      className="SliderPublish_button SliderPublish_prev"
    >
      <i className="fas fa-arrow-left" />
    </button>
  );
}

class SliderPublish extends Component {
  componentDidMount() {
    const {
      globalReducer: { global_banners },
      getBanners,
    } = this.props;

    if (!global_banners.length) {
      getBanners();
    }
  }

  render() {
    const {
      globalReducer: { global_banners },
    } = this.props;
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 10000,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };

    if (!global_banners.length) {
      return null;
    }

    return (
      <div className="SliderPublish">
        <div className="SliderPublish-slider">
          <Slider {...settings}>
            {global_banners.map((slide) => {
              if (slide.url) {
                if (slide.target === '_blank') {
                  return (
                    <a href={slide.url} target={slide.target} key={slide.id}>
                      <img src={slide.image} alt={slide.name} />
                    </a>
                  );
                }
                let currentUrl = slide.url.split('https://procinal.com.co');
                currentUrl = currentUrl.length > 1 ? currentUrl[1] : slide.url;
                return (
                  <Link to={currentUrl} key={slide.id}>
                    <img src={slide.image} alt={slide.name} />
                  </Link>
                );
              }
              return <img src={slide.image} alt={slide.name} key={slide.id} />;
            })}
          </Slider>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ globalReducer }) => {
  return {
    globalReducer,
  };
};

const mapDispatchToProps = {
  getBanners,
};

export default connect(mapStateToProps, mapDispatchToProps)(SliderPublish);
