import {
  SUBSCRIPTION_CITY,
  SUBSCRIPTION_PRICE_RECHARGE,
  SUBSCRIPTION_RECHARGE,
  SUBSCRIPTION_TERMS,
  SUBSCRIPTION_THEATER,
  SUBSCRIPTION_THEATERS,
  SUBSCRIPTION_TOTAL,
} from '../constants/Subscription';
const {
  REACT_APP_CHARGE,
  REACT_APP_PRICE_SUBSCRIPTION,
  REACT_APP_MIN_RECHARGE,
} = process.env;

export const subscriptionChangeTotal = (price) => (dispatch, getState) => {
  const { global_cine_mas } = getState().globalReducer;
  let total = parseInt(price);
  let chargeService = global_cine_mas
    ? global_cine_mas.charge_service
    : parseInt(REACT_APP_CHARGE);
  price = total + chargeService;

  dispatch({
    type: SUBSCRIPTION_TOTAL,
    payload: price,
  });
};

export const subscriptionChangeTheater = (payload) => (dispatch) => {
  dispatch({
    type: SUBSCRIPTION_THEATER,
    payload,
  });
};

export const subscriptionChangeCity = (city_id) => (dispatch, getState) => {
  const { global_cinema_list } = getState().globalReducer;
  const theaters = global_cinema_list.filter(
    (theater) => parseInt(theater.city_id) === parseInt(city_id)
  );

  dispatch(subscriptionChangeTheater(''));
  dispatch({
    type: SUBSCRIPTION_THEATERS,
    payload: theaters,
  });
  dispatch({
    type: SUBSCRIPTION_CITY,
    payload: city_id,
  });
};

export const subscriptionChangeRecharge = () => (dispatch, getState) => {
  const { subscription_recharge } = getState().subscriptionReducer;

  if (!!subscription_recharge) {
    dispatch(subscriptionChangePriceRecharge(0));
  } else {
    dispatch(
      subscriptionChangePriceRecharge(parseInt(REACT_APP_MIN_RECHARGE) + 10000)
    );
  }

  dispatch({
    type: SUBSCRIPTION_RECHARGE,
    payload: !subscription_recharge,
  });
};

export const subscriptionChangeTerms = () => (dispatch, getState) => {
  const { subscription_terms } = getState().subscriptionReducer;

  dispatch({
    type: SUBSCRIPTION_TERMS,
    payload: !subscription_terms,
  });
};

export const subscriptionChangePriceRecharge = (price) => (
  dispatch,
  getState
) => {
  const { payment_type } = getState().paymentReducer;

  dispatch({
    type: SUBSCRIPTION_PRICE_RECHARGE,
    payload: price,
  });

  if (payment_type === 3) {
    price = parseInt(price) + parseInt(REACT_APP_PRICE_SUBSCRIPTION);
  }

  dispatch(subscriptionChangeTotal(price));
};
