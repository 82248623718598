import React from 'react';
import './MovieListTitle.css';

function MovieListTitle(props) {
  const { size } = props;

  return (
    <div className={`MovieListTitle ${size}`}>
      <h2>Escoge tu película y función &</h2>
      <h2>Vive una experiencia Procinal</h2>
      <p>Escoge Fecha y hora de la función y comprar</p>
    </div>
  );
}

MovieListTitle.defaultProps = {
  size: '',
};

export default MovieListTitle;
