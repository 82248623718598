import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getCinemas } from '../../../store/actions/GlobalActions';
import {
  userGetScoreBalance,
  userGetScoreData,
} from '../../../store/actions/UserActions';
import { checkoutCacheReservation } from '../../../store/actions/CheckoutActions';
import { confectioneryClearCache } from '../../../store/actions/ConfectioneryActions';
import Cart from '.';
const { REACT_APP_LOCAL_STORAGE: LOCAL_STORAGE } = process.env;

class GeneralCart extends Component {
  componentDidMount() {
    const {
      userReducer: { user_score_balance, user_score, user },
      globalReducer: { global_all_cinemas },
      match: {
        params: { city },
      },
      location: { pathname },
      userGetScoreBalance,
      userGetScoreData,
      getCinemas,
      confectioneryClearCache,
      checkoutCacheReservation,
    } = this.props;
    let isMovie = pathname && pathname.indexOf('pelicula') >= 0 ? true : false;

    const confectioneryData = localStorage.getItem(
      `${LOCAL_STORAGE}::ticketConfectionery`
    );
    if (confectioneryData) {
      const data = JSON.parse(confectioneryData);
      const currentDate = new Date().getTime();

      if (
        currentDate < data.date &&
        !isMovie &&
        data.combos &&
        data.combos.length
      ) {
        checkoutCacheReservation(data);
      } else if (currentDate > data.date) {
        localStorage.removeItem(`${LOCAL_STORAGE}::ticketConfectionery`);
        confectioneryClearCache();
      }
    }
    if (user && !user_score) {
      userGetScoreData();
    }
    if (user && !user_score_balance) {
      userGetScoreBalance();
    }
    if (city && !global_all_cinemas.length) {
      getCinemas(city);
    }
  }

  render() {
    return <Cart showPaymentType={false} showTickets={true} />;
  }
}

const mapStateToProps = ({ userReducer, globalReducer }) => {
  return {
    userReducer,
    globalReducer,
  };
};

const mapDispatchToProps = {
  userGetScoreBalance,
  userGetScoreData,
  getCinemas,
  confectioneryClearCache,
  checkoutCacheReservation,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GeneralCart)
);
