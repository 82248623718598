import React from 'react';
import { connect } from 'react-redux';
import { couponLegals } from '../../store/actions/CouponActions';
import CouponLegals from '../CouponLegals';
import './CouponDetail.css';
import CouponPayment from './CouponPayment';

function CouponDetail(props) {
  const {
    couponReducer: { coupon_error, coupon_detail },
    couponLegals,
  } = props;
  const numberFormat = new Intl.NumberFormat('en-CO');
  if (!coupon_detail) {
    return null;
  }

  return (
    <div className="site-container">
      <CouponLegals />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="MovieDetails">
              {coupon_error ? (
                <div className="row">
                  <div className="col-md-12">
                    <h1>{coupon_error}</h1>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-md-3">
                    <img src={coupon_detail.image} alt={coupon_detail.name} />
                    <p>
                      <b>Válido desde:</b>
                      <br />
                      {coupon_detail.valid_from}
                    </p>
                    <p>
                      <b>Válido hasta:</b>
                      <br />
                      {coupon_detail.expires_on}
                    </p>
                  </div>
                  <div className="col-md-9">
                    <div className="MovieDetails-caja-titulo">
                      <h2>{coupon_detail.name.toUpperCase()}</h2>
                      <h4>
                        VALOR UNITARIO: $
                        {numberFormat
                          .format(coupon_detail.price)
                          .replace(',', '.')}
                      </h4>
                    </div>
                    <div className="MovieDetails-sinopsis">
                      <h6>Descripción:</h6>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: coupon_detail.description,
                        }}
                      />
                    </div>
                    {coupon_detail.legals && (
                      <button
                        className="CouponDetail_button-conditions"
                        onClick={() => couponLegals('visible')}
                      >
                        CONDICIONES Y RESTRICCIONES
                      </button>
                    )}
                    {coupon_detail.codes_active_count ? (
                      <CouponPayment />
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ couponReducer }) => {
  return {
    couponReducer,
  };
};

const mapDispatchToProps = {
  couponLegals,
};

export default connect(mapStateToProps, mapDispatchToProps)(CouponDetail);
