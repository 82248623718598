import React from 'react';
import Error404 from '../../components/Error404';
import DefaultLayout from '../../Layouts/DefaultLayout';
import './Error404Page.css';

function Error404Page() {
  return (
    <DefaultLayout>
      <Error404 />
    </DefaultLayout>
  );
}

export default Error404Page;
