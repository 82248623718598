import React, { Component } from 'react';
import { connect } from 'react-redux';
import PageLoader from '../PageLoader';
import { getMyCouponList } from '../../store/actions/CouponActions';
import { Link } from 'react-router-dom';

class MyCoupons extends Component {
  componentDidMount() {
    this.props.getMyCouponList();
  }

  render() {
    const {
      couponReducer: { coupon_loading, coupon_my_list },
    } = this.props;

    return (
      <div className="row Tickets">
        <div className="col-md-12">
          <h4>MIS CUPONES</h4>
          {coupon_loading ? (
            <PageLoader />
          ) : (
            <>
              {!coupon_my_list.length ? (
                <p>En este momento no tienes cupones.</p>
              ) : (
                <div className="row">
                  {coupon_my_list.map((coupon, index) => {
                    const {
                      codigo_respuesta,
                      valid_from,
                      expires_on,
                      name,
                      id_coupon_code,
                      image,
                    } = coupon;

                    return (
                      <div key={index} className="col-md-3 Tickets_item">
                        <img src={image} alt={name} />
                        <div className="Tickets_item-content">
                          <h3>{name}</h3>
                          <div>
                            <b>Válido desde: </b>
                            {valid_from}
                          </div>
                          <div>
                            <b>Válido hasta: </b>
                            {expires_on}
                          </div>
                          {parseInt(codigo_respuesta) === 1 && (
                            <Link
                              to={`/mis-cupones/${id_coupon_code}`}
                              className="Tickets_btn code"
                            >
                              Ver Código
                            </Link>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ couponReducer }) => {
  return {
    couponReducer,
  };
};

const mapDispatchToProps = {
  getMyCouponList,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyCoupons);
