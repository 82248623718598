import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getSettingValue } from '../../../functions/currentFunctions';
import { getTotalCombos } from '../../../Utils/confectioneryManager';
import { confectioneryRemoveCombo } from '../../../store/actions/ConfectioneryActions';
import { changeVisible } from '../../../store/actions/PaymentActions';
import { cinemasGoToPay } from '../../../store/actions/CinemasActions';
import PayMethods from '../../Checkout/PayMethods';
import Combos from '../Combos';
import './Cart.css';
import epayco from '../../../assets/images/epayco-white.png';
import Ticket from '../Ticket';
import CountDown from '../../CountDown';

function Cart(props) {
  const [modal, setModal] = useState('show');
  const {
    confectioneryReducer: { confectionery_combos, confectionery_date },
    checkoutReducer: {
      checkout_pay_option,
      checkout_total,
      checkout_total_tikets,
      checkout_total_transaction,
      checkout_other_price,
    },
    globalReducer: { global_settings },
    showPaymentType,
    changeVisible,
    cinemasGoToPay,
    showTickets,
  } = props;
  const numberFormat = new Intl.NumberFormat('es-CO');

  if (!confectionery_combos || !confectionery_combos.length) return null;

  const onPay = () => {
    if (checkout_pay_option === 'Deshabilitado') {
      changeVisible('visible', showTickets ? 1 : 5);
    } else {
      cinemasGoToPay();
    }
  };

  const renderTotal = () => {
    const totals = getTotalCombos(confectionery_combos);
    let recargo = parseInt(
      getSettingValue(global_settings, 'confiteria_price_service', 500)
    );

    let total =
      checkout_pay_option === 'Deshabilitado'
        ? totals.totalOthers
        : totals.totalCinemas;
    let saving = totals.saving;
    let totalTransaction = total + recargo;

    if (showTickets) {
      recargo = recargo + checkout_total_transaction;
      saving = saving + checkout_other_price;
      totalTransaction = totalTransaction + checkout_total;
    }

    return (
      <>
        <div>
          {showTickets && (
            <div className="cart_resume-total-row">
              <div className="cart_resume-total-title">VALOR ENTRADAS:</div>
              <div className="cart_resume-total-price">
                ${numberFormat.format(checkout_total_tikets)}
              </div>
            </div>
          )}
          <div className="cart_resume-total-row">
            <div className="cart_resume-total-title">VALOR CONFITERÍA:</div>
            <div className="cart_resume-total-price">
              ${numberFormat.format(total)}
            </div>
          </div>
          <div className="cart_resume-total-row">
            <div className="cart_resume-total-title">CARGO POR SERVICIO:</div>
            <div className="cart_resume-total-price">
              ${numberFormat.format(recargo)}
            </div>
          </div>
        </div>
        <div>
          <div className="cart_resume-total-row cart_resume-total">
            <div className="cart_resume-total-title">TOTAL:</div>
            <div className="cart_resume-total-price">
              ${numberFormat.format(totalTransaction)}
            </div>
          </div>
        </div>
        <div>
          <div className="cart_resume-total-row">
            <div className="cart_resume-total-title">
              CON <strong className="color-red">CINE+</strong>{' '}
              {checkout_pay_option === 'Deshabilitado'
                ? 'HABRÍAS AHORRADO'
                : 'AHORRASTE'}
            </div>
            <div className="cart_resume-total-price">
              ${numberFormat.format(saving)}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={`cart_resume ${modal}`}>
        <div className="cart_resume-container">
          <div className="cart_resume-content">
            <div className="cart_resume-header">
              <h2>Detalle de la compra</h2>
              <button
                className="cart_resume-close"
                onClick={() => setModal('')}
              >
                <i className="fas fa-times" />
              </button>
            </div>
            {confectionery_date && (
              <CountDown
                onEnd={() => console.log('Holassssss')}
                endDate={confectionery_date}
              />
            )}
            <div className="cart_resume-body">
              {showTickets && <Ticket />}
              <Combos />
            </div>
          </div>
          <div className="cart_resume-footer">
            {showPaymentType && <PayMethods />}
            {renderTotal()}
            <button className="cart_resume-pay" onClick={onPay}>
              PAGAR CON{' '}
              {checkout_pay_option === 'Deshabilitado' ? (
                <img src={epayco} alt="Logo ePayco" />
              ) : (
                <strong>CINE+</strong>
              )}
            </button>
          </div>
        </div>
      </div>
      <button className="cart_button" onClick={() => setModal('show')}>
        <i className="fas fa-shopping-cart" />
        <div className="cart_button-circle">{confectionery_combos.length}</div>
      </button>
    </>
  );
}

const mapStateToProps = ({
  confectioneryReducer,
  checkoutReducer,
  globalReducer,
}) => {
  return {
    confectioneryReducer,
    checkoutReducer,
    globalReducer,
  };
};

const mapDispatchToProps = {
  confectioneryRemoveCombo,
  changeVisible,
  cinemasGoToPay,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
