import React from 'react';
import Imax from '../../components/Imax';
import DefaultLayout from '../../Layouts/DefaultLayout';

function ImaxPage() {
  return (
    <DefaultLayout>
      <Imax />
    </DefaultLayout>
  );
}

export default ImaxPage;
