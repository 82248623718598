import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getConfectioneryDetail } from '../../Utils/confectioneryManager';

function Resume(props) {
  const {
    recipe,
    showTitle,
    confectioneryReducer: { confectionery_api_products },
  } = props;
  const [resume, setResume] = useState([]);

  useEffect(() => {
    const html = [];
    recipe.forEach((item) => {
      const productApi = getConfectioneryDetail(
        item.Codigo,
        confectionery_api_products
      );
      if (!productApi) return null;
      if (item.Tipo === 'P') {
        html.push(
          <div key={item.Codigo} className="confectionery_resume-row">
            <strong>
              {productApi.category.toUpperCase()}
              {item.Cantidad > 1 ? 'S' : ''}:{' '}
            </strong>
            {productApi.name}
          </div>
        );
      } else {
        html.push(
          <div className="confectionery_resume-row" key={item.Codigo}>
            <strong>
              {productApi.category.toUpperCase()}
              {item.Cantidad > 1 ? 'S' : ''}:{' '}
            </strong>
            {item.Receta.map((item, index) => {
              const productApi = getConfectioneryDetail(
                item.Codigo,
                confectionery_api_products
              );
              if (!productApi) return '';
              return `${index > 0 ? '; ' : ''}${productApi.name}`;
            })}
          </div>
        );
      }
    });
    setResume(html);
  }, [recipe]);

  return (
    <div className="confectionery_resume">
      {showTitle && <h3>Resumen:</h3>}
      {resume}
    </div>
  );
}

Resume.defaultProps = {
  showTitle: true,
};

const mapStateToProps = ({ confectioneryReducer }) => {
  return {
    confectioneryReducer,
  };
};

export default connect(mapStateToProps, null)(Resume);
