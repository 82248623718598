export const USER_DATA = 'USER_DATA';
export const USER_EMAIL = 'USER_EMAIL';
export const USER_PASSWORD = 'USER_PASSWORD';
export const USER_NAME = 'USER_NAME';
export const USER_LASTNAME = 'USER_LASTNAME';
export const USER_GENDER = 'USER_GENDER';
export const USER_AGE = 'USER_AGE';
export const USER_DNITYPE = 'USER_DNITYPE';
export const USER_DNI = 'USER_DNI';
export const USER_CELLPHONE = 'USER_CELLPHONE';
export const USER_EMAIL_REGISTER = 'USER_EMAIL_REGISTER';
export const USER_DATEBORN = 'USER_DATEBORN';
export const USER_PASSWORD_REGISTER = 'USER_PASSWORD_REGISTER';
export const USER_PASSWORD_CONFIRM = 'USER_PASSWORD_CONFIRM';
export const USER_MODAL_LOGIN = 'USER_MODAL_LOGIN';
export const USER_MODAL_REGISTER = 'USER_MODAL_REGISTER';
export const USER_CLEAR = 'USER_CLEAR';
export const USER_FORM_LOGIN = 'USER_FORM_LOGIN';
export const USER_POLICIES = 'USER_POLICIES';
export const USER_TERMS = 'USER_TERMS';
export const USER_EDIT_PROFILE = 'USER_EDIT_PROFILE';
export const USER_SCORE_LOADING = 'USER_SCORE_LOADING';
export const USER_SCORE = 'USER_SCORE';
export const USER_SCORE_GET = 'USER_SCORE_GET';
export const USER_SCORE_BALANCE = 'USER_SCORE_BALANCE';
export const USER_SCORE_BALANCE_GET = 'USER_SCORE_BALANCE_GET';
export const USER_SCORE_LOADING_BALANCE = 'USER_SCORE_LOADING_BALANCE';
export const USER_EMAIL_REPEAT = 'USER_EMAIL_REPEAT';
export const USER_REPEAT_DNI = 'USER_REPEAT_DNI';
