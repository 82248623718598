import {
  CORPORATIVE_LOADING,
  CORPORATIVE_LIST,
  CORPORATIVE_LOADING_DETAIL,
  CORPORATIVE_DETAIL,
  CORPORATIVE_BUSINES,
  CORPORATIVE_NIT,
  CORPORATIVE_CONTACT_NAME,
  CORPORATIVE_CONTACT_EMAIL,
  CORPORATIVE_CONTACT_PHONE,
  CORPORATIVE_COMPLEMENT,
  CORPORATIVE_ATTENDEES,
  CORPORATIVE_COMMENTS,
  CORPORATIVE_FORM_LOADING,
  CORPORATIVE_SECTION,
  CORPORATIVE_CLEAR_FORM,
  CORPORATIVE_THEATERS,
} from '../constants/Corporative';

const INITIAL_STATE = {
  corporative_loading: true,
  corporative_list: [],
  corporative_theaters: [],
  corporative_loading_detail: true,
  corporative_detail: null,
  corporative_busines: '',
  corporative_nit: '',
  corporative_contact_name: '',
  corporative_contact_email: '',
  corporative_contact_phone: '',
  corporative_complement: '',
  corporative_attendees: '',
  corporative_comments: '',
  corporative_form_loading: false,
  corporative_section: '',
};

const corportativeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CORPORATIVE_LOADING:
      return {
        ...state,
        corporative_loading: action.payload,
      };
    case CORPORATIVE_LIST:
      return {
        ...state,
        corporative_list: action.payload,
      };
    case CORPORATIVE_THEATERS:
      return {
        ...state,
        corporative_theaters: action.payload,
      };
    case CORPORATIVE_LOADING_DETAIL:
      return {
        ...state,
        corporative_loading_detail: action.payload,
      };
    case CORPORATIVE_DETAIL:
      return {
        ...state,
        corporative_detail: action.payload,
      };
    case CORPORATIVE_BUSINES:
      return {
        ...state,
        corporative_busines: action.payload,
      };
    case CORPORATIVE_NIT:
      return {
        ...state,
        corporative_nit: action.payload,
      };
    case CORPORATIVE_CONTACT_NAME:
      return {
        ...state,
        corporative_contact_name: action.payload,
      };
    case CORPORATIVE_CONTACT_EMAIL:
      return {
        ...state,
        corporative_contact_email: action.payload,
      };
    case CORPORATIVE_CONTACT_PHONE:
      return {
        ...state,
        corporative_contact_phone: action.payload,
      };
    case CORPORATIVE_COMPLEMENT:
      return {
        ...state,
        corporative_complement: action.payload,
      };
    case CORPORATIVE_ATTENDEES:
      return {
        ...state,
        corporative_attendees: action.payload,
      };
    case CORPORATIVE_COMMENTS:
      return {
        ...state,
        corporative_comments: action.payload,
      };
    case CORPORATIVE_FORM_LOADING:
      return {
        ...state,
        corporative_form_loading: action.payload,
      };
    case CORPORATIVE_SECTION:
      return {
        ...state,
        corporative_section: action.payload,
      };
    case CORPORATIVE_CLEAR_FORM:
      return {
        ...state,
        corporative_busines: '',
        corporative_nit: '',
        corporative_contact_name: '',
        corporative_contact_email: '',
        corporative_contact_phone: '',
        corporative_complement: '',
        corporative_attendees: '',
        corporative_comments: '',
      };
    default:
      return {
        ...state,
      };
  }
};

export default corportativeReducer;
