const { REACT_APP_API_ROUTE } = process.env;

class Api {
  callMethod(path, method, data = {}, callback, error) {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    };

    const cookies = document.cookie.split(';');
    let token = cookies.filter((item) => item.indexOf('procinal_token=') >= 0);
    if (token.length) {
      token = token[0].split('=');
      headers.Authorization = `Bearer ${token[1]}`;
    }

    let params = {
      mode: 'cors',
      method,
      headers,
    };

    if (method.toLowerCase() !== 'get') {
      params.body = JSON.stringify(data);
    }

    const url = REACT_APP_API_ROUTE + path;

    fetch(url, params)
      .then((res) => {
        res.json().then((response) => {
          callback(response, res.status);
        });
      })
      .catch((err) => {
        if (typeof error === 'function') {
          error(err);
        } else {
          console.log('Error API: ', err);
        }
      });
  }
}

export default new Api();
