import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userGetScoreBalance } from '../../store/actions/UserActions';
import {
  confectioneryChangeCombos,
  confectioneryChangeTheater,
  confectioneryGetProducts,
  confectioneryClearCombos,
} from '../../store/actions/ConfectioneryActions';
import Confectionery from '../../components/Confectionery';
import DefaultLayout from '../../Layouts/DefaultLayout';
import { getCinemas } from '../../store/actions/GlobalActions';
const { REACT_APP_LOCAL_STORAGE: LOCAL_STORAGE } = process.env;

class ConfectioneryPage extends Component {
  componentDidMount() {
    const {
      match: {
        params: { city },
      },
      userReducer: { user },
      globalReducer: { global_all_cinemas },
      getCinemas,
      userGetScoreBalance,
      confectioneryChangeTheater,
      confectioneryChangeCombos,
      confectioneryGetProducts,
    } = this.props;

    if (city && !global_all_cinemas.length) {
      getCinemas(city);
    }

    if (user) {
      userGetScoreBalance();
    }

    const confectioneryStorage = localStorage.getItem(
      `${LOCAL_STORAGE}:confectionery`
    );
    if (confectioneryStorage) {
      const data = JSON.parse(confectioneryStorage);
      confectioneryChangeTheater(data.theater);
      confectioneryChangeCombos(data.combos);
      confectioneryGetProducts(data.theater);
    }
  }

  componentWillUnmount() {
    this.props.confectioneryClearCombos();
  }

  render() {
    const {
      globalReducer: { global_cinemas },
    } = this.props;

    return (
      <DefaultLayout>
        <div className="vertical-gradient">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="Confectionery_header">Confitería</div>
                <Confectionery
                  theaters={global_cinemas}
                  showTheaterSelect={true}
                />
              </div>
            </div>
          </div>
        </div>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ globalReducer, userReducer }) => {
  return {
    globalReducer,
    userReducer,
  };
};

const componentDidMount = {
  getCinemas,
  userGetScoreBalance,
  confectioneryChangeCombos,
  confectioneryChangeTheater,
  confectioneryGetProducts,
  confectioneryClearCombos,
};

export default connect(mapStateToProps, componentDidMount)(ConfectioneryPage);
