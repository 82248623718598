/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { connect } from 'react-redux';
import './Register.css';
import {
  changeName,
  changeLastName,
  changeGender,
  changeDniType,
  changeDni,
  changeCellPhone,
  changeEmailRegister,
  changeDateBorn,
  changePasswordRegister,
  changePasswordConfirm,
  registerValidation,
  changeModalLogin,
  changeModalRegister,
  userChangePolicies,
  userChangeTerms,
  changeEmailRepeat,
  changeRepeatDni,
} from '../../store/actions/UserActions';
import { createPortal } from 'react-dom';

function Register(props) {
  const {
    userReducer: {
      user_name,
      user_lastName,
      user_gender,
      user_dniType,
      user_dni,
      user_repeat_dni,
      user_cellphone,
      user_email_register,
      user_dateBorn,
      user_password_register,
      user_password_confirm,
      user_modal_register,
      user_policies,
      user_terms,
      user_email_repeat,
    },
    changeName,
    changeLastName,
    changeGender,
    changeDniType,
    changeDni,
    changeCellPhone,
    changeEmailRegister,
    changeDateBorn,
    changePasswordRegister,
    changePasswordConfirm,
    registerValidation,
    changeModalLogin,
    changeModalRegister,
    userChangePolicies,
    userChangeTerms,
    changeEmailRepeat,
    changeRepeatDni,
  } = props;

  return createPortal(
    <div className={`Register ${user_modal_register}`}>
      <div className="Register-container">
        <button onClick={() => changeModalRegister('')}>
          <i className="fas fa-times" />
        </button>
        <div className="row">
          <label className="col-md-6" htmlFor="name">
            <span>* NOMBRE:</span>
            <input
              className="form-control"
              type="text"
              name="name"
              value={user_name}
              onChange={(input) => changeName(input.target.value)}
            />
          </label>
          <label className="col-md-6" htmlFor="lastName">
            <span>* APELLIDO:</span>
            <input
              className="form-control"
              type="text"
              name="lastName"
              value={user_lastName}
              onChange={(input) => changeLastName(input.target.value)}
            />
          </label>
          <label className="col-md-6" htmlFor="dniType">
            <span>* TIPO DE DOCUMENTO:</span>
            <select
              className="form-control"
              name="dniType"
              value={user_dniType}
              onChange={(input) => changeDniType(input.target.value)}
            >
              <option value="">--SELECCIONAR-</option>
              <option value="CC">Cédula de Ciudadanía</option>
              <option value="CE">Cédula de Extranjería</option>
              <option value="PPN">Pasaporte</option>
              <option value="NIT">
                Número de Identificación Tibutaria (NIT)
              </option>
            </select>
          </label>
          <label htmlFor="" className="col-md-6">
            <span>* NÚMERO DE DOCUMENTO:</span>
            <input
              className="form-control"
              placeholder="Número"
              type="number"
              min="0"
              name="dni"
              value={user_dni}
              onChange={(input) => changeDni(input.target.value)}
            />
          </label>
          <label htmlFor="" className="col-md-6">
            <span>* REPETIR NÚMERO DE DOCUMENTO:</span>
            <input
              className="form-control"
              placeholder="Número"
              type="number"
              min="0"
              name="dni"
              value={user_repeat_dni}
              onChange={(input) => changeRepeatDni(input.target.value)}
            />
          </label>
          <label className="col-md-6" htmlFor="cellPhone">
            <span>* NO. DE CELULAR:</span>
            <input
              className="form-control"
              type="number"
              min="0"
              name="cellPhone"
              value={user_cellphone}
              onChange={(input) => changeCellPhone(input.target.value)}
            />
          </label>
          <label className="col-md-6" htmlFor="email">
            <span>* CORREO ELECTRÓNICO:</span>
            <input
              className="form-control"
              type="email"
              name="email"
              value={user_email_register}
              onChange={(input) => changeEmailRegister(input.target.value)}
            />
          </label>
          <label className="col-md-6" htmlFor="email">
            <span>* REPETIR CORREO ELECTRÓNICO:</span>
            <input
              className="form-control"
              type="email"
              name="email_repetat"
              value={user_email_repeat}
              onChange={(input) => changeEmailRepeat(input.target.value)}
            />
          </label>
          <label className="col-md-6" htmlFor="password">
            <span>* CONTRASEÑA: </span>
            <input
              className="form-control"
              type="password"
              name="password"
              value={user_password_register}
              onChange={(input) => changePasswordRegister(input.target.value)}
            />
            <span className="small">
              Debe tener mínimo 8 y máximo 12 caracteres, debe ser alfanumérica
              sin caracteres especiales.
            </span>
          </label>
          <label className="col-md-6" htmlFor="passwordConfirm">
            <span>* REPETIR CONTRASEÑA:</span>
            <input
              className="form-control"
              type="password"
              name="passwordConfirm"
              value={user_password_confirm}
              onChange={(input) => changePasswordConfirm(input.target.value)}
            />
          </label>
          <label className="col-md-6" htmlFor="gender">
            <span>* GÉNERO:</span>
            <select
              className="form-control"
              name="gender"
              value={user_gender}
              onChange={(input) => changeGender(input.target.value)}
            >
              <option value="">--SELECCIONAR--</option>
              <option value="M">M</option>
              <option value="F">F</option>
            </select>
          </label>
          <label className="col-md-6" htmlFor="dateBorn">
            <span>
              * FECHA DE NACIMIENTO: <span className="small">yyyy-mm-dd</span>
            </span>
            <input
              className="form-control"
              type="text"
              name="dateBorn"
              placeholder="yyyy-mm-dd"
              value={user_dateBorn}
              onChange={(input) => changeDateBorn(input.target.value)}
            />
          </label>
        </div>
        <div className="Register_policies">
          <label>
            <input
              type="checkbox"
              onChange={userChangePolicies}
              checked={user_policies ? 'checked' : ''}
            />{' '}
            Acepto las{' '}
            <a
              href="/politica-de-proteccion-de-datos-personales"
              target="_blank"
            >
              políticas del tratamiento de y uso datos
            </a>
            .
          </label>
        </div>
        <div className="Register_policies">
          <label>
            <input
              type="checkbox"
              onChange={userChangeTerms}
              checked={user_terms ? 'checked' : ''}
            />{' '}
            Acepto los{' '}
            <a
              href="/terminos-y-condiciones-para-las-ventas-virtuales"
              target="_blank"
            >
              términos y condiciones para las ventas virtuales{' '}
            </a>
            y los{' '}
            <a href="/terminos-y-condiciones-del-programa-cine" target="_blank">
              términos y condiciones programa Cine+{' '}
            </a>
            .
          </label>
        </div>
        <div className="small">
          <strong>Nota: </strong>Todos los campos con (*) son obligatorios.
        </div>
        <button
          type="button"
          onClick={registerValidation}
          className="Register_button"
        >
          REGISTRAME
        </button>
        <div className="Login_account">
          Si ya tienes cuenta,{' '}
          <span
            onClick={() => {
              changeModalLogin('visible');
              changeModalRegister('');
            }}
          >
            inicia sesión acá.
          </span>
        </div>
      </div>
    </div>,
    document.getElementById('modal-register')
  );
}

const mapStatToProps = ({ userReducer }) => {
  return {
    userReducer,
  };
};

const mapDispatchToProps = {
  changeName,
  changeLastName,
  changeGender,
  changeDniType,
  changeDni,
  changeCellPhone,
  changeEmailRegister,
  changeDateBorn,
  changePasswordRegister,
  changePasswordConfirm,
  registerValidation,
  changeModalLogin,
  changeModalRegister,
  userChangePolicies,
  userChangeTerms,
  changeEmailRepeat,
  changeRepeatDni,
};

export default connect(mapStatToProps, mapDispatchToProps)(Register);
