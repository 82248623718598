import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { changeModalRegister } from '../../store/actions/UserActions';
import './Benefits.css';

function Benefits(props) {
  const {
    userReducer: { user },
    changeModalRegister,
  } = props;

  return (
    <div className="Benefits_container">
      <div className="Benefits_title">Beneficios:</div>
      <div className="Benefits_content">
        <div className="Benefits_item">
          <h4 className="Benefits_item-title">
            Ventas
            <br />
            Empresariales
          </h4>
          <i className="icon-factory" />
          <Link to="/corporativo" className="Benefits_item-link">
            Más
          </Link>
        </div>
        <div className="Benefits_item">
          <h4 className="Benefits_item-title">
            CINE+
            <br />
            Procinal
          </h4>
          <i className="icon-cards" />
          <div className="Benefits_item-percentage">-30%</div>
          {user ? (
            <Link to="/perfil" className="Benefits_item-link">
              Perfíl
            </Link>
          ) : (
            <button
              onClick={() => changeModalRegister('visible')}
              className="Benefits_item-link"
            >
              Regístrate aquí
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ userReducer }) => {
  return {
    userReducer,
  };
};

const mapDispatchToProps = {
  changeModalRegister,
};

export default connect(mapStateToProps, mapDispatchToProps)(Benefits);
