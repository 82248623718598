export const GLOBAL_CITIES = 'GLOBAL_CITIES';
export const GLOBAL_ALL_MOVIES = 'GLOBAL_ALL_MOVIES';
export const GLOBAL_MOVIES = 'GLOBAL_MOVIES';
export const GLOBAL_MOVIES_VIEW = 'GLOBAL_MOVIES_VIEW';
export const GLOBAL_ROOM = 'GLOBAL_ROOM';
export const GLOBAL_MOVIE = 'GLOBAL_MOVIE';
export const GLOBAL_LOADING_CITIES = 'GLOBAL_LOADING_CITIES';
export const GLOBAL_LOADING_MOVIES = 'GLOBAL_LOADING_MOVIES';
export const GLOBAL_LOADING_MOVIE = 'GLOBAL_LOADING_MOVIE';
export const GLOBAL_CITY = 'GLOBAL_CITY';
export const GLOBAL_MODAL_LOADING = 'GLOBAL_MODAL_LOADING';
export const GLOBAL_ALL_CINEMAS = 'GLOBAL_ALL_CINEMAS';
export const GLOBAL_CINEMAS = 'GLOBAL_CINEMAS';
export const GLOBAL_CINEMAS_GET = 'GLOBAL_CINEMAS_GET';
export const GLOBAL_CINEMA = 'GLOBAL_CINEMA';
export const GLOBAL_DATE = 'GLOBAL_DATE';
export const GLOBAL_MOVIE_CITY = 'GLOBAL_MOVIE_CITY';
export const GLOBAL_MOVIE_ID = 'GLOBAL_MOVIE_ID';
export const GLOBAL_MOVIE_SLUG = 'GLOBAL_MOVIE_SLUG';
export const GLOBAL_MOVIE_CLEAR = 'GLOBAL_MOVIE_CLEAR';
export const GLOBAL_CINEMA_MOVIES_LOADING = 'GLOBAL_CINEMA_MOVIES_LOADING';
export const GLOBAL_CINEMA_MOVIES = 'GLOBAL_CINEMA_MOVIES';
export const GLOBAL_DATES = 'GLOBAL_DATES';
export const GLOBAL_MENU_MOBILE = 'GLOBAL_MENU_MOBILE';
export const GLOBAL_BANNERS = 'GLOBAL_BANNERS';
export const GLOBAL_CINEMA_LIST = 'GLOBAL_CINEMA_LIST';
export const GLOBAL_SETTING_CARD = 'GLOBAL_SETTING_CARD';
export const GLOBAL_SETTING_PSE = 'GLOBAL_SETTING_PSE';
export const GLOBAL_SETTING_PAYMENTS = 'GLOBAL_SETTING_PAYMENTS';
export const GLOBAL_SETTINGS = 'GLOBAL_SETTINGS';
export const GLOBAL_CINE_MAS = 'GLOBAL_CINE_MAS';
