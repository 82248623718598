import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getResponseTicket } from '../../store/actions/PayTicketActions';
import DefaultLayout from '../../Layouts/DefaultLayout';
import loading from '../../assets/images/loading-red.svg';

function TicketResponsePage(props) {
  const {
    match: {
      params: { type, bill },
    },
    getResponseTicket,
  } = props;

  useEffect(() => {
    getResponseTicket(type, bill);
  }, []);

  return (
    <DefaultLayout>
      <div className="Response">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Response_content">
                <img src={loading} alt="loading" />
                <h4>Espera un momento.</h4>
                <p>Estamos validando el estado de pago de tu transacción.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

const mapDispatchToProps = {
  getResponseTicket,
};

export default connect(null, mapDispatchToProps)(TicketResponsePage);
