import React, { Component } from 'react';
import { connect } from 'react-redux';
import PageLoader from '../../components/PageLoader';
import Theater from '../../components/Theater';
import TheaterImax from '../../components/TheaterImax';
import DefaultLayout from '../../Layouts/DefaultLayout';
import { changeCity } from '../../store/actions/GlobalActions';
import { theaterClear } from '../../store/actions/TheaterActions';

class TheaterPage extends Component {
  componentDidMount() {
    const {
      match: {
        params: { city },
      },
      changeCity,
    } = this.props;

    changeCity(city);
  }

  componentWillUnmount() {
    this.props.theaterClear();
  }

  render() {
    const {
      globalReducer: { global_loading_movies },
      match: {
        params: { theater },
      },
    } = this.props;

    return (
      <DefaultLayout>
        {global_loading_movies ? (
          <PageLoader />
        ) : (
          <>{theater !== 'imax' ? <Theater /> : <TheaterImax />}</>
        )}
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ globalReducer }) => {
  return {
    globalReducer,
  };
};

const mapDispatchToProps = {
  changeCity,
  theaterClear,
};

export default connect(mapStateToProps, mapDispatchToProps)(TheaterPage);
