/* eslint-disable no-useless-escape */
import swal from 'sweetalert';
import { getSettingValue } from '../../functions/currentFunctions';
import {
  getCombosToPay,
  getTotalCombos,
} from '../../Utils/confectioneryManager';
import {
  PAYMENT_VISIBLE,
  PAYMENT_METHOD,
  PAYMENT_NAME,
  PAYMENT_CARD_NUMBER,
  PAYMENT_CARD_MONTH,
  PAYMENT_CARD_YEAR,
  PAYMENT_CARD_CVC,
  PAYMENT_CARD_DUES,
  PAYMENT_PSE_TYPE_PERSON,
  PAYMENT_PSE_BANK,
  PAYMENT_PSE_NAMEANDLASTNAME,
  PAYMENT_PSE_DOC_TYPE,
  PAYMENT_PSE_DOC_NUMBER,
  PAYMENT_PSE_EMAIL,
  PAYMENT_PSE_PHONE,
  PAYMENT_CLEAR_FORMS,
  PAYMENT_REDIRECT,
  PAYMENT_STATUS_LOADING,
  PAYMENT_BANKS,
  PAYMENT_RESPONSE,
  PAYMENT_TYPE,
} from '../constants/Payment';
import Api from './Api';
import { changeLoading } from './GlobalActions';
import {
  subscriptionChangeTotal,
  subscriptionChangeCity,
} from './SubscriptionActions';

const email_reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const {
  REACT_APP_PRICE_SUBSCRIPTION,
  REACT_APP_EPAYCO_KEY,
  REACT_APP_MIN_RECHARGE,
  REACT_APP_LOCAL_STORAGE: LOCAL_STORAGE,
} = process.env;

export const changeVisible = (payload, type) => (dispatch, getState) => {
  const { checkout_chairs } = getState().checkoutReducer;
  const { coupon_amount } = getState().couponReducer;
  const { global_cine_mas } = getState().globalReducer;
  type = !type ? 1 : type;

  dispatch({
    type: PAYMENT_TYPE,
    payload: type,
  });

  if (type === 1 && !checkout_chairs.length && payload === 'visible') {
    swal('Upsss!!!', 'Debe seleccionar mínimo una silla.', 'warning');
    return;
  }
  if (type === 2 && !coupon_amount && payload === 'visible') {
    swal('Upsss!!!', 'Debe seleccionar mínimo un cupón.', 'warning');
    return;
  }
  if (type === 3 && payload === 'visible') {
    let priceSubscription = global_cine_mas
      ? global_cine_mas.price_subscription
      : REACT_APP_PRICE_SUBSCRIPTION;
    dispatch(subscriptionChangeTotal(priceSubscription));
    dispatch(subscriptionChangeCity(''));
    // dispatch(subscriptionChangeRecharge());
  }
  if (type === 4 && payload === 'visible') {
    dispatch(subscriptionChangeTotal(parseInt(REACT_APP_MIN_RECHARGE) + 10000));
  }

  if (payload === '') {
    dispatch({
      type: PAYMENT_CLEAR_FORMS,
    });
    document.body.style.overflow = 'auto';
  } else {
    document.body.style.overflow = 'hidden';
  }

  dispatch({
    type: PAYMENT_VISIBLE,
    payload,
  });
};

export const changeMethod = (payload) => (dispatch) => {
  if (payload === '') {
    dispatch({
      type: PAYMENT_CLEAR_FORMS,
    });
  }
  dispatch({
    type: PAYMENT_METHOD,
    payload,
  });
};

export const changeName = (payload) => (dispatch) => {
  dispatch({
    type: PAYMENT_NAME,
    payload,
  });
};

export const changeCardNumber = (payload) => (dispatch) => {
  dispatch({
    type: PAYMENT_CARD_NUMBER,
    payload,
  });
};

export const changeCardMonth = (payload) => (dispatch) => {
  dispatch({
    type: PAYMENT_CARD_MONTH,
    payload,
  });
};

export const changeCardYear = (payload) => (dispatch) => {
  dispatch({
    type: PAYMENT_CARD_YEAR,
    payload,
  });
};

export const changeCardCvc = (payload) => (dispatch) => {
  dispatch({
    type: PAYMENT_CARD_CVC,
    payload,
  });
};

export const changeCardDues = (payload) => (dispatch) => {
  dispatch({
    type: PAYMENT_CARD_DUES,
    payload,
  });
};

export const changePseTypePerson = (payload) => (dispatch) => {
  dispatch({
    type: PAYMENT_PSE_TYPE_PERSON,
    payload,
  });
};

export const changePseBank = (payload) => (dispatch) => {
  dispatch({
    type: PAYMENT_PSE_BANK,
    payload,
  });
};

export const changePseNameAndLastName = (payload) => (dispatch) => {
  dispatch({
    type: PAYMENT_PSE_NAMEANDLASTNAME,
    payload,
  });
};

export const changePseDocType = (payload) => (dispatch) => {
  dispatch({
    type: PAYMENT_PSE_DOC_TYPE,
    payload,
  });
};

export const changePseDocNumber = (payload) => (dispatch) => {
  dispatch({
    type: PAYMENT_PSE_DOC_NUMBER,
    payload,
  });
};

export const changePseEmail = (payload) => (dispatch) => {
  dispatch({
    type: PAYMENT_PSE_EMAIL,
    payload,
  });
};

export const changePsePhone = (payload) => (dispatch) => {
  dispatch({
    type: PAYMENT_PSE_PHONE,
    payload,
  });
};

export const changePaymentType = (payload) => (dispatch) => {
  dispatch({
    type: PAYMENT_TYPE,
    payload,
  });
};

export const getBanks = () => async (dispatch) => {
  try {
    let key = REACT_APP_EPAYCO_KEY;
    let banks = await fetch(
      `https://secure.payco.co/restpagos/pse/bancos.json?public_key=${key}`
    ).then((res) => res.json());

    dispatch({
      type: PAYMENT_BANKS,
      payload: banks.data,
    });
  } catch (error) {
    console.log('Error getBanks: ', error);
  }
};

const getDataToPay = (
  data,
  paymentReducer,
  checkoutReducer,
  couponReducer,
  subscriptionReducer,
  userReducer,
  globalReducer,
  confectioneryReducer
) => {
  const { payment_type } = paymentReducer;
  const {
    checkout_pay_option,
    checkout_total_tikets,
    checkout_total_transaction,
    checkout_total,
    checkout_chairs,
    checkout_room,
    checkout_location,
    checkout_showtime,
    checkout_score_bill,
  } = checkoutReducer;
  const { coupon_detail, coupon_total, coupon_amount } = couponReducer;
  const {
    subscription_theater,
    subscription_total,
    subscription_price_recharge,
    subscription_recharge,
  } = subscriptionReducer;
  const { user_score_balance, user_score } = userReducer;
  const { global_settings } = globalReducer;
  const {
    confectionery_theater,
    confectionery_combos,
    confectionery_api_products,
  } = confectioneryReducer;
  let products = [];
  let products_bepass = [];
  let description = '';
  let combosTotal = 0;
  const combosService = parseInt(
    getSettingValue(global_settings, 'confiteria_price_service', 500)
  );

  let total = checkout_total || 0;
  let total_transaction = checkout_total_transaction || 0;
  let subtotal = checkout_total_tikets || 0;

  //   Validate if confectionery exist
  if (
    confectionery_combos.length &&
    (payment_type === 1 || payment_type === 5)
  ) {
    products = getCombosToPay(
      confectionery_combos,
      checkout_pay_option,
      confectionery_api_products
    );
    const productsDescription = products.description;
    products_bepass = products.combosBepass;
    products = products.combos;
    combosTotal = getTotalCombos(confectionery_combos);
    combosTotal =
      checkout_pay_option === 'Deshabilitado'
        ? combosTotal.totalOthers
        : combosTotal.totalCinemas;

    total = total + combosTotal + combosService;
    total_transaction = total_transaction + combosService;
    subtotal = subtotal + combosTotal;
    description = productsDescription;
  }

  // If is checkout to tickets
  if (payment_type === 1) {
    const {
      ColumnaRelativa,
      ColumnaTotal,
      FilaRelativa,
      FilaTotal,
    } = checkout_room;
    const price = checkout_location;

    // const chairStorage = localStorage.getItem('@PROCINAL:chair');
    const chairStorage = null;
    let listChairs = checkout_chairs;

    if (chairStorage) {
      listChairs = JSON.parse(chairStorage);
    }

    const chairs = listChairs.map((item) => ({
      Fila: FilaTotal[item],
      Columna: ColumnaTotal[item],
      FilRelativa: FilaRelativa[item],
      ColRelativa: ColumnaRelativa[item],
      Tarifa: price.codigo,
    }));

    data.showtime = checkout_showtime.id;
    data.chairs = chairs;
    data.products = products;
    data.products_bepass = products_bepass;
    data.description = description;
    data.total = total;
    data.total_transaction = total_transaction;
    data.subtotal = subtotal;
    data.cash_payment = 0;
    data.score_bill = checkout_score_bill[0].Secuencia;
  }

  // If is checkout to coupons
  if (payment_type === 2) {
    data.coupon = coupon_detail.id;
    data.total_coupons = coupon_amount;
    data.total = parseInt(coupon_total);
    data.total_transaction = 0;
    data.subtotal = parseInt(coupon_total);
  }

  // If is checkout to subscription
  if (payment_type === 3) {
    data.total = subscription_total;
    data.subscription = subscription_total;
    data.is_recharge = subscription_recharge;
    data.theater = parseInt(subscription_theater);
    data.total = subscription_total;
    data.old_recharge_value = 0;
    if (subscription_recharge) {
      data.recharge = subscription_price_recharge;
    }
  }

  // If is checkout to recharge
  if (payment_type === 4) {
    data.total = subscription_total;
    data.old_recharge_value = user_score_balance
      ? parseInt(user_score_balance.Saldo_Total)
      : 0;

    if (user_score && user_score.Cinema !== '') {
      data.theater = user_score.Cinema;
    }
  }

  // If is checkout to confectionery
  if (payment_type === 5) {
    data.score_theater = confectionery_theater;
    data.products = products;
    data.products_bepass = products_bepass;
    data.description = description;
    data.total = total;
    data.total_transaction = total_transaction;
    data.subtotal = subtotal;
  }

  return data;
};

export const validationCard = () => (dispatch, getState) => {
  const paymentReducer = getState().paymentReducer;
  const {
    payment_name,
    payment_card_number,
    payment_card_month,
    payment_card_year,
    payment_card_cvc,
    payment_card_dues,
    payment_type,
  } = paymentReducer;
  const checkoutReducer = getState().checkoutReducer;
  const { checkout_score_bill } = checkoutReducer;
  const couponReducer = getState().couponReducer;
  const subscriptionReducer = getState().subscriptionReducer;
  const { subscription_theater, subscription_terms } = subscriptionReducer;
  const userReducer = getState().userReducer;
  const globalReducer = getState().globalReducer;
  const confectioneryReducer = getState().confectioneryReducer;

  // Validation to buy tickets
  if (payment_type === 1 && !checkout_score_bill) {
    swal('Upsss!!!', 'Hubo un error, vuelve a intentarlo más tarde.', 'error');
    return;
  }

  // Validation to buy subscription
  if (
    payment_type === 3 &&
    (subscription_theater === '' || !subscription_theater)
  ) {
    swal(
      'Upsss!!!',
      'Debes seleccionar el teatro de tu preferencia.',
      'warning'
    );
    return;
  }
  if (payment_type === 3 && !subscription_terms) {
    swal(
      'Upsss!!!',
      'Debes aceptar los términos y condiciones de CINE+',
      'warning'
    );
    return;
  }

  if (payment_name === '') {
    swal('Upsss!!!', 'Escribe el nombre que aparece en la tarjeta', 'warning');
    return;
  }
  if (payment_card_number === '') {
    swal('Upsss!!!', 'Escribe el numero de la tarjeta', 'warning');
    return;
  }
  if (payment_card_month === '') {
    swal('Upsss!!!', 'Escribe el mes de vencimiento de la tarjeta', 'warning');
    return;
  }
  if (payment_card_year === '') {
    swal('Upsss!!!', 'Escribe el año de vencimiento en la tarjeta', 'warning');
    return;
  }
  if (payment_card_cvc === '') {
    swal(
      'Upsss!!!',
      'Escribe el CVC de la tarjeta (los 3 números en el reverso de la tarjeta)',
      'warning'
    );
    return;
  }
  if (payment_card_dues === '') {
    swal('Upsss!!!', 'Seleccione las cuotas', 'warning');
    return;
  }

  let data = {
    card_number: payment_card_number,
    exp_year: payment_card_year,
    exp_month: payment_card_month,
    cvc: payment_card_cvc,
    dues: payment_card_dues,
    payment_type: payment_type,
  };

  data = getDataToPay(
    data,
    paymentReducer,
    checkoutReducer,
    couponReducer,
    subscriptionReducer,
    userReducer,
    globalReducer,
    confectioneryReducer
  );

  dispatch(changeLoading('visible', 'Procesando el pago...'));

  if (payment_type === 1) {
    localStorage.removeItem(`${LOCAL_STORAGE}::ticketConfectionery`);
  }
  if (payment_type === 5) {
    localStorage.removeItem(`${LOCAL_STORAGE}::confectionery`);
  }

  Api.callMethod(
    'auth/payment/card',
    'POST',
    data,
    (res) => {
      if (res.code === 201) {
        if (payment_type < 3 || payment_type === 5) {
          localStorage.removeItem('@PROCINAL:chair');
          dispatch(changeLoading('', ''));
          window.location.replace(
            `/payment/status/${res.payment_status.factura}`
          );
        } else {
          let message = 'La recarga a CINE+, ha sido exitosa.';
          if (payment_type === 3)
            message =
              'Tu afiliación a CINE+ ha sido exitosa, en un plazo máximo de 2 minutos podras hacer compras con CINE+.';
          if (payment_type === 5)
            message = 'La compra de confitería ha sido exitosa.';
          swal('¡Felicidades!', message, 'success').then(() => {
            window.location.reload();
          });
        }
        return;
      }
      swal('Estado de la transacción', res.message, 'warning').then(() => {
        dispatch(changeLoading('', ''));
        if (res.payment_status && res.payment_status.factura) {
          window.location.replace(
            `/payment/response/card/${res.payment_status.factura}`
          );
        } else {
          window.location.reload();
        }
      });
    },
    (error) => {
      console.log('Error PaymentCard: ', error);
      swal(
        'Upsss!!!',
        'Hubo un error al momento de procesar el pago, vuelve a intentarlo.'
      ).then(() => {
        dispatch(changeLoading('', ''));
        window.location.reload();
      });
    }
  );
};

export const validationPSE = () => (dispatch, getState) => {
  const paymentReducer = getState().paymentReducer;
  const {
    payment_pse_type_person,
    payment_pse_bank,
    payment_pse_nameAndLastName,
    payment_pse_doc_type,
    payment_pse_doc_number,
    payment_pse_email,
    payment_pse_phone,
    payment_type,
  } = paymentReducer;
  const checkoutReducer = getState().checkoutReducer;
  const couponReducer = getState().couponReducer;
  const subscriptionReducer = getState().subscriptionReducer;
  const { subscription_theater, subscription_terms } = subscriptionReducer;
  const globalReducer = getState().globalReducer;
  const confectioneryReducer = getState().confectioneryReducer;
  const userReducer = getState().userReducer;

  // Validation to buy subscription
  if (
    payment_type === 3 &&
    (subscription_theater === '' || !subscription_theater)
  ) {
    swal(
      'Upsss!!!',
      'Debes seleccionar el teatro de tu preferencia.',
      'warning'
    );
    return;
  }
  if (payment_type === 3 && !subscription_terms) {
    swal(
      'Upsss!!!',
      'Debes aceptar los términos y condiciones de CINE+',
      'warning'
    );
    return;
  }

  if (payment_pse_type_person === '') {
    swal('Upsss!!!', 'Seleccione el tipo de persona', 'warning');
    return;
  }
  if (payment_pse_bank === '') {
    swal('Upsss!!!', 'Seleccione el banco', 'warning');
    return;
  }
  if (payment_pse_nameAndLastName === '') {
    swal('Upsss!!!', 'Escribe el nombre y apellido', 'warning');
    return;
  }
  if (payment_pse_doc_type === '') {
    swal('Upsss!!!', 'Seleccione el tipo de documento', 'warning');
    return;
  }
  if (payment_pse_doc_number === '') {
    swal('Upsss!!!', 'Escribe el número de documento', 'warning');
    return;
  }
  if (payment_pse_email === '') {
    swal('Upsss!!!', 'Escribe el correo', 'warning');
    return;
  }
  if (!email_reg.test(payment_pse_email)) {
    swal('Upsss!!!', 'El formato del correo es incorrecto', 'warning');
    return;
  }
  if (payment_pse_phone === '') {
    swal('Upsss!!!', 'Seleccione el número de móvil', 'warning');
    return;
  }

  let data = {
    cod_bank: payment_pse_bank,
    doc_number: payment_pse_doc_number,
    doc_type: payment_pse_doc_type,
    firstname: payment_pse_nameAndLastName,
    lastname: payment_pse_nameAndLastName,
    email: payment_pse_email,
    phone: payment_pse_phone,
    payment_type: payment_type,
  };

  data = getDataToPay(
    data,
    paymentReducer,
    checkoutReducer,
    couponReducer,
    subscriptionReducer,
    userReducer,
    globalReducer,
    confectioneryReducer
  );

  dispatch(changeLoading('visible', 'Procesando el pago...'));

  if (payment_type === 1) {
    localStorage.removeItem(`${LOCAL_STORAGE}::ticketConfectionery`);
  }
  if (payment_type === 5) {
    localStorage.removeItem(`${LOCAL_STORAGE}::confectionery`);
  }

  Api.callMethod(
    'auth/payment/pse',
    'POST',
    data,
    (res) => {
      if (res.code === 201) {
        dispatch(changeLoading('', ''));
        window.location.replace(res.payment_status.pse_url);
        return;
      }
      swal(
        'Upsss!!!',
        res.message,
        res.code === 410 ? 'warning' : 'error'
      ).then(() => {
        dispatch(changeLoading('', ''));
        window.location.reload();
      });
    },
    (error) => {
      console.log('Error PaymentCard: ', error);
      swal(
        'Upsss!!!',
        'Hubo un error al momento de procesar el pago, vuelve a intentarlo.'
      ).then(() => {
        dispatch(changeLoading('', ''));
        window.location.reload();
      });
    }
  );
};

export const changeRedirect = (payload) => (dispatch) => {
  dispatch({
    type: PAYMENT_REDIRECT,
    payload,
  });
};

export const changeLoadingStatus = (payload) => (dispatch) => {
  dispatch({
    type: PAYMENT_STATUS_LOADING,
    payload,
  });
};

export const getResponsePayment = (type, bill) => (dispatch) => {
  Api.callMethod(
    `auth/payment/response/${type}/${bill}`,
    'get',
    null,
    (res) => {
      if (res.code === 200) {
        if (res.status_reservation) {
          swal(
            'Upsss!!!',
            'Hubo un error al momento de reservar las sillas, ponte en contacto con nosotros.'
          ).then(() => {
            window.location.replace(`/payment/status/${bill}`);
          });
          return;
        }
        window.location.replace(`/payment/status/${bill}`);
        return;
      }
      swal('Upsss!!!', 'Hubo un error, intentalo más tarde', 'error').then(
        () => {
          window.location.replace('/');
        }
      );
    },
    (error) => {
      console.log('Error paymentStatus: ', error);
      swal('Upsss!!!', 'Hubo un error, intentalo más tarde', 'error').then(
        () => {
          window.location.replace('/');
        }
      );
    }
  );
};

export const changeResponse = (payload) => (dispatch) => {
  dispatch({
    type: PAYMENT_RESPONSE,
    payload,
  });
};

export const getPaymentStatus = (bill) => (dispatch) => {
  Api.callMethod(
    `auth/payment/status/${bill}`,
    'get',
    null,
    (res) => {
      if (res.code === 200) {
        dispatch(changeResponse(res.data));
        return;
      }
      swal('Upsss!!!', 'Hubo un error, intentalo más tarde', 'error').then(
        () => {
          dispatch(changeRedirect('/'));
        }
      );
    },
    (error) => {
      console.log('Error paymentStatus: ', error);
      swal('Upsss!!!', 'Hubo un error, intentalo más tarde', 'error').then(
        () => {
          dispatch(changeRedirect('/'));
        }
      );
    }
  );
};
