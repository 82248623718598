import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getCityName } from '../../functions/currentFunctions';
import PageLoader from '../PageLoader';
import Coupons from '../Coupons';
import MovieBox from '../MovieBox';
import SectionTitle from '../SectionTitle';
import './MovieList.css';
import MovieListTitle from '../MovieListTitle';

class MovieList extends Component {
  renderImage = (image, name) => {
    image = image.replaceAll(' ', '_');
    return <img src={image} alt={name} />;
  };

  renderMovies = () => {
    const {
      globalReducer: { global_movies_view },
      couponReducer: { coupon_list },
      link,
    } = this.props;
    const columClass = coupon_list.length ? 'col-md-4' : 'col-md-3';

    let movies = global_movies_view.map((movie) => {
      movie.showtimes = movie.showtimes.sort(
        (a, b) => parseInt(a.id_funcion) - parseInt(b.id_funcion)
      );
      return (
        <div className={`col-sm-6 ${columClass}`} key={movie.id}>
          <MovieBox
            slug={movie.slug}
            link={link}
            titulo_original={movie.titulo_original}
            titulo={movie.titulo}
            tipo={movie.tipo}
            imagen={movie.imagen}
            first_showtime={movie.showtimes[0]}
          />
        </div>
      );
    });

    if (coupon_list.length) {
      return (
        <>
          <div className="col-md-9">
            <div className="row">{movies}</div>
          </div>
          <div className="col-md-3">
            <Coupons />
          </div>
        </>
      );
    }
    return movies;
  };

  render() {
    const {
      globalReducer: {
        global_movies,
        global_loading_movies,
        global_city,
        global_cities,
      },
      section,
      background,
      title,
    } = this.props;

    if (global_loading_movies) {
      return <PageLoader />;
    }

    const city = getCityName(global_cities, global_city);

    return (
      <div className="MovieList">
        {title && <MovieListTitle />}
        <SectionTitle background={background} title={section} subtitle={city} />
        <div className="MovieList_content vertical-gradient">
          <div className="container MovieList_container">
            <div className="row">
              {global_movies.length ? (
                this.renderMovies()
              ) : (
                <div className="col-md-12">
                  <h3 className="text-center color-white">
                    Te esperamos en el teatro para comprar tus boletas en
                    taquilla
                  </h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MovieList.defaultProps = {
  background: 'white',
};

const mapStateToProps = ({ globalReducer, couponReducer }) => {
  return {
    globalReducer,
    couponReducer,
  };
};

export default withRouter(connect(mapStateToProps, null)(MovieList));
