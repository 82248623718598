import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  corporativeChangeBusines,
  corporativeChangeNit,
  corporativeChangeContactName,
  corporativeChangeContactEmail,
  corporativeChangeContactPhone,
  corporativeChangeComplement,
  corporativeChangeComments,
  corporativeChangeAttendees,
  corportativeSendForm,
} from '../../../store/actions/CorporativeActions';
import loader from '../../../assets/images/loading-white.svg';
import './CorporativeForm.css';

function CorporativeForm(props) {
  const {
    match: {
      params: { corporative },
    },
    corporativeReducer: {
      corporative_busines,
      corporative_nit,
      corporative_contact_name,
      corporative_contact_email,
      corporative_contact_phone,
      corporative_complement,
      corporative_attendees,
      corporative_comments,
      corporative_form_loading,
    },
    globalReducer: { global_cinema_list },
    corporativeChangeBusines,
    corporativeChangeNit,
    corporativeChangeContactName,
    corporativeChangeContactEmail,
    corporativeChangeContactPhone,
    corporativeChangeComplement,
    corporativeChangeAttendees,
    corporativeChangeComments,
    corportativeSendForm,
  } = props;

  return (
    <div className="CorporativeForm-container vertical-gradient">
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <h3>Contáctenos</h3>
            <div className="form-group">
              <label>Nombre de la empresa*</label>
              <input
                type="text"
                value={corporative_busines}
                onChange={(input) =>
                  corporativeChangeBusines(input.target.value)
                }
              />
            </div>
            <div className="form-group">
              <label>NIT*</label>
              <input
                type="text"
                value={corporative_nit}
                onChange={(input) => corporativeChangeNit(input.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Nombre y apellidos del Contacto*</label>
              <input
                type="text"
                value={corporative_contact_name}
                onChange={(input) =>
                  corporativeChangeContactName(input.target.value)
                }
              />
            </div>
            <div className="form-group">
              <label>E-mail*</label>
              <input
                type="email"
                value={corporative_contact_email}
                onChange={(input) =>
                  corporativeChangeContactEmail(input.target.value)
                }
              />
            </div>
            <div className="form-group">
              <label>Teléfono de Contacto*</label>
              <input
                type="text"
                value={corporative_contact_phone}
                onChange={(input) =>
                  corporativeChangeContactPhone(input.target.value)
                }
              />
            </div>
            {(!corporative || corporative === 'ventas-empresariales') && (
              <div className="form-group">
                <label>Tipo de BONO*</label>
                <select
                  value={corporative_complement}
                  onChange={(input) =>
                    corporativeChangeComplement(input.target.value)
                  }
                >
                  <option value="">-- Seleccionar --</option>
                  <option value="Tipo de bono: Boletas">Boletas</option>
                  <option value="Tipo de bono: Combos">Combos</option>
                </select>
              </div>
            )}
            {corporative === 'alquiler-de-salas' && (
              <div className="form-group">
                <label>Teatro*</label>
                <select
                  value={corporative_complement}
                  onChange={(input) =>
                    corporativeChangeComplement(input.target.value)
                  }
                >
                  <option value="">-- Seleccionar --</option>
                  {global_cinema_list.map((theater) => {
                    const name = theater.nombre_completo
                      ? theater.nombre_completo
                      : theater.nombre;
                    return (
                      <option value={`Teatro: ${name}`} key={theater.id}>
                        {theater.ciudad} - {name}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
            {(!corporative ||
              corporative === 'ventas-empresariales' ||
              corporative === 'alquiler-de-salas') && (
              <div className="form-group">
                <label>
                  {corporative === 'alquiler-de-salas'
                    ? 'Número de Asistentes*'
                    : 'Cantidad*'}
                </label>
                <input
                  type="number"
                  value={corporative_attendees}
                  onChange={(input) =>
                    corporativeChangeAttendees(input.target.value)
                  }
                />
              </div>
            )}
            <div className="form-group">
              <label>Información adicional o comentarios:</label>
              <textarea
                value={corporative_comments}
                rows={4}
                onChange={(input) =>
                  corporativeChangeComments(input.target.value)
                }
              />
            </div>
            <div className="form-group CorporativeForm-terms">
              Consulta nuestra Política de Protección de Datos personales{' '}
              <a
                href="/politica-para-el-tratamiento-de-la-informacion"
                target="_blank"
              >
                aquí
              </a>
              .
            </div>
            {corporative_form_loading ? (
              <div className="CorporativeForm-loading">
                <img src={loader} alt="Cargando..." />
                Enviando formulario...
              </div>
            ) : (
              <button type="button" onClick={corportativeSendForm}>
                ENVIAR
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ corporativeReducer, globalReducer }) => {
  return {
    corporativeReducer,
    globalReducer,
  };
};

const mapDispatchToProps = {
  corporativeChangeBusines,
  corporativeChangeNit,
  corporativeChangeContactName,
  corporativeChangeContactEmail,
  corporativeChangeContactPhone,
  corporativeChangeComplement,
  corporativeChangeComments,
  corporativeChangeAttendees,
  corportativeSendForm,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CorporativeForm)
);
