import swal from 'sweetalert';
import {
  THEATER_ALL_MOVIES,
  THEATER_CLEAR,
  THEATER_DATE,
  THEATER_DATE_MENU,
  THEATER_DETAIL,
  THEATER_LOADING,
  THEATER_MOVIES,
  THEATER_SCHEDULE,
} from '../constants/Theater';

export const theaterClear = () => (dispatch) => {
  dispatch({
    type: THEATER_CLEAR,
  });
};

export const theaterLoading = (payload) => (dispatch) => {
  dispatch({
    type: THEATER_LOADING,
    payload,
  });
};

export const theaterSchedule = (payload) => (dispatch) => {
  dispatch({
    type: THEATER_SCHEDULE,
    payload,
  });
};

export const theaterDate = (payload) => (dispatch) => {
  dispatch({
    type: THEATER_DATE,
    payload,
  });
};

export const theaterDetail = (theater_slug) => (dispatch, getState) => {
  dispatch(theaterClear());
  const { global_cinemas } = getState().globalReducer;
  const cinema = global_cinemas.find(
    (cinema) => cinema.nombre === theater_slug
  );

  if (cinema) {
    setTimeout(() => {
      dispatch({
        type: THEATER_DETAIL,
        payload: cinema,
      });
    }, 50);
  } else {
    swal(
      'Upsss!!!',
      'Hubo un error, vuelve a intentarlo más tarde.',
      'error'
    ).then(() => {
      window.location.href = '/';
    });
  }
};

export const theaterMovies = (theater_slug) => (dispatch, getState) => {
  dispatch(theaterLoading(true));
  const { global_all_movies, global_cinemas } = getState().globalReducer;
  const { theater_all_movies } = getState().theaterReducer;
  const cinema = global_cinemas.find(
    (cinema) => cinema.nombre === theater_slug
  );

  if (cinema) {
    let movies = theater_all_movies.find(
      (item) => item.theater === theater_slug
    );

    if (movies) {
      // Show save functions
      dispatch({
        type: THEATER_MOVIES,
        payload: movies.movies,
      });
      dispatch({
        type: THEATER_DATE_MENU,
        payload: movies.dateMenu,
      });
    } else {
      let movieList = global_all_movies.find(
        (item) => parseInt(item.city_id) === parseInt(cinema.city_id)
      );

      if (movieList) {
        let data = [];
        let dateMenu = [];
        let movies = movieList.movies.map((movie) => {
          let movieResponse = data.find((item) => item.slug === movie.slug);
          let saveInData = movieResponse ? false : true;

          if (!movieResponse) {
            movieResponse = {
              director: movie.director,
              distribuidor: movie.distribuidor,
              duracion: movie.duracion,
              fecha_estreno: movie.fecha_estreno,
              fecha_estreno_unixtime: movie.fecha_estreno_unixtime,
              formato: movie.formato,
              gender_id: movie.gender_id,
              id: movie.id,
              idioma: movie.idioma,
              imagen: movie.imagen,
              is_active: movie.is_active,
              medio: movie.medio,
              nombre: movie.nombre,
              pais: movie.pais,
              rating_id: movie.rating_id,
              reparto: movie.reparto,
              score_id: movie.score_id,
              sinopsis: movie.sinopsis,
              slug: movie.slug,
              tipo: movie.tipo,
              titulo: movie.titulo,
              titulo_original: movie.titulo_original,
              trailer1: movie.trailer1,
              trailer2: movie.trailer2,
              version: movie.version,
              list: [],
            };
          }

          const rooms = movie.rooms.filter(
            (room) => parseInt(room.cinema_id) === parseInt(cinema.id)
          );

          if (rooms.length) {
            const list_showtimes = [];

            let showtimes = rooms.map((room) => {
              const showtimes = movie.showtimes.filter(
                (showtime) => parseInt(showtime.room_id) === parseInt(room.id)
              );

              if (showtimes.length) {
                //Add showtimes to general list
                list_showtimes.push(...showtimes);
                return showtimes;
              }
              return null;
            });
            // Deleted null results
            showtimes = showtimes.filter((showtime) => showtime);

            //Validate if added showtime date in date-menu
            list_showtimes.forEach((showtime) => {
              let isAdded = dateMenu.find(
                (date) => date.value === showtime.fecha_funcion
              );
              if (!isAdded) {
                dateMenu.push({
                  value: showtime.fecha_funcion,
                  name: showtime.fecha_cadena,
                });
              }
            });

            if (showtimes.length) {
              movieResponse.list.push({
                movie_id: movie.id,
                name: `${movie.version} - ${movie.formato}`,
                showtimes,
                list_showtimes,
              });
              if (saveInData) {
                data.push(movieResponse);
              }
              return movieResponse;
            }
          }
          return null;
        });
        // Deleted null results
        movies = movies.filter((movie) => movie);

        if (dateMenu.length) {
          dateMenu = dateMenu.sort(
            (a, b) => new Date(a.value) - new Date(b.value)
          );

          dispatch(theaterDate(dateMenu[0].value));
        }
        dispatch({
          type: THEATER_DATE_MENU,
          payload: dateMenu,
        });
        // Add theater information
        theater_all_movies.push({
          theater: theater_slug,
          movies: data,
          list_movies: movies,
          dateMenu,
        });
        dispatch({
          type: THEATER_ALL_MOVIES,
          payload: theater_all_movies,
        });
        dispatch({
          type: THEATER_MOVIES,
          payload: data,
        });
      }
    }
  }
  setTimeout(() => {
    dispatch(theaterLoading(false));
  }, 100);
};
