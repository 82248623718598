import React from 'react';
import { connect } from 'react-redux';
import HeaderCity from '../../components/HeaderCity';
import HowToBuy from '../../components/HowToBuy';
import MovieList from '../../components/MovieList';
import DefaultLayout from '../../Layouts/DefaultLayout';
import TheaterList from '../../components/TheaterList';
import NextReleases from '../../components/NextReleases';
import Benefits from '../../components/Benefits';
import HowToSubscription from '../../components/HowToSubscription';
import PopUp from '../../components/PopUp';
const { REACT_APP_EMERGENCY } = process.env;

function CityPage(props) {
  const {
    globalReducer: { global_city },
  } = props;

  return (
    <DefaultLayout>
      <HeaderCity />
      <MovieList
        title={true}
        section="Cartelera"
        link={`/ciudad/${global_city}`}
      />
      {REACT_APP_EMERGENCY !== 'true' && <HowToSubscription />}
      <HowToBuy />
      <TheaterList limit={3} />
      <NextReleases limit={8} />
      <Benefits />
      <PopUp />
    </DefaultLayout>
  );
}

const mapStateToProps = ({ globalReducer }) => {
  return {
    globalReducer,
  };
};

export default connect(mapStateToProps, null)(CityPage);
