import React from 'react';
import { connect } from 'react-redux';
import {
  confectioneryRemoveCombo,
  confectioneryUpdateCombo,
} from '../../../store/actions/ConfectioneryActions';
import { getConfectioneryDetail } from '../../../Utils/confectioneryManager';
import Resume from '../Resume';
import './Combos.css';

function Combos(props) {
  const {
    confectioneryReducer: { confectionery_combos, confectionery_api_products },
    confectioneryRemoveCombo,
    confectioneryUpdateCombo,
  } = props;

  const addRemoveTotal = (action, combo, index) => {
    let total = combo.Cantidad;

    if (action === 'remove' && total > 1) {
      total--;
    } else if (action === 'add') {
      total++;
    }

    combo.Cantidad = total;

    confectioneryUpdateCombo(combo, index);
  };

  return (
    <>
      {confectionery_combos.map((recipe, index) => {
        const productApi = getConfectioneryDetail(
          recipe.Codigo,
          confectionery_api_products
        );
        if (!productApi) return null;
        return (
          <div key={`${recipe.Codigo}-${index}`} className="combos_row">
            <img src={productApi.image} alt={recipe.Descripcion} />
            <div className="combos_text">
              <h3>{productApi.name}</h3>
              <div className="combos_description">
                <Resume recipe={recipe.Receta} showTitle={false} />
              </div>
            </div>
            <div className="combos_actions">
              <button
                className="combos_remove"
                onClick={() => confectioneryRemoveCombo(index)}
              >
                <i className="fas fa-trash" />
              </button>
              <div className="combos_amout">
                <button
                  className="combos_amout-button combos_amout-remove"
                  onClick={() => addRemoveTotal('remove', recipe, index)}
                >
                  -
                </button>
                <div className="combos_text-amout">{recipe.Cantidad}</div>
                <button
                  className="combos_amout-button combos_amout-add"
                  onClick={() => addRemoveTotal('add', recipe, index)}
                >
                  +
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

const mapStateToProps = ({ confectioneryReducer }) => {
  return {
    confectioneryReducer,
  };
};

const mapDispatchToProps = {
  confectioneryRemoveCombo,
  confectioneryUpdateCombo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Combos);
