import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {
  cinemasChangeVisible,
  cinemasChangePassword,
  cinemasChangeConfirmPassword,
  cinemasToPay,
} from '../../store/actions/CinemasActions';
import { withRouter } from 'react-router-dom';
import loading from '../../assets/images/loading-red.svg';
import { getSettingValue } from '../../functions/currentFunctions';
import { getTotalTransaction } from '../../Utils/confectioneryManager';

function CinemasPayment(props) {
  const {
    cinemasReducer: {
      cinemas_visible,
      cinemas_password,
      cinemas_confirm_password,
      cinemas_error,
      cinemas_loading,
      checkout_pay_option,
    },
    checkoutReducer: { checkout_total },
    confectioneryReducer: { confectionery_combos },
    globalReducer: { global_settings },
    cinemasChangeVisible,
    cinemasChangePassword,
    cinemasChangeConfirmPassword,
    cinemasToPay,
  } = props;
  const numberFormat = new Intl.NumberFormat('es-CO');
  const recargo = parseInt(
    getSettingValue(global_settings, 'confiteria_price_service', 500)
  );

  return ReactDOM.createPortal(
    <div className={`PaymentMethods ${cinemas_visible}`}>
      <div className="PaymentMethods_container">
        <div className="PaymentMethods_content">
          <button
            type="button"
            className="PaymentMethods_close"
            onClick={() => cinemasChangeVisible('')}
          >
            &times;
          </button>
          <div className="PaymentMethods_resume">
            <div className="PaymentMethods_title">
              Pagar con <strong className="color-red">CINE+</strong>
            </div>
            <div className="PaymentMethods_price">
              TOTAL:{' '}
              <strong className="color-red">
                $
                {numberFormat.format(
                  getTotalTransaction(
                    confectionery_combos,
                    recargo,
                    checkout_total,
                    checkout_pay_option
                  )
                )}
              </strong>
            </div>
          </div>
          <div className="PaymentMethods_form">
            <div className="form-group">
              Tu contraseña:
              <input
                type="password"
                value={cinemas_password}
                onChange={(input) => cinemasChangePassword(input.target.value)}
                className="form-control"
              />
            </div>
            <div className="form-group">
              Repetir contraseña:
              <input
                type="password"
                value={cinemas_confirm_password}
                onChange={(input) =>
                  cinemasChangeConfirmPassword(input.target.value)
                }
                className="form-control"
              />
            </div>
            {cinemas_error !== '' && (
              <p className="color-red">{cinemas_error}</p>
            )}
            {cinemas_loading ? (
              <div className="PaymentMethods_box-loading">
                <img src={loading} alt="Cargando..." />
                Procesando el pago...
              </div>
            ) : (
              <button className="btn btn-red btn-block" onClick={cinemasToPay}>
                PAGAR
              </button>
            )}
          </div>
        </div>
      </div>
    </div>,
    document.getElementById('modal-cinemas-payment')
  );
}

const mapStateToProps = ({
  cinemasReducer,
  checkoutReducer,
  globalReducer,
  confectioneryReducer,
}) => {
  return {
    cinemasReducer,
    checkoutReducer,
    globalReducer,
    confectioneryReducer,
  };
};

const mapDispatchToProps = {
  cinemasChangeVisible,
  cinemasChangePassword,
  cinemasChangeConfirmPassword,
  cinemasToPay,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CinemasPayment)
);
