import React from 'react';
import { Link } from 'react-router-dom';

function Movie(props) {
  const { item } = props;
  const showtime = item.showtime_movie
    ? item.showtime_movie
    : item.old_showtime_movie;
  const image = showtime.movie.imagen.replaceAll(' ', '_');
  const title = showtime.movie.titulo;
  const date = showtime.fecha_cadena;
  const hour = showtime.hora_cadena;
  const bill = item.factura;
  const cod_response = parseInt(item.codigo_respuesta);
  const type = item.medio_pago === 'PSE' ? 'pse' : 'card';
  let cinema = showtime.room_cinema.cinema.nombre_completo
    ? showtime.room_cinema.cinema.nombre_completo
    : showtime.room_cinema.cinema.nombre;
  cinema =
    cinema === 'imax'
      ? cinema.toUpperCase()
      : cinema.charAt(0).toUpperCase() + cinema.slice(1);

  return (
    <div className="col-md-3 Tickets_item">
      <img src={image} alt={title} />
      <div className="Tickets_item-content">
        <h3>{title}</h3>
        <div>
          <strong>Teatro:</strong> {cinema}
        </div>
        <div>
          <strong>Fecha:</strong> {date}
        </div>
        <div>
          <strong>Hora:</strong> {hour}
        </div>
        {cod_response === 1 && (
          <Link to={`/tickets/${bill}`} className="Tickets_btn code">
            Ver Código QR
          </Link>
        )}
        {cod_response === 3 && (
          <Link
            to={`/payment/response/${type}/${bill}`}
            className="Tickets_btn status"
          >
            Validar estado de pago
          </Link>
        )}
        {cod_response === 2 && (
          <div className="Tickets_btn">Transancción rechazada</div>
        )}
      </div>
    </div>
  );
}

export default Movie;
