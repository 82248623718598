import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { popUpChangeVisible } from '../../store/actions/PopUpActions';
import './PopUp.css';

function PopUp(props) {
  const {
    popUpReducer: { pop_up_visible, pop_up_image, pop_up_width },
    popUpChangeVisible,
  } = props;

  return ReactDOM.createPortal(
    <div className={`PopUp-contaniner ${pop_up_visible}`}>
      <div className="PopUp-content" style={{ maxWidth: pop_up_width }}>
        <button className="PopUp-button" onClick={() => popUpChangeVisible('')}>
          &times;
        </button>
        <img src={pop_up_image} alt="Movie PopUp" />
      </div>
    </div>,
    document.getElementById('modal-popup')
  );
}

const mapStateToProps = ({ popUpReducer }) => {
  return {
    popUpReducer,
  };
};

const mapDispatchToProps = {
  popUpChangeVisible,
};

export default connect(mapStateToProps, mapDispatchToProps)(PopUp);
