/* eslint-disable import/no-anonymous-default-export */
import {
  PAYMENT_VISIBLE,
  PAYMENT_METHOD,
  PAYMENT_NAME,
  PAYMENT_CARD_NUMBER,
  PAYMENT_CARD_MONTH,
  PAYMENT_CARD_YEAR,
  PAYMENT_CARD_CVC,
  PAYMENT_CARD_DUES,
  PAYMENT_PSE_TYPE_PERSON,
  PAYMENT_PSE_BANK,
  PAYMENT_PSE_NAMEANDLASTNAME,
  PAYMENT_PSE_DOC_TYPE,
  PAYMENT_PSE_DOC_NUMBER,
  PAYMENT_PSE_EMAIL,
  PAYMENT_PSE_PHONE,
  PAYMENT_CLEAR_FORMS,
  PAYMENT_REDIRECT,
  PAYMENT_STATUS,
  PAYMENT_STATUS_LOADING,
  PAYMENT_BANKS,
  PAYMENT_RESPONSE,
  PAYMENT_TYPE,
} from '../constants/Payment';

const INITIAL_STATE = {
  payment_visible: '',
  payment_method: '',
  payment_name: '',
  payment_card_number: '',
  payment_card_month: '',
  payment_card_year: '',
  payment_card_cvc: '',
  payment_card_dues: '',
  payment_pse_type_person: 0,
  payment_pse_bank: '0',
  payment_pse_nameAndLastName: '',
  payment_pse_doc_type: 'CC',
  payment_pse_doc_number: '',
  payment_pse_email: '',
  payment_pse_phone: '',
  payment_redirect: null,
  payment_status: null,
  payment_status_loading: true,
  payment_banks: [],
  payment_response: null,
  payment_type: 1,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PAYMENT_VISIBLE:
      return {
        ...state,
        payment_visible: action.payload,
      };
    case PAYMENT_METHOD:
      return {
        ...state,
        payment_method: action.payload,
      };
    case PAYMENT_NAME:
      return {
        ...state,
        payment_name: action.payload,
      };
    case PAYMENT_CARD_NUMBER:
      return {
        ...state,
        payment_card_number: action.payload,
      };
    case PAYMENT_CARD_MONTH:
      return {
        ...state,
        payment_card_month: action.payload,
      };
    case PAYMENT_CARD_YEAR:
      return {
        ...state,
        payment_card_year: action.payload,
      };
    case PAYMENT_CARD_CVC:
      return {
        ...state,
        payment_card_cvc: action.payload,
      };
    case PAYMENT_CARD_DUES:
      return {
        ...state,
        payment_card_dues: action.payload,
      };
    case PAYMENT_PSE_TYPE_PERSON:
      return {
        ...state,
        payment_pse_type_person: action.payload,
      };
    case PAYMENT_PSE_BANK:
      return {
        ...state,
        payment_pse_bank: action.payload,
      };
    case PAYMENT_PSE_NAMEANDLASTNAME:
      return {
        ...state,
        payment_pse_nameAndLastName: action.payload,
      };
    case PAYMENT_PSE_DOC_TYPE:
      return {
        ...state,
        payment_pse_doc_type: action.payload,
      };
    case PAYMENT_PSE_DOC_NUMBER:
      return {
        ...state,
        payment_pse_doc_number: action.payload,
      };
    case PAYMENT_PSE_EMAIL:
      return {
        ...state,
        payment_pse_email: action.payload,
      };
    case PAYMENT_PSE_PHONE:
      return {
        ...state,
        payment_pse_phone: action.payload,
      };
    case PAYMENT_CLEAR_FORMS:
      return {
        ...state,
        payment_method: '',
        payment_name: '',
        payment_card_number: '',
        payment_card_month: '',
        payment_card_year: '',
        payment_card_cvc: '',
        payment_card_dues: '',
        payment_pse_type_person: 0,
        payment_pse_bank: '0',
        payment_pse_nameAndLastName: '',
        payment_pse_doc_type: 'CC',
        payment_pse_doc_number: '',
        payment_pse_email: '',
        payment_pse_phone: '',
      };
    case PAYMENT_REDIRECT:
      return {
        ...state,
        payment_redirect: action.payload,
      };
    case PAYMENT_STATUS:
      return {
        ...state,
        payment_status: action.payload,
        payment_status_loading: false,
      };
    case PAYMENT_STATUS_LOADING:
      return {
        ...state,
        payment_status_loading: action.payload,
      };
    case PAYMENT_BANKS:
      return {
        ...state,
        payment_banks: action.payload,
      };
    case PAYMENT_RESPONSE:
      return {
        ...state,
        payment_response: action.payload,
      };
    case PAYMENT_TYPE:
      return {
        ...state,
        payment_type: action.payload,
      };
    default:
      return { ...state };
  }
};
