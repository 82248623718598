import {
  MOVIES_ACTIVE,
  MOVIES_ANIMATION,
  MOVIES_LOADING,
  MOVIES_STEPS,
  MOVIES_NEXT_RELEASES,
  MOVIES_NEXT_RELEASES_LOADING,
} from '../constants/Movie';

const INITIAL_STATE = {
  movies_loading: true,
  movies_active: [],
  movies_image_size: 206,
  movies_steps: 2,
  movies_animation: true,
  movies_next_releases: [],
  movies_next_releases_loading: true,
};

const movieReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MOVIES_LOADING:
      return {
        ...state,
        movies_loading: action.payload,
      };
    case MOVIES_ACTIVE:
      return {
        ...state,
        movies_active: action.payload,
      };
    case MOVIES_STEPS:
      return {
        ...state,
        movies_steps: action.payload,
      };
    case MOVIES_ANIMATION:
      return {
        ...state,
        movies_animation: action.payload,
      };
    case MOVIES_NEXT_RELEASES:
      return {
        ...state,
        movies_next_releases: action.payload,
      };
    case MOVIES_NEXT_RELEASES_LOADING:
      return {
        ...state,
        movies_next_releases_loading: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default movieReducer;
