import React from 'react';
import Benefits from '../../components/Benefits';
import HowToBuy from '../../components/HowToBuy';
import NextReleases from '../../components/NextReleases';
import DefaultLayout from '../../Layouts/DefaultLayout';

function NextReleasesPage(props) {
  return (
    <DefaultLayout>
      <NextReleases />
      <HowToBuy />
      <Benefits />
    </DefaultLayout>
  );
}

export default NextReleasesPage;
