import {
  COUPON_AMOUNT,
  COUPON_DETAIL,
  COUPON_ERROR,
  COUPON_LIST,
  COUPON_LOADING,
  COUPON_MY_LIST,
  COUPON_SHOW_LEGALS,
  COUPON_TOTAL,
} from '../constants/Coupon';
import Api from './Api';

export const couponLoading = (payload) => (dispatch) => {
  dispatch({
    type: COUPON_LOADING,
    payload,
  });
};

export const getCoupons = () => (dispatch) => {
  Api.callMethod(
    'auth/coupons',
    'GET',
    null,
    (res) => {
      if (res.code === 200) {
        const coupons = res.data.filter(
          (coupon) => parseInt(coupon.codes_active_count) > 0
        );

        dispatch({
          type: COUPON_LIST,
          payload: coupons,
        });
      }
    },
    (err) => {
      console.log('Error getCoupons: ', err);
    }
  );
};

export const getMyCouponList = () => (dispatch) => {
  dispatch(couponLoading(true));
  Api.callMethod(
    'auth/user/coupons',
    'GET',
    null,
    (res) => {
      dispatch(couponLoading(false));
      if (res.code === 200) {
        dispatch({
          type: COUPON_MY_LIST,
          payload: res.data,
        });
      }
    },
    (err) => {
      console.log('Error getMyCouponList: ', err);
      dispatch(couponLoading(false));
    }
  );
};

export const setCouponDetail = (payload) => (dispatch) => {
  dispatch({
    type: COUPON_DETAIL,
    payload,
  });
};

export const getMyCoupon = (coupon) => (dispatch, getState) => {
  const { coupon_my_list } = getState().couponReducer;
  const coupon_detail = coupon_my_list.filter(
    (item) => parseInt(item.id_coupon_code) === parseInt(coupon)
  );

  if (coupon_detail.length) {
    dispatch(setCouponDetail(coupon_detail[0]));
  }
};

export const couponError = (payload) => (dispatch) => {
  dispatch({
    type: COUPON_ERROR,
    payload,
  });
};

export const getCoupon = (coupon_id) => (dispatch, getState) => {
  const { coupon_list } = getState().couponReducer;
  dispatch(couponLoading(true));

  if (!coupon_list.length) {
    Api.callMethod(
      `auth/coupons/${coupon_id}`,
      'GET',
      null,
      (res) => {
        if (res.code === 200) {
          dispatch(setCouponDetail(res.data));
        } else {
          dispatch(couponError('Lo sentimos, no encontramos el cupón.'));
        }
        dispatch(couponLoading(false));
      },
      (err) => {
        console.log('Error getCoupon: ', err);
        dispatch(couponError('Lo sentimos, no encontramos el cupón.'));
        dispatch(couponLoading(false));
      }
    );
  } else {
    const coupon = coupon_list.filter(
      (coupon) => parseInt(coupon.id) === parseInt(coupon_id)
    );
    if (coupon.length) {
      dispatch(setCouponDetail(coupon[0]));
    } else {
      dispatch(couponError('Lo sentimos, no encontramos el cupón.'));
    }
    dispatch(couponLoading(false));
  }
};

export const couponLegals = (payload) => (dispatch) => {
  dispatch({
    type: COUPON_SHOW_LEGALS,
    payload,
  });
};

export const changeCouponAmount = (type) => (dispatch, getState) => {
  let { coupon_amount, coupon_detail } = getState().couponReducer;

  if (type === 'add' && coupon_amount < coupon_detail.codes_active_count) {
    coupon_amount++;
  }
  if (type === 'remove' && coupon_amount > 1) {
    coupon_amount--;
  }

  dispatch({
    type: COUPON_AMOUNT,
    payload: coupon_amount,
  });
  dispatch({
    type: COUPON_TOTAL,
    payload: parseInt(coupon_detail.price) * coupon_amount,
  });
};
