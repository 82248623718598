import {
  GLOBAL_CITIES,
  GLOBAL_MOVIES,
  GLOBAL_MOVIE,
  GLOBAL_LOADING_CITIES,
  GLOBAL_LOADING_MOVIES,
  GLOBAL_ALL_MOVIES,
  GLOBAL_CITY,
  GLOBAL_LOADING_MOVIE,
  GLOBAL_MODAL_LOADING,
  GLOBAL_CINEMAS,
  GLOBAL_ALL_CINEMAS,
  GLOBAL_CINEMA,
  GLOBAL_DATE,
  GLOBAL_MOVIE_SLUG,
  GLOBAL_MOVIE_ID,
  GLOBAL_MOVIE_CITY,
  GLOBAL_CINEMA_MOVIES,
  GLOBAL_MOVIE_CLEAR,
  GLOBAL_CINEMA_MOVIES_LOADING,
  GLOBAL_DATES,
  GLOBAL_MENU_MOBILE,
  GLOBAL_MOVIES_VIEW,
  GLOBAL_BANNERS,
  GLOBAL_CINEMA_LIST,
  GLOBAL_SETTING_CARD,
  GLOBAL_SETTING_PSE,
  GLOBAL_SETTING_PAYMENTS,
  GLOBAL_SETTINGS,
  GLOBAL_CINE_MAS,
  GLOBAL_CINEMAS_GET,
} from '../constants/Global';
import { MOVIES_ACTIVE, MOVIES_STEPS } from '../constants/Movie';
import Api from './Api';
import { changeMoviesLoading } from './MovieActions';
import {
  popUpChangeImage,
  popUpChangeVisible,
  popUpChangeWidth,
} from './PopUpActions';

export const changeLoading = (status, message) => (dispatch) => {
  dispatch({
    type: GLOBAL_MODAL_LOADING,
    payload: {
      status,
      message,
    },
  });
};

export const citiesLoading = (payload) => (dispatch) => {
  dispatch({
    type: GLOBAL_LOADING_CITIES,
    payload,
  });
};

export const getCities = () => (dispatch) => {
  Api.callMethod(
    'cities',
    'GET',
    null,
    (res) => {
      if (res.data) {
        dispatch({
          type: GLOBAL_CITIES,
          payload: res.data,
        });
      }
    },
    (error) => {
      console.log('Error getCities: ', error);
    }
  );
};

export const getBanners = () => (dispatch) => {
  Api.callMethod(
    'banners',
    'GET',
    null,
    (res) => {
      if (res.data) {
        dispatch({
          type: GLOBAL_BANNERS,
          payload: res.data,
        });
      }
    },
    (error) => {
      console.log('Error getBanners: ', error);
    }
  );
};

export const getCinemas = (city_slug) => (dispatch, getState) => {
  const {
    global_all_cinemas,
    global_cities,
    global_cinemas_get,
  } = getState().globalReducer;

  if (global_cinemas_get) {
    return;
  }

  let city = global_cities.find((city) => city.slug === city_slug);
  city = city ? city.id : 1;

  let isAdded = global_all_cinemas.filter(
    (item) => parseInt(item.city_id) === parseInt(city)
  );

  dispatch({
    type: GLOBAL_CINEMAS_GET,
    payload: true,
  });

  if (isAdded.length) {
    dispatch({
      type: GLOBAL_CINEMAS,
      payload: isAdded[0].cinemas,
    });
    dispatch({
      type: GLOBAL_CINEMAS_GET,
      payload: false,
    });
  } else {
    Api.callMethod(
      `cinemas?city=${city}`,
      'GET',
      null,
      (res) => {
        if (res) {
          global_all_cinemas.push({
            city_id: city,
            cinemas: res.data,
          });

          dispatch({
            type: GLOBAL_ALL_CINEMAS,
            payload: global_all_cinemas,
          });
          dispatch({
            type: GLOBAL_CINEMAS,
            payload: res.data,
          });
          dispatch({
            type: GLOBAL_CINEMAS_GET,
            payload: false,
          });
        }
      },
      (error) => {
        console.log('Error getMovies: ', error);
      }
    );
  }
};

const setGlobalMovies = (movies) => (dispatch) => {
  let listView = [];

  for (let i = 0; i < movies.length; i++) {
    const isAdded = listView.find((movie) => movie.slug === movies[i].slug);

    if (!isAdded) {
      listView.push(movies[i]);
    } else if (isAdded.slug === movies[i].slug) {
      let addedShowTimes = isAdded.showtimes;
      addedShowTimes = addedShowTimes.sort(
        (a, b) =>
          parseInt(a.hora_funcion.replaceAll(':', '')) -
          parseInt(b.hora_funcion.replaceAll(':', ''))
      );
      let currentShowTimes = movies[i].showtimes;
      currentShowTimes = currentShowTimes.sort(
        (a, b) =>
          parseInt(a.hora_funcion.replaceAll(':', '')) -
          parseInt(b.hora_funcion.replaceAll(':', ''))
      );
      if (
        parseInt(addedShowTimes[0].id_funcion) >
        parseInt(currentShowTimes[0].id_funcion)
      ) {
        listView = listView.filter((movie) => movie.slug !== isAdded.slug);
        listView.push(movies[i]);
      }
    }
  }

  dispatch({
    type: GLOBAL_MOVIES_VIEW,
    payload: listView,
  });
  dispatch({
    type: GLOBAL_MOVIES,
    payload: movies,
  });
};

export const changeCity = (payload) => (dispatch, getState) => {
  const { global_all_movies, global_cities } = getState().globalReducer;
  dispatch({
    type: GLOBAL_LOADING_MOVIES,
    payload: true,
  });
  let city = global_cities.filter((city) => city.slug === payload);
  city = city.length ? city[0].id : 1;

  dispatch({
    type: GLOBAL_CITY,
    payload: payload,
  });

  dispatch({
    type: GLOBAL_CINEMA,
    payload: '',
  });
  dispatch(getCinemas(payload));

  if (city) {
    let movieList = global_all_movies.filter(
      (item) => parseInt(item.city_id) === parseInt(city)
    );

    if (movieList.length) {
      setTimeout(() => {
        dispatch(setGlobalMovies(movieList[0].movies));
      }, 250);
    } else {
      Api.callMethod(`movies?city=${city}`, 'GET', null, (res) => {
        if (res) {
          global_all_movies.push({
            city_id: parseInt(city),
            movies: res.movies,
          });
          dispatch({
            type: GLOBAL_ALL_MOVIES,
            payload: global_all_movies,
          });
          dispatch(setGlobalMovies(res.movies));
        }
      });
    }
  }
};

export const getMovie = (movieSlug, citySlug) => (dispatch, getState) => {
  const { global_all_movies, global_cities } = getState().globalReducer;
  dispatch({
    type: GLOBAL_LOADING_MOVIE,
    payload: true,
  });

  let cityId = global_cities.filter((city) => city.slug === citySlug);
  cityId = cityId.length ? cityId[0].id : 1;

  const listCities = global_all_movies.filter(
    (item) => parseInt(item.city_id) === parseInt(cityId)
  );

  if (listCities.length) {
    const listMovies = listCities[0].movies;
    const movie = listMovies.filter((movie) => movie.slug === movieSlug);

    dispatch({
      type: GLOBAL_MOVIE,
      payload: movie[0],
    });

    setTimeout(() => {
      dispatch({
        type: GLOBAL_LOADING_MOVIE,
        payload: false,
      });
    }, 250);
  }
};

export const changeCinema = (city_id, payload) => (dispatch, getState) => {
  const { global_all_movies } = getState().globalReducer;

  dispatch({
    type: GLOBAL_CINEMA,
    payload,
  });

  let movieList = global_all_movies.filter(
    (item) => parseInt(item.city_id) === parseInt(city_id)
  );

  if (payload === '') {
    dispatch({
      type: GLOBAL_MOVIES,
      payload: movieList[0].movies,
    });
  } else {
    const listMovies = movieList[0].movies;
    let movies = listMovies.map((movie) => {
      const rooms = movie.rooms.filter(
        (room) => parseInt(room.cinema_id) === parseInt(payload)
      );

      if (rooms.length) {
        let showtimes = rooms.map((room) => {
          const showtimes = movie.showtimes.filter(
            (showtime) => parseInt(showtime.room_id) === parseInt(room.id)
          );

          if (showtimes.length) {
            return showtimes;
          }
          return null;
        });
        // Deleted null results
        showtimes = showtimes.filter((showtime) => showtime);

        if (showtimes.length) {
          return movie;
        }
      }
      return null;
    });
    // Deleted null results
    movies = movies.filter((movie) => movie);

    dispatch({
      type: GLOBAL_MOVIES,
      payload: movies,
    });
  }
};

export const moviePerCinemaLoading = (payload) => (dispatch) => {
  dispatch({
    type: GLOBAL_CINEMA_MOVIES_LOADING,
    payload,
  });
};

export const formatDate = (date) => {
  let months = [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sep',
    'Oct',
    'Nov',
    'Dic',
  ];
  let days = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'];
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  let month = date.getMonth();
  let yyyy = date.getFullYear();
  let day = date.getDay();
  mm = mm < 10 ? `0${mm}` : mm;
  dd = dd < 10 ? `0${dd}` : dd;

  return {
    value: `${yyyy}-${mm}-${dd}`,
    name: `${months[month]}, ${days[day]} ${dd}`,
  };
};

export const moviesPerCinema = (slug) => (dispatch, getState) => {
  const {
    global_movies,
    global_movie_slug,
    global_cinemas,
    global_date,
    global_dates,
    global_cinema,
  } = getState().globalReducer;

  const movie_slug = slug ? slug : global_movie_slug;
  const movies = global_movies.filter((movie) => movie.slug === movie_slug);

  dispatch(moviePerCinemaLoading(true));

  let listCinemas = global_cinemas;
  if (global_cinema !== '') {
    listCinemas = global_cinemas.filter(
      (cinema) => parseInt(cinema.id) === parseInt(global_cinema)
    );
  }

  let date_selected = global_date;

  if (!global_dates) {
    const list_showtimes = [];
    // Add showtimes per movie
    movies.map((movie) => {
      movie.rooms.map((room) => {
        if (room.showtimes_list) {
          list_showtimes.push(...room.showtimes_list);
        }
        return room;
      });
      return movie;
    });

    let dates = [];

    // Create list daates per movie
    list_showtimes.map((showtime) => {
      if (dates.indexOf(showtime.fecha_funcion) < 0) {
        dates.push(showtime.fecha_funcion);
      }
      return showtime;
    });

    dates = dates.sort();
    dates = dates.map((date) => {
      const d = new Date(`${date}T01:00:00`);
      return formatDate(d);
    });

    dispatch({
      type: GLOBAL_DATES,
      payload: dates,
    });
    dispatch({
      type: GLOBAL_DATE,
      payload: dates[0].value,
    });
    date_selected = dates[0].value;
  }

  const cinemas = listCinemas.map((cinema) => {
    const cinema_movies = [];
    cinema.rooms.map((room) => {
      movies.map((movie) => {
        let showtimes = movie.showtimes.filter((showtime) => {
          if (
            parseInt(showtime.room_id) === parseInt(room.id) &&
            showtime.fecha_funcion === date_selected
          ) {
            showtime.cinema_id = room.cinema_id;
            return showtime;
          }
          return null;
        });
        showtimes = showtimes.filter((showtime) => showtime);

        if (showtimes.length) {
          room.showtimes = showtimes;
          const movieIsAdded = cinema_movies.filter(
            (item) => item.id === movie.id
          );

          if (movieIsAdded.length) {
            movieIsAdded[0].rooms.push(room);
            movieIsAdded[0].list_showtimes.push(...showtimes);
          } else {
            cinema_movies.push({
              id: movie.id,
              version: movie.version,
              medio: movie.medio,
              formato: movie.formato,
              slug: movie.slug,
              rooms: [room],
              list_showtimes: [...showtimes],
            });
          }
        }
        return null;
      });

      return null;
    });
    cinema.movies = cinema_movies;
    return cinema;
  });

  setTimeout(() => {
    dispatch({
      type: GLOBAL_CINEMA_MOVIES,
      payload: cinemas,
    });
  }, 100);
  setTimeout(() => {
    dispatch(moviePerCinemaLoading(false));
  }, 250);
};

export const changeDate = (payload) => (dispatch) => {
  dispatch({
    type: GLOBAL_DATE,
    payload,
  });
  dispatch(moviesPerCinema());
};

export const changeMovieSlug = (payload) => (dispatch) => {
  dispatch({
    type: GLOBAL_MOVIE_SLUG,
    payload,
  });
};

export const changeMovieId = (payload) => (dispatch) => {
  dispatch({
    type: GLOBAL_MOVIE_ID,
    payload,
  });
};

export const changeMovieCity = (payload) => (dispatch) => {
  dispatch({
    type: GLOBAL_MOVIE_CITY,
    payload,
  });
};

export const movieClear = () => (dispatch) => {
  dispatch({
    type: GLOBAL_MOVIE_CLEAR,
  });
};

export const changeMenuMobile = (payload) => (dispatch) => {
  dispatch({
    type: GLOBAL_MENU_MOBILE,
    payload,
  });
};

export const globalGetCinemas = () => (dispatch) => {
  Api.callMethod('cinemas', 'get', null, (res, status) => {
    if (status === 200) {
      const { data } = res;
      dispatch({
        type: GLOBAL_CINEMA_LIST,
        payload: data,
      });
    }
  });
};

export const getSettings = () => (dispatch) => {
  Api.callMethod('settings', 'get', null, (res, status) => {
    if (status === 200) {
      const { data } = res;
      const card = data.find((item) => item.name === 'card');
      const pse = data.find((item) => item.name === 'pse');
      const payments = data.find((item) => item.name === 'payments');

      dispatch({
        type: GLOBAL_SETTING_CARD,
        payload: card ? parseInt(card.value) : 1,
      });
      dispatch({
        type: GLOBAL_SETTING_PSE,
        payload: pse ? parseInt(pse.value) : 1,
      });
      dispatch({
        type: GLOBAL_SETTING_PAYMENTS,
        payload: payments ? parseInt(payments.value) : 0,
      });
      dispatch({
        type: GLOBAL_SETTINGS,
        payload: data,
      });
    }
  });
};

export const getSite = () => (dispatch) => {
  Api.callMethod('site', 'get', null, (res, status) => {
    if (status === 200) {
      const { movies_active, cities, settings, cine_mas } = res;

      const card = settings.find((item) => item.name === 'card');
      const pse = settings.find((item) => item.name === 'pse');
      const payments = settings.find((item) => item.name === 'payments');
      const popup = settings.find((item) => item.name === 'popup');

      dispatch({
        type: GLOBAL_CITIES,
        payload: cities,
      });

      let steps = 2;
      const WIDTH_WINDOW = window.innerWidth;
      const WIDTH_ROW = movies_active.length * 156;

      if (WIDTH_WINDOW > WIDTH_ROW) {
        let diffSteps = Math.ceil(WIDTH_WINDOW / WIDTH_ROW);
        if (diffSteps !== Infinity) {
          steps = diffSteps;
        }
      }
      dispatch(changeMoviesLoading(false));
      dispatch({
        type: MOVIES_ACTIVE,
        payload: movies_active,
      });
      dispatch({
        type: MOVIES_STEPS,
        payload: steps,
      });

      dispatch({
        type: GLOBAL_SETTING_CARD,
        payload: card ? parseInt(card.value) : 1,
      });
      dispatch({
        type: GLOBAL_SETTING_PSE,
        payload: pse ? parseInt(pse.value) : 1,
      });
      dispatch({
        type: GLOBAL_SETTING_PAYMENTS,
        payload: payments ? parseInt(payments.value) : 0,
      });
      dispatch({
        type: GLOBAL_CINE_MAS,
        payload: cine_mas,
      });

      if (popup && popup.value && popup.value !== '') {
        dispatch(popUpChangeImage(popup.value));
        dispatch(popUpChangeWidth(parseInt(popup.extra)));
        dispatch(popUpChangeVisible('visible'));
      }
    }
  });
};
