import React from 'react';
import { connect } from 'react-redux';
import {
  changeMethod,
  changeName,
  changeCardNumber,
  changeCardMonth,
  changeCardYear,
  changeCardCvc,
  changeCardDues,
  validationCard,
} from '../../../store/actions/PaymentActions';
import { ticketPayToCard } from '../../../store/actions/PayTicketActions';

function CardForm(props) {
  const {
    paymentReducer: {
      payment_name,
      payment_card_number,
      payment_card_month,
      payment_card_year,
      payment_card_cvc,
      payment_card_dues,
      payment_type,
    },
    changeMethod,
    changeName,
    changeCardNumber,
    changeCardMonth,
    changeCardYear,
    changeCardCvc,
    changeCardDues,
    validationCard,
    ticketPayToCard,
  } = props;

  const dues = () => {
    const dues = [];
    for (let i = 1; i <= 36; i++) {
      dues.push(
        <option value={i} key={i}>
          {i}
        </option>
      );
    }
    return dues;
  };

  return (
    <>
      <div className="PaymentMethods_header">
        <button
          className="PaymentMethods_back"
          type="button"
          onClick={() => changeMethod('')}
        >
          <i className="fas fa-chevron-left" />
          Cambiar medio de pago
        </button>
      </div>
      <div className="PaymentMethods_form">
        <span className="PaymentMethods_step">Paso 2 de 2</span>
        <h5>Información de la tarjeta</h5>
        <div className="PaymentMethods_box-input">
          <i className="fas fa-user" />
          <input
            type="text"
            placeholder="Nombre como aparece en la tarjeta"
            value={payment_name}
            onChange={(input) => changeName(input.target.value)}
          />
        </div>
        <div className="PaymentMethods_box-input">
          <i className="far fa-credit-card" />
          <input
            type="number"
            min="0"
            placeholder="Número de la tarjeta"
            value={payment_card_number}
            onChange={(input) => changeCardNumber(input.target.value)}
          />
        </div>
        <div className="PaymentMethods_row-input">
          <div className="PaymentMethods_box-input">
            <i className="far fa-calendar" />
            <input
              type="number"
              placeholder="MM"
              maxLength={2}
              max="3"
              className="PaymentMethods_month"
              value={payment_card_month}
              onChange={(input) => changeCardMonth(input.target.value)}
            />
            <span>/</span>
            <input
              type="number"
              placeholder="AAAA"
              maxLength={4}
              value={payment_card_year}
              onChange={(input) => changeCardYear(input.target.value)}
            />
          </div>
          <div className="PaymentMethods_box-input">
            <input
              type="password"
              placeholder="CVC"
              maxLength={4}
              value={payment_card_cvc}
              onChange={(input) => changeCardCvc(input.target.value)}
            />
          </div>
          <div className="PaymentMethods_box-input">
            <select
              value={payment_card_dues}
              onChange={(input) => changeCardDues(input.target.value)}
            >
              <option value="">Cuotas</option>
              {dues()}
            </select>
          </div>
        </div>
      </div>
      <button
        onClick={payment_type !== 6 ? validationCard : ticketPayToCard}
        type="button"
        className="PaymentMethods_continue"
      >
        CONTINUAR
      </button>
    </>
  );
}

const mapStatToProps = ({ paymentReducer }) => {
  return {
    paymentReducer,
  };
};

const mapDisptachToProps = {
  changeMethod,
  changeName,
  changeCardNumber,
  changeCardMonth,
  changeCardYear,
  changeCardCvc,
  changeCardDues,
  validationCard,
  ticketPayToCard,
};

export default connect(mapStatToProps, mapDisptachToProps)(CardForm);
