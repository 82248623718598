import React from 'react';
import './PageLoader.css';
import loading from '../../assets/images/loading-red.svg';

function PageLoader() {
  return (
    <div className="PageLoader">
      <img src={loading} alt="Page Loader" />
    </div>
  );
}

export default PageLoader;
