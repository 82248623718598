import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { changeVisible, getBanks } from '../../store/actions/PaymentActions';
import { withRouter } from 'react-router-dom';
import { globalGetCinemas } from '../../store/actions/GlobalActions';
import SelectMethod from './SelectMethod';
import CardForm from './CardForm';
import PSEForm from './PSEForm';
import epayco from '../../assets/images/epayco-white.png';
import HeaderMovie from './HeaderMovie';
import HeaderCoupon from './HeaderCoupon';
import HeaderSubscription from './HeaderSubscription';
import HeaderRecharge from './HeaderRecharge';
import './PaymentMethods.css';
import HeaderConfectionery from './HeaderConfectionery';
import HeaderTicket from './HeaderTicket';

class PaymentMethods extends Component {
  componentDidMount() {
    const {
      globalGetCinemas,
      globalReducer: { global_cinema_list },
      paymentReducer: { payment_banks },
      getBanks,
    } = this.props;

    if (!global_cinema_list.length) {
      globalGetCinemas();
    }
    if (!payment_banks.length) {
      getBanks();
    }
  }

  render() {
    const {
      paymentReducer: { payment_visible, payment_method, payment_type },
      changeVisible,
    } = this.props;

    return ReactDOM.createPortal(
      <div className={`PaymentMethods ${payment_visible}`}>
        <div className="PaymentMethods_container">
          <div className="PaymentMethods_content">
            <button
              type="button"
              className="PaymentMethods_close"
              onClick={() => changeVisible('')}
            >
              &times;
            </button>
            {payment_type === 1 && payment_visible === 'visible' && (
              <HeaderMovie />
            )}
            {payment_type === 2 && payment_visible === 'visible' && (
              <HeaderCoupon />
            )}
            {payment_type === 3 && payment_visible === 'visible' && (
              <HeaderSubscription />
            )}
            {payment_type === 4 && payment_visible === 'visible' && (
              <HeaderRecharge />
            )}
            {payment_type === 5 && payment_visible === 'visible' && (
              <HeaderConfectionery />
            )}
            {payment_type === 6 && payment_visible === 'visible' && (
              <HeaderTicket />
            )}
            {payment_method === '' && <SelectMethod />}
            {payment_method === 'card' && <CardForm />}
            {payment_method === 'pse' && <PSEForm />}
          </div>
          <div className="PaymentMethods_footer">
            <i className="fas fa-lock" />
            Pago seguro por
            <img src={epayco} alt="Logo Epayco" />
          </div>
        </div>
      </div>,
      document.getElementById('modal-payment')
    );
  }
}

const mapStateToProps = ({
  paymentReducer,
  checkoutReducer,
  globalReducer,
}) => {
  return {
    paymentReducer,
    checkoutReducer,
    globalReducer,
  };
};

const mapDispatchToProps = {
  changeVisible,
  globalGetCinemas,
  getBanks,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentMethods)
);
