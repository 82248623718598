/* eslint-disable import/no-anonymous-default-export */
import { TICKET_CLEAR, TICKET_DATA, TICKET_LOADING } from '../constants/Ticket';

const INITIAL_STATE = {
  ticket_loading: true,
  ticket: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TICKET_CLEAR:
      return {
        ...state,
        ticket_loading: true,
        ticket: null,
      };
    case TICKET_DATA:
      return {
        ...state,
        ticket: action.payload,
      };
    case TICKET_LOADING:
      return {
        ...state,
        ticket_loading: action.payload,
      };
    default:
      return { ...state };
  }
};
