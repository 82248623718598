import React from 'react';
import { connect } from 'react-redux';
import PageLoader from '../PageLoader';
import SelectCity from '../SelectCity';
import './HeaderCity.css';
import SliderMovies from './SliderMovies';
import SliderPublish from './SliderPublish';

function HeaderCity(props) {
  const {
    globalReducer: { global_loading_movies },
  } = props;

  return (
    <div className="HeaderCity-content vertical-gradient">
      {global_loading_movies ? (
        <PageLoader />
      ) : (
        <div className="HeaderCity-sliders">
          <SliderPublish />
          <SliderMovies />
        </div>
      )}
      <div className="HeaderCity-select">
        <SelectCity type="select" />
      </div>
    </div>
  );
}

const mapStateToProps = ({ globalReducer }) => {
  return {
    globalReducer,
  };
};

export default connect(mapStateToProps, null)(HeaderCity);
