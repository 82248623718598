import React from 'react';
import { withRouter } from 'react-router-dom';
import GeneralCart from '../components/Confectionery/Cart/GeneralCart';
import Footer from '../components/Footer';
import Header from '../components/Header';

function DefaultLayout(props) {
  const {
    location: { pathname },
  } = props;
  let isConfectionery =
    (pathname && pathname.indexOf('confiteria') >= 0) ||
    pathname.indexOf('showtime') >= 0
      ? true
      : false;

  return (
    <>
      <Header type="black" menu={true} />
      {!isConfectionery && <GeneralCart />}
      {props.children}
      <Footer />
    </>
  );
}

export default withRouter(DefaultLayout);
