import React from 'react';
import './MakeByBeit.css';

function MakeByBeit() {
  let year = new Date();
  year = year.getFullYear();

  return (
    <div className="MakeByBeit">
      Made with ❤ by{' '}
      <a href="https://believeitgroup.com/" target="_blank" rel="noreferrer">
        Believe!
      </a>{' '}
      © Copyright PROCINAL-{year}. All Rights Reserved.&#8203;
    </div>
  );
}

export default MakeByBeit;
