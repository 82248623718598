import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { getCityName } from '../../functions/currentFunctions';
import { getCinemas } from '../../store/actions/GlobalActions';
import SectionTitle from '../SectionTitle';
import './TheaterList.css';

class TheaterList extends Component {
  render() {
    const {
      globalReducer: { global_cinemas, global_city, global_cities },
      limit,
      match: {
        params: { city },
      },
    } = this.props;
    const cityName = getCityName(global_cities, global_city);
    const limitNumber = limit ? limit : global_cinemas.length;

    return (
      <div className="TheaterList_container">
        <SectionTitle title="Teatros" subtitle={cityName} />
        <div className="container">
          <div className="row TheaterList_cinemas">
            {global_cinemas.map((cinema, index) => {
              if (index < limitNumber) {
                return (
                  <div className="col-md-4" key={cinema.id}>
                    <Link
                      to={`/ciudad/${city}/teatros/${cinema.nombre}`}
                      className="TheaterList_cinema"
                    >
                      <img src={cinema.imagen} alt={cinema.nombre} />
                      <div className="TheaterList_name">
                        {cinema.nombre_completo}
                      </div>
                      <div className="TheaterList_address">
                        {cinema.direccion}
                      </div>
                    </Link>
                  </div>
                );
              }
              return null;
            })}
          </div>
          {limit && global_cinemas.length > limit && (
            <Link
              to={`/ciudad/${global_city}/teatros`}
              className="TheaterList_link"
            >
              Ver más
            </Link>
          )}
        </div>
      </div>
    );
  }
}

TheaterList.defaultProps = {
  limit: null,
};

const mapStateToProps = ({ globalReducer }) => {
  return {
    globalReducer,
  };
};

const mapDispatchToProps = {
  getCinemas,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TheaterList)
);
