import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './Coupons.css';

function Coupons(props) {
  const {
    couponReducer: { coupon_list },
  } = props;

  return (
    <div className="Coupons_content">
      <h1>CUPONES</h1>
      {coupon_list.map((coupon, index) => (
        <div className="Coupons_item" key={index}>
          <img src={coupon.image} alt={coupon.name} />
          <div className="Coupons_hover">
            <Link to={`/cupon/${coupon.id}`}>Ver Cupon</Link>
            {coupon.name}
          </div>
        </div>
      ))}
    </div>
  );
}

const mapStateToProps = ({ couponReducer }) => {
  return {
    couponReducer,
  };
};

export default connect(mapStateToProps, null)(Coupons);
