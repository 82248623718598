import Header from '../../components/Header';
import Home from '../../components/Home';

function HomePage() {
  return (
    <>
      <Header type="black" />
      <Home />
    </>
  );
}

export default HomePage;
