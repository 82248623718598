import React from 'react';
import MenuProfile from '../components/MenuProfile';
import DefaultLayout from './DefaultLayout';

function ProfileLayout(props) {
  return (
    <DefaultLayout>
      <div className="container">
        <MenuProfile />
        <div className="Profile">{props.children}</div>
      </div>
    </DefaultLayout>
  );
}

export default ProfileLayout;
