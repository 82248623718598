export const getCinemaName = (name) => {
  const cinemas = [
    { slug: 'salitre', name: 'Salitre Plaza' },
    { slug: 'bima', name: 'Bima' },
    { slug: 'palatino', name: 'Palatino' },
    { slug: 'americas', name: 'Plaza de las Américas' },
    { slug: 'bulevar', name: 'Bulevar Niza' },
    { slug: 'plazuela', name: 'La Plazuela' },
    { slug: 'occidente', name: 'Unicentro de Occidente' },
    { slug: 'suba', name: 'Centro Suba' },
    { slug: 'tunal', name: 'Ciudad Tunal' },
    { slug: 'unisur', name: 'Unisur' },
    { slug: 'tintal', name: 'Tintal Plaza' },
    { slug: 'villavo', name: 'Unicentro' },
    { slug: 'terminal', name: 'Terminal del Sur' },
    { slug: 'sabana', name: 'Viva Fontibón' },
    { slug: 'iwana', name: 'Viva Iwana' },
    { slug: 'deluxe', name: 'Auticinema DELUXE' },
    { slug: 'imax', name: 'IMAX - Plaza de las Américas' },
  ];

  const cinema = cinemas.find((cinema) => cinema.slug === name);
  return cinema ? cinema.name : name;
};

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    let fileReader = new FileReader();
    fileReader.onload = function () {
      return resolve({
        data: fileReader.result,
        name: file.name,
        size: file.size,
        type: file.type,
      });
    };
    fileReader.readAsDataURL(file);
  });
};

export const getSetting = (settings, name) => {
  const setting = settings.find((item) => item.name === name);

  return setting ? setting.value : null;
};
