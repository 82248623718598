import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  contentLoading,
  contentDetail,
  contentGetDetail,
} from '../../store/actions/ContentActions';
import Error404 from '../Error404';
import PageLoader from '../PageLoader';
import './Content.css';

class Content extends Component {
  componentDidMount() {
    const {
      match: {
        params: { slug },
      },
      contentGetDetail,
    } = this.props;
    contentGetDetail(slug);
  }

  componentDidUpdate(oldProps) {
    const {
      match: {
        params: { slug: oldSlug },
      },
    } = oldProps;
    const {
      match: {
        params: { slug },
      },
      contentGetDetail,
    } = this.props;

    if (oldSlug !== slug) {
      contentGetDetail(slug);
    }
  }

  componentWillUnmount() {
    const { contentLoading, contentDetail } = this.props;
    contentLoading(true);
    contentDetail(null);
  }

  render() {
    const {
      contentReducer: { content_detail, content_loading },
    } = this.props;

    if (content_loading || !content_detail) {
      return <PageLoader />;
    }

    if (content_detail.title === '404') {
      return <Error404 />;
    }

    return (
      <div className="Content-container">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <h1 className="Content-title">{content_detail.title}</h1>
              {content_detail.subtitle && (
                <h2 className="Content-subtitle">{content_detail.subtitle}</h2>
              )}
              <div
                className="Content-content"
                dangerouslySetInnerHTML={{ __html: content_detail.content }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ contentReducer }) => {
  return {
    contentReducer,
  };
};

const mapDispatchToProps = {
  contentLoading,
  contentDetail,
  contentGetDetail,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Content)
);
