import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  changeRoom,
  addOrRemoveChair,
  changePopup,
} from '../../../store/actions/CheckoutActions';
import PageLoader from '../../PageLoader';
import './Room.css';

class Room extends Component {
  renderChairs = () => {
    const {
      checkoutReducer: {
        checkout_chairs,
        checkout_room,
        checkout_room_status,
        checkout_location,
      },
      addOrRemoveChair,
    } = this.props;

    if (
      !checkout_room ||
      !checkout_chairs ||
      !checkout_room_status ||
      !checkout_location
    ) {
      return null;
    }

    const {
      ColumnaRelativa,
      ColumnaTotal,
      FilaRelativa,
      FilaTotal,
      TipoSilla,
      TipoZona,
    } = checkout_room;

    let room = [];
    const location = checkout_location;

    FilaTotal.map((row, index) => {
      const isAdded = room.filter((item) => item.row === row);
      if (isAdded.length) {
        isAdded[0].columns.push({
          index,
          ColumnaRelativa: ColumnaRelativa[index],
          ColumnaTotal: ColumnaTotal[index],
          FilaRelativa: FilaRelativa[index],
          TipoSilla: TipoSilla[index],
          TipoZona: TipoZona[index],
        });
      } else {
        room.push({
          row,
          columns: [
            {
              index,
              ColumnaRelativa: ColumnaRelativa[index],
              ColumnaTotal: ColumnaTotal[index],
              FilaRelativa: FilaRelativa[index],
              TipoSilla: TipoSilla[index],
              TipoZona: TipoZona[index],
            },
          ],
        });
      }
      return null;
    });

    let minWidth = 0;

    if (room[0].row === 'A') {
      room = room.sort((a, b) => b.row.charCodeAt(0) - a.row.charCodeAt(0));
    }

    const response = room.map((row, index) => {
      const width = 35 * row.columns.length;
      minWidth = width > minWidth ? width : minWidth;
      let listStatus = checkout_room_status.filter(
        (item) => item.filRel === row.columns[0].FilaRelativa
      );
      listStatus = listStatus.length ? listStatus[0] : null;

      return (
        <div key={index} className="Room_row" style={{ width, minWidth }}>
          {row.columns.map((column) => {
            if (column.TipoSilla === 'pasillo') {
              return <div className="Room_hall" key={column.index} />;
            }
            if (column.TipoZona.toUpperCase() !== location.zona.toUpperCase()) {
              return (
                <div className="Room_chair" key={column.index}>
                  <div className={`Room_chairname`}>
                    {column.FilaRelativa}-{column.ColumnaRelativa}
                  </div>
                  <div className={`Room_button opacity`} />
                </div>
              );
            }
            let status = '';
            if (listStatus) {
              status = listStatus.DescripcionSilla.find(
                (item) =>
                  parseInt(item.Columna) === parseInt(column.ColumnaRelativa)
              );
              status = status ? status.EstadoSilla : '';
            }

            const selected =
              checkout_chairs.indexOf(column.index) >= 0 || status === 'R'
                ? 'selected'
                : '';

            const renderButton = status === 'S' || status === 'R';
            const chairBlocked = renderButton ? '' : 'blocked';

            return (
              <div className="Room_chair" key={column.index}>
                <div className={`Room_chairname ${chairBlocked}`}>
                  {column.FilaRelativa}-{column.ColumnaRelativa}
                </div>
                {renderButton ? (
                  <button
                    type="button"
                    className={`Room_button ${selected}`}
                    onClick={() => addOrRemoveChair(column.index)}
                  />
                ) : (
                  <div className={`Room_button disabled`} />
                )}
              </div>
            );
          })}
        </div>
      );
    });

    response.push(
      <div style={{ width: minWidth }} key="screen">
        <div className="Room_screen">
          <div className="Room_content-screen">
            <span>PANTALLA</span>
          </div>
        </div>
      </div>
    );

    return response;
  };

  render() {
    const {
      checkoutReducer: { checkout_room },
    } = this.props;

    if (!checkout_room) {
      return <PageLoader />;
    }

    return (
      <div className="Room">
        <div className="Room_arrows">
          <i className="fas fa-chevron-left"></i>
          <i className="fas fa-chevron-right"></i>
        </div>
        <div className="Room_container">{this.renderChairs()}</div>
      </div>
    );
  }
}

const mapStateToProps = ({ checkoutReducer }) => {
  return {
    checkoutReducer,
  };
};

const mapDispatchToProps = {
  changeRoom,
  addOrRemoveChair,
  changePopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(Room);
