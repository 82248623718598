export const PAYMENT_VISIBLE = 'PAYMENT_VISIBLE';
export const PAYMENT_METHOD = 'PAYMENT_METHOD';
export const PAYMENT_NAME = 'PAYMENT_NAME';
export const PAYMENT_CARD_NUMBER = 'PAYMENT_CARD_NUMBER';
export const PAYMENT_CARD_MONTH = 'PAYMENT_CARD_MONTH';
export const PAYMENT_CARD_YEAR = 'PAYMENT_CARD_YEAR';
export const PAYMENT_CARD_CVC = 'PAYMENT_CARD_CVC';
export const PAYMENT_CARD_DUES = 'PAYMENT_CARD_DUES';
export const PAYMENT_PSE_TYPE_PERSON = 'PAYMENT_PSE_TYPE_PERSON';
export const PAYMENT_PSE_BANK = 'PAYMENT_PSE_BANK';
export const PAYMENT_PSE_NAMEANDLASTNAME = 'PAYMENT_PSE_NAMEANDLASTNAME';
export const PAYMENT_PSE_DOC_TYPE = 'PAYMENT_PSE_DOC_TYPE';
export const PAYMENT_PSE_DOC_NUMBER = 'PAYMENT_PSE_DOC_NUMBER';
export const PAYMENT_PSE_EMAIL = 'PAYMENT_PSE_EMAIL';
export const PAYMENT_PSE_PHONE = 'PAYMENT_PSE_PHONE';
export const PAYMENT_CLEAR = 'PAYMENT_CLEAR';
export const PAYMENT_CLEAR_FORMS = 'PAYMENT_CLEAR_FORMS';
export const PAYMENT_REDIRECT = 'PAYMENT_REDIRECT';
export const PAYMENT_STATUS = 'PAYMENT_STATUS';
export const PAYMENT_STATUS_LOADING = 'PAYMENT_STATUS_LOADING';
export const PAYMENT_BANKS = 'PAYMENT_BANKS';
export const PAYMENT_RESPONSE = 'PAYMENT_RESPONSE';
export const PAYMENT_TYPE = 'PAYMENT_TYPE';
