import React from 'react';
import Benefits from '../../components/Benefits';
import HowToBuy from '../../components/HowToBuy';
import TheaterList from '../../components/TheaterList';
import DefaultLayout from '../../Layouts/DefaultLayout';

function TheatersPage() {
  return (
    <DefaultLayout>
      <TheaterList />
      <HowToBuy />
      <Benefits />
    </DefaultLayout>
  );
}

export default TheatersPage;
