import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeVisible } from '../../../store/actions/PaymentActions';
import { cinemasGoToPay } from '../../../store/actions/CinemasActions';
import { getCinemaName } from '../../../Utils/currentActions';
import { getBanks } from '../../../store/actions/PaymentActions';
import epayco from '../../../assets/images/epayco-white.png';
import './Payment.css';

class Payment extends Component {
  componentDidMount() {
    const {
      paymentReducer: { payment_banks },
      getBanks,
    } = this.props;

    if (!payment_banks.length) {
      getBanks();
    }
  }

  render() {
    const {
      checkoutReducer: {
        checkout_total_tikets,
        checkout_total_transaction,
        checkout_total,
        checkout_movie,
        checkout_showtime,
        checkout_pay_option,
        checkout_other_price,
      },
      globalReducer: { global_cinemas },
      changeVisible,
      cinemasGoToPay,
    } = this.props;
    const numberFormat = new Intl.NumberFormat('en-ES');

    let cinema = [];
    const room = checkout_movie.rooms.filter(
      (room) => parseInt(room.id) === parseInt(checkout_showtime.room_id)
    );
    if (room.length) {
      cinema = global_cinemas.filter(
        (cinema) => parseInt(cinema.id) === parseInt(room[0].cinema_id)
      );
    }
    cinema = cinema.length ? cinema[0].nombre : '';

    return (
      <>
        <div className="Payment_resume">
          <h4>DETALLE COMPRA</h4>
          <div className="Payment_cinema">
            <strong>TEATRO: </strong>
            {getCinemaName(cinema)}
          </div>
          <div className="Payment_prices">
            <div className="Payment_price-row">
              <div>VALOR TAQUILLA:</div>
              <div>${numberFormat.format(checkout_total_tikets)}</div>
            </div>
            <div className="Payment_price-row">
              <div>CARGO POR SERVICIO:</div>
              <div>${numberFormat.format(checkout_total_transaction)}</div>
            </div>
          </div>
          <div className="Payment_price-row Payment_price-total">
            <div className="color-red">
              <strong>TOTAL A PAGAR:</strong>
            </div>
            <div className="color-red">
              <strong>${numberFormat.format(checkout_total)}</strong>
            </div>
          </div>
          {checkout_other_price > 0 && (
            <div className="Payment_price-row Payment_price-other">
              <div>
                {checkout_pay_option === 'Deshabilitado' ? (
                  <>
                    CON <strong className="color-red">CINE+</strong> HABRÍAS
                    AHORRADO
                  </>
                ) : (
                  <>
                    CON <strong className="color-red">CINE+</strong> AHORRASTE
                  </>
                )}
              </div>
              <div>
                <strong>${numberFormat.format(checkout_other_price)}</strong>
              </div>
            </div>
          )}
          {checkout_pay_option === 'Deshabilitado' ? (
            <button
              type="button"
              className="Payment_button"
              onClick={() => changeVisible('visible')}
            >
              Pagar con
              <img src={epayco} alt="Logo Epayco" />
            </button>
          ) : (
            <button
              type="button"
              className="Payment_button"
              onClick={() => cinemasGoToPay()}
            >
              Pagar con<strong>CINE+</strong>
            </button>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = ({
  checkoutReducer,
  globalReducer,
  paymentReducer,
}) => {
  return {
    checkoutReducer,
    globalReducer,
    paymentReducer,
  };
};

const mapDispatchToProps = {
  changeVisible,
  cinemasGoToPay,
  getBanks,
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
