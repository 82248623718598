import React from 'react';
import { connect } from 'react-redux';
import { changeMethod } from '../../../store/actions/PaymentActions';
import pse from '../../../assets/images/pse.png';

function SelectMethod(props) {
  const {
    globalReducer: { global_setting_card, global_setting_pse },
    changeMethod,
  } = props;

  return (
    <div>
      <div className="PaymentMethods_form">
        <span className="PaymentMethods_step">Paso 1 de 2</span>
        <h5>Seleccione el medio de pago</h5>
        {global_setting_card ? (
          <button
            type="button"
            className="PaymentMethods_select"
            onClick={() => changeMethod('card')}
          >
            <i className="far fa-credit-card" />
            Tarjeta de Crédito
          </button>
        ) : null}
        {global_setting_pse ? (
          <button
            type="button"
            className="PaymentMethods_select"
            onClick={() => changeMethod('pse')}
          >
            <img src={pse} alt="PSE" />
            Cuenta de ahorro y corriente
          </button>
        ) : null}
      </div>
    </div>
  );
}

const mapStateToProps = ({ globalReducer }) => {
  return {
    globalReducer,
  };
};

const mapDisptachToProps = {
  changeMethod,
};

export default connect(mapStateToProps, mapDisptachToProps)(SelectMethod);
