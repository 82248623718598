import React from 'react';
import { Link } from 'react-router-dom';
import confiteria from '../../assets/images/confiteria.jpg';

function Confectionery(props) {
  const { item } = props;
  const cod_response = parseInt(item.codigo_respuesta);
  const bill = item.factura;
  const type = item.medio_pago === 'PSE' ? 'pse' : 'card';

  return (
    <div className="col-md-3 Tickets_item">
      <img src={confiteria} alt="Confitería" />
      <div className="Tickets_item-content">
        <h3>Confitería</h3>
        {cod_response === 1 && (
          <Link to={`/tickets/${bill}`} className="Tickets_btn code">
            Ver Código QR
          </Link>
        )}
        {cod_response === 3 && (
          <Link
            to={`/payment/response/${type}/${bill}`}
            className="Tickets_btn status"
          >
            Validar estado de pago
          </Link>
        )}
        {cod_response === 2 && (
          <div className="Tickets_btn">Transancción rechazada</div>
        )}
      </div>
    </div>
  );
}

export default Confectionery;
